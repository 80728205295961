import * as React from 'react';
import { MCP_PREVENTIVES_SPEC } from 'modules/preventives/utils/constants';

export const MCP_LOGS_JOYRIDE_STEPS: any[] = [
  {
    target: '.joyride__filter-search',
    content: (
      <p>
        Você pode filtrar os logs por período ou, utilizando o campo de busca
        padrão, por ação efetuada e usuário.
      </p>
    ),
    title: 'Histórico',
    locale: {
      last: <span>Finalizar</span>,
      back: <span>Anterior</span>,
      skip: <span>Pular</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
];

export const submenuItens = [
  {
    key: 'service',
    name: 'Serviços',
    to: '/preventives',
  },
  {
    key: 'history',
    name: 'Histórico',
    to: '/preventives/history',
  },
];

export const MCP_LOGS_PREVENTIVES = MCP_PREVENTIVES_SPEC.map(
  (category) => category.preventivesList
).reduce((agg, current) => agg.concat(current), []);

export const getAwsRegionName = (regionList: string[][], regionId: string) => {
  const region = regionList.find((region) => region[0] === regionId);
  return (region && region[1]) || 'Desconhecida';
};
