import * as React from 'react';
import EmptyPage from 'modules/common/components/EmptyState';
import image from 'modules/common/images/empty_worklog.png';

const EmptyState = () => (
  <EmptyPage
    title="Bilhetagem"
    description="Histórico detalhado de horas contratadas e consumidas em projetos de Consultoria, Implantação, Migração e Sustentação."
    image={image}
    readMoreURL="https://platform.mandic.com.br/portfolio/projetos-ageis/"
    hireURL="https://br.claranet.com/contatos?pgc=portal.cliente"
  />
);

export default EmptyState;
