import { styled, effects } from '@cmp/ui';
import { IStyledProps } from 'modules/layout/types';
import theme from 'modules/common/styles/theme';
import { paletteColor } from 'modules/common/styles/colors';

const Fake = styled.div`
  border-radius: 3px;
  width: ${(props: IStyledProps) => props.width}px;
  height: ${(props: IStyledProps) => props.height}px;
  margin-bottom: ${(props: IStyledProps) => props.mb}px;
  margin-right: ${(props: IStyledProps) => props.mr}px;
  background-color: ${paletteColor.info.fill};
  background: linear-gradient(
    270deg,
    ${paletteColor.info.fill},
    ${paletteColor.info.background},
    ${paletteColor.info.fill}
  );
  background-size: 200% 100%;
  ${effects.loader};
`;

export const BoxContainer = styled.div`
  margin-top: 50px;
`;

const CardTask = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  width: 630px;
  height: 56px;
  background: ${paletteColor.primary.white};
  border-radius: 3px;
  margin-bottom: 8px;
  padding: 18px 14px;
  box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
  ${effects.slideUp};
  &:before {
    content: '';
    position: absolute;
    background: ${paletteColor.primary.main};
    width: 3px;
    height: 0;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0 5px 5px 0;
    transition: height 250ms;
  }
  & .task-issue-key {
    font-size: 14px;
    font-weight: 500;
    color: ${paletteColor.info.text};
    padding-right: 10px;
    white-space: nowrap;
  }
  & .task-issue-summary {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }
  & .task-pull-right {
    display: flex;
    align-items: center;
    margin-left: auto;
    & .task-time-spent,
    & .task-started {
      display: block;
      font-weight: 500;
      font-size: 12px;
      padding: 6px 8px;
      color: ${paletteColor.info.soft};
      border-radius: 3px;
      background: ${paletteColor.info.fill};
    }
    & .task-time-spent {
      margin-right: 16px;
    }
    & .task-icon-content {
      position: relative;
      color: ${paletteColor.info.soft};
      padding-left: 16px;
      margin-left: 16px;
      height: 24px;
      border-left: 1px solid ${paletteColor.info.border};
      display: none;
    }
  }
  &:hover {
    &:before {
      height: 24px;
    }
    & .task-pull-right {
      & .task-icon-content {
        display: block;
        & .task-icon-arrow {
          ${effects.move}
        }
      }
      & .task-time-spent,
      & .task-started {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 124px;
    display: block;
    padding: 14px;
    margin-bottom: 16px;

    & .task-pull-right {
      & .task-time-spent,
      & .task-started {
        padding: 4px 8px;
      }
    }

    & .task-issue-summary {
      margin-bottom: 5px;
      height: 40px;
      display: flex;
      align-items: center;
      white-space: normal;
    }
  }
`;

const CardTaskContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;

  ${theme.breakpoints.down('sm')} {
    display: block;
  }
`;

const BoxWeek = styled.div`
  display: flex;
  padding: 16px 0;
  width: 100%;
  max-width: 620px;
  & .week-title,
  & .week-date {
    color: ${paletteColor.info.soft};
    font-size: 14px;
    font-weight: 500;
  }
  & .week-date {
    margin-left: auto;
  }
`;

export const SelectContainer = styled.div`
  ${({ projectSelected }) => {
    if (projectSelected && projectSelected.category === 'pontual') {
      return `
        @media only screen and (min-width: 1480px) {
          margin-left: calc((100% - 1240px) / 2);
        }
      `;
    }
  }}
`;

export const Styled = {
  Fake,
  CardTask,
  CardTaskContent,
  BoxWeek,
};

export default Styled;
