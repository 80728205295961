import gql from 'graphql-tag';

const epicIssues = gql`
  query epicIssues($workspace: String!, $project: String!) {
    issues(
      filter: { workspace: $workspace, project: $project, type: epic }
      page: { size: 1000 }
    ) {
      results {
        id
        key
        issuetype {
          name
          id
        }
        status {
          name
          id
        }
        summary
        startdate
        duedate
        progress
      }
    }
  }
`;

const projects = gql`
  query projects($workspace: String!) {
    projects(workspace: $workspace) {
      key
    }
  }
`;

export default {
  epicIssues,
  projects,
};
