import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import apolloClient from './apolloClient';
import Routes from './routes';
import './modules/common/styles/App.css';

const App = () => (
  <ApolloProvider client={apolloClient}>
    <Routes />
  </ApolloProvider>
);

export default App;
