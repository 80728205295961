import config from './utils/config';
import { getItem, setItem, removeItem } from './utils/storage';

const loginUrl = config.endpoints.PAINEL_MANDIC;
loginUrl && console.log(loginUrl);
export const login = () => {
  const currentUrl = window.location.href;

  window.location.href = `${loginUrl}account/logon?ReturnUrl=${encodeURIComponent(
    currentUrl
  )}`;
};

export const logout = async () => {
  removePersonalData();

  await fetch(`${loginUrl}account/logoff`, {
    method: 'POST',
    mode: 'no-cors',
    credentials: 'include',
  }).then(() => {
    window.location.href = loginUrl;
  });
};

export const getWorkspace = () => getItem('workspace', 'localStorage');

export const setWorkspace = (workspaceCode: string) => {
  setItem('workspace', workspaceCode, 'localStorage');
};

export const removePersonalData = () => {
  removeItem('workspace', 'localStorage');
  removeItem('mcpWorkspaceData', 'localStorage');
};
