const currentWorkspaces = `
  query currentWorkspaces {
		loggedUser{
			id
			name
			cd
			email
			username
		}
    workspaces {
      code
      name
      theme
      log
      company {
        id
        name
      }
      planType
      jiraProject
    }
  }
`;

export default {
  currentWorkspaces,
};
