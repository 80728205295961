import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BoardContainer, ListContainer } from 'modules/support/containers';
import { StatusContainer } from '../status/containers';

const routes = (props) => (
  <Switch>
    <Route path={props.match.path} exact={true} component={BoardContainer} />
    <Route
      path={`${props.match.path}/list`}
      exact={true}
      component={ListContainer}
    />
    <Route path="*" component={StatusContainer} />
  </Switch>
);

export default routes;
