import * as React from 'react';
import * as S from './styled';
import { Link } from 'react-router-dom';

interface IProps {
  selected: string;
}

export default function TableAndGraph(props: IProps) {
  return (
    <>
      <S.NavButtonGroup>
        <S.NavButton
          component={Link}
          to="/schedule/table"
          selected={props.selected === 'table'}
        >
          Tabela
        </S.NavButton>
        <S.NavButton
          component={Link}
          to="/schedule/graph"
          selected={props.selected === 'graph'}
        >
          Gráfico
        </S.NavButton>
      </S.NavButtonGroup>
    </>
  );
}
