import { EmptyState } from '@cmp/ui';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import client from 'apolloClient';
import gql from 'graphql-tag';
import { ReactComponent as IconAlert } from 'modules/common/icons/icon-alert.svg';
import { IRouterProps } from 'modules/common/types';
import moment from 'moment';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import BillingCalendarContainer from '../components/BillingCalendarContainer';
import Board from '../components/Board';
import DetailsDrawer from '../components/DetailsDrawer';
import { ReactComponent as EmptyImage } from 'modules/common/images/empty_board.svg';
import Typography from '@material-ui/core/Typography';
import * as S from '../components/Styled';
import { queries } from '../graphql';
import {
  Billing,
  GetBillingServicesForMonth,
  GetLastYearBillingForService,
  GetLastYearBillingForWorkspace,
  GetServiceBillingByRegionForWorkspace,
  Group,
  IDrawerPayload,
  IServiceList,
  ISummary,
  ResultByTime,
  IAwsAccount,
} from '../types';
import EmptyPage from '../components/EmptyState';
import { withAppContext } from 'modules/common/hoc/withAppContext';
import { IWorkspace } from 'modules/auth/types';
import { MCP_BILLING_JOYRIDE_STEPS } from '../utils/constants';

const errorCodes = {
  billingDisabled: 'AWS_BILLING_DISABLED',
  billingNotConfigured: 'Error: Workspace does not have an ARN Role registred',
};
interface IProps extends IRouterProps {
  workspace: IWorkspace;
  stepsJoyride?: any[];
  setStepsJoyride: (stepsJoyride: any[]) => void;
}

interface IState {
  selectedService: number | null;
  drawerStatus?: boolean;
  drawerData: IDrawerPayload[];
  drawerChartData: ISummary[];
  drawerRef: React.RefObject<HTMLDivElement>;
  dateSelected: string;
  filterWord: string;
  drawerFilterWord: string;
  loading?: boolean;
  billingUnit?: string;
  lastYearBillingData?: Billing;
  serviceBillingData?: Billing | null;
  run: boolean;
  wrapperRef: any;
  account?: string;
  awsAccounts: IAwsAccount[] | undefined;
}
class BillingContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dateSelected: moment().format('YYYY-MM'),
      selectedService: null,
      drawerStatus: false,
      drawerData: [],
      drawerChartData: [],
      filterWord: '',
      drawerFilterWord: '',
      drawerRef: React.createRef(),
      loading: true,
      billingUnit: 'USD',
      lastYearBillingData: undefined,
      serviceBillingData: undefined,
      run: false,
      wrapperRef: React.createRef(),
      account: undefined,
      awsAccounts: undefined,
    };
  }

  componentDidMount() {
    const { stepsJoyride, setStepsJoyride } = this.props;

    this.getAccounts().then((awsAccounts) => {
      this.setState({ awsAccounts });
    });

    if (!stepsJoyride || stepsJoyride !== MCP_BILLING_JOYRIDE_STEPS) {
      setStepsJoyride(MCP_BILLING_JOYRIDE_STEPS);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { workspace } = this.props;
    const { account } = this.state;
    if (!workspace) {
      return;
    }

    if (account && account !== prevState.account) {
      this.mountBillingData(workspace.code, account);
    }
  }

  mountBillingData = async (workspaceCode: string, account: string) => {
    const { dateSelected } = this.state;
    this.setState({
      loading: true,
      serviceBillingData: null,
      lastYearBillingData: undefined,
    });
    const lastYearBillingData = await this.getLastYearBillingForWorkspace(
      workspaceCode,
      account
    );
    const serviceBillingData = await this.getBillingByServiceForWorkspace(
      workspaceCode,
      dateSelected,
      account as string
    );

    this.setState({
      lastYearBillingData,
      serviceBillingData,
      loading: false,
    });
  };

  getAccounts = async () => {
    try {
      const { data } = await client.query<{ awsAccounts: IAwsAccount[] }>({
        query: gql(queries.awsAccounts),
      });

      return data.awsAccounts;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  getAlias = (accountId: string) => {
    const { awsAccounts } = this.state;

    if (!awsAccounts) {
      return '';
    }

    const targetAccount = awsAccounts.find(
      (account) => account.id === accountId
    );

    if (!targetAccount?.alias) {
      return '';
    }

    return targetAccount.alias;
  };

  updateServiceBillingData = async (dateSelected: string) => {
    const { account } = this.state;

    const serviceBillingData = await this.getBillingByServiceForWorkspace(
      this.props.workspace.code,
      dateSelected,
      account as string
    );
    this.setState({
      serviceBillingData,
    });
  };

  getLastYearBillingForWorkspace = async (workspaceCode, account) => {
    try {
      const { data } = await client.query<GetLastYearBillingForWorkspace>({
        query: gql(queries.getLastYearBillingForWorkspace),
        variables: { workspaceCode, account },
      });
      return data.getLastYearBilling && data.getLastYearBilling[0];
    } catch (error) {
      return [];
    }
  };

  getBillingByServiceForWorkspace = async (
    workspaceCode: string,
    period: string,
    account: string
  ) => {
    try {
      const { data } = await client.query<GetBillingServicesForMonth>({
        query: gql(queries.getBillingServicesForMonth),
        variables: {
          workspaceCode,
          period: moment(period).format('YYYY-MM'),
          account,
        },
      });
      return (
        data.getBillingServicesForMonth && data.getBillingServicesForMonth[0]
      );
    } catch (error) {
      console.error(error);

      if (!error.message) {
        return null;
      }

      const messageMatch = error.message.match('GraphQL error: (.*)');
      if (!messageMatch) {
        return null;
      }

      const errorMessage = messageMatch[1];
      if (Object.values(errorCodes).indexOf(errorMessage) === -1) {
        return null;
      }

      return errorMessage;
    }
  };

  getLastYearBillingForService = async (
    serviceName: string,
    account: string
  ) => {
    try {
      const { data } = await client.query<GetLastYearBillingForService>({
        query: gql(queries.getLastYearBillingForService),
        variables: {
          workspaceCode: this.props.workspace.code,
          serviceName: serviceName,
          account: account,
        },
      });
      if (data.getLastYearBillingForService) {
        const resultsByTime =
          data.getLastYearBillingForService[0].ResultsByTime;

        const drawerChartData = resultsByTime.map(
          (billingData: ResultByTime): ISummary => {
            return {
              month: billingData.TimePeriod.Start,
              value: parseFloat(
                Number(billingData.Total.UnblendedCost.Amount).toFixed(2)
              ),
            };
          }
        );
        this.setState({ drawerChartData });
      }
    } catch (e) {
      this.setState({ drawerChartData: [] });
    }
  };

  parseDrawerData = async (serviceName: string, account: string) => {
    try {
      const {
        data,
      } = await client.query<GetServiceBillingByRegionForWorkspace>({
        query: gql(queries.getServiceBillingByRegionForWorkspace),
        variables: {
          workspaceCode: this.props.workspace.code,
          serviceName: serviceName,
          period: moment(this.state.dateSelected).format('YYYY-MM'),
          account: account,
        },
      });
      if (data.getServiceBillingByRegion) {
        const payloadContent = data.getServiceBillingByRegion[0]
          .ResultsByTime[0].Groups as Group[];
        const hasSubtypes =
          new Set(payloadContent.map((x) => x.Keys[0])).size > 1;
        let drawerData;
        hasSubtypes
          ? (drawerData = payloadContent.map((data) => {
              return {
                serviceName: serviceName,
                region: data.Keys[0],
                pricePerRegion: Number(data.Metrics.UnblendedCost.Amount),
                productImprovement: false,
                servicesPerRegion: [],
              };
            }))
          : (drawerData = [
              {
                serviceName: serviceName,
                region: payloadContent[0].Keys[0],
                pricePerRegion: payloadContent.reduce((acc, current) => {
                  return acc + Number(current.Metrics.UnblendedCost.Amount);
                }, 0),
                productImprovement: false,
                servicesPerRegion: payloadContent.map((data) => {
                  return {
                    product: data.Keys[1],
                    price: Number(data.Metrics.UnblendedCost.Amount),
                    reserved: false,
                    serviceInfo: '',
                  };
                }),
              },
            ]);
        const billingUnit = payloadContent[0].Metrics.UnblendedCost.Unit;
        this.setState({ drawerData, billingUnit });
      }
    } catch {
      return [];
    }
  };

  parseServiceList = (): IServiceList[] => {
    const { serviceBillingData } = this.state;

    if (!serviceBillingData) {
      return [];
    }

    const billingServicePayload =
      serviceBillingData.ResultsByTime[0].Groups || [];

    return billingServicePayload
      .filter((element) => element.Keys[0] !== 'Tax')
      .map(
        (service: Group): IServiceList => {
          const serviceName = service.Keys[0];
          const serviceValue = service.Metrics.UnblendedCost.Amount;
          return {
            value: parseFloat(Number(serviceValue).toFixed(2)),
            serviceName: serviceName,
            isSelected: true,
            hasProductImprovement: false,
            billingUnit: service.Metrics.UnblendedCost.Unit,
          };
        }
      );
  };

  openDrawer = (index: number, serviceName: string) => (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const { account } = this.state;
    this.getLastYearBillingForService(serviceName, account as string);
    this.parseDrawerData(serviceName, account as string);
    event.stopPropagation();
    this.setState({
      selectedService: index,
      drawerStatus: true,
    });
  };

  handleCloseDrawer = () => {
    this.setState({
      drawerStatus: false,
      selectedService: null,
    });
  };

  handleClickCloseDrawer = (event: any) => {
    if (
      this.state.drawerRef &&
      this.state.drawerRef.current &&
      this.state.drawerRef.current.contains(event.target)
    ) {
      return;
    } else {
      this.handleCloseDrawer();
    }
  };

  filteredServiceList = () => {
    const matchWord = this.state.filterWord;
    const serviceList = this.parseServiceList();
    const regex = new RegExp(matchWord, 'gi');
    return (
      serviceList &&
      serviceList.filter((result: IServiceList) => {
        return result.serviceName.match(regex);
      })
    );
  };

  setFilterWord = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filterWord = event.target.value;
    this.setState({ filterWord });
  };

  setDrawerFilterWord = (event: React.ChangeEvent<HTMLInputElement>) => {
    const drawerFilterWord = event.target.value;
    this.setState({ drawerFilterWord });
  };

  filteredDrawerData = () => {
    const matchWord = this.state.drawerFilterWord;
    const drawerData = this.state.drawerData;
    const regex = new RegExp(matchWord, 'gi');
    return (
      drawerData &&
      this.state.drawerData.filter((result: IDrawerPayload) => {
        return result.region.match(regex);
      })
    );
  };

  handleChangeDateSelected = (year: string, month: string) => () => {
    const dateSelected = `${year}-${month}-01`;
    this.updateServiceBillingData(dateSelected);
    this.setState({ dateSelected });
  };

  renderBillingContent(
    workspaceCode?: string,
    loading?: boolean,
    serviceBillingData?: Billing | string | null,
    lastYearBillingData?: Billing
  ) {
    // if (loading) {
    //   return (
    //     <Grid container={true}>
    //       <Grid item={true} xs={12}>
    //         <EmptyState>
    //           <S.EmptyStateTitle>Carregando dados de custos</S.EmptyStateTitle>
    //           <S.EmptyStateParagraph>Aguarde ...</S.EmptyStateParagraph>
    //         </EmptyState>
    //       </Grid>
    //     </Grid>
    //   );
    // }
    if (
      !loading &&
      (!serviceBillingData || typeof serviceBillingData == 'string')
    ) {
      const contactEmail = (
        <a href="mailto:red@br.clara.net">red@br.clara.net</a>
      );

      const errorRepresentations = {
        [errorCodes.billingDisabled]: {
          title: 'Visualização de Billing AWS',
          description: (
            <>
              Informamos que a visualização dos dados de billing da AWS está
              sendo atualizada conforme as nossas novas diretrizes de produto.
              Portanto, este serviço está temporariamente indisponível. Para
              mais informações, contate {contactEmail}.
            </>
          ),
        },
        [errorCodes.billingNotConfigured]: {
          title: 'Configuração de Billing AWS',
          description: (
            <>
              Parece que ainda não há configuração para a função de billing
              nessa Workspace. Para solicitar uma configuração entre em contato
              conosco, basta enviar um e-mail para {contactEmail}.
            </>
          ),
        },
        default: {
          title: 'Não é você, somos nós!',
          description: (
            <>
              Parece que algo de errado não está certo. Um erro inesperado
              ocorreu, tente recarregar a página e caso este erro volte a
              aparecer, entre em contato conosco em {contactEmail} e iremos te
              auxiliar.
            </>
          ),
        },
      };

      const errorIdentifier = serviceBillingData || 'default';
      const title = errorRepresentations[errorIdentifier].title;
      const description = errorRepresentations[errorIdentifier].description;

      return (
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <EmptyState>
              <IconAlert />
              <S.EmptyStateTitle>{title}</S.EmptyStateTitle>
              <S.EmptyStateParagraph>{description}</S.EmptyStateParagraph>
            </EmptyState>
          </Grid>
        </Grid>
      );
    }
    const { stepsJoyride, setStepsJoyride } = this.props;
    return (
      <S.ContentVerticalWrapper onClick={this.handleClickCloseDrawer}>
        <BillingCalendarContainer
          loading={loading}
          lastYearBillingData={lastYearBillingData}
          handleChangeDateSelected={this.handleChangeDateSelected}
          dateSelected={this.state.dateSelected}
          workspaceCode={workspaceCode}
          stepsJoyride={stepsJoyride}
          setStepsJoyride={setStepsJoyride}
        />
        <Box display="flex" maxWidth="100%" minHeight="100vh">
          <S.BoardContainer status={this.state.drawerStatus}>
            <Board
              loading={loading}
              filterWord={this.state.filterWord}
              lastYearBillingData={lastYearBillingData}
              serviceList={this.filteredServiceList()}
              selectedService={this.state.selectedService}
              openDrawer={this.openDrawer}
              setFilterWord={this.setFilterWord}
            />
          </S.BoardContainer>
          <DetailsDrawer
            ref={this.state.drawerRef}
            drawerListData={this.filteredDrawerData()}
            drawerChartData={this.state.drawerChartData}
            drawerStatus={this.state.drawerStatus}
            filterWord={this.state.drawerFilterWord}
            setFilterWord={this.setDrawerFilterWord}
            billingUnit={this.state.billingUnit}
            handleCloseDrawer={this.handleCloseDrawer}
          />
        </Box>
      </S.ContentVerticalWrapper>
    );
  }

  render() {
    const { workspace } = this.props;
    const {
      loading,
      serviceBillingData,
      lastYearBillingData,
      awsAccounts,
      account,
    } = this.state;

    if (!workspace) {
      return <EmptyPage />;
    }

    return (
      <>
        <S.FormControlSpacing>
          <S.FormControlCustom>
            <S.CustomSelect
              className="custom-select"
              labelId="Single-select-account"
              value={
                account ? (
                  <S.AccountSelect>
                    {`${this.getAlias(account)} (${account})`}
                  </S.AccountSelect>
                ) : (
                  'Selecione a conta da AWS'
                )
              }
              onChange={(e) => this.setState({ account: e.target.value })}
              renderValue={(value) => {
                return value;
              }}
            >
              {awsAccounts &&
                awsAccounts.map((account) => (
                  <S.MenuItemAccount key={account.id} value={account.id}>
                    {account.alias} ({account.id})
                  </S.MenuItemAccount>
                ))}
            </S.CustomSelect>
          </S.FormControlCustom>
        </S.FormControlSpacing>
        {account ? (
          <>
            {this.renderBillingContent(
              workspace.code,
              loading,
              serviceBillingData,
              lastYearBillingData
            )}
          </>
        ) : (
          <S.SpacingAWSSelect>
            <S.ScreenAWSSelectAccout>
              <div className="image">
                <EmptyImage />
              </div>
              <Typography align="center" className="title">
                Nenhuma conta selecionada
              </Typography>
              <Typography align="center" className="text">
                Selecione uma conta da AWS para ver as informações associadas a
                ela
              </Typography>
            </S.ScreenAWSSelectAccout>
          </S.SpacingAWSSelect>
        )}
      </>
    );
  }
}

export default withAppContext(withRouter(BillingContainer));
