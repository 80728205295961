import { IIssue } from 'modules/support/types';
import gql from 'graphql-tag';

export interface IGetIssueById {
  issue: IIssueResult;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IIssueResult extends IIssue {}

export const getIssueById = gql`
  query issue($workspaceCode: String!, $projectKey: String!, $issueId: Int!) {
    issue(workspace: $workspaceCode, project: $projectKey, issueId: $issueId) {
      id
      issueKey: key
      summary
      priority
      sprint
      project
      description
      assignee {
        displayName
      }
      issuetype {
        name
      }
      status {
        id
        name
      }
      worklogs {
        results {
          id
          timeSpentSeconds
          started
          comment
          author {
            displayName
          }
        }
        totalTimeSpent
      }
      comments {
        id
        body
        created
        author {
          displayName
        }
      }
      epicIssue {
        id
        issueKey: key
        summary
      }
    }
  }
`;
