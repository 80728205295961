import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { TableContainer } from './containers';
import { GraphContainer } from './containers';
import { StatusContainer } from '../status/containers';
import { ISubmenuItem } from 'modules/layout/types';

export const submenuItens: ISubmenuItem[] = [
  {
    key: 'schedule',
    name: 'Cronograma',
    to: '/schedule',
  },
  {
    key: 'worklogs',
    name: 'Bilhetagem',
    to: '/worklogs',
  },
  {
    key: 'tasks',
    name: 'Tarefas',
    to: '/tasks',
  },
];

const routes = (props) => (
  <Switch>
    <Redirect
      exact={true}
      from={props.match.path}
      to={`${props.match.path}/table`}
    />
    <Route
      path={`${props.match.path}/table`}
      exact={true}
      component={TableContainer}
    />
    <Route
      path={`${props.match.path}/graph`}
      exact={true}
      component={GraphContainer}
    />
    <Route path="*" component={StatusContainer} />
  </Switch>
);

export default routes;
