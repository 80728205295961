import * as React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { timeSpentToString } from 'modules/common/utils/worklog';
import S from './styled';

interface IProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  loading: boolean;
  issuesCount: number;
  issuesTotalTime: number;
}

const Drawer = ({
  startDate,
  endDate,
  loading,
  issuesCount,
  issuesTotalTime,
}: IProps) => (
  <S.Drawer>
    <div className="container">
      <div className="content">
        <Typography className="title">Dados gerais</Typography>
        <div className="box">
          <div className="row">
            <Typography className="title">Data início</Typography>
            {loading ? (
              <S.Fake width={120} height={17} mb={7} />
            ) : (
              <Typography className="date">
                {startDate.format('DD/MM/YYYY')}
              </Typography>
            )}
          </div>
          <div className="row">
            <Typography className="title">Data fim</Typography>
            {loading ? (
              <S.Fake width={120} height={17} mb={7} />
            ) : (
              <Typography className="date">
                {endDate.format('DD/MM/YYYY')}
              </Typography>
            )}
          </div>
        </div>
      </div>
      <div className="content">
        <Typography className="title">Tarefas e Tempo</Typography>
        <div className="box">
          <div className="row">
            <Typography className="title">Número de tarefas</Typography>
            {loading ? (
              <S.Fake width={120} height={17} mb={7} />
            ) : (
              <Typography className="date">
                {issuesCount}
                {issuesCount > 1 ? ' tarefas' : ' tarefa'}
              </Typography>
            )}
          </div>
          <div className="row">
            <Typography className="title">Tempo gasto</Typography>
            {loading ? (
              <S.Fake width={120} height={17} mb={7} />
            ) : (
              <Typography className="date">
                {timeSpentToString(issuesTotalTime, true) || '0h'}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  </S.Drawer>
);

export default Drawer;
