import Grid from '@material-ui/core/Grid';
import { IWorkspace } from 'modules/auth/types';
import EmptyStateProject from 'modules/common/components/EmptyStateProject';
import SelectProject from 'modules/common/components/SelectProject';
import { withAppContext } from 'modules/common/hoc/withAppContext';
import { IProject } from 'modules/common/types';
import {
  MCP_PA_PROJECT,
  MCP_WORKSPACE_INDEX,
} from 'modules/common/utils/constants';
import Submenu from 'modules/layout/components/Submenu';
import moment from 'moment';
import * as React from 'react';
import client from '../../../apolloClient';
import {
  createOrUpdateItemAtBrowserDB,
  getItem,
  getStateFromBrowserDB,
} from '../../../utils/storage';
import BoardView from '../components/BoardView';
import EmptyState from '../components/EmptyState';
import ListView from '../components/ListView';
import PeriodDatePicker from '../components/PeriodDatePicker';
import S from '../components/styled';
import ViewTypeButtonGroup from '../components/ViewTypeButtonGroup';
import { queries } from '../graphql';
import { IGetIssues, IGetIssuesTotalTime, ISupportIssueResult } from '../types';
import {
  IBoards,
  IIssuesByBoard,
  IIssuesFilter,
  IIssuesPage,
  IssueType,
} from '../types';
import {
  MCP_PA_TASKS,
  MCP_PA_TASKS_BOARDS,
  MCP_PA_TASKS_JOYRIDE_STEPS,
  MCP_PA_TASKS_SUBMENUS,
} from '../utils/constants';
import IssueModal from './IssueModalContainer';
import { Dialog } from './IssueModalContainer/styled';

interface IProps {
  workspace?: IWorkspace;
  viewType: 'board' | 'list';
  stepsJoyride?: any[];
  setStepsJoyride: (stepsJoyride: any[]) => void;
  setProjectSelected: (projectSelected: IProject | undefined) => void;
  projectSelected?: IProject;
}

const GenericTasksContainer = ({
  workspace,
  viewType,
  stepsJoyride,
  setStepsJoyride,
  projectSelected,
  setProjectSelected,
}: IProps) => {
  const Content = viewType === 'board' ? BoardView : ListView;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [issueIdSelected, setIssueIdSelected] = React.useState<
    ISupportIssueResult['id'] | undefined
  >();
  const [startDate, setStartDate] = React.useState<moment.Moment>(
    moment().subtract(1, 'month')
  );
  const [endDate, setEndDate] = React.useState<moment.Moment>(moment());
  const [issuesByBoard, setIssuesByBoard] = React.useState<IIssuesByBoard>(
    emptyIssuesByBoard()
  );
  const [issuesTotalTime, setIssuesTotalTime] = React.useState<number>(0);

  const resetSuperState = React.useCallback(() => {
    setLoading(false);
    setIssuesByBoard(emptyIssuesByBoard());
    setIssueIdSelected(undefined);
    setIsModalOpen(false);
  }, []);

  const onComponentUpdate = React.useCallback(
    (currentWorkspace: string, projectSelected: IProject | undefined) => {
      const state = getStateFromBrowserDB(
        MCP_WORKSPACE_INDEX,
        currentWorkspace,
        'cookies',
        MCP_PA_PROJECT
      );

      if (!state) {
        return;
      }

      if (!projectSelected && state.projectSelected) {
        setProjectSelected(state.projectSelected as IProject);
      }

      if (state.startDate && moment(state.startDate).isValid()) {
        setStartDate(moment(state.startDate));
      }

      if (state.endDate && moment(state.endDate).isValid()) {
        setEndDate(moment(state.endDate));
      }
    },
    [setProjectSelected]
  );

  const onStartDate = (workspaceCode: string) => (startDate: moment.Moment) => {
    setStartDate(startDate);
    createOrUpdateItemAtBrowserDB(
      MCP_WORKSPACE_INDEX,
      workspaceCode,
      'cookies',
      MCP_PA_TASKS,
      { startDate: startDate.toISOString() }
    );
  };

  const onEndDate = (workspaceCode: string) => (endDate: moment.Moment) => {
    setEndDate(endDate);
    createOrUpdateItemAtBrowserDB(
      MCP_WORKSPACE_INDEX,
      workspaceCode,
      'cookies',
      MCP_PA_TASKS,
      { endDate: endDate.toISOString() }
    );
  };

  const openIssueModal = (issueId: ISupportIssueResult['id']) => {
    setIssueIdSelected(issueId);
    setIsModalOpen(true);
  };

  const closeIssueModal = (e: React.MouseEvent) => {
    setIsModalOpen(false);
  };

  const renderDialog = (
    workspaceCode: string,
    isModalOpen: boolean,
    issueIdSelected: ISupportIssueResult['id'] | undefined,
    projectSelected: IProject | undefined
  ) => {
    return (
      workspaceCode &&
      issueIdSelected &&
      projectSelected && (
        <Dialog
          onClose={closeIssueModal}
          aria-labelledby="form-resource"
          open={isModalOpen}
          maxWidth="lg"
          disableEnforceFocus
        >
          <IssueModal
            workspaceCode={workspaceCode}
            issueId={issueIdSelected}
            projectKey={projectSelected.key}
            closeIssueModal={closeIssueModal}
          />
        </Dialog>
      )
    );
  };

  const getIssuesForBoard = React.useCallback(
    async (board: IBoards, page = 1) => {
      if (workspace && projectSelected) {
        if (page === 1) {
          setLoading(true);
          setTimeout(() => setLoading(false), 5000);
        }
        const issues = await getIssues({
          workspaceCode: workspace.code,
          projectKey: projectSelected.key,
          issueType: IssueType.task,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          status: board.filterStatus,
          page,
        });
        return issues;
      }
      return { issues: [], totalPages: 0, count: 0, currentPage: 0 };
    },
    [workspace, projectSelected, startDate, endDate]
  );

  const getAllBoards = React.useCallback(
    () =>
      Promise.all(
        MCP_PA_TASKS_BOARDS.map(async (board) => ({
          board,
          issuesPage: await getIssuesForBoard(board),
        }))
      ).then((m) => {
        const allBoards = {} as IIssuesByBoard;
        m.forEach(
          (boardData) =>
            (allBoards[boardData.board.columnTitle] = boardData.issuesPage)
        );
        return allBoards;
      }),
    [getIssuesForBoard]
  );

  const loadIssuesForBoard = React.useCallback(
    async (board: IBoards, page = 1) => {
      const boardPage = issuesByBoard[board.columnTitle];
      const issuesPage = await getIssuesForBoard(board, page);
      setIssuesByBoard({
        ...issuesByBoard,
        [board.columnTitle]: {
          ...issuesPage,
          issues: [...boardPage.issues, ...issuesPage.issues],
        },
      });
    },
    [getIssuesForBoard, issuesByBoard]
  );

  React.useEffect(() => {
    if (workspace && projectSelected) {
      resetSuperState();

      getAllBoards().then(setIssuesByBoard);
    }
  }, [workspace, projectSelected, getAllBoards, resetSuperState]);

  React.useEffect(() => {
    if (
      MCP_PA_TASKS_BOARDS.some(
        (board) => issuesByBoard[board.columnTitle].count > 0
      )
    ) {
      setLoading(false);
    }
  }, [issuesByBoard]);

  React.useEffect(() => {
    if (!stepsJoyride || stepsJoyride !== MCP_PA_TASKS_JOYRIDE_STEPS) {
      setStepsJoyride(MCP_PA_TASKS_JOYRIDE_STEPS);
    }
  }, [stepsJoyride, setStepsJoyride]);

  React.useEffect(() => {
    const currentWorkspace =
      (workspace && workspace.code) || getItem('workspace', 'localStorage');

    if (currentWorkspace) {
      onComponentUpdate(currentWorkspace, projectSelected);
    }
  }, [workspace, projectSelected, viewType, onComponentUpdate]);

  React.useEffect(() => {
    if (workspace && projectSelected) {
      getIssuesTotalTime({
        workspaceCode: workspace.code,
        projectKey: projectSelected.key,
        issueType: IssueType.task,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      }).then(setIssuesTotalTime);
    }
  }, [workspace, projectSelected, startDate, endDate]);

  return (
    <>
      {workspace ? (
        <>
          <Submenu selected="tasks" items={MCP_PA_TASKS_SUBMENUS} />
          <S.BoardRoot>
            <div className="container">
              <Grid container alignItems="center">
                <SelectProject
                  workspace={workspace.code}
                  projectSelected={projectSelected}
                  getProjects={getProjects}
                  setProjectSelected={setProjectSelected}
                />
                <ViewTypeButtonGroup selected={viewType} />
                <PeriodDatePicker
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={onStartDate(workspace.code)}
                  setEndDate={onEndDate(workspace.code)}
                />
              </Grid>
              {projectSelected ? (
                <Content
                  loading={loading}
                  issuesByBoard={issuesByBoard}
                  loadIssuesForBoard={loadIssuesForBoard}
                  startDate={startDate}
                  endDate={endDate}
                  openIssueModal={openIssueModal}
                  issuesTotalTime={issuesTotalTime}
                />
              ) : (
                <EmptyStateProject
                  title="Nenhum projeto selecionado"
                  description="Selecione um projeto para ver as tarefas associadas a ele"
                />
              )}
            </div>
            {renderDialog(
              workspace.code,
              isModalOpen,
              issueIdSelected,
              projectSelected
            )}
          </S.BoardRoot>
        </>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

export const getIssues = async (
  issueFilter: IIssuesFilter
): Promise<IIssuesPage> => {
  try {
    const {
      data: { issues },
    } = await client.query<IGetIssues>({
      query: queries.getIssues,
      variables: issueFilter,
    });
    return {
      issues: issues.results,
      totalPages: issues.totalPages,
      count: issues.count,
      currentPage: issueFilter.page || 0,
    };
  } catch (error) {
    console.error(error);
    return { issues: [], totalPages: 0, count: 0, currentPage: 0 };
  }
};

export const emptyIssuesByBoard = () =>
  MCP_PA_TASKS_BOARDS.reduce(
    (allBoards, board) => ({
      ...allBoards,
      [board.columnTitle]: {
        issues: [],
        totalPages: 0,
        count: 0,
        currentPage: 0,
      },
    }),
    {}
  );

export const getIssuesTotalTime = async (
  issueFilter: IIssuesFilter
): Promise<number> => {
  try {
    const {
      data: { issuesTotalTime },
    } = await client.query<IGetIssuesTotalTime>({
      query: queries.getIssuesTotalTime,
      variables: issueFilter,
    });
    return issuesTotalTime;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getProjects = async (workspaceCode: string) => {
  try {
    const { data } = await client.query<{ projects: IProject[] }>({
      query: queries.getProjects,
      variables: {
        workspace: workspaceCode,
      },
    });
    return data.projects;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default withAppContext(GenericTasksContainer);
