import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import S from './CalendarStyled';

export default function FakeLoaderCalendar() {
  return (
    <>
      {new Array(12).fill(true).map((value: string, index: number) => (
        <S.BoxMonth key={`month_${index}`} style={{ marginRight: 8 }}>
          <div className="month-content">
            <Typography component="h3">
              <S.Fake width={55} height={25} mb={4} />
            </Typography>
            <Typography component="h5">
              <S.Fake width={34} height={16} mb={8} />
            </Typography>
          </div>
          <S.ProgressContent>
            <S.Progress value={0} />
          </S.ProgressContent>
        </S.BoxMonth>
      ))}
    </>
  );
}
