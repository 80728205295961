import * as React from 'react';
import * as S from '../components/styled';
import Skeleton from '@material-ui/lab/Skeleton';
import reportPDF from 'modules/common/images/reportPDF.png';
import downloadIcon from 'modules/common/images/downloadIcon.png';
import client from 'apolloClient';
import { queries } from '../graphql';
import { IGeneratedReports } from '../types';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as EmptyImage } from 'modules/common/images/empty_board.svg';

const ReportDownload = () => {
  const [reportList, setReportList] = React.useState<IGeneratedReports[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  React.useEffect(() => {
    getGeneratedReports();
  }, []);

  const handleDownload = async (report: IGeneratedReports) => {
    fetch(report.s3Url, { method: 'GET' })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `${report.reportName}_${report.createdAt}.pdf`;
        document.body.appendChild(anchor);
        anchor.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 60000);
        anchor.remove();
      })
      .catch((err) => {
        console.error('err: ', err);
      });
  };

  const getGeneratedReports = async () => {
    try {
      const { data } = await client.query<{
        generatedReports: IGeneratedReports[];
      }>({
        query: queries.generatedReports,
        fetchPolicy: 'network-only',
      });
      setLoading(false);
      setReportList(data.generatedReports);
    } catch (error) {
      console.log(error);
      setLoading(false);
      return [];
    }
  };

  return (
    <>
      <S.ContainerReport>
        {loading &&
          Array.from(Array(4).keys()).map((_, key) => (
            <Skeleton key={key} height={84} />
          ))}
        {!loading && reportList.length === 0 && (
          <S.EmptyState>
            <div className="image">
              <EmptyImage />
            </div>
            <Typography align="center" className="title">
              Nenhum relatório de execução disponível.
            </Typography>
            <Typography align="center" className="text">
              Ao executar um novo preventivo, o relatório ficará disponível
              nesta página pelo período de 6 meses.
            </Typography>
          </S.EmptyState>
        )}
        {reportList &&
          !loading &&
          reportList.map((report) => (
            <S.SquareReport key={report.createdAt}>
              <S.AlignTextAndDocImg>
                <S.DocImg>
                  <img src={reportPDF} alt="Ícone de documento" />
                </S.DocImg>

                <S.ContainerText>
                  <S.Title>{`Relatório de execução de ${report.reportName}`}</S.Title>
                  <S.MobileText />
                  <S.SubTitle>
                    {`Criado em ${report.createdAt} por ${report.username}`}{' '}
                  </S.SubTitle>
                </S.ContainerText>
              </S.AlignTextAndDocImg>

              <S.ButtonDownloadImg onClick={() => handleDownload(report)}>
                <img src={downloadIcon} alt="Ícone de download" />
              </S.ButtonDownloadImg>
            </S.SquareReport>
          ))}
      </S.ContainerReport>
    </>
  );
};

export default ReportDownload;
