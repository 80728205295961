import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import * as S from './CalendarStyled';

export default function ContentMonth({
  monthBoxUnit,
  year,
  month,
  monthName,
  timeSpent,
  active,
  handleChangeDateSelected,
}: {
  monthBoxUnit?: string;
  year: string;
  month: string;
  monthName: string;
  timeSpent: number;
  active: boolean;
  handleChangeDateSelected: (
    year: string,
    month: string
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
  const handleOnclick = handleChangeDateSelected(year, month);

  return (
    <S.CustomTooltip
      arrow={true}
      title={
        <>
          <S.TooltipProgress>
            <div className="tooltip-content">
              <Typography component="h5">Gasto Mensal</Typography>
              <Typography component="h6">
                {timeSpent.toFixed(2)}
                {monthBoxUnit}
              </Typography>
            </div>
          </S.TooltipProgress>
          <span className="tooltip-arrow" />
        </>
      }
    >
      <S.BoxMonth onClick={handleOnclick}>
        <div className="month-content">
          <S.Capitalize component="h3">{monthName}</S.Capitalize>
          <Typography component="h5">
            {`${timeSpent.toFixed(2)} ${monthBoxUnit}`}
          </Typography>
          <S.MonthActive active={active} />
        </div>
      </S.BoxMonth>
    </S.CustomTooltip>
  );
}
