import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ListResourcesContainer, ResourcesContainer } from './containers';
import { StatusContainer } from '../status/containers';

const routes = (props) => (
  <Switch>
    <Route
      exact={true}
      path={props.match.path}
      component={ResourcesContainer}
    />
    <Route
      path={`${props.match.path}/list`}
      exact={true}
      component={ListResourcesContainer}
    />
    <Route path="*" component={StatusContainer} />
  </Switch>
);

export default routes;
