import { styled, css } from '@cmp/ui';
import { paletteColor } from '../styles/colors';

export const Main = styled.div`
  flex-grow: 1;
  padding: 10px 30px;
`;

export const Base = css`
  background: ${paletteColor.primary.white};
  box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
  border-radius: 6px;
  padding: 16px 27px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const Badge = styled.span`
  height: 24px;
  line-height: 24px;
  border-radius: 3px;
  background: ${paletteColor.info.fill};
  color: ${paletteColor.info.soft};
  font-size: 12px;
  font-weight: bold;
  padding: 0 5px;
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: ${paletteColor.info.soft};
`;

export default {
  Main,
  Base,
  Badge,
  Title,
};
