import { hotjar } from 'react-hotjar';

declare global {
  interface Window {
    hj: any;
  }
}

const parseJwt = (token: string) => {
  // https://stackoverflow.com/a/46188039
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const initializeHotjar = (token: string) => {
  hotjar.initialize(1414037, 6);

  const parsedToken = parseJwt(token);
  const userId = parsedToken['master-user'];
  const email = parsedToken['email-user'];

  window.hj('identify', userId, {
    e_mail: email,
  });
};

//https://help.hotjar.com/hc/en-us/articles/115011624487-How-to-Use-JavaScript-Triggers-for-Feedback-Tools
export const trigger = (triggerName: string) => {
  window.hj('trigger', triggerName);
};

//https://help.hotjar.com/hc/en-us/articles/115011819488-How-to-Tag-your-Hotjar-Recordings
export const tagRecording = (recordingArr: Array<string>) => {
  window.hj('tagRecording', recordingArr);
};
