import * as React from 'react';
import { Billing, IServiceList, ISummary, ResultByTime } from '../types';
import BillingChart from './BillingChart';
import CardList from './CardList';
import * as S from './Styled';
import { Input } from '@cmp/ui';
import { ReactComponent as SearchIcon } from 'modules/common/icons/icon-search.svg';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

interface IProps {
  loading?: boolean;
  lastYearBillingData?: Billing;
  serviceList?: IServiceList[];
  openDrawer: (
    arrayIndex: number,
    serviceName: string
  ) => (event: React.MouseEvent<HTMLDivElement>) => void;
  selectedService: number | null;
  filterWord: string;
  setFilterWord?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const parseChartData = (lastYearBillingData): ISummary[] => {
  const resultsByTime = lastYearBillingData.ResultsByTime;
  const billingDataByMonth = resultsByTime.map(
    (billingData: ResultByTime): ISummary => {
      return {
        month: billingData.TimePeriod.Start,
        value: parseFloat(
          Number(billingData.Total.UnblendedCost.Amount).toFixed(2)
        ),
      };
    }
  );
  return billingDataByMonth;
};

export default function Board({
  loading,
  openDrawer,
  lastYearBillingData,
  serviceList,
  filterWord,
  setFilterWord,
  selectedService,
}: IProps) {
  return (
    <S.BoardContent>
      {lastYearBillingData && (
        <Box mb={3}>
          <BillingChart data={parseChartData(lastYearBillingData)} />
        </Box>
      )}
      {loading ? (
        <>
          {new Array(7).fill(true).map((_, index) => (
            <Skeleton key={`loading_${index}`} height={'80px'} />
          ))}
        </>
      ) : (
        <>
          <Box mb={3}>
            <Input
              type="text"
              text="Digite aqui para buscar um serviço"
              value={filterWord}
              onChange={setFilterWord}
              fullWidth={true}
              Icon={SearchIcon}
            />
          </Box>
          {serviceList && (
            <CardList
              serviceList={serviceList}
              openDrawer={openDrawer}
              selectedService={selectedService}
            />
          )}
        </>
      )}
    </S.BoardContent>
  );
}
