import { MuiThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';
import { AppProvider } from 'appContext';
import { IUser, IWorkspace } from 'modules/auth/types';
import theme from 'modules/common/styles/theme';
import { MainLayout } from '../components';

interface IProps {
  workspaces: IWorkspace[];
  userLogged?: IUser;
  children: React.ReactNode;
}

const container = ({ workspaces, userLogged, children }: IProps) => (
  <MuiThemeProvider theme={theme}>
    <AppProvider workspaces={workspaces} userLogged={userLogged}>
      <MainLayout>{children}</MainLayout>
    </AppProvider>
  </MuiThemeProvider>
);

export default container;
