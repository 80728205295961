import { styled, effects, css } from '@cmp/ui';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as React from 'react';
import { IStyledProps } from 'modules/layout/types';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { paletteColor } from '../styles/colors';

export const EmptyState = styled.div`
  ${effects.slideUp};
  height: ${(props) => (props.height ? props.height : 'calc(100% - 30px)')};
  color: ${paletteColor.info.soft};
  background: ${paletteColor.info.fill};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  border-radius: 10px;
  margin-bottom: 20px;

  & .image {
    ${effects.shake};
    animation-delay: 500ms;
    text-align: center;
  }

  & .title {
    font-weight: 500;
    margin-top: 20px;
  }

  & .text {
    margin: 0 auto;
    margin-top: 8px;
    line-height: 1.6;
    width: 33%;

    @media only screen and (min-width: 320px) {
      width: 70%;
    }

    @media only screen and (min-width: 375px) {
      width: 75%;
    }

    @media only screen and (min-width: 425px) {
      width: 70%;
    }

    @media only screen and (min-width: 767px) {
      width: 65%;
    }

    @media only screen and (min-width: 1024px) {
      width: 40%;
    }

    @media only screen and (min-width: 1440px) {
      width: 30%;
    }
  }
`;

const FilterContent = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 20;
  margin: 30px 20px 30px 0px;

  & .sprint-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }
  & .time-spent-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 14px;

    & .time-spent-title {
      font-weight: 500;
      font-size: 12px;
      color: ${paletteColor.info.soft};
      text-transform: initial;
    }
    & .time-spent-text {
      font-size: 14px;
      color: ${paletteColor.info.text};
    }
  }
`;

const FilterButton = styled(Button)`
  && {
    height: 42px;
    min-width: 260px;
    border: 1px solid ${paletteColor.info.border};
    border-radius: 3px;
    font-size: 14px;
    color: ${paletteColor.info.soft};
    background: ${paletteColor.primary.white};
    box-sizing: border-box;

    &:hover {
      background: ${paletteColor.primary.white};
    }

    & .MuiTypography-body1 {
      margin-right: 33px;
      text-transform: initial;
    }

    &:before {
      content: '';
      position: absolute;
      right: 18px;
      top: 14px;
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: ${paletteColor.info.soft};
      border-width: 0px 2px 2px 0px;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      position: absolute;
      border-left: 1px solid ${paletteColor.info.border};
      right: 40px;
      top: 8px;
      height: 24px;
    }
  }
`;

const Fake = styled.div`
  border-radius: 3px;
  width: ${(props: IStyledProps) => props.width}px;
  height: ${(props: IStyledProps) => props.height}px;
  margin-bottom: ${(props: IStyledProps) => props.mb}px;
  ${effects.loader};
  background-color: ${paletteColor.info.fill};
  background: linear-gradient(
    270deg,
    ${paletteColor.info.fill},
    ${paletteColor.info.background},
    ${paletteColor.info.fill}
  );
  background-size: 200% 100%;
`;

const MenuSelect = styled((props: any) => (
  <Menu {...props} open={props.open} classes={{ paper: 'menu-paper' }} />
))`
  && {
    & .menu-paper {
      padding: 0 8px;
      margin-top: 8px;
      box-shadow: 0px 4px 6px rgba(223, 225, 235, 0.5);
    }
  }
`;

const MenuItemSelect = styled((props: any) => <MenuItem {...props} />)`
  && {
    position: relative;
    font-weight: 500;
    font-size: 12px;
    color: ${paletteColor.info.soft};
    padding: 8px;
    border-radius: 3px;
    overflow: inherit;
    &:not(:first-child) {
      margin-top: 8px;
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:hover,
    &:active {
      background: rgba(242, 101, 34, 0.1);
      color: ${paletteColor.primary.main};
    }
    &:not(:last-child):before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 4px);
      border-bottom: 1px solid ${paletteColor.info.border};
    }
  }
`;

const CustomTooltip = styled(({ title, className, ...other }: any) => (
  <Tooltip
    placement="right"
    classes={{ popper: className, tooltip: 'tooltip', arrow: 'arrow' }}
    title={title}
    {...other}
  />
))`
  && {
    & .tooltip {
      background: ${paletteColor.info.text};
      width: 250px;
      padding: 14px;
      margin-right: 10px;

      @media (max-width: 375px) {
        width: 190px;
      }
    }
    & .arrow {
      color: ${paletteColor.info.text};
      font-size: 13px;
    }
  }
`;

const IconCustom = styled(Icon)`
  && {
    color: ${paletteColor.info.border};
    transition: color 500ms;
    font-size: 1.2rem;
    margin: 4px 0;
    cursor: pointer;

    &:hover {
      color: ${paletteColor.primary.main};
    }

    &.iconMobile {
      display: none;
    }

    @media (max-width: 768px) {
      &.iconDesktop {
        display: none;
      }

      &.iconMobile {
        display: block;
      }
    }
  }
`;

const ButtonTooltip = styled.div`
  background: transparent;
  height: 100%;
  width: 15px;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${paletteColor.primary.main};
  }
`;

const CustomTooltipContent = styled(Typography)`
  && {
    color: ${paletteColor.primary.white};
    font-size: 14px;

    & .tooltipTitle {
      font-weight: 500;
      margin-bottom: 4px;
    }
  }
`;

export const Main = styled.div`
  border: none;
  color: ${paletteColor.primary.white};
  padding: 0 16px 16px 0;
  text-align: center;
  display: inline-block;
`;

export const PageMenu = styled.div`
  &:hover {
    background: ${paletteColor.info.fill};
    color: ${paletteColor.info.soft};
    border-radius: 85px;
  }
`;

export const PageMenuItem = styled.div`
  && {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85px;
    height: 34px;
    padding: 0 20px;
    border-radius: 85px;
    text-transform: capitalize;
    color: ${paletteColor.info.soft};
    font-weight: 500;

    ${(props: IStyledProps) =>
      props.selected &&
      css`
        color: ${paletteColor.primary.white};
        background: ${paletteColor.primary.main};
      `}

    &:hover {
      cursor: pointer;
    }
  }
`;

export const Styled = {
  FilterContent,
  FilterButton,
  Fake,
  MenuSelect,
  MenuItemSelect,
  EmptyState,
  CustomTooltip,
  IconCustom,
  ButtonTooltip,
  CustomTooltipContent,
};

export default Styled;
