const config = {
  cookies: {
    _authcd_: '',
  },
  publicJiraAPI: process.env.REACT_APP_JIRA_API || 'http://localhost:4000',
  appMENU: process.env.REACT_APP_MENU || 'development',
  baseURI: process.env.REACT_APP_API_BASE_URI || 'http://localhost:3000/',
  contentfulURL: process.env.REACT_APP_CONTENTFUL_URL,
  contentfulAccessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  contentfulSpace: process.env.REACT_APP_CONTENTFUL_SPACE || 'fyx8avztqkfo',
  endpoints: {
    GRAPHQL:
      process.env.REACT_APP_MIDDLEWARE ||
      'https://api.devops.mandic.com.br/graphql',
    PAINEL_MANDIC:
      process.env.REACT_APP_PAINEL_MANDIC || 'https://portal.claranet.com.br/',
  },
  env: process.env.NODE_ENV,
  constants: {
    devopsAuthCookie: '_AUTHCD_',
  },
};

export default config;
