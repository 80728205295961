import * as React from 'react';
import EmptyPage from '../../common/components/EmptyState';
import image from 'modules/common/images/empty_preventives.png';

const EmptyState = () => (
  <EmptyPage
    title="Cloud Compliance"
    description="Uma coletânea de serviços automatizados que verificam a segurança, otimização de custos, eficiência de performance, excelência operacional e confiabilidade do seu ambiente em nuvem."
    image={image}
    readMoreURL="https://platform.mandic.com.br/portfolio/cloud-compliance/"
    hireURL="https://br.claranet.com/contatos?pgc=portal.cliente"
  />
);

export default EmptyState;
