import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import * as S from './Styled';
import Box from '@material-ui/core/Box';

export default function FakeLoaderCalendarPunctual() {
  return (
    <Box display="flex" justifyContent="center">
      <S.BoxContainer>
        <S.CalendarPunctualContainer>
          <S.CardCalendarPunctual>
            <Box display="flex" justifyContent="center">
              <S.Fake width="50%" height="28px" />
            </Box>
            <Box>
              <S.ProgressContent>
                <Typography>Andamento</Typography>
                <S.Fake width="100%" height="4px" />
              </S.ProgressContent>
              <S.ProgressContent>
                <Typography>Prazo</Typography>
                <S.Fake width="100%" height="4px" />
              </S.ProgressContent>
            </Box>
          </S.CardCalendarPunctual>

          <S.CardDescription>
            <S.CardItem className="separator-line flex">
              <Box>
                <S.Title>Horas contratadas</S.Title>
                <Typography>
                  <S.Fake width="20%" height="16px" />
                </Typography>
              </Box>
              <Box>
                <S.Title>Data de contratação</S.Title>
                <Typography>
                  <S.Fake width="30%" height="16px" />
                </Typography>
              </Box>
            </S.CardItem>
            <S.CardItem>
              <Box>
                <S.Title>Consumo atual</S.Title>
                <Typography>
                  <S.Fake width="20%" height="16px" />
                </Typography>
              </Box>
              <Box>
                <S.Title>Data prevista de término</S.Title>
                <Typography>
                  <S.Fake width="30%" height="16px" />
                </Typography>
              </Box>
            </S.CardItem>
          </S.CardDescription>
        </S.CalendarPunctualContainer>
      </S.BoxContainer>
    </Box>
  );
}
