const listWorkspaces = `
  query workspaces {
    workspaces {
      code
      name
    }
  }
`;

export default {
  listWorkspaces,
};
