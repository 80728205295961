import React from 'react';
import S from './styled';

interface IProps {
  title?: string;
  description?: string;
  icon?: string;
}

const Tooltip = ({ title, description, icon = 'info' }: IProps) => {
  const [openTooltip, setOpenTooltip] = React.useState<boolean>(false);

  const handleTooltipOpen = () => {
    setOpenTooltip(!openTooltip);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  return (
    <>
      <S.CustomTooltip
        title={
          <S.CustomTooltipContent>
            <div className="tooltipTitle">{title}</div>
            <div className="tooltipeDescription">{description}</div>
          </S.CustomTooltipContent>
        }
        arrow={true}
      >
        <S.IconCustom className="iconDesktop">{icon}</S.IconCustom>
      </S.CustomTooltip>
      <S.CustomTooltip
        onClose={handleTooltipClose}
        open={openTooltip}
        disableTouchListener
        title={
          <S.CustomTooltipContent>
            <div className="tooltipTitle">{title}</div>
            <div className="tooltipeDescription">{description}</div>
          </S.CustomTooltipContent>
        }
        arrow={true}
      >
        <S.ButtonTooltip onClick={handleTooltipOpen}>
          <S.IconCustom className="iconMobile">{icon}</S.IconCustom>
        </S.ButtonTooltip>
      </S.CustomTooltip>
    </>
  );
};

export default Tooltip;
