import * as React from 'react';
import { IWorkspace } from 'modules/auth/types';
import GenericTasksContainer from './GenericTasksContainer';
import { withAppContext } from 'modules/common/hoc/withAppContext';

interface IProps {
  workspace: IWorkspace;
  stepsJoyride?: any[];
  setStepsJoyride: (stepsJoyride: any[]) => void;
}

class BoardContainer extends React.Component<IProps> {
  public render() {
    const { workspace, stepsJoyride, setStepsJoyride } = this.props;

    return (
      <GenericTasksContainer
        stepsJoyride={stepsJoyride}
        setStepsJoyride={setStepsJoyride}
        workspace={workspace}
        viewType="board"
      />
    );
  }
}

export default withAppContext(BoardContainer);
