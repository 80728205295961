import Calendar from './Calendar';
import moment from 'moment';
import * as React from 'react';
import { IWorkspace } from '../../auth/types';
import {
  Billing,
  ISummary,
  ISummaryQuarter,
  ISupportPlan,
  ResultByTime,
} from '../types';

interface IProps {
  loading?: boolean;
  lastYearBillingData?: Billing;
  dateSelected?: string;
  workspace?: IWorkspace;
  workspaceCode?: string;
  handleChangeDateSelected: (
    year: string,
    month: string
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  stepsJoyride?: any[];
  setStepsJoyride: (stepsJoyride: any[]) => void;
}

interface IState {
  loading: boolean;
  boxCalendarRef: React.RefObject<HTMLDivElement> | null;
  currentMonth?: string;
  poolSize: number;
  summary: ISummaryQuarter[];
  billingUnit: string;
}

export default class BillingCalendarContainer extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      billingUnit: '',
      summary: [],
      poolSize: 0,
      loading: true,
      boxCalendarRef: React.createRef<HTMLDivElement>(),
    };
  }

  public componentDidMount() {
    this.mountCalendar();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (
      this.props.workspaceCode !== prevProps.workspaceCode ||
      this.props.loading !== prevProps.loading
    ) {
      this.mountCalendar();
    }
  }

  public getAverageBilling(): ISupportPlan[] {
    const BillingSummary = this.getBillingSummary();
    const averageAmountLastYear =
      BillingSummary.reduce((acc, current) => {
        return acc + current.value;
      }, 0) / 12;
    const averageBillingByMonth = BillingSummary.map((billingData) => {
      return { pool_size: averageAmountLastYear, month: billingData.month };
    });
    return averageBillingByMonth;
  }

  public getBillingSummary(): ISummary[] {
    if (this.props.lastYearBillingData) {
      const resultsByTime = this.props.lastYearBillingData.ResultsByTime;
      const billingDataByMonth = resultsByTime.map(
        (billingData: ResultByTime): ISummary => {
          return {
            month: billingData.TimePeriod.Start,
            value: parseFloat(
              Number(billingData.Total.UnblendedCost.Amount).toFixed(2)
            ),
          };
        }
      );
      return billingDataByMonth;
    }
    return [];
  }

  public getSummaryByQuarter(
    summary: ISummary[],
    supportPlan: ISupportPlan[]
  ): ISummaryQuarter[] {
    return summary.reduce((resume: ISummaryQuarter[], data: ISummary) => {
      if (data.value <= 0) {
        return resume;
      }
      const year = moment(data.month, 'YYYY-MM').format('YYYY');
      const quarter = moment(data.month, 'YYYY-MM').quarter();
      const current = resume[year] || {};
      const plan = supportPlan.find(
        ({ month }: { month: string }) => month === data.month
      ) || { pool_size: 0 };

      if (!current[quarter]) {
        current[quarter] = { list: [], timeSpent: 0, poolSize: 0 };
      }
      current[quarter].list.push({
        date: data.month,
        poolSize: plan.pool_size,
        timeSpent: data.value,
      });
      current[quarter].timeSpent += data.value;
      current[quarter].poolSize += plan.pool_size;
      return Object.assign([], resume, { [year]: current });
    }, []);
  }

  public mountCalendar() {
    if (!this.props.lastYearBillingData) {
      return;
    }
    const worklogsByMonth = this.getBillingSummary();
    const supportPlanByMonth = this.getAverageBilling();
    this.setState(
      {
        summary: this.getSummaryByQuarter(worklogsByMonth, supportPlanByMonth),
        loading: false,
        billingUnit: this.props.lastYearBillingData.ResultsByTime[0].Total
          .UnblendedCost.Unit,
      },
      () => {
        if (this.state.boxCalendarRef && this.state.boxCalendarRef.current) {
          this.state.boxCalendarRef.current.scrollBy({
            left: this.state.boxCalendarRef.current.scrollWidth,
            behavior: 'smooth',
          });
        }
      }
    );
  }

  public render() {
    return (
      <Calendar
        monthBoxUnit={this.state.billingUnit}
        dateSelected={this.props.dateSelected}
        handleChangeDateSelected={this.props.handleChangeDateSelected}
        loading={this.props.loading}
        summary={this.state.summary}
        boxCalendarRef={this.state.boxCalendarRef}
      />
    );
  }
}
