import { DatePicker } from '@cmp/ui';
import DateFnsUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import client from 'apolloClient';
import { IWorkspace } from 'modules/auth/types';
import EmptyPage from 'modules/common/components/EmptyState';
import SelectProject from 'modules/common/components/SelectProject';
import graphImage from 'modules/common/images/empty_schedule_graph.png';
import tableImage from 'modules/common/images/empty_schedule_table.png';
import { IProject } from 'modules/common/types';
import {
  MCP_PA_PROJECT,
  MCP_WORKSPACE_INDEX,
} from 'modules/common/utils/constants';
import Submenu from 'modules/layout/components/Submenu';
import Graph from 'modules/schedule/components/Graph';
import ProjectEmptyState from 'modules/schedule/components/ProjectEmptyState';
import SearchSchedule from 'modules/schedule/components/SearchSchedule';
import SelectCronogramType from 'modules/schedule/components/SelectCronogramType';
import * as S from 'modules/schedule/components/styled';
import TableSchedule from 'modules/schedule/components/TableSchedule';
import { queries } from 'modules/schedule/graphql';
import { submenuItens } from 'modules/schedule/routes';
import { IIssue, IIssueFilter } from 'modules/schedule/types';
import {
  getStepsJoyride,
  MCP_PA_SCHEDULE,
} from 'modules/schedule/utils/constants';
import moment from 'moment';
import * as React from 'react';
import {
  createOrUpdateItemAtBrowserDB,
  getItem,
  getStateFromBrowserDB,
} from 'utils/storage';

interface IProps {
  workspace?: IWorkspace;
  scheduleType: 'table' | 'graph';
  setProjectSelected: (projectSelected: IProject | undefined) => void;
  projectSelected?: IProject;
  stepsJoyride?: any[];
  setStepsJoyride: (stepsJoyride: any[]) => void;
}

const GenericScheduleContainer = ({
  workspace,
  scheduleType,
  projectSelected,
  setProjectSelected,
  stepsJoyride,
  setStepsJoyride,
}: IProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [issues, setIssues] = React.useState<IIssue[]>([]);
  const [filteredIssues, setFilteredIssues] = React.useState<IIssue[]>([]);
  const [filter, setFilter] = React.useState<IIssueFilter>({
    text: '',
    date: {},
  });

  React.useEffect(() => {
    const JOYRIDE_STEPS = getStepsJoyride(scheduleType, filteredIssues);
    if (!stepsJoyride || stepsJoyride.length !== JOYRIDE_STEPS.length) {
      setStepsJoyride(JOYRIDE_STEPS);
    }
  }, [filteredIssues, scheduleType, setStepsJoyride, stepsJoyride]);

  const loadIssuesForCurrentProject = React.useCallback(() => {
    if (!workspace || !projectSelected) {
      setIssues([]);
      return;
    }
    setLoading(true);

    getEpicIssues({
      workspace: workspace.code,
      project: projectSelected.key,
    }).then((epicIssues) => {
      setLoading(false);
      setIssues(epicIssues);
    });
  }, [workspace, projectSelected]);

  const loadFilteredIssues = React.useCallback(() => {
    type issueFilter = (issue: IIssue) => boolean;

    const matchText: issueFilter = (issue) => {
      const issueText = issue.summary.toLowerCase().trim();
      const filterText = filter.text.toLowerCase().trim();

      return issueText.includes(filterText);
    };

    const matchDate: issueFilter = (issue) => {
      const [filterStart, filterEnd, issueStart, issueEnd] = [
        filter.date.start,
        filter.date.end,
        issue.startdate,
        issue.duedate,
      ];

      if (filterStart) {
        if (!issueStart) {
          return false;
        }
        if (moment(issueStart).isBefore(filterStart)) {
          return false;
        }
      }

      if (filterEnd) {
        if (!issueEnd) {
          return false;
        }
        if (moment(issueEnd).isAfter(filterEnd)) {
          return false;
        }
      }

      return true;
    };

    setFilteredIssues(issues.filter(matchText).filter(matchDate));
  }, [issues, filter]);

  const onComponentUpdate = React.useCallback(
    (currentWorkspace: string, projectSelected: IProject | undefined) => {
      const state = getStateFromBrowserDB(
        MCP_WORKSPACE_INDEX,
        currentWorkspace,
        'cookies',
        MCP_PA_PROJECT
      );

      if (!state) {
        return;
      }

      if (!projectSelected && state.projectSelected) {
        setProjectSelected(state.projectSelected as IProject);
      }

      if (state.startDate && moment(state.startDate).isValid()) {
        setStartDate(moment(state.startDate));
      }

      if (state.endDate && moment(state.endDate).isValid()) {
        setEndDate(moment(state.endDate));
      }
    },
    [setProjectSelected]
  );

  const setTextSearch = (event) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      text: event.target.value,
    }));
  };

  const clearDates = (workspaceCode: string) => {
    onStartDate(workspaceCode)(undefined);
    onEndDate(workspaceCode)(undefined);
  };

  const setStartDate = (date?: moment.Moment) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      date: { ...prevFilter.date, start: date },
    }));
  };

  const setEndDate = (date?: moment.Moment) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      date: { ...prevFilter.date, end: date },
    }));
  };

  const onStartDate = (workspaceCode: string) => (
    startDate?: moment.Moment
  ) => {
    setStartDate(startDate);
    createOrUpdateItemAtBrowserDB(
      MCP_WORKSPACE_INDEX,
      workspaceCode,
      'cookies',
      MCP_PA_SCHEDULE,
      { startDate: startDate && startDate.toISOString() }
    );
  };

  const onEndDate = (workspaceCode: string) => (endDate?: moment.Moment) => {
    setEndDate(endDate);
    createOrUpdateItemAtBrowserDB(
      MCP_WORKSPACE_INDEX,
      workspaceCode,
      'cookies',
      MCP_PA_SCHEDULE,
      { endDate: endDate && endDate.toISOString() }
    );
  };

  React.useEffect(() => {
    const currentWorkspace =
      (workspace && workspace.code) || getItem('workspace', 'localStorage');

    if (currentWorkspace) {
      onComponentUpdate(currentWorkspace, projectSelected);
    }
  }, [workspace, projectSelected, onComponentUpdate]);

  React.useEffect(() => {
    loadIssuesForCurrentProject();
  }, [loadIssuesForCurrentProject]);

  React.useEffect(() => {
    loadFilteredIssues();
  }, [loadFilteredIssues]);

  const Content = scheduleType === 'table' ? TableSchedule : Graph;
  const emptyStateImage = scheduleType === 'table' ? tableImage : graphImage;

  return (
    <>
      {workspace ? (
        <>
          <Submenu selected="schedule" items={submenuItens} />
          <S.ScheduleWrapper>
            <Grid container alignItems="center">
              <SelectProject
                workspace={workspace.code}
                getProjects={(workspace) =>
                  getProjects({ workspace: workspace })
                }
                setProjectSelected={setProjectSelected}
                projectSelected={projectSelected}
              />
              <SelectCronogramType selected={scheduleType} />
            </Grid>
          </S.ScheduleWrapper>
          {projectSelected ? (
            <S.BoxContainer>
              <S.FilterWrapper>
                <S.SearchWrapper>
                  <SearchSchedule onChange={setTextSearch} filter={filter} />
                </S.SearchWrapper>
                <S.DateWrapper>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <S.TypographyCustom>Filtrar de</S.TypographyCustom>
                    <DatePicker
                      autoOk={true}
                      value={filter.date.start || null}
                      onChange={onStartDate(workspace.code)}
                      variant="inline"
                      format="DD/MM/YYYY"
                      width={130}
                      className="date-picker"
                      maxDate={filter.date.end}
                      data-cy="start-date"
                    />
                    <S.TypographyCustom>Até</S.TypographyCustom>
                    <DatePicker
                      autoOk={true}
                      value={filter.date.end || null}
                      onChange={onEndDate(workspace.code)}
                      variant="inline"
                      format="DD/MM/YYYY"
                      width={130}
                      className="date-picker"
                      minDate={filter.date.start}
                      data-cy="end-date"
                    />
                  </MuiPickersUtilsProvider>
                  <S.ButtonCustom
                    onClick={() => clearDates(workspace.code)}
                    data-cy="clear-date"
                  >
                    Limpar datas
                  </S.ButtonCustom>
                </S.DateWrapper>
              </S.FilterWrapper>

              <Content
                issues={[...issues]}
                filteredIssues={[...filteredIssues]}
                loading={loading}
              />
            </S.BoxContainer>
          ) : (
            <ProjectEmptyState />
          )}
        </>
      ) : (
        <EmptyPage
          title="Cronograma"
          description="A página de cronograma possuí uma tabela e um gráfico que permitem uma visualização rápida do andamento das atividades do seu Projeto."
          image={emptyStateImage}
          readMoreURL="https://platform.mandic.com.br/portfolio/projetos-ageis/"
          hireURL="https://br.claranet.com/contatos?pgc=portal.cliente"
        />
      )}
    </>
  );
};

const getEpicIssues = (variables: {
  workspace: string;
  project: string;
}): Promise<IIssue[]> =>
  client
    .query({
      query: queries.epicIssues,
      variables,
    })
    .then((response) => response.data.issues.results);

const getProjects = (variables: { workspace: string }): Promise<IProject[]> =>
  client
    .query({
      query: queries.projects,
      variables,
    })
    .then((response) => response.data.projects);

export default GenericScheduleContainer;
