import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EmptyStateProject from 'modules/common/components/EmptyStateProject';
import * as React from 'react';
import { IPropsView } from '../types';
import { MCP_PA_TASKS_BOARDS } from '../utils';
import BoardRow from './BoardRow';
import Drawer from './Drawer';
import FakeRow from './FakeRow';
import S from './styled';

const ListView = ({
  issuesByBoard,
  loading,
  startDate,
  endDate,
  openIssueModal,
  loadIssuesForBoard,
  issuesTotalTime,
}: IPropsView) => {
  const issuesCount = MCP_PA_TASKS_BOARDS.map(
    (board) => issuesByBoard[board.columnTitle].count
  ).reduce((total, count) => total + count);

  return (
    <Grid className="grid-content-drawer">
      <div className="drawer">
        <Drawer
          loading={loading}
          startDate={startDate}
          endDate={endDate}
          issuesCount={issuesCount}
          issuesTotalTime={issuesTotalTime}
        />
      </div>

      <div className="content">
        {loading ? (
          <>
            <S.InfoContainer>
              <Typography className="title-info">
                Coletando informações
              </Typography>
              <Typography className="text-info">
                Por favor, aguarde enquanto levantamos as informações
                solicitadas.
              </Typography>
            </S.InfoContainer>
            <FakeRow count={7} />
          </>
        ) : issuesCount > 1 ? (
          <Grid container={true} className="board">
            {MCP_PA_TASKS_BOARDS.map((board) => {
              const issuesPage = issuesByBoard[board.columnTitle];
              return (
                issuesPage.count >= 1 && (
                  <Grid item={true} xs={12}>
                    <S.TitleColumn>
                      <Typography className="title">
                        {board.columnTitle} {issuesPage.count}
                      </Typography>
                    </S.TitleColumn>
                    <BoardRow
                      loading={loading}
                      issuesPage={issuesPage}
                      issueModalHandler={openIssueModal}
                      loadIssuesForBoard={loadIssuesForBoard}
                      board={board}
                    />
                  </Grid>
                )
              );
            })}
          </Grid>
        ) : (
          <EmptyStateProject
            title="Esta lista está vazia"
            description="Essa busca não retornou tarefas. Tente filtrar um período diferente."
            height="100%"
          />
        )}
      </div>
    </Grid>
  );
};

export default ListView;
