import { css, styled } from '@cmp/ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import TableHead from '@material-ui/core/TableHead';
import theme from 'modules/common/styles/theme';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import TableContainer from '@material-ui/core/TableContainer';
import { paletteColor } from 'modules/common/styles/colors';

interface IStyledProps {
  active?: boolean;
  selected?: boolean;
  color?: string;
  buttonBorder?: boolean;
}

const HeaderMenu = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  overflow: auto;
  background: ${paletteColor.primary.white};
  display: flex;
  box-shadow: 0px 4px 4px rgba(223, 225, 235, 0.25);
  border-top: 1px solid ${paletteColor.info.border};
  padding: 0 24px;
`;

const CustomLink = styled.a`
  color: ${paletteColor.primary.main};
  text-decoration: none;
`;

const HeaderMenuItem = styled(Link)`
  position: relative;
  border: 0;
  display: flex;
  padding: 0 15px;
  color: ${paletteColor.info.soft};
  text-decoration: none;
  align-items: center;

  ${(props: IStyledProps) =>
    props.selected &&
    css`
      color: ${paletteColor.info.text};
      &:after {
        content: ' ';
        background: ${paletteColor.primary.main};
        height: 2px;
        width: 80%;
        position: absolute;
        bottom: 0;
        left: -30%;
        transform: translateX(50%);
      }
    `}
`;

const HeaderElementContainer = styled.div`
  display: flex;
  .select-box {
    width: 200px;
    padding-top: 8px;
    border-radius: 6px;
    min-height: 54px;
    &:before {
      display: none;
    }
    &:after {
      border: 1px solid ${paletteColor.primary.main};
    }
  }
`;
const AlertCell = styled.div`
  display: flex;
  letter-spacing: 0.03em;
  color: ${(props: IStyledProps) =>
    props.color ? props.color : paletteColor.dark.main};
  position: relative;
`;

const AlertRow = styled(Grid)<IStyledProps>`
  background: ${paletteColor.primary.white};
  box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
  border: ${(props) =>
    props.selected
      ? `1px solid ${paletteColor.primary.main}`
      : `1px solid ${paletteColor.info.border}`};
  border-radius: 6px;
  padding: 15px;
  display: flex;
  margin-bottom: 16px;
  width: 100%;
  position: relative;
  transition: all 200ms;

  &:before {
    content: '';
    position: absolute;
    background: ${paletteColor.primary.main};
    width: 3px;
    height: 0;
    left: 0;
    top: 25px;
    transform: translateY(-50%);
    border-radius: 0 5px 5px 0;
    transition: height 250ms;
  }
  &:hover {
    &:before {
      height: 24px;
    }
  }
`;

const ReservedTag = styled.div`
  display: inline;
  background: ${paletteColor.info.fill};
  border-radius: 3px;
  padding: 2px 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
`;

const SmallButton = styled(Button)`
  && {
    height: 24px;
    max-height: 24px;
    min-height: 24px;
    line-height: 10px;
    border: none;
  }
`;

const BoxCustom = styled.div`
  margin-top: 50px;
  padding: 30px;

  & .execution-action {
    margin-bottom: 20px;
  }

  @media (max-width: 600px) {
    padding: 30px 0;
  }
`;

const CustomSelect = styled(Select)`
  && {
    background-color: ${paletteColor.primary.white};
    width: 280px;
    height: 42px;
    border: 1px solid ${paletteColor.info.border};
    border-radius: 3px;
    padding: 8px;
    color: ${paletteColor.info.soft};
    font-size: 14px;
    letter-spacing: 0.03em;
    text-indent: 7px;
    margin-right: 20px;
    margin-bottom: 15px;

    &&:before {
      border-bottom: none;
    }

    & .MuiSelect-select {
      &:focus {
        background-color: transparent;
      }
      &:before {
        content: '';
        position: absolute;
        right: 16px;
        top: calc(50% - 6px);
        box-sizing: border-box;
        height: 8px;
        width: 8px;
        border-style: solid;
        border-color: ${paletteColor.info.soft};
        border-width: 0px 2px 2px 0px;
        transform: rotate(45deg);
      }

      &:after {
        content: '';
        position: absolute;
        border-left: 1px solid ${paletteColor.info.border};
        right: 34px;
        top: 10px;
        bottom: 10px;
        max-height: 90%;
        height: auto;
      }

      && .MuiMenu-paper {
        top: 140px !important;
      }
    }

    & .MuiSelect-icon {
      display: none;
    }
    & .Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }

    & .MuiListItem-button:hover {
      background: none;
    }

    @media (max-width: 600px) {
      margin-bottom: 15px;
      margin-right: 0;
      width: 100%;
    }
  }

  &&:after {
    background-color: ${paletteColor.primary.white};
    border-bottom: none;
  }
`;

const CustomSelectMultiple = styled(CustomSelect)`
  && {
    height: auto;
    overflow: hidden;
    min-height: 42px;
    white-space: nowrap;
    text-overflow: ellipsis;

    & .chip-container {
      display: flex;
      flex-wrap: wrap;
    }

    & .MuiChip-root {
      background-color: ${paletteColor.info.fill};
      color: ${paletteColor.info.soft};
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    & .MuiSvgIcon-root {
      flex-direction: row-reverse;
    }

    & .MuiChip-deleteIcon {
      color: ${paletteColor.info.soft};
      width: 18px;
      height: 18px;

      &:hover {
        color: ${paletteColor.dark.icon};
      }
    }

    & .MuiInputBase-input {
      padding: 0 24px 0 0;
    }
  }
`;

const FormControlCustom = styled(FormControl)`
  && {
    min-width: 120px;
    max-width: 300px;
  }
`;

const MenuItemCustom = styled(MenuItem)`
  && {
    padding: 5px;
  }
`;

const CustomMenuItem = styled(MenuItem)`
  && {
    padding: 0;
  }
`;

const MenuItemAccount = styled(MenuItem)`
  && {
    text-transform: capitalize;
    color: ${paletteColor.info.soft};
    &.MuiListItem-root.Mui-selected,
    &.MuiListItem-root.Mui-selected:hover,
    &.MuiListItem-button:hover {
      background: ${paletteColor.info.fill};
    }
  }
`;

const DescriptionPreventive = styled(Typography)`
  && {
    color: ${paletteColor.info.soft};
    margin-bottom: 16px;
    font-size: 12px;
    padding-left: 78px;

    ${theme.breakpoints.down('sm')} {
      padding-left: 48px;
    }
  }
`;

const DialogCustom = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: 512px;
  }

  & .dialogSuccess {
    background: ${paletteColor.success.main};
    height: 52px;
    position: relative;
    display: flex;
    justify-content: center;

    & .icon-success {
      background: ${paletteColor.primary.white};
      color: ${paletteColor.success.main};
      width: 50px;
      height: 50px;
      border-radius: 50px;
      position: absolute;
      bottom: -12px;
      font-size: 33px;
      border: 4px solid ${paletteColor.success.main};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .title-success {
    text-align: center;
    margin-top: 20px;
    padding: 8px;

    & .MuiTypography-h6 {
      font-size: 14px;
      font-weight: 600;
    }
  }

  & .MuiDialogContent-root {
    width: 70%;
    margin: 0 auto;
    text-align: center;

    & .MuiTypography-body1 {
      letter-spacing: 0.03em;
      &:first-child {
        margin-bottom: 22px;
      }
      & .link-history {
        color: ${paletteColor.primary.main};
        margin-left: 5px;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 600px) {
    & .MuiDialog-paperScrollPaper {
      margin: 1px;
      width: 95%;
    }

    & .MuiDialogContent-root {
      width: 100%;
    }
  }
`;

const DialogTitleCustom = styled(DialogTitle)`
  && {
    background: ${paletteColor.primary.main};
    color: white;

    & .MuiTypography-h6 {
      font-size: 16px;
    }
  }
`;

const ExecutionTitle = styled(Typography)`
  && {
    font-weight: 600;
    letter-spacing: 0.03em;
    margin-top: 20px;
  }
`;

const DialogExecute = styled(Dialog)`
  & .MuiPaper-elevation24 {
    box-shadow: none;
    margin: 1px;
  }

  & .alignTooltip {
    display: flex;
    align-items: center;
  }

  & ul {
    padding-left: 18px;
    line-height: 2;
  }
`;

const DialogActionsPreventive = styled(DialogActions)`
  && {
    padding: 18px 12px;

    & .btnExecute {
      min-height: 44px;
      max-height: 44px;
      width: 104px;
    }
  }
`;

const Flag = styled(Typography)`
  && {
    background: ${paletteColor.info.fill};
    color: ${paletteColor.info.soft};
    font-size: 12px;
    font-weight: 500;
    padding: 0 8px;
    border-radius: 3px;
    height: 24px;
    line-height: 24px;
    margin-right: 4px;

    @media (max-width: 320px) {
      margin-right: 3px;
    }
  }
`;

const FlagContainer = styled(Box)`
  && {
    display: flex;
    justify-content: flex-end;
  }
`;

const CheckboxGrid = styled.div`
  && {
    padding: 7px 24px 10px 9px;
    display: flex;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      padding-right: 16px;
      padding-left: 0;
    }
  }
`;

const TypographyCategory = styled(Typography)`
  && {
    font-weight: bold;
    padding-left: 24px;
    ${theme.breakpoints.down('sm')} {
      padding-left: 16px;
    }
  }
`;

const LargeButton = styled(Button)`
  && {
    width: 176px;
    height: 42px;
    border-radius: 3px;
    padding: 5px;
    font-size: 14px;
    letter-spacing: 0.03em;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const PreventiveTitle = styled(Typography)`
  && {
    margin-top: 10px !important;
  }
`;

const CustomChip = styled(Chip)`
  && .MuiChip-label {
    padding-left: 2px;
    text-transform: capitalize;
  }
`;

const ButtonResult = styled(Button)`
  && {
    width: 104px;
  }
`;

export const ButtonProgress = styled(CircularProgress)`
  margin-left: 15px;
  margin-right: 14px;
  margin-bottom: 1px;
`;

const TableHeaderCustom = styled(TableContainer)`
  && {
    overflow: hidden;
  }
`;

const TableHeader = styled(TableHead)`
  && {
    background: ${paletteColor.primary.main};
    border-radius: 6px 6px 0px 0px;

    ${theme.breakpoints.down('sm')} {
      && {
        width: 100%;
        border-radius: 6px;

        & .MuiTableCell-root {
          border: none;
        }
      }
    }
  }

  & th {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    height: 60px;
  }

  & th:first-child {
    border-top-left-radius: 6px;
    padding: 0;
  }
  & th:last-child {
    border-top-right-radius: 6px;
  }

  & .MuiTableCell-paddingCheckbox {
    max-width: 60px;
  }
`;

const TableBodyCustom = styled(TableBody)`
  && {
    & .MuiTableCell-root {
      font-size: 14px;
    }
    & .MuiTableRow-root.Mui-selected {
      background-color: ${paletteColor.primary.white};
    }
  }
`;

const TableRowContent = styled(TableRow)`
  && {
    background: ${paletteColor.primary.white};
  }
`;

const TableCellContent = styled(TableCell)`
  && {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const TableCollapseContainer = styled(Box)`
  && {
    display: flex;
    align-items: center;
  }
  & .MuiTypography-root {
    padding-left: 22px;
  }
`;

const TableRowCollapse = styled(TableRowContent)`
  && {
    background: ${paletteColor.primary.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const TableCellTitle = styled(TableCell)`
  && {
    color: ${paletteColor.primary.white};
  }
`;

const PaperCustom = styled(Paper)`
  && {
    display: flex;
    flex-direction: column;
    padding: 8px;
    box-shadow: 0px 4px 6px rgba(223, 225, 235, 0.5);
    border-radius: 3px;
  }
`;

const CheckboxCustom = styled(Checkbox)`
  && {
    color: ${paletteColor.primary.white};
  }

  & th {
    min-width: 30px;
  }
`;

const ButtonContent = styled(MenuItem)`
  && {
    color: ${paletteColor.info.soft};

    &:hover,
    &:active,
    &:focus {
      background: rgba(242, 101, 34, 0.1);
      color: ${paletteColor.primary.main};
      font-weight: 500;
    }
  }
`;

const ButtonSelectContainer = styled(Button)`
  && {
    color: transparent;
    min-width: 10px;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      background: transparent;
    }
  }

  & .icon-color {
    color: ${paletteColor.primary.white};
  }
`;

const DialogCustomFail = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: 512px;
    min-height: 295px;
    min-width: 590px;
  }

  & .dialogFail {
    background: ${paletteColor.error.main};
    height: 52px;
    position: relative;
    display: flex;
    justify-content: center;

    & .icon-fail {
      background: ${paletteColor.primary.white};
      color: ${paletteColor.error.main};
      width: 50px;
      height: 50px;
      border-radius: 50px;
      position: absolute;
      bottom: -12px;
      font-size: 33px;
      border: 4px solid ${paletteColor.error.main};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .title-fail {
    text-align: center;
    margin-top: 20px;
    padding: 8px;

    & .MuiTypography-h6 {
      font-size: 14px;
      font-weight: 600;
    }
  }

  & .MuiDialogContent-root {
    width: 70%;
    margin: 0 auto;
    text-align: center;

    & .MuiTypography-body1 {
      letter-spacing: 0.03em;
      &:first-child {
        margin-bottom: 22px;
      }
    }
  }

  @media (max-width: 600px) {
    & .MuiDialog-paperScrollPaper {
      margin: 1px;
      width: 95%;
      max-height: 500px;
      min-height: 100px;
      min-width: 100px;
    }

    & .MuiDialogContent-root {
      width: 100%;
    }
  }
`;

export default {
  CustomLink,
  HeaderMenu,
  HeaderMenuItem,
  ReservedTag,
  AlertRow,
  AlertCell,
  HeaderElementContainer,
  SmallButton,
  CustomMenuItem,
  MenuItemCustom,
  MenuItemAccount,
  CustomSelect,
  CustomSelectMultiple,
  DescriptionPreventive,
  DialogCustom,
  DialogExecute,
  DialogActionsPreventive,
  FlagContainer,
  Flag,
  CheckboxGrid,
  TypographyCategory,
  LargeButton,
  PreventiveTitle,
  CustomChip,
  DialogTitleCustom,
  ExecutionTitle,
  BoxCustom,
  ButtonProgress,
  ButtonResult,
  FormControlCustom,
  TableHeader,
  TableHeaderCustom,
  TableBodyCustom,
  TableRowContent,
  TableCellContent,
  TableCollapseContainer,
  TableRowCollapse,
  TableCellTitle,
  PaperCustom,
  CheckboxCustom,
  ButtonContent,
  ButtonSelectContainer,
  DialogCustomFail,
};
