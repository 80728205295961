import * as React from 'react';
import * as S from './styled';

type IProps = {
  count: number;
};

export default function FakeLoaderMobile(props: IProps) {
  return (
    <>
      {new Array(props.count)
        .fill(true)
        .map((value: boolean, index: number) => (
          <S.FakeCardMobile animationDelay="0" key={`fake_${index}`}>
            <S.Fake width={180} height={20} mb={10} />
            {Array.from(Array(4).keys()).map((_, key) => (
              <S.Fake key={key} width="100%" height={30} mb={7} />
            ))}
          </S.FakeCardMobile>
        ))}
    </>
  );
}
