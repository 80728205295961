import { styled, css, effects } from '@cmp/ui';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import theme from 'modules/common/styles/theme';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import { paletteColor } from 'modules/common/styles/colors';

interface IStyledProps {
  width?: string | number;
  height?: string | number;
  mb?: string | number;
}

export const NavButtonGroup = styled(ButtonGroup)`
  background: ${paletteColor.primary.white};
  box-sizing: border-box;
  border-radius: 3px;
  height: 42px;
  margin-left: 16px;

  ${theme.breakpoints.down('sm')} {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 16px;
  }
`;

export const NavButton = styled(Button)`
  && {
    border: 1px solid ${paletteColor.info.border} !important;
    color: ${paletteColor.info.soft};
    &:after {
      content: '';
      background: ${paletteColor.primary.main};
      height: 2px;
      width: 0;
      position: absolute;
      bottom: 0;
      left: -30%;
      transform: translateX(50%);
      border-radius: 3px 3px 0 0;
      transition: width 250ms;
    }
    ${(props) =>
      props.selected &&
      css`
        color: ${paletteColor.info.text};
        &:after {
          width: 72%;
          left: -22%;
        }
      `}
  }
`;

export const BoxContainer = styled.div`
  background: ${paletteColor.info.fill};
  border-radius: 10px;
  padding: 20px;
  margin: 16px 0;
  ${effects.slideUp};
  animation-delay: 500ms;
  animation-fill-mode: forwards;

  & .item-mobile {
    display: none;
  }

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: flex-start;

    & .MuiToolbar-gutters {
      padding: 0;
    }
    & .MuiTablePagination-toolbar {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
    & .MuiTablePagination-actions {
      margin-left: 0;
    }
    & .MuiIconButton-root {
      padding: 8px;
    }
    & .MuiTablePagination-caption {
      grid-column-start: 1;
    }
    & .MuiTablePagination-select,
    .MuiTablePagination-selectRoot {
      width: 48px;
    }

    .MuiTablePagination-selectRoot {
      margin-left: 14px;
    }

    & .text-mobile {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 600px) {
    padding: 10px;

    & .item-mobile {
      display: block;
    }
  }

  & .MuiTablePagination-selectRoot {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: 10px;
      top: 10px;
      width: 8px;
      height: 8px;
      border-style: solid;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      border-color: ${paletteColor.info.soft};
    }
  }

  & .MuiTablePagination-selectIcon {
    color: transparent;
  }
`;

export const TableContainerCustom = styled(TableContainer)`
  && {
    background: ${paletteColor.primary.white};

    ${theme.breakpoints.down('sm')} {
      border-radius: 6px;
    }
  }
`;

export const ScheduleWrapper = styled.div`
  display: flex;
  margin-top: 50px;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 5px 20px 0px 0px;

  ${theme.breakpoints.down('md')} {
    align-items: flex-start;
    flex-direction: column;
    padding: 0px;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;

  & .date-picker {
    max-width: calc(250px - 50px);
  }

  ${theme.breakpoints.down('sm')} {
    align-items: flex-start;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

export const SearchWrapper = styled.div`
  margin-left: 16px;
  max-width: 300px;
  width: 100%;

  ${theme.breakpoints.down('md')} {
    margin-left: 0;
    margin-top: 8px;
  }
`;

export const TypographyCustom = styled(Typography)`
  && {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: ${paletteColor.info.text};
    padding: 0 16px;
    color: ${paletteColor.info.soft};

    &:first-child {
      padding-left: 0;
    }

    @media (max-width: 768px) {
      padding: 5px 5px 5px 5px;
    }

    ${theme.breakpoints.down('sm')} {
      && {
        margin: 5px 0;
      }
    }
  }
`;

export const ButtonCustom = styled(Button)`
  && {
    height: 42px;
    background-color: rgba(0, 0, 0, 0.04);
    margin-left: 16px;
    padding: 0 16px;
    background: ${paletteColor.info.background};
    font-size: 12px;
    width: 108px;
    border-radius: 3px;

    &:hover {
      background-color: ${paletteColor.info.background};
    }

    && .MuiButton-label {
      text-transform: none;
      font-weight: normal;
    }

    ${theme.breakpoints.down('sm')} {
      margin-left: 0;
      margin-top: 6px;
    }
  }
`;

export const EmptyState = styled.div`
  ${effects.slideUp};
  color: ${paletteColor.info.soft};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;

  & .title {
    font-weight: 500;
    margin-top: 20px;
  }
`;

export const ScheduleTableCell = styled(TableCell)`
  && {
    padding: 16px 39px;
    font-size: 14px;
    max-width: 315px;
    height: 56px;

    @media (max-width: 600px) {
      display: none;
    }
  }
`;

export const ScheduleHeaderCell = styled(TableCell)`
  && {
    align-items: center;
    font-size: 14px;
    color: white;
  }
`;

export const ScheduleHeaderCellName = styled(ScheduleHeaderCell)`
  && {
    min-width: 500px;
    padding: 0 20px 0px 40px;
    height: 60px;

    @media (min-width: 767px) {
      min-width: 200px;
    }

    @media (min-width: 1024px) {
      min-width: 253px;
    }
  }
`;

export const ScheduleHead = styled(TableHead)`
  && {
    background: ${paletteColor.primary.main};

    & tr th:first-child {
      border-top-left-radius: 6px;
    }

    & th:last-child {
      border-top-right-radius: 6px;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }
`;

export const UndefinedDate = styled(Typography)`
  && {
    color: ${paletteColor.info.text};
  }
`;

export const ArrowUpIcon = styled(KeyboardArrowUpIcon)`
  && {
    fill: white;
  }
`;

export const ArrowDownIcon = styled(KeyboardArrowDownIcon)`
  && {
    fill: white;
  }
`;

export const AlertCell = styled.div`
  && {
    display: flex;
    letter-spacing: 0.03em;
    color: ${paletteColor.primary.white};
    font-weight: 600;
    width: 100%;
  }
`;

export const TableMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
    border-bottom: 1px solid ${paletteColor.info.border};
    &:last-child {
      border-bottom: none;
    }

    & .card-items {
      padding: 0px 20px;

      & .items {
        border-bottom: 1px solid ${paletteColor.info.fill};
        padding: 4px 0px;
        display: flex;
        align-items: center;

        &:last-child {
          border: none;
        }
      }
    }
  }
`;

export const AlertCustom = styled(AlertCell)`
  && {
    color: ${paletteColor.dark.main};
    padding: 18px 18px 14px 18px;
  }
`;

export const TypographyItem = styled(Typography)`
  && {
    color: ${paletteColor.info.soft};
    font-size: 12px;
    letter-spacing: 0.03em;
  }
`;

export const AlertRow = styled(Grid)`
  background: ${paletteColor.primary.main};
  box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
  border-radius: 6px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  position: relative;
  height: 56px;

  & .item-mobile {
    display: none;
  }

  @media (max-width: 425px) {
    display: block;

    & .item-mobile {
      display: block;
    }
  }
`;

export const TypographyDate = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  padding: 6px 8px;
  border-radius: 3px;
  color: ${paletteColor.info.text};
`;

export const TableText = styled(Typography)`
  display: inline-block;
  text-align: left;
`;

export const FakeMargin = styled.div`
  margin-top: 50px;
`;

export const Pagination = styled(TablePagination)`
  && {
    border-bottom: none;
    color: ${paletteColor.info.soft};
    font-size: 12px;
    letter-spacing: 0.03em;
    font-size: 12px;
  }

  && .MuiTypography-body2 {
    font-weight: 500;
  }

  && .MuiSelect-root,
  .MuiSelect-select {
    color: transparent;
  }

  & .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: red;
  }

  && .MuiTablePagination-select {
    text-align: center;
    padding-left: 0;
    padding-right: 7px;
    text-align-last: center;
    background: transparent;
    z-index: 9;
  }
`;
