import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ISummary } from '../types';
import * as S from './Styled';
import moment from 'moment';
import { paletteColor } from 'modules/common/styles/colors';

interface IProps {
  data: ISummary[];
}

const formatValue = (value: string) => {
  return value + ' USD';
};

const formatDate = (value: string) => {
  return moment(value).format('MMM/YY');
};

const CustomChartTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <S.ChartTooltip>
        <Typography component="p">{formatValue(payload[0].value)}</Typography>
      </S.ChartTooltip>
    );
  }

  return null;
};

const BillingChart = ({ data }: IProps) => {
  return (
    <Grid container={true} spacing={2}>
      <Grid container={true} item={true} xs={12} justify="center">
        <Grid item={true} xs={12}>
          <S.GraphContainer>
            <ResponsiveContainer width="100%" height={180}>
              <LineChart className="graphic-general" data={data}>
                <CartesianGrid strokeWidth="0.8" strokeDasharray="1 1" />
                <XAxis dataKey="month" tickFormatter={formatDate} />
                <YAxis />
                <Tooltip content={CustomChartTooltip} />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke={paletteColor.third.orange}
                  strokeWidth={2}
                  dot={false}
                  activeDot={{ r: 4 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </S.GraphContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BillingChart;
