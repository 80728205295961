import * as React from 'react';
import Box from '@material-ui/core/Box';
import { Input } from '@cmp/ui';
import { ReactComponent as IconSearch } from 'modules/common/icons/icon-search.svg';
import { IIssueFilter } from '../types';

interface IProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filter: IIssueFilter;
}

const SearchSchedule = ({ onChange, filter }: IProps) => {
  return (
    <>
      <Box display="flex" alignItems="center" data-cy="input-search">
        <Input
          type="text"
          text="Digite aqui para buscar"
          Icon={IconSearch}
          value={filter.text}
          onChange={onChange}
          fullWidth
        />
      </Box>
    </>
  );
};

export default SearchSchedule;
