import Calendar from 'modules/common/components/Calendar';
import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import client from '../../../apolloClient';
import { IWorkspace } from '../../auth/types';
import { queries } from 'modules/worklog/graphql';
import config from '../../../utils/config';
import axios from 'axios';
import { getItem } from '../../../utils/storage';

import {
  IProject,
  ISummaryQuarter,
  ISummary,
  ISupportPlan,
} from 'modules/common/types';

interface IProps {
  dateSelected?: string;
  workspace?: IWorkspace;
  workspaceCode?: string;
  projectSelected?: IProject;
  handleChangeDateSelected?: (
    year: string,
    month: string
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  stepsJoyride?: any[];
  setStepsJoyride?: (stepsJoyride: any[]) => void;
  showActive?: boolean;
}

interface IState {
  loading: boolean;
  boxCalendarRef: any;
  currentMonth?: string;
  poolSize: number;
  summary: ISummaryQuarter[];
}

export default class CalendarContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      summary: [],
      poolSize: 0,
      loading: true,
      boxCalendarRef: React.createRef(),
    };
  }

  public async componentDidMount() {
    this.mountCalendar();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (
      this.props.workspaceCode !== prevProps.workspaceCode ||
      this.props.projectSelected !== prevProps.projectSelected
    ) {
      this.setState({ loading: true });
      this.mountCalendar();
    }
  }

  public async getSupportPlan(
    companyId?: string
  ): Promise<{ supportPlanByMonth?: ISupportPlan[] }> {
    try {
      const { data }: any = await client.query({
        query: gql(queries.supportPlanByMonth),
        variables: {
          companyId: companyId,
          projectKey: this.props.projectSelected?.key,
        },
      });
      return data;
    } catch (error) {
      return {};
    }
  }

  public async getWorklogSummary(
    workspace: string,
    project: string
  ): Promise<{ worklogsByMonth?: ISummary[] }> {
    try {
      const { data }: any = await client.query({
        query: gql(queries.worklogsByMonth),
        variables: {
          workspace,
          project,
        },
      });

      if (data['worklogsByMonth']) {
        const additional_data: any = await axios.get(
          `${config.publicJiraAPI}/public_tickets/gethours`,
          {
            headers: {
              'x-access-token': getItem(
                config.constants.devopsAuthCookie,
                'cookies'
              ),
            },
          }
        );

        if (Array.isArray(additional_data.data)) {
          additional_data.data.forEach((item: any) => {
            const matched_entry = data['worklogsByMonth'].find((worklog) => {
              return worklog.month === item.month;
            });
            if (matched_entry) {
              matched_entry.value += item.value;
            } else {
              data['worklogsByMonth'].push({
                month: item.month,
                value: item.value,
              });
            }
          });
        }
      }

      return data;
    } catch (error) {
      return {};
    }
  }

  public getSummaryByQuarter(
    summary: ISummary[],
    supportPlan: ISupportPlan[]
  ): ISummaryQuarter[] {
    return summary.reduce((resume: ISummaryQuarter[], data: ISummary) => {
      if (data.value < 0) {
        return resume;
      }
      const year = moment(data.month, 'YYYY-MM').format('YYYY');
      const quarter = moment(data.month, 'YYYY-MM').quarter();
      const current = resume[year] || {};
      const plan = supportPlan.find(
        ({ month }: { month: string }) => month === data.month
      ) || { pool_size: 0 };

      if (!current[quarter]) {
        current[quarter] = { list: [], timeSpent: 0, poolSize: 0 };
      }
      current[quarter].list.push({
        date: data.month,
        poolSize: plan.pool_size,
        timeSpent: data.value,
      });
      current[quarter].timeSpent += data.value;
      current[quarter].poolSize += plan.pool_size;
      return Object.assign([], resume, { [year]: current });
    }, []);
  }

  public async mountCalendar() {
    const { workspaceCode, workspace, projectSelected } = this.props;
    if (!workspaceCode || !workspace || !projectSelected) {
      return this.setState({ summary: [], poolSize: 0, loading: false });
    }
    const companyId = workspace.company.id;
    const { worklogsByMonth } = await this.getWorklogSummary(
      workspaceCode,
      projectSelected.key
    );
    const { supportPlanByMonth } = await this.getSupportPlan(companyId);

    const filterWorklogsByMonth = supportPlanByMonth!.map(
      (supportPlanByMonthModel) => {
        const filterWorklogsByMonth = worklogsByMonth!.find(
          (worklogsByMonthConsumed) =>
            supportPlanByMonthModel.month === worklogsByMonthConsumed.month
        );
        if (filterWorklogsByMonth === undefined) {
          return {
            month: supportPlanByMonthModel.month,
            value: 0,
          };
        } else {
          return filterWorklogsByMonth;
        }
      }
    );

    this.setState(
      {
        summary: this.getSummaryByQuarter(
          filterWorklogsByMonth,
          supportPlanByMonth!
        ),
        loading: false,
      },
      () => {
        this.state.boxCalendarRef.current.scrollBy({
          left: this.state.boxCalendarRef.current.scrollWidth,
          behavior: 'smooth',
        });
      }
    );
  }

  public render() {
    return (
      <Calendar
        dateSelected={this.props.dateSelected}
        handleChangeDateSelected={this.props.handleChangeDateSelected}
        loading={this.state.loading}
        summary={this.state.summary}
        boxCalendarRef={this.state.boxCalendarRef}
        showActive={this.props.showActive}
      />
    );
  }
}
