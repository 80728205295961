import { EmptyState } from '@cmp/ui';
import { IIssue } from 'modules/support/types';
import React from 'react';
import { IWorklog, PageResults } from '../types';
import FakeLoaderTasks from './FakeLoaderTasks';
import RowWorklog from './RowWorklog';
import { ReactComponent as EmptyBoard } from 'modules/common/images/empty_board.svg';
import Typography from '@material-ui/core/Typography';
import InfiniteScroller from './InfiniteScroller';

interface IProps {
  loading: boolean;
  worklogList: PageResults;
  issueModalHandler: (issueId: IIssue['id']) => void;
  loadMore: (page: number) => Promise<void>;
}

export default function WorklogCardTaskList({
  loading,
  worklogList,
  issueModalHandler,
  loadMore,
}: IProps) {
  const [pageLastIndex, setPageLastIndex] = React.useState<number[]>([]);
  React.useEffect(() => {
    setPageLastIndex((pageLastIndex) => {
      pageLastIndex[worklogList.currentPage] = worklogList.results.length;
      return pageLastIndex;
    });
  }, [worklogList]);

  return (
    <>
      {loading ? (
        <FakeLoaderTasks />
      ) : !worklogList.results.length ? (
        <EmptyState>
          <EmptyBoard />
          <Typography component="h1">
            Não há registros de trabalho neste mês
          </Typography>
        </EmptyState>
      ) : (
        <InfiniteScroller
          onLoad={() => loadMore(worklogList.currentPage + 1)}
          hasMore={worklogList.currentPage < worklogList.totalPages}
        >
          {worklogList.results.map(
            (worklog: IWorklog, worklogIndex: number) => (
              <RowWorklog
                key={`task_${worklog.issue.key}`}
                index={worklogIndex}
                pageLastIndex={pageLastIndex}
                currentPage={worklogList.currentPage}
                timeSpent={worklog.timeSpentSeconds}
                issueId={worklog.issue.id}
                issueKey={worklog.issue.key}
                startedAt={worklog.started}
                summary={worklog.issue.summary}
                issueModalHandler={issueModalHandler}
                isJira={worklog.isJira ? worklog.isJira : ''}
              />
            )
          )}
        </InfiniteScroller>
      )}
    </>
  );
}
