import React from 'react';
import * as S from './styled';
import Grid from '@material-ui/core/Grid';
import { IWorklog } from '../../types';
import { timeSpentToString } from 'modules/common/utils/worklog';
import moment from 'moment';
import ImgModalWorklog from 'modules/common/images/empty_modal_worklog.png';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { sanitize } from 'dompurify';

const WorklogEmptyState = () => {
  return (
    <S.EmptyState>
      <Box>
        <img
          src={ImgModalWorklog}
          alt="Nuvem com dois relógios em sua frente simbolizando as horas de trabalho"
        />
      </Box>
      <Box className="content-empty-state">
        <S.TitleEmptyState>
          Ainda não há horas registradas para esta tarefa!
        </S.TitleEmptyState>
        <Typography>
          Não se preocupe, quando houver algum registro, ele aparecerá aqui!
        </Typography>
      </Box>
    </S.EmptyState>
  );
};

const WorklogSection = ({ worklogs }: { worklogs: IWorklog[] }) => {
  return (
    <>
      {worklogs.length ? (
        <>
          {worklogs.map((worklog, index) => (
            <S.SectionTabContent key={`section-tab-content-${index}`}>
              <Grid item>
                <S.AuthorAvatar>
                  {worklog.author.displayName.charAt(0)}
                </S.AuthorAvatar>
              </Grid>
              <S.GridWrapper>
                <S.TypographyAuthor>
                  {worklog.author.displayName} <span>registrou </span>
                  {timeSpentToString(worklog.timeSpentSeconds, true) || '0h'}
                  <span> em </span>
                  {moment(worklog.started).format('DD/MM/YYYY')}
                  <span> às </span>
                  {moment(worklog.started).format('HH:mm')}
                </S.TypographyAuthor>
                <Grid>
                  {worklog.comment && (
                    <S.DangerInsert
                      dangerouslySetInnerHTML={{
                        __html: sanitize(worklog.comment),
                      }}
                    />
                  )}
                </Grid>
              </S.GridWrapper>
            </S.SectionTabContent>
          ))}
        </>
      ) : (
        <WorklogEmptyState />
      )}
    </>
  );
};

export default WorklogSection;
