import * as React from 'react';
import { Submenu } from 'modules/layout/components';
import { submenuItens } from '../utils/constants';
import ButtonReportOurExecution from '../components/ButtonReportOurExecution';
import ReportDownload from '../components/ReportDownload';
import * as S from '../components/styled';

const ReportContainer = () => {
  return (
    <>
      <ButtonReportOurExecution selected="report" />
      <Submenu selected="history" items={submenuItens} />
      <S.AlignSpace />
      <ReportDownload />
      <S.SpaceFooter />
    </>
  );
};

export default ReportContainer;
