import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import FakeLoaderTasks from './FakeLoaderTasks';

type IProps = React.Props<HTMLElement> & {
  hasMore: boolean;
  onLoad: () => Promise<void>;
};

class InfiniteScroller extends React.Component<IProps> {
  public render() {
    return (
      <InfiniteScroll
        hasMore={this.props.hasMore}
        loadMore={this.props.onLoad}
        loader={<FakeLoaderTasks key={`loader_infinite_scroll`} />}
      >
        {this.props.children}
      </InfiniteScroll>
    );
  }
}

export default InfiniteScroller;
