import { createMuiTheme } from '@material-ui/core/styles';
import { paletteColor } from './colors';

const theme = createMuiTheme({
  palette: {
    primary: { main: paletteColor.primary.main },
    secondary: { main: paletteColor.primary.white },
  },
  typography: {
    fontSize: 14,
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '12px 16px',
      },
      label: {
        textTransform: 'capitalize',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
});

export default theme;
