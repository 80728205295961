import withTracker from 'modules/common/hoc/withTracker';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { WorklogContainer } from './containers';
import { StatusContainer } from '../status/containers';

const WorklogWithTracker = withTracker(WorklogContainer, {});

const routes = (props) => (
  <Switch>
    <Route path={props.match.path} component={WorklogWithTracker} />
    <Route path="*" component={StatusContainer} />
  </Switch>
);

export default routes;
