import gql from 'graphql-tag';

const getProjects = gql`
  query projects($workspace: String!) {
    projects(workspace: $workspace) {
      key
    }
  }
`;

const getSprints = gql`
  query sprints($workspace: String!, $project: String!) {
    sprints(
      filter: { workspace: $workspace, project: $project }
      page: { size: 5 }
    ) {
      results {
        id
        name
        state
        endDate
        startDate
      }
    }
  }
`;

const issueResultFragment = `
  count
  totalPages
  results {
    id
    issueKey: key
    summary
    priority
    issuetype {
      name
    }
    status {
      id
    }
    worklogs{
      totalTimeSpent
    }
  }
`;

const getBacklogIssues = gql`
  query backlogIssues($workspace: String!, $project: String!) {
    backlogIssues(filter: {workspace: $workspace, project: $project}) {
      ${issueResultFragment}
    }
  }
`;

const getSprintIssues = gql`
  query issues($workspace: String!, $sprint: Int!, $project: String!) {
    issues(filter: { workspace: $workspace, sprint: $sprint, project: $project }, page: {size: 1000}) {
      ${issueResultFragment}
    }
  }
`;

const getIssues = gql`
  query issues(
    $workspaceCode: String!
    $projectKey: String!
    $issueType: projectType!
    $startDate: String!
    $endDate: String!
    $status: [Int]
    $page: Int
  ) {
    issues(
      filter: {
        workspace: $workspaceCode
        project: $projectKey
        type: $issueType
        historyDates: { start: $startDate, end: $endDate }
        status: $status
      }
      page: { size: 25, current: $page }
    ) {
      count
      totalPages
      results {
        id
        issueKey: key
        summary
        priority
        issuetype {
          name
        }
        status {
          id
          name
        }
        worklogs {
          totalTimeSpent
        }
        subtasks {
          id
          issueKey: key
          summary
          priority
          worklogs {
            totalTimeSpent
          }
          issuetype {
            name
          }
          status {
            id
          }
        }
      }
    }
  }
`;

const getIssuesTotalTime = gql`
  query issuesTotalTime(
    $workspaceCode: String!
    $projectKey: String!
    $issueType: projectType!
    $startDate: String!
    $endDate: String!
    $status: [Int]
  ) {
    issuesTotalTime(
      filter: {
        workspace: $workspaceCode
        project: $projectKey
        type: $issueType
        historyDates: { start: $startDate, end: $endDate }
        status: $status
      }
    )
  }
`;

export default {
  getSprints,
  getBacklogIssues,
  getSprintIssues,
  getProjects,
  getIssues,
  getIssuesTotalTime,
};
