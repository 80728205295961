import Grid from '@material-ui/core/Grid';
import { Main, Title } from 'modules/common/components/Utils';
import * as React from 'react';
import { IResource } from '../types';
import ResourceBox from './ResourceBox';
import * as S from './styled';
import Skeleton from '@material-ui/lab/Skeleton';
import { Input } from '@cmp/ui';
import Box from '@material-ui/core/Box';
import { ReactComponent as SearchIcon } from 'modules/common/icons/icon-search.svg';

interface IProps {
  workspaceCode: string;
  reload: boolean;
  setReload: (value: boolean) => void;
  getResources: (workspaceCode: string) => Promise<any>;
  handleEditResource: React.Dispatch<React.SetStateAction<string | null>>;
  handleDeleteResource: React.Dispatch<React.SetStateAction<string | null>>;
}

const ResourceList = ({
  workspaceCode,
  reload,
  setReload,
  getResources,
  handleEditResource,
  handleDeleteResource,
}: IProps) => {
  const [resources, setResources] = React.useState<IResource[]>([]);
  const [searchField, setSearchField] = React.useState<string>('');
  const [resourceFiltered, setResourceFiltered] = React.useState<IResource[]>(
    []
  );

  const loadResources = React.useCallback(async () => {
    try {
      const response = await getResources(workspaceCode);
      setResources(response);
      setResourceFiltered(response);
    } catch (error) {
      console.error(error);
      setResources([]);
    } finally {
      setReload(false);
    }
  }, [setReload, getResources, workspaceCode]);

  React.useEffect(() => {
    setReload(true);
    setResources([]);
  }, [workspaceCode, setReload]);

  React.useEffect(() => {
    if (reload) {
      setTimeout(() => loadResources(), 1000);
    }
  }, [reload, loadResources]);

  const filterResource = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const regex = new RegExp(value, 'gi');
    setSearchField(value);
    setResourceFiltered(
      resources.filter((resource) => resource.name.match(regex))
    );
  };

  const renderResources = () => {
    if (reload) {
      return Array.from(Array(6).keys()).map((_, key) => (
        <Skeleton key={key} height={52} />
      ));
    }

    if (!resourceFiltered.length) {
      return (
        <Box textAlign="center" m={1}>
          Nenhum recurso encontrado
        </Box>
      );
    }

    return resourceFiltered.map((resource: IResource, index: number) => (
      <S.BoxResource key={`resource_${index}`}>
        <ResourceBox
          id={resource.id}
          name={resource.name}
          version={resource.version}
          handleDelete={() => handleDeleteResource(resource.id)}
          handleEdit={() => handleEditResource(resource.id)}
        />
      </S.BoxResource>
    ));
  };

  return (
    <Main>
      <Grid container={true} justify="center">
        <Grid item={true} xs={12} sm={6}>
          <Box mb={1} data-cy="field-search">
            <Input
              type="text"
              text="Digite aqui para buscar um recurso"
              value={searchField}
              onChange={filterResource}
              fullWidth={true}
              Icon={SearchIcon}
            />
          </Box>
          <Title>Recursos ({resources.length})</Title>
          {renderResources()}
        </Grid>
      </Grid>
    </Main>
  );
};

export default ResourceList;
