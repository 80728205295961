import client from 'apolloClient';
import gql from 'graphql-tag';
import { IWorkspace } from 'modules/auth/types';
import EmptyStateProject from 'modules/common/components/EmptyStateProject';
import SelectProject from 'modules/common/components/SelectProject';
import { withAppContext } from 'modules/common/hoc/withAppContext';
import { IIssueByKey, IFullProject, IProject } from 'modules/common/types';
import { ILocation, SelectedIssueTabEnum } from 'modules/support/types';
import {
  MCP_WORKSPACE_INDEX,
  MCP_PA_PROJECT,
} from 'modules/common/utils/constants';
import Submenu from 'modules/layout/components/Submenu';
import { ISubmenuItem } from 'modules/layout/types';
import * as React from 'react';
import {
  createOrUpdateItemAtBrowserDB,
  getItem,
  getStateFromBrowserDB,
} from '../../../utils/storage';
import { CalendarPunctualContainer, ListWorklog } from '../components';
import CalendarContainer from 'modules/common/components/CalendarContainer';
import EmptyState from '../components/EmptyState';
import { queries } from '../graphql';
import { MCP_PA_WORKLOG, MCP_WORKLOG_JOYRIDE_STEPS } from '../utils/constants';
import * as S from '../components/styled';

interface IProps {
  workspace: IWorkspace;
  workspaceCode: string;
  stepsJoyride?: any[];
  setStepsJoyride: (stepsJoyride: any[]) => void;
  setProjectSelected: (projectSelected: IProject | undefined) => void;
  projectSelected?: IProject;
  location: ILocation;
}

interface IState {
  dateSelected: string;
  submenuItens: ISubmenuItem[];
  issueToBeOpened?: number;
  selectedTab?: SelectedIssueTabEnum;
}

class WorklogContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dateSelected: '',
      submenuItens: [
        {
          key: 'schedule',
          name: 'Cronograma',
          to: '/schedule',
        },
        {
          key: 'worklogs',
          name: 'Bilhetagem',
          to: '/worklogs',
        },
        {
          key: 'tasks',
          name: 'Tarefas',
          to: '/tasks',
        },
      ],
    };
  }

  public async onComponentUpdate() {
    const currentWorkspace =
      this.props.workspaceCode || getItem('workspace', 'localStorage');

    if (!currentWorkspace) {
      return;
    }

    if (!this.props.projectSelected || !this.state.dateSelected) {
      const projects = await this.getProjects(currentWorkspace);

      const urlParams = this.props.location.pathname;
      const lastUrlParamPosition = urlParams.lastIndexOf('/');
      const lastUrlParam = urlParams.substring(lastUrlParamPosition + 1);
      const projectKey = lastUrlParam.split('-')[0];

      if (lastUrlParam !== 'worklogs') {
        const issue = await this.getIssueByProjectId(lastUrlParam);

        if (issue && issue.idIssue) {
          const issueToBeOpened = issue.idIssue;
          const selectedTab = SelectedIssueTabEnum['SURVEY'];
          const date = new Date(issue.created);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const dateSelected = `${year}-${month}`;
          const project: IProject = projects.find((p) => p.key === projectKey)!;

          this.setState({ selectedTab, issueToBeOpened, dateSelected });
          this.props.setProjectSelected(project);
        }
      }

      const { projectSelected, dateSelected } = getStateFromBrowserDB(
        MCP_WORKSPACE_INDEX,
        currentWorkspace,
        'cookies',
        MCP_PA_PROJECT
      );
      if (projectSelected && lastUrlParam === 'worklogs') {
        this.props.setProjectSelected(
          projects.find((p) => p.key === projectSelected.key)
        );
      }

      if (dateSelected) {
        this.setState({
          dateSelected,
        });
      }
    }
  }

  public componentDidMount() {
    this.onComponentUpdate();

    const { stepsJoyride, setStepsJoyride } = this.props;

    if (!stepsJoyride || stepsJoyride !== MCP_WORKLOG_JOYRIDE_STEPS) {
      setStepsJoyride(MCP_WORKLOG_JOYRIDE_STEPS);
    }
  }

  public async getIssueByProjectId(
    issueKey: string
  ): Promise<IIssueByKey | null> {
    try {
      const { data } = await client.query<{ issueByKey: IIssueByKey }>({
        query: gql(queries.getIssueByProjectId),
        variables: {
          issueKey,
        },
      });
      const issue = data.issueByKey;
      return issue;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getProjects(workspace: string) {
    try {
      const { data } = await client.query<{ projects: IProject[] }>({
        query: gql(queries.getProjects),
        variables: {
          workspace,
        },
      });
      return data.projects;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  public async getFullProjectById(
    workspace: string,
    projectId: number
  ): Promise<IFullProject | null> {
    try {
      const { data } = await client.query<{ project: IFullProject }>({
        query: gql(queries.getFullProjectById),
        variables: {
          workspace,
          projectId,
        },
      });
      return data.project;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public handleChangeDateSelected = (year: string, month: string) => () => {
    const dateSelected = `${year}-${month}`;
    this.setState({ dateSelected });
    createOrUpdateItemAtBrowserDB(
      MCP_WORKSPACE_INDEX,
      this.props.workspaceCode,
      'cookies',
      MCP_PA_WORKLOG,
      { dateSelected }
    );
  };

  public render() {
    const {
      workspace,
      workspaceCode,
      stepsJoyride,
      setStepsJoyride,
      projectSelected,
    } = this.props;

    return (
      <>
        {workspace ? (
          <>
            <Submenu selected="worklogs" items={this.state.submenuItens} />
            <S.BoxContainer>
              <S.SelectContainer projectSelected={projectSelected}>
                <SelectProject
                  workspace={workspaceCode}
                  getProjects={this.getProjects}
                  setProjectSelected={this.props.setProjectSelected}
                  projectSelected={projectSelected}
                />
              </S.SelectContainer>
              {projectSelected ? (
                [
                  projectSelected.category === 'pontual' ? (
                    <CalendarPunctualContainer
                      key="worklogCalendarPunctual"
                      projectSelected={projectSelected}
                      getFullProjectById={(projectId) =>
                        this.getFullProjectById(workspaceCode, projectId)
                      }
                    />
                  ) : (
                    <CalendarContainer
                      key="worklogCalendar"
                      workspace={workspace}
                      workspaceCode={workspace.code}
                      projectSelected={projectSelected}
                      dateSelected={this.state.dateSelected}
                      handleChangeDateSelected={this.handleChangeDateSelected}
                      stepsJoyride={stepsJoyride}
                      setStepsJoyride={setStepsJoyride}
                    />
                  ),
                  <ListWorklog
                    key="worklogIssueList"
                    workspaceCode={workspace.code}
                    date={this.state.dateSelected}
                    projectSelected={projectSelected}
                    issueToBeOpened={this.state.issueToBeOpened}
                    selectedTab={this.state.selectedTab}
                  />,
                ]
              ) : (
                <EmptyStateProject
                  title="Nenhum projeto selecionado"
                  description="Selecione um projeto para ver as tarefas associadas a ele"
                />
              )}
            </S.BoxContainer>
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}

export default withAppContext(WorklogContainer);
