import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import config from './utils/config';
import { getItem } from './utils/storage';
import { login } from './authClient';
import { initializeHotjar } from './utils/hotjar';

const cache = new InMemoryCache({
  dataIdFromObject: (object) => object.id || null,
  addTypename: false,
});
const httpLink = createHttpLink({
  credentials: 'same-origin',

  uri: config.endpoints.GRAPHQL,
});

let currentToken: string | null = '';
const authLink = setContext((_, { headers }) => {
  const token =
    getItem(config.constants.devopsAuthCookie, 'cookies') ||
    config.cookies._authcd_;
  if (token && currentToken !== token) {
    currentToken = token;
    initializeHotjar(token);
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ operation }) => {
  const context = operation.getContext();
  const status = context.response !== undefined ? context.response.status : 0;

  switch (status) {
    case 401:
      login();
      break;
  }
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

const client = new ApolloClient({
  cache,
  link,
});

export default client;
