type storageType = 'cookies' | 'localStorage';

type options = { [key: string]: string };

type getItemFn = (name: string) => string | null;

type setItemFn = (name: string, value: string, options?: options) => void;

type removeItemFn = (name: string, options?: options) => void;

export const getItem = (name: string, storageType: storageType) => {
  const getItemFn = getItemFunctions[storageType];
  return getItemFn(name);
};

export const setItem = (
  name: string,
  value: string,
  storageType: storageType,
  options = {}
) => {
  const setItemFn = setItemFunctions[storageType];
  setItemFn(name, value, options);
};

export const removeItem = (
  name: string,
  storageType: storageType,
  options = {}
) => {
  const removeItemFn = removeItemFunctions[storageType];
  removeItemFn(name, options);
};

const getItemFunctions: { [key: string]: getItemFn } = {
  cookies: (name) => {
    const cookies = document.cookie.split('; ').map((kv) => kv.split('='));
    const cookie = cookies.find(([k, v]) => k === name);

    return cookie ? cookie[1] : null;
  },

  localStorage: (name) => localStorage.getItem(name),
};

const setItemFunctions: { [key: string]: setItemFn } = {
  cookies: (name, value, customOptions = {}) => {
    const defaultCookieOptions = { path: '/' };
    const options = { ...defaultCookieOptions, ...customOptions };

    const parsedOptions = Object.keys(options)
      .map((k) => `${k}=${options[k]}`)
      .join('; ');

    document.cookie = `${name}=${value}; ${parsedOptions}`;
  },

  localStorage: (name, value) => localStorage.setItem(name, value),
};

const removeItemFunctions: { [key: string]: removeItemFn } = {
  cookies: (name, customOptions = {}) => {
    const options = {
      ...customOptions,
      expires: 'Thu, 01 Jan 1970 00:00:01 GMT',
    };

    setItem(name, '', 'cookies', options);
  },

  localStorage: (name) => localStorage.removeItem(name),
};

export const getNestedItem = (
  root: string,
  elementKey: string,
  from: storageType
): Object | [] | string | number | undefined => {
  const savedState = getItem(root, from);

  if (!savedState) {
    return;
  }

  try {
    const state = JSON.parse(savedState);
    return Object.keys(state).includes(elementKey) && state[elementKey];
  } catch (err) {
    console.error(err);
    return;
  }
};

export const setNestedItem = (
  root: string,
  elementKey: string,
  elementValue: Object | string | [] | undefined,
  where: storageType
) => {
  const savedState = getItem(root, where);

  try {
    let state = {};

    if (savedState) {
      state = JSON.parse(savedState);
    }

    state[elementKey] = elementValue;
    setItem(root, JSON.stringify(state), where);
  } catch (err) {
    console.error(err);
  }
};

export const createOrUpdateItemAtBrowserDB = (
  storageId: string,
  storageName: string,
  storageType: storageType,
  key: string,
  value: Object
) => {
  let workspaceDatabase = getNestedItem(storageId, storageName, storageType);

  if (!workspaceDatabase) {
    workspaceDatabase = {};
  }

  workspaceDatabase[key] = { ...workspaceDatabase[key], ...value };

  setNestedItem(storageId, storageName, workspaceDatabase, storageType);
};

export const getStateFromBrowserDB = (
  storageId: string,
  storageName: string,
  storageType: storageType,
  key: string
) => {
  const state = {};

  const workspaceDatabase = getNestedItem(storageId, storageName, storageType);

  if (!workspaceDatabase) {
    return state;
  }

  return Object.keys(workspaceDatabase).includes(key) && workspaceDatabase[key];
};
