import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as Edit } from 'modules/common/icons/icon-edit.svg';
import { ReactComponent as Trash } from 'modules/common/icons/icon-trash.svg';
import { Badge } from 'modules/common/components/Utils';
import * as React from 'react';
import * as S from './styled';

const ResourceBox = ({
  name,
  version,
  handleDelete,
  handleEdit,
}: {
  id: string;
  name: string;
  version: string;
  handleDelete: () => void;
  handleEdit: () => void;
}) => {
  return (
    <S.ResourceContent>
      <Typography className="text" noWrap={true} title={name}>
        {name}
      </Typography>
      <S.ResourceVersion className="version">
        <Badge>{version}</Badge>
      </S.ResourceVersion>
      <S.ResourceTools className="tools">
        <IconButton
          aria-label="edit"
          data-cy="btn-edit"
          size="small"
          onClick={handleEdit}
        >
          <Edit />
        </IconButton>
        <IconButton
          aria-label="delete"
          data-cy="btn-delete"
          size="small"
          onClick={handleDelete}
        >
          <Trash />
        </IconButton>
      </S.ResourceTools>
    </S.ResourceContent>
  );
};

export default ResourceBox;
