const createImpact = `
  mutation createImpact(
    $workspaceCode: String!,
    $resourceId: ID!,
    $dependsOnId: ID!,
    $impactType: ImpactType!
  ) {
    createDependencyImpact(resourceDependency: {
      workspaceCode: $workspaceCode,
      resourceId: $resourceId,
      dependsOnId: $dependsOnId,
      impactType: $impactType
    }) {
      message
    }
  }
`;

const createResource = `
  mutation createResource(
    $workspaceCode: String!,
    $name: String!,
    $type: ResourceType!,
    $version: String!,
    $isDependency: Boolean!
  ) {
    createResource(resource: {
      workspaceCode: $workspaceCode,
      name: $name,
      type: $type,
      version: $version,
      isDependency: $isDependency
    }) {
      message
    }
  }
`;

const updateResource = `
  mutation updateResource(
    $id: ID!,
    $workspaceCode: String!,
    $name: String!,
    $type: ResourceType!,
    $version: String!,
    $isDependency: Boolean!
  ) {
    updateResource(resource: {
      id: $id,
      workspaceCode: $workspaceCode,
      name: $name,
      type: $type,
      version: $version,
      isDependency: $isDependency
    }) {
      message
    }
  }
`;

const deleteResource = `
  mutation deleteResource($id: ID!, $workspaceCode: String!) {
    deleteResource(resource: {
      id: $id,
      workspaceCode: $workspaceCode
    }) {
      message
    }
  }
`;

export default {
  createImpact,
  createResource,
  updateResource,
  deleteResource,
};
