import { IIssue } from 'modules/schedule/types';

export const compareIssues = (a: IIssue, b: IIssue): number => {
  const compare = (v1: any, v2: any, ascending: boolean): number => {
    let r = 0;

    if (v1 < v2) {
      r = -1;
    } else if (v1 > v2) {
      r = 1;
    }

    return ascending ? r : -r;
  };

  if (!a.duedate && b.duedate) {
    return 1;
  }

  if (a.duedate && !b.duedate) {
    return -1;
  }

  if (!a.startdate && b.startdate) {
    return 1;
  }

  if (a.startdate && !b.startdate) {
    return -1;
  }

  const ascending = true;
  const comparisons: Array<[any, any, boolean]> = [
    [a.startdate, b.startdate, ascending],
    [a.duedate, b.duedate, ascending],
    [a.progress || 0, b.progress || 0, !ascending],
    [a.summary, b.summary, ascending],
  ];

  return comparisons.reduce(
    (result, [v1, v2, asc]) => (result !== 0 ? result : compare(v1, v2, asc)),
    0
  );
};
