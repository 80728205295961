import Icon from '@material-ui/core/Icon';
import * as React from 'react';
import { hoursToFixed } from '../utils';
import * as S from './Styled';

interface IProps {
  serviceName: string;
  value: number;
  isSelected?: boolean;
  highlightRow?: boolean;
  openDrawer: (
    arrayIndex: number,
    serviceName: string
  ) => (event: React.MouseEvent<HTMLDivElement>) => void;
  arrayIndex: number;
  hasProductImprovement?: boolean;
  billingUnit?: string;
}

const scrollTop = () =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

export default function CardRow(props: IProps) {
  const handleAlertRow = () => {
    scrollTop();
    return props.openDrawer(props.arrayIndex, props.serviceName);
  };

  return (
    <S.AlertRow
      highlightRow={props.highlightRow}
      onClick={handleAlertRow()}
      active={props.isSelected}
      className="consumption-detail"
    >
      {props.isSelected ? <S.ActiveLink></S.ActiveLink> : undefined}
      <div className="service-alert-container">
        <S.CloudAWSMobile>Nuvem AWS</S.CloudAWSMobile>

        <S.AlertCell>{props.serviceName}</S.AlertCell>
        {props.hasProductImprovement && (
          <Icon className="color-icon">warning</Icon>
        )}
      </div>
      <S.DividerLine />
      <S.AlignValue>
        <S.CloudAWSMobile>Gasto Atual</S.CloudAWSMobile>
        <S.AlertCell color="#E92641">
          {`${hoursToFixed(props.value)} ${props.billingUnit}`}
        </S.AlertCell>
      </S.AlignValue>
    </S.AlertRow>
  );
}
