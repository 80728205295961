import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import * as React from 'react';
import * as S from './CalendarStyled';
import ContentMonth from './ContentMonth';
import FakeLoaderCalendar from './FakeLoaderCalendar';
import { ISummaryQuarter } from './types';

interface IProps {
  monthBoxUnit?: string;
  dateSelected?: string;
  handleChangeDateSelected: (
    year: string,
    month: string
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  loading?: boolean;
  summary: ISummaryQuarter[];
  boxCalendarRef: React.RefObject<HTMLDivElement> | null;
}

class Calendar extends React.Component<IProps> {
  public handleScrollRight = (
    content: React.RefObject<HTMLDivElement> | null
  ) => () => {
    if (content && content.current) {
      content.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  public handleScrollLeft = (
    content: React.RefObject<HTMLDivElement> | null
  ) => () => {
    if (content && content.current) {
      content.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  public renderCalendar() {
    const { loading, summary, monthBoxUnit } = this.props;
    if (loading) {
      return <FakeLoaderCalendar />;
    }
    return Object.keys(summary).map((year: string) => (
      <React.Fragment key={`year_${year}`}>
        <S.BoxYear>
          <Typography component="h3">{year}</Typography>
        </S.BoxYear>

        {Object.keys(summary[year]).map((quarter: string) =>
          summary[year][
            quarter
          ].list.map((data: { date: string; timeSpent: number }) => (
            <ContentMonth
              monthBoxUnit={monthBoxUnit}
              key={`month_${data.date}`}
              year={year}
              timeSpent={data.timeSpent}
              month={moment(data.date).format('MM')}
              monthName={moment(data.date).format('MMM')}
              active={
                data.date ===
                (this.props.dateSelected || moment().format('YYYY-MM'))
              }
              handleChangeDateSelected={this.props.handleChangeDateSelected}
            />
          ))
        )}
      </React.Fragment>
    ));
  }

  public render() {
    const { boxCalendarRef } = this.props;
    return (
      <S.BoxCalendarRoot>
        <S.BoxArrowButton>
          <Button
            className="arrow-button"
            onClick={this.handleScrollLeft(boxCalendarRef)}
          >
            <Icon>chevron_left</Icon>
          </Button>
        </S.BoxArrowButton>
        <S.BoxCalendar>
          <div
            className="calendar-scroll first-month calendar-month"
            ref={boxCalendarRef}
          >
            {this.renderCalendar()}
          </div>
        </S.BoxCalendar>
        <S.BoxArrowButton>
          <Button
            className="arrow-button"
            onClick={this.handleScrollRight(boxCalendarRef)}
          >
            <Icon>chevron_right</Icon>
          </Button>
        </S.BoxArrowButton>
      </S.BoxCalendarRoot>
    );
  }
}

export default Calendar;
