import React, { useState } from 'react';
import * as S from './styled';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import client from 'apolloClient';
import { createSurvey } from './graphql/mutations';
import { IIssue } from '../../types';
import { paletteColor } from 'modules/common/styles/colors';

type IProps = {
  issue: IIssue;
};

interface IState {
  sended: boolean;
  loading: boolean;
  suggestion?: string;
  rating: RatingSurvey | undefined;
  enhancement: boolean | undefined;
  efficiency: boolean | undefined;
}

enum RatingSurvey {
  Péssimo = 'VERY_BAD',
  Ruim = 'BAD',
  Bom = 'GOOD',
  Excelente = 'EXCELLENT',
}

const initialState: IState = {
  sended: false,
  loading: false,
  suggestion: '',
  rating: undefined,
  enhancement: undefined,
  efficiency: undefined,
};

const Rating: React.FC<IProps> = ({ issue }) => {
  const ratingLabel = [
    ['Péssimo', 'Ruim', 'Bom', 'Excelente'],
    ['Sim', 'Não'],
  ];

  const [
    { sended, loading, suggestion, rating, enhancement, efficiency },
    setState,
  ] = useState(initialState);

  const clearState = () => {
    setState({ ...initialState });
  };

  const disableSendButton =
    rating === undefined ||
    enhancement === undefined ||
    efficiency === undefined ||
    loading;

  const MessageSuccess = () => (
    <S.TextContainer>
      <Typography>Avaliação enviada</Typography>
      <Typography> Obrigado! Sua contribuição é muito importante.</Typography>
    </S.TextContainer>
  );

  const handleSendButton = async () => {
    handleStateChange('loading', true);
    try {
      await client.mutate({
        mutation: createSurvey,
        variables: {
          issueId: issue.id.toString(),
          isEfficiency: efficiency,
          isEnhancement: enhancement,
          rating: rating,
          suggestion: suggestion,
          issueKey: issue.issueKey,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      clearState();

      handleStateChange('sended', true);
      setTimeout(() => handleStateChange('sended', false), 5000);
    }
  };

  const handleStateChange = (state: string, value: any) => {
    setState((prevState) => ({ ...prevState, [state]: value }));
  };

  return (
    <S.RatingContainer>
      <S.RatingContent>
        <S.Text textSize="14px" textAlign="center">
          Como você avalia o trabalho feito nesta tarefa?
        </S.Text>
        <S.Text
          textColor={paletteColor.primary.main}
          textWeight={400}
          textAlign="center"
        >
          Seu feedback é muito importante para podermos melhorar!
        </S.Text>
        <S.RadioGroupCustom name="rating-group-1">
          {ratingLabel[0].map((ratingValue) => (
            <FormControlLabel
              value={ratingValue}
              control={<Radio color="primary" disableRipple />}
              label={ratingValue}
              labelPlacement="bottom"
              key={ratingValue}
              checked={rating === RatingSurvey[ratingValue]}
              onChange={() =>
                handleStateChange('rating', RatingSurvey[ratingValue])
              }
            />
          ))}
        </S.RadioGroupCustom>
        <S.Text>
          Você acredita que a execução desta tarefa trouxe melhorias para o
          ambiente?
        </S.Text>
        <RadioGroup name="rating-group-2" className="rating-label">
          {ratingLabel[1].map((rating) => (
            <FormControlLabel
              value={rating}
              control={<Radio color="primary" disableRipple />}
              label={rating}
              key={rating}
              checked={enhancement === (rating === 'Sim')}
              onChange={() =>
                handleStateChange('enhancement', rating === 'Sim')
              }
            />
          ))}
        </RadioGroup>
        <S.Text>A eficiência de execução foi satisfatória?</S.Text>
        <RadioGroup name="rating-group-3" className="rating-label">
          {ratingLabel[1].map((rating) => (
            <FormControlLabel
              value={rating}
              control={<Radio color="primary" disableRipple />}
              label={rating}
              key={rating}
              checked={efficiency === (rating === 'Sim')}
              onChange={() => handleStateChange('efficiency', rating === 'Sim')}
            />
          ))}
        </RadioGroup>

        <S.Text>
          Deixe o seu comentário para críticas, elogios ou sugestões.
        </S.Text>
        <S.Textarea
          rowsMin={3}
          rowsMax={6}
          placeholder="Escreva aqui sua resposta"
          value={suggestion}
          onChange={(e) => {
            handleStateChange('suggestion', e.target.value);
          }}
        />
        <Collapse in={sended}>
          <MessageSuccess />
        </Collapse>
        <S.ButtonContainer>
          <S.SendButton
            variant="outlined"
            color="primary"
            onClick={handleSendButton}
            disabled={disableSendButton}
          >
            {loading ? <S.ButtonProgress size={15} /> : 'Enviar'}
          </S.SendButton>
        </S.ButtonContainer>
      </S.RatingContent>
    </S.RatingContainer>
  );
};

export default Rating;
