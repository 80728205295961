import * as React from 'react';

export const MCP_BILLING_JOYRIDE_STEPS: any[] = [
  {
    target: '.calendar-month',
    content: (
      <p>
        Essa lista mostra informações de consumo mês a mês da sua conta AWS
        conectada neste WorkSpace.
      </p>
    ),
    title: 'AWS',
    locale: {
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
  {
    target: '.graphic-general',
    content: (
      <p>
        Este gráfico em linhas sumariza essas informações em uma visualização
        simplificada mês a mês. Ele é interativo, então você pode coletar
        detalhes ao passar o mouse sobre ele.
      </p>
    ),
    title: 'AWS',
    locale: {
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'bottom-end',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
  {
    target: '.consumption-detail',
    content: (
      <p>
        A lista exibida nessa tela detalha exatamente o serviço que sua conta
        está consumindo e quando ele está gastando no mês vigente, caso a
        visualização se trate de um mês anterior, o consumo será o valor fechado
        do mês. Você pode clicar para obter detalhes do consumo.
      </p>
    ),
    title: 'AWS',
    locale: {
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'bottom',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
  {
    target: '.total-value',
    content: (
      <p>
        Este é o valor total consumido por todos serviços AWS presentes na
        lista, em suma, é o quanto foi gasto até o momento no mês atual ou o
        valor total gasto no mês fechado.
      </p>
    ),
    title: 'AWS',
    locale: {
      last: <span>Finalizar</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
];
