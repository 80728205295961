import Typography from '@material-ui/core/Typography';
import { timeSpentToString } from 'modules/common/utils/worklog';
import * as React from 'react';
import { ISupportIssueResult } from '../types';
import { priorityColor, translatedName } from '../utils/support';
import S from './styled';
import { Subtask } from '../types';

type IProps = {
  issue: ISupportIssueResult | Subtask;
  index: number;
  onClick?: (e: React.MouseEvent) => void;
  page: number;
};

export default function BoardCard({ issue, index, onClick }: IProps) {
  return (
    <S.Card
      className={'animation joyride__board-card'}
      onClick={onClick}
      style={{
        animationDelay: `${((index >= 25 ? index % 25 : index) || 1) * 0.1}s`,
      }}
      data-cy="board-item"
    >
      <div className="content">
        {issue.issuetype && (
          <>
            <div className="title-content">
              <span
                style={{ background: priorityColor(issue.priority) }}
              ></span>
              <Typography className="title" variant="body2" noWrap={true}>
                {translatedName(issue.issuetype.name)}
              </Typography>
            </div>
          </>
        )}
        <Typography
          className="description"
          variant="subtitle1"
          gutterBottom={true}
        >
          {issue.summary}
        </Typography>
        <S.BadgeContainer>
          <S.Badge>{issue.issueKey}</S.Badge>
          <S.Badge
            margins="margin: 8px 0px 0px 0px"
            lastChild="margin-left: inherit"
          >
            {timeSpentToString(issue.worklogs.totalTimeSpent, true) || '0h'}
          </S.Badge>
        </S.BadgeContainer>
      </div>
    </S.Card>
  );
}
