import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';
import { paletteColor } from 'modules/common/styles/colors';
import * as React from 'react';

interface IDefault {
  style?: object;
  className?: string;
  margin?: number | string;
  padding?: number | string;
}

interface IProps extends IDefault {
  actions?: React.ReactNode;
  classes: {
    expandIcon: string;
    detailsStyle: string;
    mainStyle: string;
  };
  defaultExpanded?: boolean;
  details?: React.ReactNode;
  disabled?: boolean;
  expandIcon?: React.ReactNode;
  expanded?: boolean;
  summary?: React.ReactNode;
  summaryStyle?: object;
  detailsStyle?: object;
  actionsStyle?: object;
  onChange?: (event?, expanded?) => void;
}

const styles = () => ({
  mainStyle: {
    boxShadow: 'none',
    marginBottom: 16,
    borderRadius: 6,
    overflow: 'hidden',
  },
  detailsStyle: {
    backgroundColor: paletteColor.info.fill,
    boxShadow: 'none',
    padding: 0,
  },
  expandIcon: {
    left: '8px',
    right: 'unset',
  },
});

const ExpansionPanelSummary = withStyles({
  root: {
    borderRadius: '6px',
    backgroundColor: paletteColor.primary.white,
    boxShadow: 'none',
    content: 'none',
    minHeight: 49,
    '&$expanded': {
      minHeight: 49,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanel: React.SFC<IProps> = ({
  actions,
  classes,
  details,
  expandIcon,
  margin,
  padding,
  style,
  summary,
  summaryStyle,
  detailsStyle,
  actionsStyle,
  ...otherProps
}) => (
  <MuiExpansionPanel
    className={classes.mainStyle}
    {...otherProps}
    style={{ margin, padding, ...style }}
  >
    {summary && (
      <ExpansionPanelSummary expandIcon={expandIcon} style={summaryStyle}>
        {summary}
      </ExpansionPanelSummary>
    )}
    {details && (
      <MuiExpansionPanelDetails
        className={classes.detailsStyle}
        style={detailsStyle}
      >
        {details}
      </MuiExpansionPanelDetails>
    )}
    {actions && (
      <MuiExpansionPanelActions style={actionsStyle}>
        {actions}
      </MuiExpansionPanelActions>
    )}
  </MuiExpansionPanel>
);

export default withStyles(styles)(ExpansionPanel);
