import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import S from './styled';
import { ISupportSprintResult } from '../types';
import { IProject } from 'modules/common/types';

interface IProps {
  workspace: string;
  loading: boolean;
  sprintList: ISupportSprintResult[];
  sprintName: string;
  changeIssues: (sprint: number, state?: string) => {};
  disabled: boolean;
}

interface IStates {
  anchorMenu: HTMLInputElement | null;
  projectSelected?: IProject;
}

class Filter extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      anchorMenu: null,
    };
  }

  public handleOpenMenu = (event: any) => {
    if (event) {
      this.setState({ anchorMenu: event.target });
    }
  };

  public handleCloseMenu = () => {
    this.setState({ anchorMenu: null });
  };

  public handleChangeSprint = (sprintId: number, state?: string) => () => {
    this.handleCloseMenu();
    this.props.changeIssues(sprintId, state);
  };

  public render() {
    const { sprintList, loading, sprintName, disabled } = this.props;
    const { anchorMenu } = this.state;

    return (
      <S.FilterContent>
        <div className="sprint-content joyride__sprint-select">
          <S.FilterButton
            aria-owns={anchorMenu ? 'menu-sprints' : undefined}
            aria-haspopup="true"
            onClick={this.handleOpenMenu}
            disabled={disabled}
          >
            {loading ? (
              <S.Fake width={100} height={17} />
            ) : (
              <Typography className="time-spent-title">{sprintName}</Typography>
            )}
          </S.FilterButton>
          <S.MenuSelect
            id="menu-sprints"
            anchorEl={anchorMenu}
            open={Boolean(anchorMenu)}
            onClose={this.handleCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          >
            <S.MenuItemSelect
              key={`sprint_0`}
              onClick={this.handleChangeSprint(0)}
            >
              Backlog
            </S.MenuItemSelect>
            {sprintList.map(({ id, name, state }: ISupportSprintResult) => (
              <S.MenuItemSelect
                key={`sprint_${id}`}
                onClick={this.handleChangeSprint(id, state)}
              >
                {name} {state === 'active' && '(ativa)'}
              </S.MenuItemSelect>
            ))}
          </S.MenuSelect>
        </div>
      </S.FilterContent>
    );
  }
}

export default Filter;
