import gql from 'graphql-tag';

export const createSurvey = gql`
  mutation createSurvey(
    $issueId: String!
    $isEfficiency: Boolean!
    $isEnhancement: Boolean!
    $rating: RatingEnum!
    $suggestion: String
    $issueKey: String!
  ) {
    createSurvey(
      survey: {
        issueId: $issueId
        isEfficiency: $isEfficiency
        isEnhancement: $isEnhancement
        rating: $rating
        suggestion: $suggestion
        issueKey: $issueKey
      }
    )
  }
`;
