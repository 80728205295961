import * as React from 'react';
import EmptyPage from '../../common/components/EmptyState';
import image from 'modules/common/images/empty_logs.png';

const EmptyState = () => (
  <EmptyPage
    title="Cloud Compliance"
    description="Visualização de registros de atividades que resultaram em bilhetagem automática de planos contratados de Consultoria, Implantação, Migração ou Sustentação de ambientes em nuvem."
    image={image}
    readMoreURL="https://platform.mandic.com.br/portfolio/auditoria/"
    hireURL="https://br.claranet.com/contatos?pgc=portal.cliente"
  />
);

export default EmptyState;
