import { Container, css, List, ListItem, styled } from '@cmp/ui';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import { IStyledProps } from 'modules/layout/types';
import { Link, NavLink } from 'react-router-dom';
import { paletteColor } from 'modules/common/styles/colors';

export const NavItem = styled(NavLink).attrs({
  activeClassName: 'nav-item-active',
})`
  text-decoration: none;
  display: block;
  color: ${paletteColor.dark.icon};

  &.nav-item-active {
    & > div {
      color: ${paletteColor.primary.main};
      position: relative;
      font-weight: bold;
      letter-spacing: 0.01em;
      &:after {
        content: '';
        position: absolute;
        left: -14px;
        top: 4px;
        height: 18px;
        width: 3px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        background: ${paletteColor.primary.main};
        display: block;
      }
    }
  }
`;

export const NavItemExternal = styled.div`
  & a {
    text-decoration: none;
    display: block;
    color: ${paletteColor.dark.icon};

    &:hover {
      color: ${paletteColor.primary.main};
    }
  }
`;

export const RootContainer = styled(Container)`
  min-height: calc(100% - 50px);
`;

export const MainContent = styled.div`
  position: relative;
  margin: 50px 16px 0px 16px;
  overflow: hidden;
  width: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    ${({ isMenuOpened }) =>
      isMenuOpened
        ? 'margin: 50px 24px 0px 224px;'
        : 'margin: 50px 24px 0px 24px;'}
  }

  @media only screen and (min-width: 1200px) {
    ${({ isMenuOpened }) =>
      isMenuOpened
        ? 'margin: 50px 120px 0px 230px;'
        : 'margin: 50px 120px 0px 120px;'}
  }
`;

export const TitleMenu = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  color: ${paletteColor.info.soft};
  font-size: 14px;
  padding: 8px 0 4px;
  font-weight: 500;
  letter-spacing: 0.05em;
`;

export const ListMenu = styled(List)`
  && {
    background-color: ${paletteColor.primary.white};
    padding: 15px;
  }
`;

export const ListMenuItem = styled(List)`
  && {
    background-color: ${paletteColor.primary.white};
    padding-top: 4px;
  }
`;

export const ListItemMenu = styled(ListItem)`
  && {
    padding: 3px 5px;
    color: ${paletteColor.dark.icon};
    letter-spacing: 0.01em;

    &:hover {
      background-color: ${paletteColor.primary.white};
      color: ${paletteColor.primary.main};
      font-weight: bold;
      letter-spacing: 0.01em;
    }
  }
`;

export const WorkspaceMenu = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${paletteColor.primary.white};
  width: 100%;

  & .avatarCustom {
    color: ${paletteColor.primary.white};
    width: 24px;
    height: 24px;
    font-size: 1em;
    background-color: #efad5f;
  }
`;

export const ButtonWorkspaces = styled(Button)`
  && {
    display: flex;
    width: 100%;
    color: ${paletteColor.primary.white};
    height: 50px;
  }
`;

export const WorkspaceWrap = styled.div`
  display: none;
  flex-direction: column;
  padding-left: 11px;
  line-height: 1.4;
  align-items: center;
  justify-content: center;
  margin-right: 15;
  padding-right: 5px;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

export const WorkspaceLabel = styled.span`
  display: flex;
  flex-direction: column;
  line-height: 1.4;
  align-items: center;
  justify-content: center;
  color: ${paletteColor.info.text};
  text-transform: capitalize;
`;

export const WorkspaceMenuIcon = styled(Icon)`
  && {
    display: none;
    color: ${paletteColor.dark.icon};
  }

  @media only screen and (min-width: 768px) {
    && {
      display: block;
    }
  }
`;

export const NavigationContainer = styled.div`
  && {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
    background-color: ${paletteColor.primary.white};
  }
`;

export const AppBarCustom = styled(AppBar)`
  && {
    position: absolute;
    right: 0px;
    width: 100%;
    margin-left: 0px;
    box-shadow: 0px 4px 4px rgba(223, 225, 235, 0.25);
    transition: margin 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      width 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  @media only screen and (min-width: 992px) {
    && .appBarShift {
      position: absolute;
      right: 0px;
      margin-left: 204px;
      width: calc(100% - 204px);
      transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        width 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
`;

export const DrawerCustom = styled(Drawer)`
  && {
    width: 200px;

    & .MuiDrawer-paper {
      width: 204px;
      ::-webkit-scrollbar {
        width: 4px;
      }
    }
  }
  & .drawHeader {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-top: 7px;
    font-size: 12px;
    color: ${paletteColor.info.soft};
    letter-spacing: 0.03em;
    border: 0;
  }

  & .MuiDrawer-paper {
    top: 51px;
  }

  @media only screen and (min-width: 992px) {
    & .MuiDrawer-paper {
      top: 0px;
    }
  }
`;

export const ToolbarCustom = styled(Toolbar)`
  && {
    background-color: ${paletteColor.primary.white};
    color: rgba(0, 0, 0, 0.87);
    min-height: 48px;
    box-shadow: 0px 4px 4px rgba(223, 225, 235, 0.25);
    padding-left: 0;
    padding-right: 0;

    & .MuiAppBar-root {
      width: 6%;
      display: block;
      z-index: auto;
      flex-shrink: 1;
      flex-direction: row;
      box-shadow: none;
    }

    & .MuiPaper-elevation4 {
      box-shadow: none !important;
    }

    & .MuiIconButton-root {
      padding: 15px;
      color: transparent;
      padding-left: 12px;
      padding-right: 12px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
        border-radius: 0;
        padding-left: 12px;
      }
    }

    & .custom-divider {
      border: 0.6px solid ${paletteColor.info.border};
      height: 50px;
      margin: 0px;
    }
  }
`;

export const NavigationRoot = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 50px;
`;

export const WorkspaceMenuContainer = styled.div`
  position: relative;
  top: 0;
  width: auto;
  height: 50px;
  background-color: black;
`;

export const LogoutButton = styled(Button)`
  && {
    width: 65px;
    height: 50px;
    border-radius: 0;
  }

  & .logout-button__label {
    display: none;
  }

  & .logout-button__icon--desktop {
    display: none;
  }

  & .logout-button__icon--mobile {
    pointer-events: none;
    margin-left: 0px;
    height: 32;
    width: 32;
  }

  &:before {
    content: ' ';
    position: absolute;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-width: 768px) {
    && {
      width: 120px;
    }

    & .logout-button__label {
      display: inherit;
    }

    & .logout-button__icon--desktop {
      display: inherit;
      pointer-events: none;
      margin-left: 6px;
      height: 16;
      width: 16;
    }

    & .logout-button__icon--mobile {
      display: none;
    }

    &:before {
      border-left: 1px solid ${paletteColor.info.border};
    }
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: auto;
  & .logo {
    width: 28px;
    height: 22px;
  }
  & .itemList {
    color: ${paletteColor.primary.main};
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.05em;
    margin-left: 14px;
    width: 170px;

    @media (max-width: 375px) {
      width: 110px;
      margin-left: 10px;
    }
  }

  @media (max-width: 375px) {
    margin-left: 10px;
  }
`;

export const Item = styled.div`
  width: 100%;
`;

export const NavigationPullRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  width: 100%;

  & .selectWork {
    min-width: 65px;

    & span {
      font-weight: 400;
    }

    @media only screen and (min-width: 768px) {
      min-width: 200px;
    }
  }
`;

export const NavigationPullLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  width: auto;
`;

export const UsernameContent = styled.div`
  display: none;
  justify-self: flex-end;
  margin-right: 15px;
  color: ${paletteColor.info.soft};
  font-size: 12px;
  letter-spacing: 0.02em;

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

export const PageMenu = styled.div`
  position: fixed;
  z-index: 101;
  top: 50px;
  left: 0;
  width: 100%;
  height: 50px;
  background: ${paletteColor.primary.white};
  display: flex;
  box-shadow: 0px 4px 4px rgba(223, 225, 235, 0.25);
  border-top: 1px solid ${paletteColor.info.border};
  padding: 0 6px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 4px;
  }
`;

export const PageMenuItem = styled(Link)`
  && {
    position: relative;
    border: 0;
    display: flex;
    padding: 0 30px;
    color: ${paletteColor.info.soft};
    text-decoration: none;
    align-items: center;
    &:after {
      content: '';
      background: ${paletteColor.primary.main};
      height: 2px;
      width: 0;
      position: absolute;
      bottom: 0;
      left: -30%;
      transform: translateX(50%);
      border-radius: 3px 3px 0 0;
      transition: width 250ms;
    }
    ${(props: IStyledProps) =>
      props.selected &&
      css`
        color: ${paletteColor.info.text};
        &:after {
          width: 72%;
          left: -22%;
        }
      `}

    @media only screen and (min-width: 992px) {
      left: 194px;
    }
  }
`;

export const BoxPage = styled(Box)`
  && {
    max-width: 600px;
    margin: 40px auto 80px;

    & .text-page {
      padding: 0px 13px;
    }
  }
`;

export const ContainerNewProduct = styled.div`
  padding-left: 16px;
`;

export const NewProduct = styled.div`
  && {
    background: ${paletteColor.error.main};
    border-radius: 12px;
    width: 48px;
    height: 24px;
    color: ${paletteColor.primary.white};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.05em;
  }
`;
