import { ISubmenuItem } from 'modules/layout/types';
import * as React from 'react';
import { IBoards } from '../types';
import typeId from './issuesTypes';

export const MCP_PA_TASKS = 'mcpProjetosAgeisTasks';

export const MCP_PA_TASKS_SUBMENUS: ISubmenuItem[] = [
  {
    key: 'schedule',
    name: 'Cronograma',
    to: '/schedule',
  },
  {
    key: 'worklogs',
    name: 'Bilhetagem',
    to: '/worklogs',
  },
  {
    key: 'tasks',
    name: 'Tarefas',
    to: '/tasks',
  },
];

export const MCP_PA_TASKS_BOARDS: IBoards[] = [
  {
    columnTitle: 'A Fazer',
    filterStatus: typeId.todo,
    description: 'Atividades que serão realizadas no período atual.',
  },
  {
    columnTitle: 'Em Progresso',
    filterStatus: typeId.doing,
    description: 'Atividades que estão em andamento.',
  },
  {
    columnTitle: 'Bloqueado',
    filterStatus: typeId.blocked,
    description: 'Atividades que estão bloqueadas ou com pendências.',
  },
  {
    columnTitle: 'Revisando',
    filterStatus: typeId.review,
    description: 'Atividades concluídas aguardando validação.',
  },
  {
    columnTitle: 'Feito',
    filterStatus: typeId.done,
    description: 'Atividades concluídas e validadas.',
  },
];

export const MCP_PA_TASKS_JOYRIDE_STEPS: any[] = [
  {
    target: '.joyride__sprint-select',
    content: (
      <p>
        Os projetos são divididos por sprints semanais ou quinzenais, dependendo
        do time de atuação do projeto. Nesse seletor você pode visualizar a
        sprint mais recente (padrão) ou navegar em entregas mais antigas de
        sprints anteriores.
      </p>
    ),
    title: 'Tarefas',
    locale: {
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
    },
    disableBeacon: true,
    placement: 'bottom-end',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
  {
    target: '.joyride__board',
    content: (
      <p>
        Estes quadros exibem o andamento de cada tarefa no seu projeto. Em
        ordem: Tarefas a fazer; Tarefas sendo executadas; Tarefas bloqueadas por
        algum problema externo e Tarefas finalizadas na sprint selecionada.
      </p>
    ),
    title: 'Tarefas',
    locale: {
      back: <span>Voltar</span>,
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
    },
    disableBeacon: true,
    placement: 'bottom',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
  {
    target: '.joyride__board-card',
    content: (
      <p>
        Isto é uma tarefa. Ela contém nome, prioridade (verde, amarelo ou
        vermelho) e tempo bilhetado ou previsto. Clique em uma tarefa se desejar
        saber mais informações sobre ela.
      </p>
    ),
    title: 'Tarefas',
    locale: {
      back: <span>Voltar</span>,
      last: <span>Finalizar</span>,
      skip: <span>Pular</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
];
