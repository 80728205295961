import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import client from 'apolloClient';
import SelectProject from 'modules/common/components/SelectProject';
import { IProject } from 'modules/common/types';
import {
  createOrUpdateItemAtBrowserDB,
  getStateFromBrowserDB,
} from 'utils/storage';
import * as React from 'react';
import { queries } from '../graphql';
import { isDisableExecuteButton } from '../rules';
import {
  IPreventive,
  IPreventiveCategory,
  IPreventiveResponse,
  IAwsAccount,
} from '../types';
import PreventiveDialog from './PreventiveDialog';
import PreventiveTimeout from './PreventiveTimeout';
import S from './styled';
import Typography from '@material-ui/core/Typography';
import { MCP_WORKSPACE_INDEX } from 'modules/common/utils/constants';
import {
  MCP_CLOUD_COMPLIANCE_STATE,
  MCP_AWS_REGIONS_LIST,
} from '../utils/constants';
import Tooltip from 'modules/common/components/Tooltip';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Checkbox from '@material-ui/core/Checkbox';

interface IProps {
  preventivesList: IPreventive[];
  preventiveCategory: IPreventiveCategory[];
  workspaceCode: string;
  workspaceName: string;
  executePreventives: (
    workspaceCode: string,
    workspaceName: string,
    project: string,
    preventivesToExecute: IPreventive[],
    regions?: string[],
    accounts?: string[]
  ) => Promise<IPreventiveResponse[]>;
  stepsJoyride?: any[];
  setStepsJoyride: (stepsJoyride: any[]) => void;
}

export default function PreventiveList(props: IProps) {
  const {
    workspaceCode,
    workspaceName,
    executePreventives,
    preventiveCategory,
  } = props;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [preventivesTimeout, setPreventivesTimeout] = React.useState<boolean>(
    false
  );
  const [dialogStats, setDialogStats] = React.useState<boolean>(false);
  const [preventivesResponse, setPreventivesResponse] = React.useState<
    IPreventiveResponse[]
  >([]);
  const [awsRegion, setAwsRegion] = React.useState<string>('');
  const [awsAccounts, setAwsAccounts] = React.useState<
    IAwsAccount[] | undefined
  >(undefined);
  const [accountsSelectedIds, setAccountsSelectedIds] = React.useState<
    string[]
  >([]);
  const [preventivesSelected, setPreventivesSelected] = React.useState<
    IPreventive[]
  >([]);
  const [projectSelected, setProjectSelected] = React.useState<
    IProject | undefined
  >();
  const [preventivesToExecute, setPreventivesToExecute] = React.useState<
    IPreventive[]
  >([]);
  const [expandedRows, setExpandedRows] = React.useState<string[]>([]);
  const [expand, setExpand] = React.useState({});

  const getPreventivesSelectedKeys = React.useCallback(() => {
    return preventivesSelected.map((preventive) => preventive.key);
  }, [preventivesSelected]);

  const getAlias = React.useCallback(
    (accountId: string) => {
      if (!awsAccounts) {
        return '';
      }

      const targetAccount = awsAccounts.find(
        (account) => account.id === accountId
      );

      if (!targetAccount?.alias) {
        return '';
      }

      return targetAccount.alias;
    },
    [awsAccounts]
  );

  React.useEffect(() => {
    getAccounts().then(setAwsAccounts);

    const savedState =
      getStateFromBrowserDB(
        MCP_WORKSPACE_INDEX,
        workspaceCode,
        'localStorage',
        MCP_CLOUD_COMPLIANCE_STATE
      ) || {};

    setPreventivesSelected(savedState.preventivesSelected || []);
    setProjectSelected(savedState.projectSelected || undefined);
    setAccountsSelectedIds(savedState.accountsSelected || []);
    setAwsRegion(savedState.awsRegion || '');
  }, [workspaceCode]);

  React.useEffect(() => {
    const availablePreventivesSelected = preventivesSelected.filter(
      (preventiveSelected) =>
        preventiveCategory.some((category) =>
          category.preventivesList.some((p) => p.key === preventiveSelected.key)
        )
    );

    if (availablePreventivesSelected.length !== preventivesSelected.length) {
      setPreventivesSelected(availablePreventivesSelected);
    }
  }, [preventivesSelected, preventiveCategory]);

  React.useEffect(() => {
    if (!awsAccounts) {
      return;
    }

    const availableAccounts = accountsSelectedIds.filter((accountSelected) =>
      awsAccounts.some((account) => account.id === accountSelected)
    );

    if (availableAccounts.length !== accountsSelectedIds.length) {
      setAccountsSelectedIds(availableAccounts);
    }
  }, [accountsSelectedIds, awsAccounts]);

  React.useEffect(() => {
    createOrUpdateItemAtBrowserDB(
      MCP_WORKSPACE_INDEX,
      workspaceCode,
      'localStorage',
      MCP_CLOUD_COMPLIANCE_STATE,
      {
        preventivesSelected,
        awsRegion,
        projectSelected,
        accountsSelected: accountsSelectedIds,
      }
    );
  }, [
    preventivesSelected,
    awsRegion,
    projectSelected,
    accountsSelectedIds,
    workspaceCode,
  ]);

  const getProjects = async (workspace: string) => {
    try {
      const { data } = await client.query<{ projects: IProject[] }>({
        query: queries.getProjects,
        variables: {
          workspace,
        },
      });
      return data.projects;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getAccounts = async () => {
    try {
      const { data } = await client.query<{ awsAccounts: IAwsAccount[] }>({
        query: queries.awsAccounts,
      });
      return data.awsAccounts;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleClose = () => {
    setPreventivesToExecute([]);
  };

  const getRegionName = (regions: string[][], selectedRegion: string) => {
    if (!selectedRegion) {
      return 'Global';
    }
    return regions.find((region) => region[0] === selectedRegion)![1];
  };

  const renderDialog = () => {
    if (!preventivesToExecute.length) {
      return null;
    }

    const dialogCharge = () => {
      const charge = preventivesToExecute.reduce((t, p) => (t += p.charge), 0);
      if (charge > 1) {
        return charge + ' horas';
      }
      return charge + ' hora';
    };

    return (
      <S.DialogExecute open={true}>
        <S.DialogTitleCustom>
          Confirmar execução de serviços
        </S.DialogTitleCustom>
        <DialogContent>
          <S.ExecutionTitle>
            Os seguintes serviços serão executados:
          </S.ExecutionTitle>
          <ul>
            {preventivesToExecute.map((preventive, i) => (
              <li key={i}>
                <Typography>{preventive.name}</Typography>
              </li>
            ))}
          </ul>
          <S.ExecutionTitle>
            A execução será feita nas seguintes contas da AWS:
          </S.ExecutionTitle>
          <ul>
            {accountsSelectedIds.map((value, i) => (
              <li key={i}>
                <Typography>
                  {getAlias(value)} ({value})
                </Typography>
              </li>
            ))}
          </ul>
          <Typography variant="body1">
            Na região{' '}
            <strong>{getRegionName(MCP_AWS_REGIONS_LIST, awsRegion)}</strong>
          </Typography>
          <div className="alignTooltip">
            <SelectProject
              workspace={workspaceCode}
              getProjects={getProjects}
              setProjectSelected={setProjectSelected}
              projectSelected={projectSelected}
            />
            <Tooltip
              title="Selecione o Projeto"
              description="Escolha em qual projeto serão cobradas as horas de execução."
            />
          </div>

          <Typography variant="body1">
            Normalmente, este serviço descontaria
            <strong> {dialogCharge()} </strong>
            do seu plano. No entanto, temporariamente, esta execução
            <strong> descontará 0 horas</strong> do seu plano. Ou seja, não
            haverá cobrança de horas.
          </Typography>
        </DialogContent>
        <S.DialogActionsPreventive>
          <S.SmallButton
            variant="outlined"
            size="small"
            color="primary"
            className="btnExecute"
            onClick={handleClose}
          >
            Cancelar
          </S.SmallButton>
          <S.SmallButton
            variant="contained"
            size="small"
            color="primary"
            className="btnExecute"
            onClick={() => handleExecutePreventives()}
            disabled={!projectSelected}
          >
            Executar
          </S.SmallButton>
        </S.DialogActionsPreventive>
      </S.DialogExecute>
    );
  };

  const handleExecutePreventivesTimeout = () => {
    setPreventivesTimeout(true);
  };

  const handleExecutePreventives = async () => {
    handleClose();
    setLoading(true);
    setDialogStats(true);

    const executePreventTimeout = setTimeout(() => {
      handleExecutePreventivesTimeout();
    }, 600000);

    const responses = await executePreventives(
      workspaceCode,
      workspaceName,
      projectSelected!.key,
      preventivesToExecute,
      [awsRegion],
      accountsSelectedIds
    );
    setPreventivesResponse(responses);
    setLoading(false);
    clearTimeout(executePreventTimeout);
  };

  const handleDialogClose = () => {
    setDialogStats(false);
    setPreventivesToExecute([]);
    setPreventivesResponse([]);
    setPreventivesTimeout(false);
  };

  const handleDelete = (account: string) => () => {
    setAccountsSelectedIds((accounts) =>
      accounts.filter((acc) => acc !== account)
    );
  };

  const handleToggleExpander = (preventiveId: string) => {
    const isExpanded = expandedRows.includes(preventiveId);

    isExpanded ? (expand[preventiveId] = false) : (expand[preventiveId] = true);

    setExpand(expand);

    setExpandedRows(
      isExpanded
        ? expandedRows.filter((id) => id !== preventiveId)
        : expandedRows.concat(preventiveId)
    );
  };

  const handleMouseOver = (e) => {
    e.stopPropagation();
  };

  const handleSelectAll = (
    e?: React.ChangeEvent<HTMLInputElement>,
    ignoreEvent = false
  ) => {
    if (ignoreEvent || (e && e.target.checked)) {
      const selectPreventiveList = preventiveCategory.map(
        (category) => category.preventivesList
      );

      let newPreventiveList: typeof selectPreventiveList[0] = [];

      for (let i = 0; i < selectPreventiveList.length; i++) {
        newPreventiveList = newPreventiveList.concat(selectPreventiveList[i]);
      }

      setPreventivesSelected(newPreventiveList);

      return;
    }

    setPreventivesSelected([]);
  };

  const handleSelectAllCategory = (selectedCategory: IPreventiveCategory) => {
    const preventiveListByCategory = preventiveCategory.find(
      (category) => category.key === selectedCategory.key
    );

    const unique = (items: IPreventive[]) =>
      items.filter((value, index) => items.indexOf(value) === index);

    setPreventivesSelected((prevSelected) => {
      const preventivesInCategory = preventiveListByCategory!.preventivesList;

      const newSelected = unique(prevSelected.concat(preventivesInCategory));

      return newSelected;
    });
  };

  const handleSelectNone = () => {
    setPreventivesSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    preventive: IPreventive
  ) => {
    const selectedIndex = getPreventivesSelectedKeys().indexOf(preventive.key);

    const toAddPreventive = selectedIndex === -1;

    if (toAddPreventive) {
      setPreventivesSelected([...preventivesSelected, preventive]);
    } else {
      const beforeSelected = preventivesSelected.slice(0, selectedIndex);
      const afterSelected = preventivesSelected.slice(selectedIndex + 1);
      const newSelected = beforeSelected.concat(afterSelected);

      setPreventivesSelected(newSelected);
    }
  };

  const isSelected = (preventive: IPreventive) =>
    getPreventivesSelectedKeys().indexOf(preventive.key) !== -1;

  const PopperSelect = (props: { category: IPreventiveCategory }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );
    const [placement, setPlacement] = React.useState<PopperPlacementType>();
    const [open, setOpen] = React.useState(false);

    const handleClickPopper = (newPlacement: PopperPlacementType) => (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <Popper open={open} anchorEl={anchorEl} placement={placement}>
          <ClickAwayListener onClickAway={handleClose}>
            <S.PaperCustom>
              <S.ButtonContent onClick={() => handleSelectAll(undefined, true)}>
                Todos os serviços
              </S.ButtonContent>
              <S.ButtonContent
                onClick={() => handleSelectAllCategory(props.category)}
              >
                Todos em {props.category.name}
              </S.ButtonContent>
              <S.ButtonContent onClick={handleSelectNone}>
                Nenhum
              </S.ButtonContent>
            </S.PaperCustom>
          </ClickAwayListener>
        </Popper>

        <S.ButtonSelectContainer onClick={handleClickPopper('bottom-start')}>
          <ExpandMoreIcon className="icon-color" />
        </S.ButtonSelectContainer>
      </>
    );
  };

  return (
    <>
      <S.BoxCustom>
        {!preventivesTimeout ? (
          <PreventiveDialog
            loading={loading}
            dialogStats={dialogStats}
            handleDialogClose={handleDialogClose}
            preventivesResponse={preventivesResponse}
            workspaceCode={workspaceCode}
            workspaceName={workspaceName}
            projectKey={projectSelected?.key}
          />
        ) : (
          <PreventiveTimeout
            dialogStats={dialogStats}
            handleDialogClose={handleDialogClose}
          />
        )}
        <Grid container className="execution-action">
          <S.FormControlCustom>
            <S.CustomSelectMultiple
              className="custom-select"
              labelId="multiple-multi-account"
              value={
                accountsSelectedIds.length
                  ? accountsSelectedIds
                  : [
                      accountsSelectedIds.length === 1
                        ? 'Selecione a conta da AWS'
                        : 'Selecione as contas da AWS',
                    ]
              }
              onChange={(e) =>
                setAccountsSelectedIds(
                  e.target.value.filter((acc) => !isNaN(Number(acc)))
                )
              }
              multiple
              renderValue={(value) =>
                accountsSelectedIds.length ? (
                  <div className="chip-container">
                    {value.map((multi, i) => (
                      <>
                        <S.CustomChip
                          key={i}
                          onDelete={handleDelete(multi)}
                          label={`${getAlias(multi)} (${multi})`}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                        />
                      </>
                    ))}
                  </div>
                ) : (
                  <S.CustomMenuItem key="placeholder" value="">
                    {value}
                  </S.CustomMenuItem>
                )
              }
            >
              {awsAccounts &&
                awsAccounts.map((account) => (
                  <S.MenuItemAccount key={account.id} value={account.id}>
                    {account.alias} ({account.id})
                  </S.MenuItemAccount>
                ))}
            </S.CustomSelectMultiple>
          </S.FormControlCustom>
          <S.CustomSelect
            displayEmpty
            className="joyride__selector"
            value={awsRegion}
            onChange={(event) => setAwsRegion(event.target.value as string)}
            disabled={accountsSelectedIds.length === 0}
          >
            <S.CustomMenuItem key="placeholder" value="" disabled>
              Selecione a Região da Cloud
            </S.CustomMenuItem>

            {MCP_AWS_REGIONS_LIST.map(([regionCode, regionName]) => (
              <S.MenuItemCustom key={regionCode} value={regionCode}>
                {regionName}
              </S.MenuItemCustom>
            ))}
          </S.CustomSelect>
          <S.LargeButton
            variant="contained"
            size="small"
            color="primary"
            className="btnExecuteLarge joyride__btnExecute"
            disableElevation
            disabled={isDisableExecuteButton(
              awsRegion,
              accountsSelectedIds,
              preventivesSelected
            )}
            onClick={() => setPreventivesToExecute(preventivesSelected)}
          >
            Executar Selecionados
          </S.LargeButton>
        </Grid>
        {preventiveCategory.map((category) => {
          const categoryPreventives = category.preventivesList;

          return (
            <S.TableHeaderCustom key={category.key}>
              <Table>
                <S.TableHeader>
                  <TableRow>
                    <S.TableCellTitle>
                      <S.CheckboxGrid>
                        <div>
                          <S.CheckboxCustom
                            indeterminate={
                              categoryPreventives.some(isSelected) &&
                              !categoryPreventives.every(isSelected)
                            }
                            checked={categoryPreventives.every(isSelected)}
                            onChange={handleSelectAll}
                            icon={<CheckBoxOutlineBlankOutlinedIcon />}
                            checkedIcon={<CheckBoxOutlinedIcon />}
                            indeterminateIcon={
                              <IndeterminateCheckBoxOutlinedIcon />
                            }
                          />

                          <PopperSelect category={category} />
                        </div>
                        <S.TypographyCategory>
                          {category.name}
                        </S.TypographyCategory>
                      </S.CheckboxGrid>
                    </S.TableCellTitle>
                  </TableRow>
                </S.TableHeader>
                <S.TableBodyCustom>
                  {category.preventivesList.map(
                    (preventive: IPreventive, index: number) => (
                      <>
                        <S.TableRowCollapse
                          key={`preventive_${index}`}
                          className="joyride__preventives-list"
                          onClick={(e) => handleToggleExpander(preventive.key)}
                        >
                          <S.TableCollapseContainer>
                            <S.CheckboxGrid onClick={handleMouseOver}>
                              <Checkbox
                                name="Executar"
                                onChange={(e) => {
                                  const el = e.target as HTMLInputElement;
                                  if (el.checked) {
                                    setPreventivesSelected([
                                      ...preventivesSelected,
                                      preventive,
                                    ]);
                                  } else {
                                    setPreventivesSelected(
                                      preventivesSelected.filter(
                                        ({ key }) => key !== preventive.key
                                      )
                                    );
                                  }
                                }}
                                color="primary"
                                icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                checkedIcon={<CheckBoxOutlinedIcon />}
                                checked={isSelected(preventive)}
                                onClick={(e) => handleClick(e, preventive)}
                              />
                            </S.CheckboxGrid>
                            <Typography>{preventive.name}</Typography>
                            <IconButton size="small">
                              {expand[preventive.key] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </S.TableCollapseContainer>
                          <S.FlagContainer onClick={handleMouseOver}>
                            {preventive.global && (
                              <>
                                <S.Flag>Global</S.Flag>
                                <Tooltip
                                  title="Serviço Global"
                                  description="Não depende da escolha de região da cloud para ser executado."
                                />
                              </>
                            )}
                          </S.FlagContainer>
                        </S.TableRowCollapse>
                        <S.TableRowContent>
                          <S.TableCellContent>
                            {expandedRows.includes(preventive.key) && (
                              <S.DescriptionPreventive>
                                {preventive.description}
                              </S.DescriptionPreventive>
                            )}
                          </S.TableCellContent>
                        </S.TableRowContent>
                      </>
                    )
                  )}
                </S.TableBodyCustom>
              </Table>
            </S.TableHeaderCustom>
          );
        })}
        {renderDialog()}
      </S.BoxCustom>
    </>
  );
}
