import Typography, { TypographyProps } from '@material-ui/core/Typography';
import * as React from 'react';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { priorityColor } from '../../utils/support';
import { styled, css } from '@cmp/ui';
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from 'modules/common/styles/theme';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { paletteColor } from 'modules/common/styles/colors';

export interface IStyledProps {
  color?: string;
  selected?: boolean;
  maxwidth?: string;
  height?: string;
  width?: string;
}

interface ITextProps extends TypographyProps {
  textColor?: string;
  textSize?: string;
  textWeight?: number;
  textAlign?: string;
}
interface ITextContainer {
  sended?: string;
  height?: string;
  display?: string;
}

const TypographyWrapper = React.forwardRef<HTMLSpanElement, ITextProps>(
  function TypographyWrapper(
    { textColor, textSize, textWeight, textAlign, ...other }: ITextProps,
    ref
  ) {
    return <Typography {...other} ref={ref} />;
  }
);

export const Dialog = styled((props: DialogProps) => (
  <MuiDialog
    classes={{ paper: 'paper' }}
    fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
    {...props}
  />
))`
  & .MuiDialog-scrollPaper {
    display: flex;
    justify-content: center;
  }

  & .paper {
    max-width: 1000px;
    width: 800px;
    max-height: calc(100% - 110px);
  }

  ${theme.breakpoints.down('sm')} {
    & .paper {
      max-height: 100%;
      width: 100%;
      background: ${paletteColor.info.background};
    }
  }
`;

export const AlertCell = styled.div`
  display: flex;
  letter-spacing: 0.03em;
  color: ${(props: IStyledProps) =>
    props.color ? props.color : paletteColor.dark.main};
`;

export const AlertRow = styled(Grid)<IStyledProps>`
  background: ${paletteColor.primary.white};
  box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
  border-radius: 6px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  position: relative;
  transition: all 200ms;
  height: 56px;
  &:before {
    content: '';
    position: absolute;
    background: ${paletteColor.primary.main};
    width: 3px;
    height: 0;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0 5px 5px 0;
    transition: height 250ms;
  }
  &:hover {
    &:before {
      height: 24px;
    }
  }

  & .task-content {
    justify-content: flex-end;
  }

  ${theme.breakpoints.down('sm')} {
    height: auto;
    display: block;

    & .task-content {
      justify-content: flex-start;
      margin-top: 10px;
    }
  }
`;

export const Typography2 = styled(Typography)`
  && {
    display: flex;
    color: ${paletteColor.info.soft};
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const CommentsBox = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
  border-top: 1px solid ${paletteColor.info.border};
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const CommentWrapper = styled(Grid)`
  margin-bottom: 16px;

  & pre {
    overflow-x: auto;
  }
`;

export const DangerInsert = styled.div`
  && {
    word-break: break-word;
    color: ${paletteColor.info.soft};
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
  }
  & .rendericon {
    display: none;
  }
  & .nobr > a[data-attachment-name] {
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: 100%;
    padding: 8px;
    margin-top: 8px;
    border-radius: 3px;

    background: ${paletteColor.info.fill};
    color: ${paletteColor.info.soft};
    text-decoration: inherit;
    font-size: 14px;
    font-weight: 400;
    ::after {
      font-family: 'Material Icons';
      content: '\\E884';
      font-size: 20px;
      margin-left: 8px;
    }
  }
  & .image-wrap img {
    width: 100%;
    height: auto;
  }
  & .codeContent {
    overflow: auto;
    background: ghostwhite;
    border-radius: 3px;
    border: 1px solid rgb(223, 225, 235);
    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const AuthorAvatar = styled.div`
  display: flex;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;

  color: rgba(255, 255, 255, 0.6);
  background: #62d589;
  border-radius: 100%;

  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
`;

export const ModalBoxHeader = styled.div`
  border-bottom: 1px solid ${paletteColor.info.border};
  padding-left: 20px;
  padding-right: 20px;
  min-width: 100%;
  font-size: 16px;

  & .btn-close {
    color: ${paletteColor.primary.white};
  }

  ${theme.breakpoints.down('sm')} {
    :not(.bg-inherit) {
      background-color: ${paletteColor.primary.main};
      color: ${paletteColor.primary.white};
    }
  }
`;

export const ModalBox = styled(Grid)`
  && {
    overflow-y: auto;
    display: grid;
    grid-template-columns: 9fr 3fr;
    grid-template-rows: 2fr;
  }

  & .grid-left {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  & .grid-center {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }

  & .grid-right {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  & .box-title-container {
    flex-grow: 2;
  }

  ${theme.breakpoints.down('sm')} {
    && {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    & .grid-left {
      width: 100%;
      order: 1;
      background: ${paletteColor.primary.white};
    }

    & .grid-right {
      width: 100%;
      order: 2;
    }

    & .grid-center {
      width: 100%;
      order: 3;
    }
  }
`;

export const BoxTitle = styled.h3`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down('sm')} {
    font-size: 14px;
  }
`;

export const Topic = styled.div`
  margin-top: 18px;
  margin-left: 24px;
  margin-right: 24px;
`;

export const TopicHeading = styled.h4`
  display: flex;
  align-items: center;
  margin: 0;
  color: ${paletteColor.info.text};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 164.06%;
  letter-spacing: 0.03em;
`;

export const GridRight = styled(Grid)`
  border-left: 1px solid ${paletteColor.info.border};
`;

export const IssueInformationBox = styled.div`
  margin-bottom: 24px;
`;

export const InformationChip = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  background: ${paletteColor.info.fill};
  color: ${paletteColor.info.soft};
  border-radius: 3px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.05em;

  ${theme.breakpoints.down('sm')} {
    &.information-bg {
      background: ${paletteColor.primary.white};
    }
  }
`;

export const InformationChipCentered = styled(InformationChip)`
  display: flex;
  justify-content: center;
`;

export const InformationChipTableCell = styled(InformationChip)`
  display: table-cell;
`;

const translatedPriority = {
  Lowest: 'Mais Baixa',
  Low: 'Baixa',
  Medium: 'Média',
  High: 'Alta',
  Highest: 'Mais alta',
};

export const FakeIssuePriorityHeader = styled<{ priority: React.ReactNode }>(
  ({ priority, ...rest }) => (
    <ModalBoxHeader {...rest}>
      <BoxTitle>{translatedPriority[priority]}</BoxTitle>
    </ModalBoxHeader>
  )
)`
  display: flex;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 24px;
    background: ${paletteColor.primary.white};
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

export const IssuePriorityHeader = styled<{ priority: string }>(
  ({ priority, ...rest }) => (
    <ModalBoxHeader {...rest}>
      <BoxTitle>{translatedPriority[priority]}</BoxTitle>
    </ModalBoxHeader>
  )
)`
  display: flex;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 24px;
    background: ${(props) => priorityColor(props.priority)};
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  ${theme.breakpoints.down('sm')} {
    border-bottom: none;
    background: ${paletteColor.primary.white};
    color: ${paletteColor.info.soft};
    height: 26px;

    &:after {
      height: 14px;
    }
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: inherit;
`;

export const TabComponentRoot = styled.div`
  & .MuiBox-root.bg-white {
    background-color: white;
  }

  & .MuiBox-root.bg-inherit {
    background-color: inherit;
  }
`;

export const PageMenu = styled.div`
  top: 50px;
  left: 0;
  width: 100%;
  height: 50px;
  overflow: auto;
  background: ${paletteColor.primary.white};
  display: flex;
  border-bottom: 1px solid ${paletteColor.info.border};
  padding: 0 6px;
  ${theme.breakpoints.down('sm')} {
    && {
      border-top: 1px solid ${paletteColor.info.border};
    }
  }
`;

export const PageMenuItem = styled(Tab)`
  && {
    z-index: 99;
    position: relative;
    border: 0;
    display: flex;
    padding: 0 30px;
    color: ${paletteColor.info.soft};
    text-decoration: none;
    align-items: center;
    font-size: 14px;
    text-transform: initial;

    &:after {
      content: '';
      background: ${paletteColor.primary.main};
      height: 2px;
      width: 0;
      position: absolute;
      bottom: 0;
      left: -30%;
      transform: translateX(50%);
      border-radius: 3px 3px 0 0;
      transition: width 250ms;
    }
    ${(props: IStyledProps) =>
      props.selected &&
      css`
        color: ${paletteColor.info.text};
        &:after {
          width: 72%;
          left: -22%;
        }
      `}
  }
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.03em;
  line-height: 20px;
  color: ${paletteColor.info.soft};
  & .content-empty-state {
    margin-left: 15px;
  }

  ${theme.breakpoints.down('xs')} {
    flex-direction: column;
    text-align: center;

    & .content-empty-state {
      margin-top: 30px;
    }
  }
`;

export const TitleEmptyState = styled(Typography)`
  && {
    font-weight: 500;
    margin-bottom: 4px;
  }
`;

export const TabContainer = styled.div`
  background: ${paletteColor.info.background};
`;

export const IssueDescriptionWrapper = styled.div``;

export const SectionTabContent = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 16px;
`;

export const GridWrapper = styled(Grid)`
  && {
    flex: 1 1 auto;
    margin-left: 16px;
    padding: 10px;
    background: ${paletteColor.primary.white};
    border: 1px solid ${paletteColor.info.fill};
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    max-width: 500px;

    ${theme.breakpoints.down('sm')} {
      min-width: 200px;
    }
  }
`;

export const TypographyAuthor = styled(Typography)`
  && {
    color: ${paletteColor.info.text};
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
    margin: 0;
    letter-spacing: 0.03em;
    margin-bottom: 4px;
  }

  & span {
    font-weight: normal;
  }
`;

export const TypographyCustom = styled(Typography)`
  color: ${paletteColor.info.soft};
  line-height: 20px;
`;

export const Doc = styled.div`
  & a {
    color: ${paletteColor.info.text};
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    letter-spacing: 0.03em;
    text-decoration: none;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RatingContent = styled.div`
  width: 100%;
  max-width: 448px;
  background: ${paletteColor.primary.white};
  border: 1px solid ${paletteColor.info.fill};
  border-radius: 3px;
  padding: 32px;

  & .rating-label {
    padding-left: 16px;
  }

  & .MuiFormControlLabel-label {
    font-size: 12px;
  }

  & .MuiIconButton-colorPrimary:hover,
  & .MuiRadio-colorPrimary.Mui-checked:hover {
    background: transparent;
  }

  & .MuiRadio-root {
    color: ${paletteColor.primary.main};
  }

  & .MuiSvgIcon-root:first-child {
    fill: ${paletteColor.info.border};
  }
`;

export const Text = styled(TypographyWrapper)<ITextProps>`
  && {
    color: ${(props: ITextProps) => props.textColor || paletteColor.info.text};
    font-size: ${(props: ITextProps) => props.textSize || '12px'};
    font-weight: ${(props: ITextProps) => props.textWeight || 500};
    line-height: 16px;
    text-align: ${(props: ITextProps) => props.textAlign || 'left'};
    padding: 8px 0;
    letter-spacing: 0.03em;
  }
`;

export const RadioGroupCustom = styled(RadioGroup)`
  && {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding: 16px 0;

    & .MuiFormControlLabel-label {
      color: ${paletteColor.info.soft};
      font-size: 11px;
      line-height: 18px;
      letter-spacing: 0.03em;
    }
  }
`;

export const Textarea = styled(TextareaAutosize)`
  && {
    width: 100%;
    background-color: ${paletteColor.primary.white};
    border: 1px solid ${paletteColor.info.border};
    border-radius: 3px;
    color: ${paletteColor.info.soft};
    font-size: 11px;
    line-height: 18px;
    font-style: italic;
    resize: none;
    padding: 8px;
    outline: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
`;

export const SendButton = styled(Button)`
  && {
    width: 100%;
    max-width: 90px;
    height: 24px;
  }
`;

export const ButtonProgress = styled(CircularProgress)`
  margin-left: 15px;
  margin-right: 14px;
  margin-bottom: 1px;
`;

export const TextContainer = styled.div<ITextContainer>`
  background: rgba(65, 194, 101, 0.1);
  border: 1px solid #41c265;
  border-radius: 3px;
  text-align: center;
  color: #41c265;
  padding: 16px;
  display: ${(props: ITextContainer) => props.display || 'block'};
  align-items: center;
  justify-content: center;
  height: ${(props: ITextContainer) => props.height || 'auto'};

  & .MuiTypography-root:first-child {
    font-weight: 700;
  }
`;

export const CopyButton = styled(Button)`
  display: flex;
  width: ${(props: IStyledProps) => props.width || '100%'};
  max-width: ${(props: IStyledProps) => props.maxwidth || '90px'};
  height: ${(props: IStyledProps) => props.height || '24px'};

  & svg {
    margin-right: auto;
    fill: ${paletteColor.primary.main};
  }

  & span {
    margin-right: auto;
  }
`;
