import * as React from 'react';
import * as S from './styled';

interface IProps {
  workspace: string;
  selected: string;
}

const Filter = (props: IProps) => (
  <S.Main>
    <S.PageMenu>
      <S.FabCustom>
        <S.PageMenuItem to="/cmdb" selected={props.selected === 'matriz'}>
          Matriz
        </S.PageMenuItem>
      </S.FabCustom>
      <S.FabCustom>
        <S.PageMenuItem to="/cmdb/list" selected={props.selected === 'list'}>
          Lista
        </S.PageMenuItem>
      </S.FabCustom>
    </S.PageMenu>
  </S.Main>
);

export default Filter;
