import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';
import { ResourceType } from '../types';
import { ResourceForm } from './';
import * as S from './styled';

interface IProps {
  workspaceCode: string;
  updateId: string | null;
  setReload: (state: boolean) => void;
  handleClose: () => void;
  getResource: (id: string, workspaceCode: string) => Promise<any>;
  updateResource: (
    id: string,
    workspaceCode: string,
    resourceName: string,
    resourceVersion: string,
    resourceType: string,
    isDependency: boolean
  ) => Promise<any>;
}

const initialResourceType = 'APPLICATION';

const DialogResourceAdd = ({
  workspaceCode,
  setReload,
  updateId,
  handleClose,
  getResource,
  updateResource,
}: IProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [resourceName, setResourceName] = React.useState<string>('');
  const [resourceVersion, setResourceVersion] = React.useState<string>('');
  const [isDependency, setIsDependency] = React.useState<boolean>(false);
  const [resourceType, setResourceType] = React.useState<ResourceType>(
    initialResourceType
  );
  const [error, setError] = React.useState<{
    field: string;
    message: string;
  }>({
    field: '',
    message: '',
  });

  const loadResource = React.useCallback(async () => {
    if (updateId) {
      setLoading(true);
      try {
        const response = await getResource(updateId, workspaceCode);
        setResourceName(response.name);
        setResourceType(response.type);
        setResourceVersion(response.version);
        setIsDependency(response.isDependency);
      } finally {
        setLoading(false);
      }
    }
  }, [getResource, updateId, workspaceCode]);

  React.useEffect(() => {
    loadResource();
  }, [updateId, loadResource]);

  const handleUpdateResource = async () => {
    if (!updateId) {
      handleClose();
    } else if (!resourceName) {
      setError({ field: 'name', message: 'Campo obrigatório' });
    } else if (!resourceVersion) {
      setError({ field: 'version', message: 'Campo obrigatório' });
    } else if (resourceVersion.length > 8) {
      setError({ field: 'version', message: 'Máximo 8 caracteres' });
    } else {
      setLoading(true);
      try {
        await updateResource(
          updateId,
          workspaceCode,
          resourceName,
          resourceVersion,
          resourceType,
          isDependency
        );
        setReload(true);
        handleClose();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={Boolean(updateId)} onClose={handleClose}>
      <S.DialogTitleCustom>Editar recurso</S.DialogTitleCustom>
      <S.DialogContentCustom>
        {loading ? (
          <>
            <Skeleton width={350} height={45} />
            <Skeleton width="60%" />
            <Skeleton height={45} />
          </>
        ) : (
          <ResourceForm
            error={error}
            resourceName={resourceName}
            resourceVersion={resourceVersion}
            isDependency={isDependency}
            resourceType={resourceType}
            setResourceName={setResourceName}
            setResourceVersion={setResourceVersion}
            setIsDependency={setIsDependency}
            setResourceType={setResourceType}
          />
        )}
      </S.DialogContentCustom>
      <S.DialogActionsCustom>
        <Button color="primary" size="medium" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          data-cy="btn-saveEdit"
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleUpdateResource}
          disabled={loading}
        >
          Salvar Alterações
        </Button>
      </S.DialogActionsCustom>
    </Dialog>
  );
};

export default DialogResourceAdd;
