import gql from 'graphql-tag';

const logs = `
  query getLogs($workspace: String!, $pageSize: Int, $nextToken: String, $startDate: DateTime, $endDate: DateTime, $text: String) {
    getLogs(type: preventives, workspaceCode: $workspace, pageSize: $pageSize, filters: {
      date: {
        start: $startDate,
        end: $endDate
      }
      text: $text
    }
      nextToken: $nextToken) {
      data{
        id
        timestamp
        operation
        username
      }
      nextToken
      total
    }
  }
`;
const logsById = `
  query getLogsById($workspace: String!, $id: [String]!) {
    getLogsById(workspaceCode: $workspace, id: $id) {
      data{
        id
        timestamp
        operation
        username
        data
      }
    }
  }
`;

const generateReport = gql`
  query generateReport(
    $code: String!
    $type: ReportType!
    $data: String!
    $jiraProject: String!
  ) {
    generateReport(
      code: $code
      type: $type
      data: $data
      jiraProject: $jiraProject
    ) {
      data
    }
  }
`;

const generatedReports = gql`
  query generatedReports($jiraProject: String) {
    generatedReports(jiraProject: $jiraProject) {
      jiraProject
      reportName
      username
      s3Url
      createdAt
    }
  }
`;

export default {
  logs,
  logsById,
  generateReport,
  generatedReports,
};
