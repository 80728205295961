import * as React from 'react';
import S from './styled';

type IProps = {
  count: number;
};

export default function FakeLoader(props: IProps) {
  return (
    <>
      {new Array(props.count)
        .fill(true)
        .map((value: boolean, index: number) => (
          <S.Card animationDelay="0" key={`fake_${index}`}>
            <div className="content">
              <S.Fake width="100%" height={17} mb={7} />
              <S.Fake width="100%" height={45} mb={7} />
              <S.Fake width={80} height={25} mb={7} />
            </div>
          </S.Card>
        ))}
    </>
  );
}
