import * as React from 'react';
import EmptyPage from '../../common/components/EmptyState';
import image from 'modules/common/images/empty_billing.png';

const EmptyState = () => (
  <EmptyPage
    title="AWS Billing"
    description="Tenha visibilidade do consumo dos recursos contratados da plataforma de nuvem AWS."
    image={image}
    readMoreURL="https://platform.mandic.com.br/portfolio/aws-billing/"
    hireURL="https://br.claranet.com/contatos?pgc=portal.cliente"
  />
);

export default EmptyState;
