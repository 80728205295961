import * as React from 'react';
import * as S from './styled';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { withAppContext } from 'modules/common/hoc/withAppContext';
import { IRouterProps } from 'modules/common/types';
import { IWorkspace } from 'modules/auth/types';
import Navigation from '../components/Navigation';
import { getWorkspace, logout } from '../../../authClient';
import { JoyRide, Helper } from '@cmp/ui';

interface IProps extends IRouterProps {
  workspace: IWorkspace;
  workspaces: IWorkspace[];
  runJoyride: boolean;
  stepsJoyride?: any[];
  changeWorkspace: (workspaceCode: string) => void;
  setRunJoyride: (runJoyride: boolean) => void;
  children: React.ReactNode;
}

const hideNavigationUrls = [
  /^\/login(\/)?$/,
  /^\/forgot-password(\/)?$/,
  /^\/new-password(\/)?$/,
  /^\/workspaces(\/)?$/,
];
const hideMenuUrls = [/^\/workspaces(\/)?$/];

const MainLayout = ({
  workspace,
  workspaces,
  changeWorkspace,
  location,
  runJoyride,
  stepsJoyride,
  setRunJoyride,
  children,
}: IProps) => {
  const [
    shouldRedirectToWorkspaces,
    setShouldRedirectToWorkspaces,
  ] = React.useState(false);

  const [redirectUrl, setRedirectUrl] = React.useState<string | undefined>(
    undefined
  );

  React.useEffect(() => {
    const workspacesPage = /^\/workspaces(\/)?$/.test(location.pathname);
    if (workspacesPage || location.pathname === '/') {
      setShouldRedirectToWorkspaces(false);
      return;
    }

    const workspaceStored = getWorkspace();

    if (!workspaceStored) {
      setRedirectUrl(location.pathname);
      setShouldRedirectToWorkspaces(true);
      return;
    }

    if (
      workspaceStored !== 'empty' &&
      !workspaces.find((w) => w.code === workspaceStored)
    ) {
      setRedirectUrl(location.pathname);
      setShouldRedirectToWorkspaces(true);
      return;
    }

    if (workspaceStored === 'empty' && workspaces.length > 0) {
      setRedirectUrl(location.pathname);
      setShouldRedirectToWorkspaces(true);
      return;
    }

    if (workspace === undefined) {
      changeWorkspace(workspaceStored);
    }
  }, [workspace, changeWorkspace, workspaces, location.pathname]);

  if (shouldRedirectToWorkspaces) {
    return (
      <Redirect
        to={{
          pathname: '/workspaces',
          state: {
            redirectUrl: redirectUrl,
          },
        }}
      />
    );
  }

  const hideNavigation = hideNavigationUrls.some((item) =>
    item.test(location.pathname)
  );
  const hideMenu = hideMenuUrls.some((item) => item.test(location.pathname));

  if (hideNavigation) {
    return <>{children}</>;
  }

  if (hideMenu) {
    return (
      <Navigation workspacesPage={true} logout={logout}>
        {(_: boolean) => children}
      </Navigation>
    );
  }

  return (
    <>
      <Navigation workspacesPage={false} logout={logout}>
        {(isMenuOpened: boolean) => (
          <S.RootContainer>
            <S.MainContent id="main-content" isMenuOpened={isMenuOpened}>
              {children}
            </S.MainContent>
          </S.RootContainer>
        )}
      </Navigation>
      <JoyRide steps={stepsJoyride} run={runJoyride} setRun={setRunJoyride} />
      {stepsJoyride && <Helper handleClickStart={() => setRunJoyride(true)} />}
    </>
  );
};

export default withAppContext(withRouter(MainLayout));
