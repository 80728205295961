import React from 'react';
import * as S from './styled';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import { MCP_AWS_REGIONS_LIST } from 'modules/preventives/utils/constants';
import { getAwsRegionName } from 'modules/logs/utils/constants';
import FakeRow from './FakeRow';
import Skeleton from '@material-ui/lab/Skeleton';

interface IProps {
  log: any;
  handleClose: () => void;
  open: boolean;
}

const AnalizeModal = ({ handleClose, log, open }: IProps) => {
  const DialogActions = () => (
    <S.DialogActionsPreventive>
      <S.SmallButton
        variant="contained"
        size="small"
        color="primary"
        className="btnExecute"
        onClick={handleClose}
        data-cy="button-add-to-report"
      >
        Fechar
      </S.SmallButton>
    </S.DialogActionsPreventive>
  );

  if (!log) {
    return (
      <S.DialogExecute open={open}>
        <S.DialogTitleCustom>
          <Skeleton />
        </S.DialogTitleCustom>

        <DialogContent>
          <S.AnalysisText>
            <S.BoldText>Conta da AWS:</S.BoldText>
            <FakeRow count={1} />
          </S.AnalysisText>

          <S.AnalysisText>
            <S.BoldText>Data e hora de execução: </S.BoldText>
            <FakeRow count={1} />
          </S.AnalysisText>

          <S.AnalysisText>
            <S.BoldText>Usuário: </S.BoldText>
            <FakeRow count={1} />
          </S.AnalysisText>

          <S.AnalysisText>
            <S.BoldText>Problemas críticos:</S.BoldText>
            <FakeRow count={2} />
          </S.AnalysisText>

          <S.AnalysisText>
            <S.BoldText>Avisos:</S.BoldText>
            <FakeRow count={2} />
          </S.AnalysisText>

          <S.AnalysisText>
            <S.BoldText>Informações extras:</S.BoldText>
            <FakeRow count={2} />
          </S.AnalysisText>
        </DialogContent>
        <DialogActions />
      </S.DialogExecute>
    );
  }

  const logData = JSON.parse(log.data);

  const executePreventiveError = (logData) => {
    const templateUrl =
      'https://cmp-cf-template.s3.amazonaws.com/customer-role-latest';
    const contactEmail = 'red@br.clara.net';
    const errorCodeLog = {
      ACCOUNT_NOT_FOUND: `Precisamos adicionar essa conta em nosso banco de dados. Para isso, entre em contato conosco em ${contactEmail}.`,
      EXECUTION_ROLE_NOT_FOUND: `Esta conta não está configurada para executar o Cloud Compliance. Crie uma stack no CloudFormation com o seguinte template: ${templateUrl}`,
      INSUFFICIENT_PERMISSIONS_IN_EXECUTION_ROLE: `O CloudFormation com a configuração do serviço está desatualizado, por favor atualize a stack com o seguinte template: ${templateUrl}`,
      EXPLICIT_DENY_ON_PERMISSIONS:
        'Alguma role presente nessa conta está bloqueando as permissões necessárias do Cloud Compliance.',
      REGION_NOT_SUPPORTED: `Este serviço não está disponível na região selecionada. Por favor, selecione outra região da cloud.`,
      CUSTOM_ERROR: logData.result.error.message,
    };
    return (
      errorCodeLog[logData.result.error.code] ||
      `Ops, ocorreu um erro interno ao rodar este serviço, tente novamente. Caso o erro persista, entre em contato conosco em ${contactEmail}.`
    );
  };

  return (
    <S.DialogExecute open={open}>
      <S.DialogTitleCustom>
        <S.BoldText>{logData.preventive.name}</S.BoldText>
      </S.DialogTitleCustom>

      <DialogContent>
        <S.AnalysisTextFlex>
          <S.BoldText>Conta da AWS: </S.BoldText>
          {logData.account}
        </S.AnalysisTextFlex>

        <S.AnalysisTextFlex>
          <S.BoldText>Região da cloud: </S.BoldText>
          {logData.global
            ? 'Global'
            : getAwsRegionName(MCP_AWS_REGIONS_LIST, logData.region)}
        </S.AnalysisTextFlex>

        <S.AnalysisTextFlex>
          <S.BoldText>Data e hora de execução: </S.BoldText>
          {moment(log.timestamp.split('.')[0]).format(
            'DD/MM/YYYY, hh[h]mm[min]'
          )}
        </S.AnalysisTextFlex>

        <S.AnalysisTextFlex>
          <S.BoldText>Usuário: </S.BoldText>
          {log.username}
        </S.AnalysisTextFlex>

        {logData.result.errors && (
          <S.AnalysisText>
            <S.BoldText>Problemas críticos: </S.BoldText>
            {logData.result.errors.length
              ? logData.result.errors.map((err, i) => (
                  <li key={`error_${i}`}>{err}</li>
                ))
              : 'Nenhum problema crítico'}
          </S.AnalysisText>
        )}

        {logData.result.warnings && (
          <S.AnalysisText>
            <S.BoldText>Avisos: </S.BoldText>
            {logData.result.warnings.length
              ? logData.result.warnings.map((war, i) => (
                  <li key={`warning_${i}`}>{war}</li>
                ))
              : 'Nenhum aviso'}
          </S.AnalysisText>
        )}

        {logData.result.info && (
          <S.AnalysisText>
            <S.BoldText>Informações extras: </S.BoldText>
            {logData.result.info.length
              ? logData.result.info.map((info, i) => (
                  <li key={`info_${i}`}>{info}</li>
                ))
              : 'Nenhuma informação extra'}
          </S.AnalysisText>
        )}

        {logData.result.error && (
          <S.AnalysisText>
            <S.BoldText>Erro de execução: </S.BoldText>
            {logData.result.error && executePreventiveError(logData)}
          </S.AnalysisText>
        )}

        <S.AnalysisText>
          <S.BoldText>Importante: </S.BoldText>
          Caso deseje executar alguma das recomendações contidas nesta análise,
          sugerimos que procure o time de especialistas da Mandic, para que
          essas ações sejam feitas sempre de acordo com as melhores práticas de
          DevOps.
        </S.AnalysisText>
      </DialogContent>
      <DialogActions />
    </S.DialogExecute>
  );
};

export default AnalizeModal;
