import { IPreventive } from './types';

export const isDisableExecuteButton = (
  awsRegion: string,
  accountsSelected: string[],
  selectedPreventives: IPreventive[] = []
) => {
  if (!accountsSelected.length) {
    return true;
  }

  if (!selectedPreventives.length) {
    return true;
  }

  if (selectedPreventives.every((p) => p.global)) {
    return false;
  }

  if (awsRegion === '') {
    return true;
  }

  return false;
};
