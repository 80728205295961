import * as React from 'react';
import * as S from './styled';

type IProps = {
  count: number;
};

export default function FakeLoader(props: IProps) {
  return (
    <>
      {new Array(props.count)
        .fill(true)
        .map((value: boolean, index: number) => (
          <S.Fake key={`fake_${index}`} width="100%" height={40} mb={8} />
        ))}
    </>
  );
}
