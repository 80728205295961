import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import S from './CalendarStyled';
import {
  calcTimePercent,
  timeSpentToString,
} from 'modules/common/utils/worklog';

export default function ContentMonth({
  year,
  month,
  monthName,
  timeSpent,
  active,
  poolSize,
  handleChangeDateSelected,
}: {
  year: string;
  month: string;
  monthName: string;
  timeSpent: number;
  active: boolean;
  poolSize: number;
  handleChangeDateSelected?: (
    year: string,
    month: string
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
  const handleOnclick = handleChangeDateSelected
    ? handleChangeDateSelected(year, month)
    : // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {};

  return (
    <S.CustomTooltip
      arrow={true}
      title={
        <>
          <S.TooltipProgress>
            <div className="tooltip-content">
              <Typography component="h5">Horas Planejadas</Typography>
              <Typography component="h6">{poolSize}h</Typography>
            </div>
            <div className="tooltip-content">
              <Typography component="h5">Horas Consumidas</Typography>
              <Typography component="h6">
                {timeSpentToString(timeSpent) || '0h'}
              </Typography>
            </div>
          </S.TooltipProgress>
          <span className="tooltip-arrow" />
        </>
      }
    >
      <S.BoxMonth onClick={handleOnclick} data-cy="month">
        <div className="month-content">
          <S.Capitalize component="h3">{monthName}</S.Capitalize>
          <Typography component="h5">
            {timeSpentToString(timeSpent) || '0h'}
          </Typography>
          <S.MonthActive active={active} />
        </div>
        <S.ProgressContent className="joyride__one-month-progress">
          <S.Progress value={calcTimePercent(timeSpent, poolSize)} />
        </S.ProgressContent>
      </S.BoxMonth>
    </S.CustomTooltip>
  );
}
