import { styled, css } from '@cmp/ui';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Base } from 'modules/common/components/Utils';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { IStyledProps } from '../types';
import { getImpactTypeColor } from '../util';
import Fab from '@material-ui/core/Fab';
import { paletteColor } from 'modules/common/styles/colors';

export const TableCell = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 220px;
  max-width: 220px;
  height: 50px;
  border-color: ${paletteColor.info.border};
  border-style: solid;
  background: ${paletteColor.primary.white};
  font-size: 14px;
  color: ${paletteColor.info.text};

  &:last-child {
    border-bottom: 1px solid ${paletteColor.info.border};
  }
`;

export const SelectorButtomActive = css`
  background-color: ${paletteColor.primary.main};
  color: ${paletteColor.primary.white};
  &:hover,
  &:active {
    background-color: ${paletteColor.primary.main};
    color: ${paletteColor.primary.white};
  }
`;

export const GraphContainer = styled.div`
  position: relative;
  display: block;
`;

export const GraphMenu = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  z-index: 10;
`;

export const SliderContainer = styled.div`
  display: flex;
  background: ${paletteColor.primary.white};
  border: 1px solid ${paletteColor.info.border};
  border-radius: 3px;
  width: auto;
  margin-right: 10px;
`;

export const SliderContent = styled.div`
  display: flex;
  align-items: center;
  border-width: 0 1px 0 1px;
  border-color: ${paletteColor.info.border}s;
  border-style: solid;
  width: 100px;
  padding: 10px;
`;

export const SliderButton = styled(Button)`
  && {
    border-radius: 0;
    padding: 2px 0;
    font-size: 18px;
    color: ${paletteColor.info.soft};
  }
`;

export const ResetLocationButton = styled(Button)`
  && {
    padding: 2px 0;
    z-index: 10;
    background: ${paletteColor.primary.white};
    border: 1px solid ${paletteColor.info.border}s;
    color: ${paletteColor.info.soft};
  }
`;

export const GraphContent = styled.div`
  position: relative;
  display: block;
  & .graph-scroll {
    max-height: calc(100vh - 100px);
    user-select: none;
    overflow: auto;
  }
`;

export const BoxContent = styled.div<IStyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 500px;
  margin-bottom: 500px;
  transform: ${({ zoom }: IStyledProps) => `scale(${zoom})`};
`;

export const BoxResource = styled.div<IStyledProps>`
  display: flex;
  background: ${paletteColor.primary.white};
  box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
  border-radius: 3px;
  height: 50px;
  width: 100%;
  margin-bottom: 8px;
`;

export const DependenceLine = styled.div<IStyledProps>`
  position: absolute;
  top: 25px;
  left: 220px;
  border: 1px solid ${paletteColor.info.soft};
  border-left: transparent;
  width: ${({ size }: IStyledProps) => size && size / 2}px;
  height: ${({ size }: IStyledProps) => size}px;
  border-bottom-right-radius: ${({ size }: IStyledProps) => size && size * 2}px;
  border-top-right-radius: ${({ size }: IStyledProps) => size && size * 2}px;
  border-color: ${({ hasSelected, active }: IStyledProps) =>
    hasSelected && !active && paletteColor.info.border};
  transition: border-color 250ms;
  pointer-events: none;
`;

export const DialogTitleCustom = styled(DialogTitle)`
  && {
    border-bottom: 1px solid ${paletteColor.info.border};
    padding: 16px;
    h2 {
      font-size: 16px;
      font-weight: normal;
    }
  }
`;

export const DialogContentCustom = styled(DialogContent)`
  && {
    padding: 20px;
  }
`;

export const DialogActionsCustom = styled(DialogActions)`
  && {
    padding: 16px;
    border-top: 1px solid ${paletteColor.info.border};
    margin: 0;
  }
`;

export const SelectorCustom = styled.div`
  display: flex;
  justify-content: stretch;
  width: 100%;
`;

export const SelectorButtomCustom = styled(Button)<IStyledProps>`
  && {
    background-color: ${paletteColor.primary.white};
    color: ${paletteColor.info.soft};
    border-color: ${paletteColor.info.border};
    border-style: solid;
    border-width: 1px 1px 1px 0;
    transition: background-color 250ms;
    border-radius: 0;
    padding: 12px;
    &:hover,
    &:active {
      background-color: ${paletteColor.primary.white};
      color: ${paletteColor.info.soft};
    }
    &:first-child {
      border-width: 1px;
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }
    ${({ selected }: IStyledProps) => selected && SelectorButtomActive}
  }
`;

export const FormCustom = styled(FormControl)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &:not(:first-child) {
      margin-top: 8px;
    }
    &:not(:last-child) {
      margin-bottom: 14px;
    }

    @media (max-width: 320px) {
      display: block;
    }
  }
`;

export const FormContent = styled.div`
  min-width: 320px;
  &:not(:first-child) {
    border-left: 1px solid ${paletteColor.info.border};
  }

  @media (max-width: 375px) {
    min-width: 0;
  }
`;

export const MenuCustom = styled((props: any) => (
  <Menu {...props} open={props.open} classes={{ paper: 'menu-paper' }} />
))`
  && {
    & .menu-paper {
      background-color: ${paletteColor.dark.medium};
      padding: 0 8px;
      box-shadow: 0px 4px 6px rgba(223, 225, 235, 0.5);
      min-width: 180px;
    }
  }
`;

export const MenuItemCustom = styled(MenuItem)`
  && {
    position: relative;
    font-weight: 500;
    font-size: 12px;
    padding: 8px;
    border-radius: 3px;
    overflow: inherit;
    color: rgba(255, 255, 255, 0.6);
    &:not(:first-child) {
      margin-top: 8px;
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:hover,
    &:active {
      background: ${paletteColor.dark.main};
      color: ${paletteColor.primary.white};
    }
    &:not(:last-child):before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 4px);
      border-bottom: 1px solid ${paletteColor.dark.icon};
    }
  }
`;

export const ResourceTableContainer = styled.div`
  height: calc(100vh - 100px);
  overflow: hidden;
  padding: 0 24px;
`;

export const ResourceTableContent = styled.div`
  height: 100%;
  overflow: auto;
`;

export const ResourceTable = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResourceTableRow = styled.div`
  display: flex;
  flex-direction: row;

  &:first-child {
    position: sticky;
    top: 0;
    z-index: 100;
  }
`;

export const ResourceTableHeader = styled.div`
  ${TableCell}
  border-width: 1px 1px 0 0;
  font-weight: normal;
  border-top: 1px solid ${paletteColor.info.border};
`;

export const ResourceTableCell = styled.div`
  ${TableCell}
  border-width: 1px 1px 0 0;
  &:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    border-width: 1px 1px 0 1px;
  }
  &:last-child {
    border-width: 1px 0 0 0;
  }
`;

export const ResourceStatusBar = styled.div<IStyledProps>`
  border-radius: 2px;
  height: 24px;
  width: 4px;
  margin-right: 28px;
  background-color: ${({ color }) => color};
`;

export const ResourceButton = styled.div<IStyledProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  &:hover,
  &:active {
    background: ${paletteColor.info.background};
    & .icon-button-resource {
      width: 28px;
      opacity: 1;
    }
  }
  & .text {
    color: ${({ impactType }) =>
      impactType === 'AVAILABLE'
        ? paletteColor.info.text
        : getImpactTypeColor(impactType)};
  }
  & .status-bar {
    background: ${({ impactType }) => getImpactTypeColor(impactType)};
  }
`;

export const ResourceIconContent = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 30px;
  & .icon-button-resource {
    color: ${paletteColor.info.soft};
    font-size: 22px;
    border-left: 1px solid ${paletteColor.info.border};
    height: 24px;
    padding-left: 8px;
    width: 0;
    opacity: 0;
    transition: all 400ms;
  }
`;

export const ResourceButtonAdd = styled(Button)`
  && {
    position: sticky;
    left: 0;
    height: 50px;
    min-width: 220px;
    border-radius: 0;
    text-transform: none;
    font-size: 14px;
    background: ${paletteColor.primary.main};
    color: ${paletteColor.primary.white};
    z-index: 100;
    .icon-button-add {
      margin-right: 20px;
    }
    &:hover {
      background: ${paletteColor.primary.main};
    }
  }
`;

export const ResourceRow = styled.div`
  ${Base};
  justify-content: space-between;
  .description {
    color: ${paletteColor.info.text};
    font-size: 14px;
    letter-spacing: 0.03em;
  }
`;

export const ResourceContent = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: flex-end;
  align-items: center;
  & .text {
    margin-right: auto;
  }
  &:hover {
    & .tools {
      width: 65px;
      min-width: 65px;
    }
  }
`;

export const ResourceVersion = styled.div`
  display: flex;
  justify-content: flex-end;
  transition: all 400ms;
`;

export const ResourceTools = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 0;
  min-width: 0;
  overflow: hidden;
  transition: all 400ms;
`;

export const PageMenu = styled.div`
  display: flex;
  align-items: center;
  padding: 0 30px;
  margin: 30px 0;
`;

export const FabCustom = styled(Fab)`
  && {
    width: auto;
    height: 34px;
    border-radius: 25px;
    box-shadow: none;
    background: transparent;
    text-transform: capitalize;
    margin-right: 10px;

    &:hover {
      background: ${paletteColor.info.fill};
      color: ${paletteColor.info.soft};
    }
  }
`;

export const PageMenuItem = styled(Link)`
  && {
    position: relative;
    border: 0;
    display: flex;
    color: ${paletteColor.info.soft};
    width: 85px;
    height: 34px;
    text-decoration: none;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    font-size: 14px;

    ${(props: IStyledProps) =>
      props.selected &&
      css`
        color: ${paletteColor.primary.white};
        background: ${paletteColor.primary.main};
        border-radius: 25px;
        display: flex;

        &:after {
          width: 72%;
          left: -22%;
        }
      `}
  }
`;

export const Main = styled.div`
  margin-top: 5px;
`;
