import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LogContainer } from './containers';
import { StatusContainer } from '../status/containers';

const routes = (props) => (
  <Switch>
    <Route path={props.match.path} exact={true} component={LogContainer} />
    <Route path="*" component={StatusContainer} />
  </Switch>
);

export default routes;
