import gql from 'graphql-tag';
import * as React from 'react';
import client from 'apolloClient';
import {
  DialogResourceAdd,
  DialogResourceDelete,
  DialogResourceEdit,
  Filter,
  ResourceTable,
} from '../components';
import { mutations, queries } from '../graphql';
import { ImpactType } from '../types';
import EmptyState from '../components/EmptyState';
import { IWorkspace } from 'modules/auth/types';
import { withAppContext } from 'modules/common/hoc/withAppContext';

interface IProps {
  workspace: IWorkspace;
}

const ResourcesContainer = ({ workspace }: IProps) => {
  const [reload, setReload] = React.useState<boolean>(false);
  const [dialogAddOpen, setDialogAddOpen] = React.useState<boolean>(false);
  const [updateId, setUpdateId] = React.useState<string | null>(null);
  const [deleteId, setDeleteId] = React.useState<string | null>(null);

  const getResources = (workspaceCode: string) => {
    return client
      .query({
        query: gql(queries.resources),
        variables: {
          workspaceCode,
        },
        fetchPolicy: 'network-only',
      })
      .then((response) => response.data.resources);
  };

  const getResource = (id: string, workspaceCode: string) => {
    return client
      .query({
        query: gql(queries.resource),
        variables: {
          id,
          workspaceCode,
        },
        fetchPolicy: 'network-only',
      })
      .then((response) => response.data.resource);
  };

  const createResource = (
    workspaceCode: string,
    resourceName: string,
    resourceVersion: string,
    resourceType: string,
    isDependency: boolean
  ) => {
    return client.mutate({
      mutation: gql(mutations.createResource),
      variables: {
        workspaceCode,
        name: resourceName,
        version: resourceVersion,
        type: resourceType,
        isDependency,
      },
    });
  };

  const updateResource = (
    id: string,
    workspaceCode: string,
    resourceName: string,
    resourceVersion: string,
    resourceType: string,
    isDependency: boolean
  ) => {
    return client.mutate({
      mutation: gql(mutations.updateResource),
      variables: {
        id,
        workspaceCode,
        name: resourceName,
        version: resourceVersion,
        type: resourceType,
        isDependency,
      },
    });
  };

  const createImpact = (
    workspaceCode: string,
    resourceId: string,
    dependsOnId: string,
    impactType: ImpactType
  ) => {
    return client.mutate({
      mutation: gql(mutations.createImpact),
      variables: { workspaceCode, resourceId, dependsOnId, impactType },
    });
  };

  const deleteResource = (workspaceCode: string, resourceId: string) => {
    return client.mutate({
      mutation: gql(mutations.deleteResource),
      variables: { workspaceCode, id: resourceId },
    });
  };

  return (
    <>
      {workspace ? (
        <>
          <Filter selected="matriz" workspace={workspace.code} />
          <ResourceTable
            workspaceCode={workspace.code}
            getResources={getResources}
            createImpact={createImpact}
            handleDialogAddOpen={() => setDialogAddOpen(true)}
            handleEditResource={setUpdateId}
            handleDeleteResource={setDeleteId}
            reload={reload}
            setReload={setReload}
          />
          <DialogResourceDelete
            workspaceCode={workspace.code}
            deleteId={deleteId}
            setReload={setReload}
            deleteResource={deleteResource}
            handleCloseForm={() => setDeleteId(null)}
          />
          <DialogResourceEdit
            workspaceCode={workspace.code}
            updateId={updateId}
            setReload={setReload}
            getResource={getResource}
            updateResource={updateResource}
            handleClose={() => setUpdateId(null)}
          />
          <DialogResourceAdd
            workspaceCode={workspace.code}
            dialogOpen={dialogAddOpen}
            setReload={setReload}
            createResource={createResource}
            handleClose={() => setDialogAddOpen(false)}
          />
        </>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

export default withAppContext(ResourcesContainer);
