import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as S from './styled';
import theme from 'modules/common/styles/theme';
import Styled from '../../components/styled';

interface IProps {
  closeIssueModal: (e: React.MouseEvent) => void;
}

const FakeModal = ({ closeIssueModal }: IProps) => (
  <S.ModalBox>
    <Grid className="grid-left">
      <S.ModalBoxHeader>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item hidden={useMediaQuery(theme.breakpoints.up('md'))}>
            <IconButton className="btn-close" onClick={closeIssueModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item className="box-title-container">
            <S.BoxTitle>
              <Styled.Fake width="100" height="19" mb="0" />
            </S.BoxTitle>
          </Grid>
          <Grid item hidden={useMediaQuery(theme.breakpoints.down('sm'))}>
            <S.InformationChipCentered>
              <Styled.Fake width="50" height="20" mb="0" />
            </S.InformationChipCentered>
          </Grid>
        </Grid>
      </S.ModalBoxHeader>
      <S.IssueDescriptionWrapper>
        <Grid item hidden={useMediaQuery(theme.breakpoints.up('md'))}>
          <S.Topic>
            <S.InformationChipTableCell>
              <Styled.Fake width="200" height="15" mb="5" />
            </S.InformationChipTableCell>
          </S.Topic>
        </Grid>
        <S.Topic>
          <S.TopicHeading>Sumário</S.TopicHeading>
          <Styled.Fake width="200" height="15" mb="5" />
        </S.Topic>
        <S.Topic>
          <S.TopicHeading>Descrição</S.TopicHeading>
          <Styled.Fake width="200" height="15" mb="5" />
          <Styled.Fake width="200" height="15" mb="0" />
        </S.Topic>
      </S.IssueDescriptionWrapper>
    </Grid>
    <S.GridRight className="grid-right">
      {useMediaQuery(theme.breakpoints.up('md')) && (
        <S.FakeIssuePriorityHeader
          className="bg-inherit"
          priority={<Styled.Fake width="40" height="19" mb="0" />}
        />
      )}
      {useMediaQuery(theme.breakpoints.up('md')) && (
        <S.IssueInformationBox>
          <S.Topic>
            <S.TopicHeading>Registro de trabalho</S.TopicHeading>
            <Styled.Fake width="126" height="15" mb="0" />
          </S.Topic>
          <S.Topic>
            <S.TopicHeading>Tipo de demanda</S.TopicHeading>
            <Styled.Fake width="126" height="15" mb="0" />
          </S.Topic>
          <S.Topic>
            <S.TopicHeading>Status</S.TopicHeading>
            <Styled.Fake width="126" height="15" mb="0" />
          </S.Topic>

          <S.Topic>
            <S.TopicHeading>Épico</S.TopicHeading>
            <Styled.Fake width="126" height="15" mb="0" />
          </S.Topic>

          <S.Topic>
            <S.TopicHeading>Responsável</S.TopicHeading>
            <Styled.Fake width="126" height="15" mb="0" />
          </S.Topic>
        </S.IssueInformationBox>
      )}
    </S.GridRight>
  </S.ModalBox>
);

export default FakeModal;
