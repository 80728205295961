import Icon from '@material-ui/core/Icon';
import Slider from '@material-ui/core/Slider';
import * as React from 'react';
import { IDependency, IResource } from '../types';
import * as S from './styled';

interface IProps {
  resources: IResource[];
}

interface IState {
  zoom: number;
  resourceOver: string | null;
  resourceSelected: string | null;
  graphContentRef: React.RefObject<HTMLDivElement>;
}

class GraphComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      zoom: 1,
      resourceOver: null,
      resourceSelected: null,
      graphContentRef: React.createRef(),
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPressed);
    this.state.graphContentRef.current!.scroll({
      top: 450,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPressed);
  }

  onKeyPressed = (event: KeyboardEvent) => {
    switch (event.keyCode) {
      case 107:
        this.zoomIn();
        break;
      case 109:
        this.zoomOut();
        break;
      case 37:
        this.moveGraph(event, { left: -30 });
        break;
      case 38:
        this.moveGraph(event, { top: -30 });
        break;
      case 39:
        this.moveGraph(event, { left: 30 });
        break;
      case 40:
        this.moveGraph(event, { top: 30 });
        break;
      default:
        return;
    }
  };

  resetLocation = () => {
    this.setState({ zoom: 1 });
    this.state.graphContentRef.current!.scroll({
      top: 450,
      left: 0,
    });
  };

  moveGraph = (
    event: KeyboardEvent,
    position: { left?: number; top?: number }
  ) => {
    event.preventDefault();
    this.state.graphContentRef.current!.scrollBy(position);
  };

  zoomIn = () => {
    this.setState(({ zoom }: { zoom: number }) => ({
      zoom: zoom >= 2 ? 2 : zoom + 0.1,
    }));
  };

  zoomOut = () => {
    this.setState(({ zoom }: { zoom: number }) => ({
      zoom: zoom <= 0.4 ? 0.4 : zoom - 0.1,
    }));
  };

  handlerZoom = (event: React.ChangeEvent<{}>, value: any) => {
    this.setState({ zoom: value });
  };

  handlerSetResourceSelect = (id: string | null) => (
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    this.setState({
      resourceSelected: id,
    });
  };

  handlerSetResourceOver = (id: string | null) => () => {
    this.setState({
      resourceOver: id,
    });
  };

  isLineSelected(serviceId: string, dependenceId: string) {
    const { resourceOver, resourceSelected } = this.state;
    if (resourceOver === serviceId || resourceOver === dependenceId) {
      return true;
    }
    if (resourceSelected === serviceId || resourceSelected === dependenceId) {
      return true;
    }
    return false;
  }

  hasSelected() {
    const { resourceOver, resourceSelected } = this.state;
    return Boolean(resourceOver) || Boolean(resourceSelected);
  }

  renderResourcesBox() {
    const { resources } = this.props;
    const { resourceOver, resourceSelected, zoom } = this.state;
    return (
      <S.BoxContent zoom={zoom}>
        {resources.map(
          ({ id, name, dependencies }: IResource, resourceIndex: number) => {
            return (
              <S.BoxResource
                key={`resource_${resourceIndex}`}
                onClick={this.handlerSetResourceSelect(id)}
                onMouseEnter={this.handlerSetResourceOver(id)}
                onMouseLeave={this.handlerSetResourceOver(null)}
                active={resourceOver === id || resourceSelected === id}
                hasSelected={this.hasSelected()}
              >
                {name}
                {dependencies &&
                  this.renderDependenciesLines(id, dependencies, resourceIndex)}
              </S.BoxResource>
            );
          }
        )}
      </S.BoxContent>
    );
  }

  renderDependenciesLines(
    serviceId: string,
    dependences: IDependency[],
    resourceIndex: number
  ) {
    const { resources } = this.props;
    return (
      dependences &&
      dependences.map((dependence: IDependency) => {
        const dependenceIndex = resources.findIndex(
          ({ id }: { id: string }) => id === dependence.dependsOn.id
        );
        const size = (dependenceIndex - resourceIndex) * 58;
        return (
          <S.DependenceLine
            key={`dependence_${dependenceIndex}`}
            active={this.isLineSelected(serviceId, dependence.dependsOn.id)}
            hasSelected={this.hasSelected()}
            size={size}
          />
        );
      })
    );
  }

  render() {
    return (
      <S.GraphContainer>
        <S.GraphMenu>
          <S.SliderContainer>
            <S.SliderButton onClick={this.zoomOut}>-</S.SliderButton>
            <S.SliderContent>
              <Slider
                defaultValue={this.state.zoom}
                min={0.4}
                max={2}
                step={0.1}
                onChange={this.handlerZoom}
              />
            </S.SliderContent>
            <S.SliderButton onClick={this.zoomIn}>+</S.SliderButton>
          </S.SliderContainer>
          <S.ResetLocationButton
            variant="outlined"
            onClick={this.resetLocation}
          >
            <Icon fontSize="small">my_location</Icon>
          </S.ResetLocationButton>
        </S.GraphMenu>
        <S.GraphContent onClick={this.handlerSetResourceSelect(null)}>
          <div className="graph-scroll" ref={this.state.graphContentRef}>
            {this.renderResourcesBox()}
          </div>
        </S.GraphContent>
      </S.GraphContainer>
    );
  }
}

export default GraphComponent;
