import * as React from 'react';

import Error404 from '../components/Error404';

interface IProps {
  status: string;
}

const StatusContainer = ({ status }: IProps) => {
  return <Error404 />;
};

export default StatusContainer;
