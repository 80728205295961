import * as React from 'react';

export const MCP_PA_WORKLOG = 'mcpProjetosAgeisWorklogs';

export const MCP_WORKLOG_JOYRIDE_STEPS: any[] = [
  {
    target: '.joyride__first-month',
    content: (
      <p>
        Aqui ficam os meses do seu contrato. Nosso contrato é, em via de regra,
        trimestral. Portanto, o valor de horas que você acordou será exibido
        aqui e você poderá acompanhá-lo
      </p>
    ),
    title: 'Bilhetagem',
    locale: {
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
  {
    target: '.joyride__quarter:last-child .joyride__quarter-progress',
    content: (
      <p>
        Essa barra de progresso mostra quantas horas você contratou para o
        período do trimestre. Você pode visualizar o valor total contratado e o
        que já foi gasto.
      </p>
    ),
    title: 'Bilhetagem',
    locale: {
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
  {
    target: '.joyride__progress-month:last-child .joyride__one-month-progress',
    content: (
      <p>
        Também exibimos valores mensais do quanto recomendamos que você use
        mensalmente.
      </p>
    ),
    title: 'Bilhetagem',
    locale: {
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
  {
    target: '.joyride__card-task',
    content: (
      <p>
        Aqui aparecem as bilhetagens que o time que te atende fizer. Ou seja,
        você conseguirá acompanhar tudo que está sendo feito, quanto tempo estão
        gastando com isso e quando foi feito. Ao clicar neste item você receberá
        detalhes desta tarefa.
      </p>
    ),
    title: 'Bilhetagem',
    locale: {
      last: <span>Finalizar</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'bottom',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
];
