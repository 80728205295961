import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { FakeCard } from '.';

interface IProps {
  useWindow?: boolean;
  hasMore: boolean;
  loadMore: (page: number) => void;
  getScrollParent: () => HTMLElement;
  children?: any;
}

const InfiniteScroller = ({
  useWindow,
  hasMore,
  loadMore,
  children,
  getScrollParent,
}: IProps) => {
  const renderScroll = () => {
    return <FakeCard key={children.length} count={2} />;
  };

  return (
    <InfiniteScroll
      pageStart={1}
      hasMore={hasMore}
      loadMore={loadMore}
      loader={renderScroll()}
      style={{ width: '100%' }}
      initialLoad={false}
      getScrollParent={getScrollParent}
      useWindow={useWindow}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScroller;
