export const hoursToString = (time: number): string => {
  const hours = Math.floor(time / 60 / 60);
  const hoursText = `${hours}h`;
  return `${(hours && hoursText) || ''}`;
};

export const minutesToString = (
  time: number,
  showMinutes?: boolean
): string => {
  const minutes = Math.floor((time / 60) % 60);
  return `${minutes || ''}`;
};

export const timeSpentToString = (
  time: number,
  showMinutes?: boolean
): string => {
  const hoursAndMinutes = [
    hoursToString(time),
    minutesToString(time, showMinutes),
  ];
  const hoursAndMinutesJoin = hoursAndMinutes.filter((value) => value).join('');

  return minutesToString(time, showMinutes)
    ? hoursAndMinutesJoin + 'min'
    : hoursAndMinutes.filter((value) => value).join('');
};

export const calcTimePercent = (time: number, pool: number) => {
  return (time / 60 / 60 / pool) * 100;
};

export const calcPoolPercent = (consumed: number, available: number) => {
  return (consumed / available) * 100;
};

export const calcDatePercent = (start: string, due: string) => {
  if (!start || !due) return 0;
  const startDate = new Date(start);
  const dueDate = new Date(due);
  const msPassed = Date.now() - startDate.getTime();
  const msMax = dueDate.getTime() - startDate.getTime();
  return (msPassed / msMax) * 100;
};

export const toFixedIfDecimals = (num: number, decimals: number) => {
  if (num.toString().includes('.')) {
    return num.toFixed(decimals);
  }
  return num;
};
