import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { IFullProject, IProject } from 'modules/common/types';
import {
  calcDatePercent,
  toFixedIfDecimals,
} from 'modules/common/utils/worklog';
import moment from 'moment';
import * as React from 'react';
import * as S from './Styled';
import FakeLoaderCalendarPunctual from './FakeLoaderCalendarPunctual';

interface IProps {
  projectSelected: IProject;
  getFullProjectById: (projectId: number) => Promise<IFullProject | null>;
}

const CalendarPunctual = ({ projectSelected, getFullProjectById }: IProps) => {
  const [
    fullProjectSelected,
    setFullProjectSelected,
  ] = React.useState<IFullProject | null>(null);

  React.useEffect(() => {
    getFullProjectById(projectSelected.id).then((project) => {
      setFullProjectSelected(project);
    });
  }, [projectSelected, getFullProjectById]);

  if (!fullProjectSelected) {
    return <FakeLoaderCalendarPunctual />;
  }

  const progress = fullProjectSelected.progress * 100;
  const deadline = calcDatePercent(
    fullProjectSelected.startdate,
    fullProjectSelected.duedate
  );

  const content = (
    <Box display="flex" justifyContent="center">
      <S.BoxContainer>
        <S.CalendarPunctualContainer>
          <S.CardCalendarPunctual>
            <S.TypographyCustom>{fullProjectSelected.name}</S.TypographyCustom>
            <Box>
              <S.ProgressContent>
                <Typography>
                  Andamento <S.Percentage>{progress.toFixed(0)}%</S.Percentage>
                </Typography>
                <S.Progress value={progress} />
              </S.ProgressContent>
              <S.ProgressContent>
                <Typography>
                  Prazo <S.Percentage>{deadline.toFixed(0)}%</S.Percentage>
                </Typography>
                <S.Progress value={deadline} />
              </S.ProgressContent>
            </Box>
          </S.CardCalendarPunctual>

          <S.CardDescription>
            <S.CardItem className="separator-line flex">
              <Box>
                <S.Title>Horas contratadas</S.Title>
                <Typography>
                  {fullProjectSelected.pool.available} horas
                </Typography>
              </Box>
              <Box>
                <S.Title>Data de contratação</S.Title>
                <Typography>
                  {fullProjectSelected.startdate
                    ? moment(
                        fullProjectSelected.startdate,
                        'YYYY-MM-DD'
                      ).format('DD/MM/YYYY')
                    : '-'}
                </Typography>
              </Box>
            </S.CardItem>
            <S.CardItem>
              <Box>
                <S.Title>Consumo atual</S.Title>
                <Typography>
                  {toFixedIfDecimals(fullProjectSelected.pool.consumed, 1)}{' '}
                  horas
                </Typography>
              </Box>
              <Box>
                <S.Title>Data prevista de término</S.Title>
                <Typography>
                  {fullProjectSelected.duedate
                    ? moment(fullProjectSelected.duedate, 'YYYY-MM-DD').format(
                        'DD/MM/YYYY'
                      )
                    : '-'}
                </Typography>
              </Box>
            </S.CardItem>
          </S.CardDescription>
        </S.CalendarPunctualContainer>

        {/* <S.Text>Ver último relatório de apontamento de horas</S.Text> */}
      </S.BoxContainer>
    </Box>
  );

  return content;
};

export default CalendarPunctual;
