const supportPlanByMonth = `
  query supportPlanByMonth($companyId: String!, $projectKey: String!) {
    supportPlanByMonth(companyId: $companyId, projectKey: $projectKey) {
      pool_size
      month
      quarter
    }
  }
`;

const worklogsByMonth = `
  query worklogsByMonth($workspace: String!, $project: String!) {
    worklogsByMonth(filter: {workspace: $workspace, project: $project}) {
      month
      value
    }
  }
`;

const worklogsByProject = `
  query worklogs($workspace: String!, $started: PeriodFilter, $project: String!, $page: Int) {
    worklogs(filter: {workspace: $workspace, project: $project, started: $started}, page: {size: 100, current: $page}) {
      currentPage
      totalPages
      results {
        id
        started
        timeSpentSeconds
        issue {
          id
          key
          summary
        }
      }
    }
  }
`;

const getProjects = `
  query projects($workspace: String!) {
    projects(workspace: $workspace) {
      id
      key
      category
    }
  }
`;

const getFullProjectById = `
  query project($workspace: String!, $projectId: Int!) {
    project(workspace: $workspace, projectId: $projectId) {
      id
      key
      name
      category
      progress
      startdate
      duedate
      pool {
        available
        consumed
      }
    }
  }
`;

const getIssueByProjectId = `
  query issueByKey($issueKey: String!) {
    issueByKey(issueKey: $issueKey) {
      idIssue
      issueKey
      created
      error
    }
  }
`;

export default {
  worklogsByProject,
  supportPlanByMonth,
  worklogsByMonth,
  getProjects,
  getFullProjectById,
  getIssueByProjectId,
};
