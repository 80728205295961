import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import S from './styled';

interface IProps {
  dialogStats: boolean;
  handleDialogClose: () => void;
}

export default function PreventiveDialog({
  dialogStats,
  handleDialogClose,
}: IProps) {
  const handleClose = () => {
    handleDialogClose();
  };

  return (
    <S.DialogCustomFail open={dialogStats}>
      <div className="dialogFail">
        <CloseSharpIcon className="icon-fail"></CloseSharpIcon>
      </div>
      <DialogTitle className="title-fail">Ocorreu um problema!</DialogTitle>
      <DialogContent>
        <Typography component="p">
          Devido a uma instabilidade nos nossos servidores, não foi possível
          gerar o relatório solicitado.
        </Typography>
        <Typography component="p">
          Você pode tentar novamente clicando em Fechar e executando o
          procedimento novamente.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Fechar
        </Button>
      </DialogActions>
    </S.DialogCustomFail>
  );
}
