import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import * as S from './styled';

interface IProps {
  children: React.ReactNode;
}

const Layout = ({ children }: IProps) => {
  return (
    <S.BoxPage>
      <Typography component="p" className="text-page">
        Os espaços de trabalho permitem que você colabore com membros da equipe,
        adicione permissões e compartilhe origens em toda a sua equipe em uma
        conta de faturamento compartilhada.
      </Typography>
      {children}
    </S.BoxPage>
  );
};

export default Layout;
