import { IPreventiveCategory } from 'modules/preventives/types';
import * as React from 'react';

export const MCP_AWS_REGIONS_LIST = [
  ['us-east-1', 'US East (N. Virginia)'],
  ['us-east-2', 'US East (Ohio)'],
  ['us-west-1', 'US West (N. California)'],
  ['us-west-2', 'US West (Oregon)'],
  ['ca-central-1', 'Canada (Central)'],
  ['eu-central-1', 'EU (Frankfurt)'],
  ['eu-west-1', 'EU (Ireland)'],
  ['eu-west-2', 'EU (London)'],
  ['eu-west-3', 'EU (Paris)'],
  ['eu-north-1', 'EU (Stockholm)'],
  ['ap-east-1', 'Asia Pacific (Hong Kong)'],
  ['ap-northeast-1', 'Asia Pacific (Tokyo)'],
  ['ap-northeast-2', 'Asia Pacific (Seoul)'],
  ['ap-northeast-3', 'Asia Pacific (Osaka-Local)'],
  ['ap-southeast-1', 'Asia Pacific (Singapore)'],
  ['ap-southeast-2', 'Asia Pacific (Sydney)'],
  ['ap-south-1', 'Asia Pacific (Mumbai)'],
  ['me-south-1', 'Middle East (Bahrain)'],
  ['sa-east-1', 'South America (São Paulo)'],
];

export const MCP_CLOUD_COMPLIANCE_STATE = 'mcpCloudComplianceState';

export const MCP_CLOUD_COMPLIANCE_JOYRIDE_STEPS: any[] = [
  {
    target: '.joyride__selector',
    content: <p>Selecione a região que gostaria de executar o serviço</p>,
    title: 'Cloud Compliance',
    locale: {
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
  {
    target: '.joyride__preventives-list',
    content: <p>Estes são os serviços disponíveis para execução no momento</p>,
    title: 'Cloud Compliance',
    locale: {
      next: <span>Próximo</span>,
      back: <span>Anterior</span>,
      skip: <span>Pular</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
  {
    target: '.joyride__btnExecute',
    content: (
      <p>
        Clique aqui para executar um serviço e receber um relatório detalhado
        sobre o mesmo.
      </p>
    ),
    title: 'Cloud Compliance',
    locale: {
      last: <span>Finalizar</span>,
      back: <span>Anterior</span>,
      skip: <span>Pular</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
];

export const submenuItens = [
  {
    key: 'service',
    name: 'Serviços',
    to: '/preventives',
  },
  {
    key: 'history',
    name: 'Histórico',
    to: '/preventives/history',
  },
];

export const MCP_PREVENTIVES_SPEC: IPreventiveCategory[] = [
  {
    key: 'segurança',
    name: 'Segurança',
    preventivesList: [
      {
        name: 'Monitor de acessos e identidade',
        key: 'IAM',
        description:
          'Analisa se as melhores práticas de gestão de acessos e identidade em nuvem estão sendo utilizadas.',
        charge: 1,
        global: true,
      },
      {
        name: 'Monitor de grupos de segurança',
        key: 'SG',
        description:
          'Analisa se há regras de Firewall inadequadas e que comprometem a segurança do ambiente em nuvem.',
        charge: 2,
        global: false,
        params: JSON.stringify({
          cidrWhitelist: [],
        }),
      },
      {
        name: 'Monitor de auditoria',
        key: 'CloudTrail',
        description:
          'Verifica se as melhores práticas de auditoria em nuvem estão sendo atendidas.',
        charge: 1,
        global: false,
      },
    ],
  },
  {
    key: 'otimização de custos',
    name: 'Otimização de custos',
    preventivesList: [
      {
        name: 'Monitor de reservas de Instâncias',
        key: 'Reserves',
        description:
          'Gera um relatório de custos de reservas, faz recomendação de reservas, custos e sugere como otimizar.',
        charge: 1,
        global: false,
      },
      {
        name: 'Escâner de custos de IP',
        key: 'ElasticIP',
        description:
          'Verifica os Elastic Ip que não estão sendo utilizados e geram custos desnecessários.',
        charge: 2,
        global: false,
      },
      {
        name: 'Escâner de custos de Volumes',
        key: 'Volumes',
        description:
          'Analisa se há discos na nuvem que não estão sendo utilizados e que geram custos desnecessários.',
        charge: 2,
        global: false,
      },
      {
        name: 'Escâner de custos de Gateways',
        key: 'InternetGateway',
        description:
          'Verifica se há Internet Gateways sem uso gerando custos desnecessários.',
        charge: 1,
        global: false,
      },
      {
        name: 'Escâner de custos de VPNs',
        key: 'Vpns',
        description:
          'Verifica as VPNs na nuvem que não estão sendo utilizadas e geram custos desnecessários.',
        charge: 2,
        global: false,
      },
      {
        name: 'Emissário de sugestões',
        key: 'CostInsights',
        description:
          'Fornece sugestões de otimizações gerais de custos na nuvem.',
        charge: 1,
        global: true,
      },
    ],
  },
  {
    key: 'confiabilidade',
    name: 'Confiabilidade',
    preventivesList: [
      {
        name: 'Monitor de atividade de VPNs',
        key: 'VpnDown',
        description: 'Verifica as VPNs com o serviço em status Down.',
        charge: 2,
        global: false,
      },
      {
        name: 'Monitor de créditos de Instâncias T',
        key: 'CpuCredits',
        description:
          'Verifica saldo de créditos de CPUs em instâncias T e que podem comprometer a operação em nuvem.',
        charge: 1,
        global: false,
      },
    ],
  },
];
