import React from 'react';
import { PageMenu, PageMenuItem } from './styled';
import { ISubmenuItem } from '../types';

interface IProps {
  selected: string;
  items: ISubmenuItem[];
}

export default function Submenu({ items, selected }: IProps) {
  return (
    <PageMenu id="submenu">
      {items.map(({ key, name, to }: ISubmenuItem) => {
        console.log(name);
        return (
          <PageMenuItem
            key={`SubMenuItem-${key}`}
            to={to}
            selected={selected === key}
            className="data-cy=page-title"
          >
            {name}
          </PageMenuItem>
        );
      })}
    </PageMenu>
  );
}
