import React from 'react';
import Icon from '@material-ui/core/Icon';
import { Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IPropsView, Subtask, ISupportIssueResult } from '../types';
import { filterIssues } from '../utils/support';
import { MCP_PA_TASKS_BOARDS } from '../utils/constants';
import S from './styled';
import BoardView from './BoardView';
import { paletteColor } from 'modules/common/styles/colors';

interface IProps extends IPropsView {
  issuesWithSubtasks: ISupportIssueResult[];
}

const SubtasksBoard = ({
  loading,
  issuesByBoard,
  startDate,
  endDate,
  openIssueModal,
  issuesWithSubtasks,
  loadIssuesForBoard,
  issuesTotalTime,
}: IProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderSubtasksByBoard = (subtasks: Subtask[]) => {
    const issuesByBoard = MCP_PA_TASKS_BOARDS.reduce((partialBoard, board) => {
      const filtered = filterIssues(subtasks, board.filterStatus);

      return {
        ...partialBoard,
        [board.columnTitle]: {
          issues: filtered,
          totalPages: 1,
          currentPage: 1,
          count: filtered.length,
        },
      };
    }, {});

    return issuesByBoard;
  };

  return (
    <>
      <S.BoardColumnContainer container={true} spacing={1}>
        {MCP_PA_TASKS_BOARDS.map(
          ({ columnTitle, filterStatus, description }) => {
            const issuesPage = issuesByBoard[columnTitle];

            return (
              <S.BoardColumnItem
                key={`board_${columnTitle}`}
                item={true}
                xs={10}
                sm={2}
              >
                <S.TitleColumn
                  id="subtasks-list-title"
                  style={{
                    display: !issuesWithSubtasks.length ? 'none' : 'flex',
                  }}
                >
                  <Typography className="title">
                    {columnTitle} {!loading && issuesPage.issues.length}
                  </Typography>
                  <S.CustomTooltip
                    title={
                      <S.CustomTooltipDescription>
                        {description}
                      </S.CustomTooltipDescription>
                    }
                  >
                    <Icon className="icon">info</Icon>
                  </S.CustomTooltip>
                </S.TitleColumn>
              </S.BoardColumnItem>
            );
          }
        )}
      </S.BoardColumnContainer>

      {issuesWithSubtasks.map((issue: ISupportIssueResult, index: number) => (
        <S.ExpansionPanelCustom
          key={`subtask-panel${index}`}
          expanded={expanded === `subtask-panel${index}`}
          onChange={handleChange(`subtask-panel${index}`)}
        >
          <S.ExpansionPanelSummaryCustom
            style={{
              backgroundColor:
                expanded === `subtask-panel${index}`
                  ? paletteColor.info.fill
                  : paletteColor.primary.white,
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <S.FlexContainer>
              <S.KeyContainer>
                <S.KeySubTasks>{issue.issueKey}</S.KeySubTasks>
                <S.TitleContainer>
                  <S.TitleSubTasks>{issue.summary}</S.TitleSubTasks>
                </S.TitleContainer>
              </S.KeyContainer>

              <S.Square>
                {issue.subtasks?.length}
                {issue.subtasks?.length === 1 ? ' Subtarefa' : ' Subtarefas'}
              </S.Square>
            </S.FlexContainer>
          </S.ExpansionPanelSummaryCustom>
          <S.PanelDetailsCustom>
            <BoardView
              loading={loading}
              loadIssuesForBoard={loadIssuesForBoard}
              issuesTotalTime={issuesTotalTime}
              issuesByBoard={renderSubtasksByBoard(issue.subtasks || [])}
              startDate={startDate}
              endDate={endDate}
              openIssueModal={openIssueModal}
              hiddenTitleColumns={Boolean(issuesWithSubtasks.length)}
              subtaskBoard={true}
            />
          </S.PanelDetailsCustom>
        </S.ExpansionPanelCustom>
      ))}
    </>
  );
};

export default SubtasksBoard;
