import { BillingContainer } from 'modules/billing/containers';
import withTracker from 'modules/common/hoc/withTracker';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { StatusContainer } from '../status/containers';

const BillingWithTracker = withTracker(BillingContainer, {});

const routes = (props) => {
  return (
    <Switch>
      <Route
        path={props.match.path}
        exact={true}
        component={BillingWithTracker}
      />
      <Route path="*" component={StatusContainer} />
    </Switch>
  );
};

export default routes;
