import React from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { IIssueComment } from '../../types';
import { sanitize } from 'dompurify';
import * as S from './styled';
import EmptyImgComment from '../../../common/images/emptyStateComment.png';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function CommentsEmptyState() {
  return (
    <S.EmptyState>
      <Box>
        <img
          src={EmptyImgComment}
          alt="Dois balões sobrepostos representando comentários"
        />
      </Box>
      <Box className="content-empty-state">
        <S.TitleEmptyState>
          Ainda não há comentários para esta tarefa!
        </S.TitleEmptyState>
        <Typography>
          Não se preocupe, quando houver algum, ele aparecerá aqui!
        </Typography>
      </Box>
    </S.EmptyState>
  );
}

const Comment = ({ comment }: { comment: IIssueComment }) => (
  <S.CommentWrapper container direction="row" wrap="nowrap">
    <Grid item>
      <S.AuthorAvatar>{comment.author.displayName.charAt(0)}</S.AuthorAvatar>
    </Grid>
    <S.GridWrapper item>
      <S.TypographyAuthor>
        {comment.author.displayName} <span> em </span>
        {moment(comment.created).format('DD/MM/YYYY')}
        <span> às </span>
        {moment(comment.created).format('HH:mm')}
      </S.TypographyAuthor>
      <S.DangerInsert
        dangerouslySetInnerHTML={{ __html: sanitize(comment.body) }}
      />
    </S.GridWrapper>
  </S.CommentWrapper>
);

const CommentsSection = ({ comments }: { comments: IIssueComment[] }) => {
  return comments.length ? (
    <Grid container>
      {comments.map((comment) => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </Grid>
  ) : (
    <CommentsEmptyState />
  );
};

export default CommentsSection;
