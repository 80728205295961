import { List, ListItem, styled } from '@cmp/ui';
import Avatar from '@material-ui/core/Avatar';
import { paletteColor } from 'modules/common/styles/colors';

export const ListWorkspaces = styled(List)`
  && {
    background: transparent;
    padding: 10px;
  }
`;

export const AvatarCustom = styled(Avatar)`
  && {
    background-color: rgb(228, 231, 235);
    border-radius: 4px;
    color: rgb(66, 90, 112);
  }
`;

export const ListItemWorkspaces = styled(ListItem)`
  && {
    padding: 12px;
    margin-bottom: 10px;
    border-radius: 4px;
    background: ${paletteColor.primary.white};
    box-shadow: 0 0 1px rgba(67, 90, 111, 0.415),
      0 2px 4px -2px rgba(67, 90, 111, 0.301);
  }
`;
export default styled;
