import withTracker from 'modules/common/hoc/withTracker';
import { Layout } from 'modules/layout/components';
import * as React from 'react';
import { Route } from 'react-router-dom';
import { ListWorkspaceContainer } from './containers';

export const PageListWorkspace = () => {
  return (
    <Layout>
      <ListWorkspaceContainer />
    </Layout>
  );
};

const PageListWorkspaceWithTracker = withTracker(PageListWorkspace, {});

const routes = (props) => (
  <>
    <Route
      path={props.match.path}
      exact={true}
      component={PageListWorkspaceWithTracker}
    />
  </>
);

export default routes;
