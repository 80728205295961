import { DatePicker, EmptyState, Input } from '@cmp/ui';
import DateFnsUtils from '@date-io/moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Tooltip from 'modules/common/components/Tooltip';
import { ReactComponent as IconSearch } from 'modules/common/icons/icon-search.svg';
import moment from 'moment';
import * as React from 'react';
import * as S from './styled';
import {
  MCP_LOGS_JOYRIDE_STEPS,
  MCP_LOGS_PREVENTIVES,
} from '../utils/constants';
import AnalysisModal from './AnalysisModal';
import ReportHistory from './ReportHistory';
import FakeRow from './FakeRow';
import FakeCardMobile from './FakeCardMobile';
import { paletteColor } from 'modules/common/styles/colors';

interface IProps {
  workspace: string;
  workspaceName: string;
  getLogs: (
    workspace: string,
    startDate: { day: number; month: number; year: number },
    endDate: { day: number; month: number; year: number },
    text: string,
    pageSize: number,
    nextToken?: string,
    total?: number
  ) => any;
  getLogsById: (workspace: string, id: string | string[]) => any;
  loading: boolean;
  setLoading: (value: boolean) => void;
  stepsJoyride?: any[];
  setStepsJoyride: (stepsJoyride: any[]) => void;
}

const LogView = ({
  workspace,
  getLogs,
  loading,
  setLoading,
  stepsJoyride,
  setStepsJoyride,
  getLogsById,
  workspaceName,
}: IProps) => {
  const [startDate, setStartDate] = React.useState<any>(
    moment().subtract(3, 'month')
  );
  const [endDate, setEndDate] = React.useState<any>(moment());
  const [search, setSearch] = React.useState('');
  const [searchBar, setSearchBar] = React.useState('');
  const [logs, setLogs] = React.useState<any>([]);
  const [nextToken, setNextToken] = React.useState<string | undefined>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [analyzedLog, setAnalyzedLog] = React.useState<any | undefined>();
  const [analysisOpen, setAnalysisOpen] = React.useState<boolean>(false);
  const [reportOpen, setReportOpen] = React.useState<boolean>(false);
  const [total, setTotal] = React.useState<number>();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [firstPageLoading, setFirstPageLoading] = React.useState<number>(0);

  const styleRowPerPage = makeStyles({
    paper: {
      padding: '0px 8px',
      boxShadow: '0px 4px 6px rgba(223, 225, 235, 0.5)',
      color: `${paletteColor.info.soft}`,
    },
    menuItem: {
      borderRadius: '3px',
      justifyContent: 'center',
      '&:hover, &:active, &:focus': {
        fontWeight: '500',
        color: `${paletteColor.primary.main}`,
        backgroundColor: 'rgba(242, 101, 34, 0.1)',
        hoverBackgroundColor: 'rgba(242, 101, 34, 0.1)',
        '&:hover': {
          backgroundColor: 'rgba(242, 101, 34, 0.1)',
        },
      },
    },
  });

  React.useEffect(() => {
    setLoading(firstPageLoading > 0);
  }, [firstPageLoading, setLoading]);

  const loadLogs = React.useCallback(async () => {
    setFirstPageLoading((firstPageLoading) => firstPageLoading + 1);
    setPage(0);
    try {
      const response = await getLogs(
        workspace,
        {
          day: Number(startDate.format('DD')),
          month: Number(startDate.format('MM')),
          year: Number(startDate.format('YYYY')),
        },
        {
          day: Number(endDate.format('DD')),
          month: Number(endDate.format('MM')),
          year: Number(endDate.format('YYYY')),
        },
        search,
        rowsPerPage
      );
      setTotal(response.total);
      setNextToken(response.nextToken);
      setLogs(response.data);
    } catch (error) {
      setLogs([]);
    } finally {
      setFirstPageLoading((firstPageLoading) => firstPageLoading - 1);
    }
  }, [workspace, getLogs, startDate, endDate, search, rowsPerPage]);

  React.useEffect(() => {
    if (!stepsJoyride || stepsJoyride !== MCP_LOGS_JOYRIDE_STEPS) {
      setStepsJoyride(MCP_LOGS_JOYRIDE_STEPS);
    }
  }, [stepsJoyride, setStepsJoyride]);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  React.useEffect(() => {
    loadLogs();
  }, [loadLogs, startDate, endDate, search]);

  const handleSearch = () => {
    setSearch(searchBar);
  };

  const closeReport = () => {
    setReportOpen(false);
  };

  const classes = styleRowPerPage();

  const breakpoint = 600;

  const TableHeader = () => {
    const optionsHeader = [
      'Serviço executado',
      'Usuário',
      'Data',
      'Hora',
      'Análise',
    ];
    return (
      <S.TableHeader>
        <TableRow>
          {width < breakpoint ? (
            <S.TableCellTitle
              colSpan={2}
              key={`key-option-header-${optionsHeader[0]}`}
            >
              {optionsHeader[0]}
            </S.TableCellTitle>
          ) : (
            <>
              {optionsHeader.map((option, index) => (
                <S.TableCellTitle key={`option-header-${index}`}>
                  {option}
                </S.TableCellTitle>
              ))}
            </>
          )}
        </TableRow>
      </S.TableHeader>
    );
  };

  const renderLogs = () => {
    if (loading) {
      return Array.from(Array(6).keys()).map((_, key) => (
        <Skeleton key={key} height={56} />
      ));
    }

    if (logs.length === 0) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <EmptyState>
            <S.EmptyStateText component="h1">
              Nenhum resultado encontrado!
            </S.EmptyStateText>
          </EmptyState>
        </Box>
      );
    }

    const handleChangePage = (event, newPage: number) => {
      setPage(newPage);

      handleLoadMore();
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const closeView = () => {
      setAnalysisOpen(false);
      setAnalyzedLog(undefined);
      setSelected([]);
    };

    return (
      <>
        <AnalysisModal
          log={analyzedLog}
          handleClose={closeView}
          open={analysisOpen}
        />
        <TableContainer>
          <Table>
            <TableHeader />
            <S.TableBodyCustom>
              {logs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((log, index) => {
                  const preventive = MCP_LOGS_PREVENTIVES.find(
                    (preventive) =>
                      log.operation === `preventivo-${preventive.key}`
                  );
                  const preventiveName = preventive
                    ? preventive.name
                    : log.operation;

                  const labelId = `enhanced-table-checkbox-${index}`;

                  const handleView = () => {
                    setAnalysisOpen(true);
                    getLogsById(workspace, log.id).then((logs) => {
                      setAnalyzedLog(logs.pop());
                    });
                  };

                  const handleTooltip = log.id === null && (
                    <Tooltip
                      title="Análise indisponível"
                      description="A análise desta execução não está disponível para visualização e não
                  pode ser incluída em um relatório. Por favor, selecione outra execução."
                      icon="help"
                    />
                  );

                  return (
                    <>
                      {width < breakpoint ? (
                        <S.TableRowContentMobile key={`log-mobile_${index}`}>
                          <S.TableCellCheckbox>
                            <S.Text id={labelId}>{preventiveName}</S.Text>
                          </S.TableCellCheckbox>

                          <S.TableCellMobile>
                            <S.TypographyCustom className="text-margin">
                              Usuário
                            </S.TypographyCustom>
                            <S.Text>{log.username}</S.Text>
                          </S.TableCellMobile>

                          <S.TableCellMobile>
                            <S.TypographyCustom>Data</S.TypographyCustom>
                            <S.Text>
                              {moment(log.timestamp.split('.')[0]).format(
                                'DD/MM/YY'
                              )}
                            </S.Text>
                          </S.TableCellMobile>

                          <S.TableCellMobile>
                            <S.TypographyCustom>Hora</S.TypographyCustom>
                            <S.Text>
                              {moment(log.timestamp.split('.')[0]).format('HH')}
                              h
                              {moment(log.timestamp.split('.')[0]).format('mm')}
                              min
                            </S.Text>
                          </S.TableCellMobile>

                          <S.TableCellMobile>
                            <S.TypographyCustom>Análise</S.TypographyCustom>
                            <S.TableCellTooltip>
                              <S.LinkOpenModal
                                onClick={handleView}
                                disabled={!log.id}
                              >
                                Ver
                              </S.LinkOpenModal>
                              {handleTooltip}
                            </S.TableCellTooltip>
                          </S.TableCellMobile>
                        </S.TableRowContentMobile>
                      ) : (
                        <S.TableRowContent
                          data-cy="table-row"
                          key={`log_${index}`}
                        >
                          <TableCell id={labelId}>{preventiveName}</TableCell>
                          <TableCell>{log.username}</TableCell>
                          <TableCell>
                            {moment(log.timestamp.split('.')[0]).format(
                              'DD/MM/YY'
                            )}
                          </TableCell>
                          <TableCell>
                            {moment(log.timestamp.split('.')[0]).format('HH')}h
                            {moment(log.timestamp.split('.')[0]).format('mm')}
                            min
                          </TableCell>
                          <TableCell data-cy="button-open-modal">
                            <S.TableCellTooltip>
                              <S.LinkOpenModal
                                disabled={!log.id}
                                onClick={handleView}
                              >
                                Ver
                              </S.LinkOpenModal>
                              {handleTooltip}
                            </S.TableCellTooltip>
                          </TableCell>
                        </S.TableRowContent>
                      )}
                    </>
                  );
                })}
            </S.TableBodyCustom>
            {!(logs.length > rowsPerPage * page) &&
              (width < breakpoint ? (
                <FakeCardMobile
                  count={Math.min(rowsPerPage, total || rowsPerPage)}
                />
              ) : (
                <S.TableRowContent>
                  <TableCell colSpan={6}>
                    <FakeRow
                      count={Math.min(rowsPerPage, total || rowsPerPage)}
                    />
                  </TableCell>
                </S.TableRowContent>
              ))}
          </Table>
        </TableContainer>
        <S.TableFooterContainer>
          <S.Pagination
            rowsPerPageOptions={[5, 10, 25]}
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to, count }) =>
              `Mostrando ${from}-${to} de ${count} itens`
            }
            component="div"
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: classes.paper,
                },
              },
            }}
            classes={{ menuItem: classes.menuItem }}
          />
        </S.TableFooterContainer>
      </>
    );
  };

  const handleLoadMore = async () => {
    const response = await getLogs(
      workspace,
      {
        day: Number(startDate.format('DD')),
        month: Number(startDate.format('MM')),
        year: Number(startDate.format('YYYY')),
      },
      {
        day: Number(endDate.format('DD')),
        month: Number(endDate.format('MM')),
        year: Number(endDate.format('YYYY')),
      },
      search,
      rowsPerPage,
      nextToken,
      total
    );
    setNextToken(response.nextToken);
    setLogs([...logs, ...response.data]);
    setTotal(response.total);
  };

  return (
    <S.Root>
      <ReportHistory
        logIds={selected}
        handleClose={closeReport}
        getLogsById={getLogsById}
        open={reportOpen}
        workspace={workspace}
        workspaceName={workspaceName}
      />
      <S.BoxContainer>
        <Grid className="box-wrapper">
          <Grid className="align-items joyride__filter-search">
            <Box
              data-cy="input-search"
              display="flex"
              alignItems="center"
              className="align-card"
            >
              <Input
                type="text"
                text="Digite aqui para filtrar palavras-chave"
                Icon={IconSearch}
                value={searchBar}
                onChange={(e) => setSearchBar(e.target.value)}
                fullWidth
              />
              <S.ButtonSearch onClick={handleSearch}>Buscar</S.ButtonSearch>
            </Box>

            <S.DateContainer>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <S.Text className="text-padding">Filtrar de</S.Text>
                <DatePicker
                  autoOk={true}
                  value={startDate}
                  onChange={setStartDate}
                  variant="inline"
                  format="DD/MM/YYYY"
                  width={130}
                  maxDate={endDate}
                  data-cy="start-date"
                />

                <S.Text className="text-padding">Até</S.Text>
                <DatePicker
                  autoOk={true}
                  value={endDate}
                  onChange={setEndDate}
                  variant="inline"
                  format="DD/MM/YYYY"
                  width={130}
                  maxDate={new Date()}
                  minDate={startDate}
                  data-cy="end-date"
                />
              </MuiPickersUtilsProvider>
            </S.DateContainer>
          </Grid>
        </Grid>

        <Grid>{renderLogs()}</Grid>
      </S.BoxContainer>
    </S.Root>
  );
};

export default LogView;
