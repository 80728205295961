import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as EmptyImage } from 'modules/common/images/empty_board.svg';
import S from './styled';

interface IProps {
  title: string;
  description: string;
  height?: string;
}

const EmptyStateProject = ({ title, description, height }: IProps) => (
  <S.EmptyState height={height}>
    <div className="image">
      <EmptyImage />
    </div>
    <Typography align="center" className="title">
      {title}
    </Typography>
    <Typography align="center" className="text">
      {description}
    </Typography>
  </S.EmptyState>
);

export default EmptyStateProject;
