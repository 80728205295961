const resources = `
  query resources($workspaceCode: String!) {
    resources(workspaceCode: $workspaceCode) {
			id
      name
      type
      version
      isDependency
      workspaceCode
      dependencies {
        dependsOn {
          id
        }
        impactType
      }
    }
  }
`;

const resource = `
  query resource($id: ID!, $workspaceCode: String!) {
    resource(id: $id, workspaceCode: $workspaceCode) {
      id
      name
      type
      version
      isDependency
      workspaceCode
    }
  }
`;

export default {
  resources,
  resource,
};
