import moment from 'moment';
import 'moment/locale/pt-br';
import * as React from 'react';
import { IUser, IWorkspace } from './modules/auth/types';
import { getItem, setItem } from './utils/storage';
import { setWorkspace } from './authClient';
import { IProject } from 'modules/common/types';
import { createOrUpdateItemAtBrowserDB } from '../src/utils/storage';
import {
  MCP_WORKSPACE_INDEX,
  MCP_PA_PROJECT,
} from 'modules/common/utils/constants';

interface IProps {
  workspaces: IWorkspace[];
  userLogged?: IUser;
}

interface IState {
  workspace?: IWorkspace | null;
  workspaces: IWorkspace[];
  workspaceCode?: IWorkspace['code'];
  userLogged?: IUser;
  currentLanguage: string;
  runJoyride: boolean;
  stepsJoyride?: any[];
  projectSelected: IProject | undefined;
}

interface IStore extends IState {
  changeLanguage: (languageCode: string) => void;
  changeWorkspace: (workspaceCode: string) => void;
  setRunJoyride: (runJoyride: boolean) => void;
  setStepsJoyride: (stepsJoyride: any[]) => void;
  setProjectSelected: (projectSelected: IProject | undefined) => void;
}

export const AppContext = React.createContext({} as IStore);

export const AppConsumer = AppContext.Consumer;

export class AppProvider extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const currentLanguage =
      getItem('currentLanguage', 'localStorage') || 'pt-br';
    this.state = {
      workspaces: props.workspaces,
      userLogged: props.userLogged,
      currentLanguage,
      runJoyride: false,
      stepsJoyride: undefined,
      projectSelected: undefined,
    };
    this.setLocale(currentLanguage);
  }

  public setLocale = (currentLanguage: string) => {
    moment.locale(currentLanguage);
  };

  public changeLanguage = (languageCode: string) => {
    setItem('currentLanguage', languageCode, 'localStorage');
    this.setLocale(languageCode);
    this.setState({ currentLanguage: languageCode });
  };

  public changeWorkspace = (workspaceCode: string) => {
    if (workspaceCode !== 'empty') {
      const workspace = this.props.workspaces.find(
        ({ code }: { code: string }) => workspaceCode === code
      );
      this.setState({ workspace });
    } else {
      this.setState({ workspace: null });
    }

    this.setState({ workspaceCode });
    setWorkspace(workspaceCode);
  };

  public setRunJoyride = (runJoyride: boolean) => {
    this.setState({ runJoyride });
  };

  public setStepsJoyride = (stepsJoyride: any[]) => {
    this.setState({ stepsJoyride });
  };
  public setProjectSelected = (workspaceCode?: string) => (
    projectSelected?: IProject
  ) => {
    if (workspaceCode) {
      this.setState({
        projectSelected,
      });

      createOrUpdateItemAtBrowserDB(
        MCP_WORKSPACE_INDEX,
        workspaceCode,
        'cookies',
        MCP_PA_PROJECT,
        { projectSelected }
      );
    }
  };

  public render() {
    const {
      workspaces,
      workspaceCode,
      workspace,
      currentLanguage,
      userLogged,
      runJoyride,
      stepsJoyride,
      projectSelected,
    } = this.state;

    return (
      <AppContext.Provider
        value={{
          workspaces,
          workspaceCode,
          workspace,
          currentLanguage,
          userLogged,
          runJoyride,
          stepsJoyride,
          projectSelected,
          changeLanguage: this.changeLanguage,
          changeWorkspace: this.changeWorkspace,
          setRunJoyride: this.setRunJoyride,
          setStepsJoyride: this.setStepsJoyride,
          setProjectSelected: this.setProjectSelected(workspaceCode),
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
