import Typography from '@material-ui/core/Typography';
import { ReactComponent as EmptyBoard } from 'modules/common/images/empty_board.svg';
import * as React from 'react';
import { BoardCard, FakeCard } from '../components';
import { ISupportIssueResult } from '../types';
import { IBoards, IIssuesPage } from '../types';
import InfiniteScroller from './InfiniteScroller';
import S from './styled';

const render = (
  loading: boolean,
  issueModalHandler: (issueId: ISupportIssueResult['id']) => void,
  issuesPage: IIssuesPage
) => {
  if (loading) {
    return <FakeCard count={4} />;
  }
  if (issuesPage.count) {
    return issuesPage.issues.map((issue, index) => (
      <BoardCard
        key={`issue_${issue.issueKey}`}
        issue={issue}
        onClick={() => issueModalHandler(issue.id)}
        index={index}
        page={issuesPage.currentPage}
      />
    ));
  }
  return (
    <S.EmptyText>
      <div className="image">
        <EmptyBoard />
      </div>
      <Typography align="center" className="title">
        Esta lista está vazia
      </Typography>
      <Typography align="center" className="text">
        Nenhuma atividade aqui atualmente.
      </Typography>
    </S.EmptyText>
  );
};

export default function BoardColumn({
  board,
  loading,
  issuesPage,
  issueModalHandler,
  loadIssuesForBoard,
}: {
  board: IBoards;
  loading: boolean;
  issuesPage: IIssuesPage;
  issueModalHandler: (issueId: ISupportIssueResult['id']) => void;
  loadIssuesForBoard: (board: IBoards, page: number) => void;
}) {
  const ref = React.createRef<HTMLDivElement>();
  return (
    <S.BoardColumn>
      <div ref={ref} className="content board">
        <InfiniteScroller
          useWindow={false}
          hasMore={Boolean(
            !loading && issuesPage.currentPage < issuesPage.totalPages
          )}
          loadMore={() => loadIssuesForBoard(board, issuesPage.currentPage + 1)}
          getScrollParent={() => ref.current!}
        >
          {render(loading, issueModalHandler, issuesPage)}
        </InfiniteScroller>
      </div>
    </S.BoardColumn>
  );
}
