import gql from 'graphql-tag';
import { IRouterProps } from 'modules/common/types';
import * as React from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { ListWorkspace } from '../components';
import { queries } from '../graphql';

interface IProps extends IRouterProps {
  workspacesQuery?: any;
  loading?: boolean;
}

const defaultState = {
  redirectUrl: '/worklogs',
};

const ListWorkspaceContainer = ({
  workspacesQuery,
  location: { state: { redirectUrl } = defaultState },
}: IProps) => {
  const workspaces = workspacesQuery.workspaces || [];
  return <ListWorkspace workspaces={workspaces} redirectUrl={redirectUrl} />;
};

export default compose(
  graphql(gql(queries.listWorkspaces), {
    name: 'workspacesQuery',
  })
)(withRouter(ListWorkspaceContainer));
