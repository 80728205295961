import React, { useRef, useLayoutEffect, RefObject } from 'react';
import * as S from './styled';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from 'modules/common/styles/theme';
import Worklog from './Worklog';
import CommentsSection from './Comments';
import {
  IWorklog,
  IIssueComment,
  IIssue,
  SelectedIssueTabEnum,
} from '../../types';
import IssueInformationBox from './IssueInformationBox';
import Rating from './Rating';

interface IPropsTabComponent {
  selected: number;
  value: number;
  className?: string;
  children: React.ReactNode;
  reference?: RefObject<HTMLDivElement>;
}

interface IPropsIssueTab {
  worklogs: IWorklog[];
  comments: IIssueComment[];
  issue: IIssue;
  selectedTab?: SelectedIssueTabEnum;
}

const TabComponent = ({
  value,
  selected,
  className,
  children,
  reference,
}: IPropsTabComponent) => {
  const ref = value === 2 ? reference : null;
  return (
    <S.TabComponentRoot ref={ref}>
      {value === selected && (
        <Box paddingX={3} paddingY={6} className={className}>
          {children}
        </Box>
      )}
    </S.TabComponentRoot>
  );
};

export default function IssueTab({
  worklogs,
  comments,
  issue,
  selectedTab,
}: IPropsIssueTab) {
  const tabs: { label: string; value: React.ReactNode }[] = [
    { label: 'Geral', value: <IssueInformationBox issue={issue} /> },
    { label: 'Comentários', value: <CommentsSection comments={comments} /> },
    { label: 'Registro de trabalho', value: <Worklog worklogs={worklogs} /> },
    { label: 'Avaliação', value: <Rating issue={issue} /> },
  ];
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tab = selectedTab
    ? tabs.findIndex((tab) => tab.label === selectedTab) - 1
    : 0;

  const [page, setPage] = React.useState(tab);

  const tabRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (tab && selectedTab && tabRef.current !== null) {
      tabRef.current.scrollIntoView();
    }
  });

  const changeTabs = (e, newValue) => {
    setPage(newValue);
  };

  const removeGeneralTabOnMobile = ({ label }) =>
    isMobile ? true : label !== 'Geral';

  return (
    <>
      <S.PageMenu>
        <Tabs
          value={page}
          onChange={changeTabs}
          scrollButtons="auto"
          variant="scrollable"
          data-cy="tabs-menu"
        >
          {tabs.filter(removeGeneralTabOnMobile).map(({ label }, index) => (
            <S.PageMenuItem key={`tab-header-${index}`} label={label} />
          ))}
        </Tabs>
      </S.PageMenu>
      {tabs.filter(removeGeneralTabOnMobile).map(({ value }, index) => (
        <TabComponent
          reference={tabRef}
          key={`tab-body-${index}`}
          value={page}
          selected={index}
        >
          {value}
        </TabComponent>
      ))}
    </>
  );
}
