import * as React from 'react';
import * as S from './styled';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { Input } from '@cmp/ui';
import { ReactComponent as IconSearch } from 'modules/common/icons/icon-search.svg';
import { IRouterProps } from 'modules/common/types';
import modules from '../modules';
import { IMenu, IMenuList } from '../types';

interface IProps extends IRouterProps {
  isMenuOpened: boolean;
  searchProduct: string;
  setSearchProduct: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode;
}

const Menu = ({
  location,
  isMenuOpened,
  searchProduct,
  setSearchProduct,
  children,
}: IProps) => {
  const flatMap = (listProduct: IMenu[]) => {
    return listProduct
      .map((item: IMenu) => item.list)
      .reduce(
        (destinationArray: IMenuList[], array: IMenuList[]) =>
          destinationArray.concat(array),
        []
      );
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchProduct(event.target.value);
  };

  const newProductFlag = () => (
    <S.ContainerNewProduct>
      <S.NewProduct>Novo</S.NewProduct>
    </S.ContainerNewProduct>
  );

  const buildNavItem = (item: IMenuList, itemIndex: number) => {
    return (
      <>
        {!item.external ? (
          <S.NavItem
            key={`item_${itemIndex}`}
            exact={true}
            to={item.modules[0]}
            activeClassName="nav-item-active"
            isActive={() =>
              item.modules.some((m) => new RegExp(m).test(location.pathname))
            }
            onClick={() => setSearchProduct('')}
          >
            <S.ListItemMenu>
              {item.name}
              {item.new ? newProductFlag() : ''}
            </S.ListItemMenu>
          </S.NavItem>
        ) : (
          <S.NavItemExternal>
            <a href={item.modules[0]} className="nav-item-active">
              <S.ListItemMenu>{item.name}</S.ListItemMenu>
            </a>
          </S.NavItemExternal>
        )}
      </>
    );
  };

  return (
    <>
      {children}
      <S.DrawerCustom
        id="drawer-left"
        className="drawer"
        variant="persistent"
        anchor="left"
        open={isMenuOpened}
      >
        <div className="drawHeader">
          <Input
            type="text"
            text="Localizar serviços"
            fullWidth={true}
            Icon={IconSearch}
            rootProps={{ style: { border: 0 } }}
            value={searchProduct}
            onChange={handleSearch}
          />
        </div>
        <Divider />
        <S.ListMenu>
          {searchProduct
            ? flatMap(modules).map((item, itemIndex) => (
                <div key={`item_${itemIndex}`}>
                  {new RegExp(searchProduct, 'i').test(item.name) && (
                    <>{buildNavItem(item, itemIndex)}</>
                  )}
                </div>
              ))
            : modules.map((category, categoryIndex) => (
                <div key={`category_${categoryIndex}`}>
                  <S.TitleMenu>{category.name}</S.TitleMenu>
                  <S.ListMenuItem>
                    {category.list.map((item, itemIndex) => {
                      return (
                        <div key={`item_${itemIndex}`}>
                          {buildNavItem(item, itemIndex)}
                        </div>
                      );
                    })}
                  </S.ListMenuItem>
                </div>
              ))}
        </S.ListMenu>
      </S.DrawerCustom>
    </>
  );
};

export default withRouter(Menu);
