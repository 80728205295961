import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as EmptyImage } from 'modules/common/images/empty_board.svg';
import * as S from './styled';

const ProjectEmptyState = () => (
  <S.EmptyState>
    <div className="image">
      <EmptyImage />
    </div>
    <Typography align="center" className="title">
      Nenhum projeto selecionado
    </Typography>
    <Typography align="center" className="text">
      Selecione um projeto para ver as tarefas associadas a ele
    </Typography>
  </S.EmptyState>
);

export default ProjectEmptyState;
