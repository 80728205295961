import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { IRouterProps } from 'modules/common/types';
import * as S from './styled';

interface IProps extends IRouterProps {
  code: string;
  name: string;
  redirectUrl: string;
  changeWorkspace: (workspaceCode: string) => void;
}

const RowWorkspace = ({
  code,
  name,
  history,
  changeWorkspace,
  redirectUrl,
}: IProps) => {
  const handleChangeWorkspace = () => {
    changeWorkspace(code);
    history.push(redirectUrl);
  };

  return (
    <S.ListItemWorkspaces
      avatar={<S.AvatarCustom>{name.charAt(0)}</S.AvatarCustom>}
      title={name}
      onClick={handleChangeWorkspace}
      button={true}
    />
  );
};

export default withRouter(RowWorkspace);
