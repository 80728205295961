import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import S from './CalendarStyled';
import {
  calcTimePercent,
  timeSpentToString,
} from 'modules/common/utils/worklog';

export default function ContentQuarter({
  poolSize,
  timeSpent,
  children,
}: {
  poolSize: number;
  timeSpent: number;
  children: any;
}) {
  return (
    <S.BoxQuarter className="joyride__quarter joyride__progress-month">
      <div className="content-months">{children}</div>
      <S.CustomTooltip
        arrow={true}
        title={
          <>
            <S.TooltipProgress>
              <div className="tooltip-content">
                <Typography component="h5">Horas Contratadas</Typography>
                <Typography component="h6">{poolSize}h</Typography>
              </div>
              <div className="tooltip-content">
                <Typography component="h5">Horas Consumidas</Typography>
                <Typography component="h6">
                  {timeSpentToString(timeSpent)
                    ? timeSpentToString(timeSpent)
                    : '0h'}
                </Typography>
              </div>
            </S.TooltipProgress>
            <span className="tooltip-arrow" />
          </>
        }
      >
        <S.ProgressContent className="joyride__quarter-progress">
          <S.Progress value={calcTimePercent(timeSpent, poolSize)} />
        </S.ProgressContent>
      </S.CustomTooltip>
    </S.BoxQuarter>
  );
}
