import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import * as S from './styled';

interface IProps {
  workspaceCode: string;
  deleteId: string | null;
  setReload: (state: boolean) => void;
  handleCloseForm: () => void;
  deleteResource: (workspaceCode: string, resourceId: string) => void;
}

const DialogResourceDelete = ({
  workspaceCode,
  deleteId,
  setReload,
  handleCloseForm,
  deleteResource,
}: IProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDeleteResource = async () => {
    if (deleteId) {
      try {
        setLoading(true);
        await deleteResource(workspaceCode, deleteId);
      } finally {
        setReload(true);
        setLoading(false);
        handleCloseForm();
      }
    }
  };

  return (
    <Dialog open={Boolean(deleteId)} onClose={handleCloseForm}>
      <S.DialogContentCustom>
        <Typography>Deseja deletar o recurso ?</Typography>
      </S.DialogContentCustom>
      <S.DialogActionsCustom>
        <Button color="primary" size="medium" onClick={handleCloseForm}>
          Não
        </Button>
        <Button
          data-cy="btn-yes"
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleDeleteResource}
          disabled={loading}
        >
          Sim
        </Button>
      </S.DialogActionsCustom>
    </Dialog>
  );
};

export default DialogResourceDelete;
