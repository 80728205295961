import { paletteColor } from 'modules/common/styles/colors';

export const getImpactTypeName = (name?: string): string => {
  switch (name) {
    case 'UNAVAILABLE':
      return 'Indisponível';
    case 'DEGRADED':
      return 'Degradado';
    default:
      return 'Disponível';
  }
};

export const getImpactTypeColor = (name?: string): string => {
  switch (name) {
    case 'UNAVAILABLE':
      return paletteColor.error.main;
    case 'DEGRADED':
      return paletteColor.warning.main;
    default:
      return paletteColor.success.main;
  }
};
