export const paletteColor = {
  primary: {
    main: '#e00000',
    dark: '#34454e',
    light: '#c8d2d8',
    white: '#ffffff',
  },
  secondary: {
    main: '#F26522',
    dark: '#B91313',
    medium: '#E53E3E',
  },
  third: {
    orange: '#f37021',
    yellow: '#fdb813',
    blue: '#1090c0',
    green: '#A0C585',
  },
  error: {
    main: '#E92641',
    medium: '#E28982',
    light: '#FFE8E0',
  },
  warning: {
    main: '#F5CF07',
    medium: '#FDEDAE',
    light: '#FEFAEB',
  },
  success: {
    main: '#72CB46',
    medium: '#A0C585',
    light: '#EAFADE',
  },
  info: {
    text: '#252733',
    border: '#DFE1EB',
    background: '#F7F8FC',
    soft: '#9FA2B4',
    fill: '#F0F1F7',
  },
  dark: {
    main: '#363740',
    medium: '#434552',
    icon: '#636573',
    text: '#A4A8B3',
  },
};
