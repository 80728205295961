import { IWorkspace } from 'modules/auth/types';
import { withAppContext } from 'modules/common/hoc/withAppContext';
import { IProject } from 'modules/common/types';
import * as React from 'react';
import GenericScheduleContainer from './GenericScheduleContainer';

interface IProps {
  workspace: IWorkspace;
  setProjectSelected: (projectSelected: IProject | undefined) => void;
  projectSelected?: IProject;
  stepsJoyride?: any[];
  setStepsJoyride: (stepsJoyride: any[]) => void;
}

class ScheduleContainer extends React.Component<IProps> {
  public render() {
    const {
      workspace,
      setProjectSelected,
      projectSelected,
      stepsJoyride,
      setStepsJoyride,
    } = this.props;

    return (
      <GenericScheduleContainer
        workspace={workspace}
        scheduleType="graph"
        projectSelected={projectSelected}
        setProjectSelected={setProjectSelected}
        setStepsJoyride={setStepsJoyride}
        stepsJoyride={stepsJoyride}
      />
    );
  }
}

export default withAppContext(ScheduleContainer);
