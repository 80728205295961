import * as React from 'react';
import * as S from './styled';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import logo from 'modules/common/brand/brand.svg';
import { ReactComponent as IconMenu } from 'modules/common/icons/icon-menu.svg';
import { ReactComponent as IconLogoutDesktop } from 'modules/common/icons/logout.svg';
import { ReactComponent as IconLogoutMobile } from 'modules/common/icons/logout-mobile.svg';
import { IUser, IWorkspace } from 'modules/auth/types';
import { withAppContext } from 'modules/common/hoc/withAppContext';
import { IRouterProps } from 'modules/common/types';
import Menu from './Menu';
import WorkspaceMenu from './WorkspaceMenu';
import modules from '../modules';
import { getItem, setItem } from '../../../utils/storage';

interface IProps extends IRouterProps {
  userLogged?: IUser;
  workspace: IWorkspace;
  workspacesPage: boolean;
  logout: () => Promise<void>;
  children: (V: boolean) => React.ReactNode;
}

const Navigation = ({
  location,
  userLogged,
  workspace,
  workspacesPage,
  logout,
  children,
}: IProps) => {
  const menuOpenSettingName = 'keepMenuOpen';
  const getMenuOpenSetting = () => {
    const setting = getItem(menuOpenSettingName, 'cookies');
    return setting === 'true' || setting === null;
  };

  const setMenuOpenSetting = (openSetting: boolean) => {
    setItem(menuOpenSettingName, String(openSetting), 'cookies', {
      domain: '.claranet.com.br',
    });
  };

  const [isMenuOpened, setIsMenuOpened] = React.useState<boolean>(
    getMenuOpenSetting()
  );

  React.useEffect(() => {
    setMenuOpenSetting(isMenuOpened);
  }, [isMenuOpened]);

  const [searchProduct, setSearchProduct] = React.useState('');

  const handleDrawerToggle = () => {
    setIsMenuOpened(!isMenuOpened);
    setSearchProduct('');
  };

  return (
    <>
      <S.NavigationContainer id="navigation-container">
        <S.NavigationRoot>
          <Menu
            isMenuOpened={isMenuOpened}
            searchProduct={searchProduct}
            setSearchProduct={setSearchProduct}
          >
            <S.AppBarCustom position="sticky">
              <S.ToolbarCustom className={!isMenuOpened ? '' : 'appBarShift'}>
                <IconButton color="inherit" onClick={handleDrawerToggle}>
                  <IconMenu />
                </IconButton>
                <Divider className="custom-divider" />
                <S.NavigationPullLeft>
                  <S.LogoContainer>
                    <img
                      className="logo"
                      src={logo}
                      alt="Logomarca da Mandic SA"
                    />
                    {modules.map((item, itemIndex) =>
                      item.list.map((itemList) => {
                        const isActive = itemList.modules.some((item) => {
                          return new RegExp(item).test(location.pathname);
                        });
                        if (isActive) {
                          return (
                            <S.Item
                              key={`item_${itemIndex}`}
                              exact={true}
                              to={itemList.modules[0]}
                            >
                              <Typography className="itemList">
                                {itemList.name}
                              </Typography>
                            </S.Item>
                          );
                        }
                        return null;
                      })
                    )}
                  </S.LogoContainer>
                </S.NavigationPullLeft>
                <S.NavigationPullRight>
                  <S.UsernameContent>
                    {userLogged && userLogged.username}
                  </S.UsernameContent>
                  <div className="selectWork">
                    {!workspacesPage && workspace && (
                      <Box>
                        <S.WorkspaceMenuContainer>
                          <WorkspaceMenu />
                        </S.WorkspaceMenuContainer>
                      </Box>
                    )}
                  </div>
                  <S.LogoutButton onClick={() => logout()}>
                    <span className="logout-button__label">Logout</span>
                    <IconLogoutDesktop className="logout-button__icon--desktop" />
                    <IconLogoutMobile className="logout-button__icon--mobile" />
                  </S.LogoutButton>
                </S.NavigationPullRight>
              </S.ToolbarCustom>
            </S.AppBarCustom>
          </Menu>
        </S.NavigationRoot>
      </S.NavigationContainer>
      {children(isMenuOpened)}
    </>
  );
};

export default withRouter(withAppContext(Navigation));
