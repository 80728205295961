import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import client from '../../../apolloClient';
import { queries } from '../graphql';
import S from './styled';
import IssueModal from 'modules/support/containers/IssueModalContainer';
import * as IssueStyled from 'modules/support/containers/IssueModalContainer/styled';
import { IIssue, SelectedIssueTabEnum } from 'modules/support/types';
import WorklogCardTaskList from './WorklogCardTaskList';
import { IWorklog, PageResults } from '../types';
import { IProject } from 'modules/common/types';
import { sumWorklogsByIssue } from '../rules';
import config from '../../../utils/config';
import axios from 'axios';
import { getItem } from '../../../utils/storage';

interface IProps {
  workspaceCode?: string;
  date?: string;
  projectSelected?: IProject;
  issueToBeOpened?: number;
  selectedTab?: SelectedIssueTabEnum;
}

interface IState {
  loading: boolean;
  worklogList: PageResults;
  issueIdSelected?: IIssue['id'];
  isModalOpen: boolean;
  issueToBeOpened?: number;
  selectedTab?: SelectedIssueTabEnum;
}

class ListWorklog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      worklogList: { results: [], totalPages: 0, currentPage: 0 },
      loading: true,
      isModalOpen: false,
      issueIdSelected: undefined,
      issueToBeOpened: props.issueToBeOpened,
      selectedTab: props.selectedTab,
    };
  }

  openIssueModal = (issueId: IIssue['id'], isJira = '') => {
    if (isJira) {
      window.open(isJira, '_blank');
    } else {
      console.log('IS JIRA FALSE');
      this.setState({ issueIdSelected: issueId, isModalOpen: true });
    }
  };

  closeIssueModal = (e: React.MouseEvent) => {
    this.setState({
      isModalOpen: false,
      selectedTab: undefined,
      issueToBeOpened: undefined,
    });
  };

  public async componentDidMount() {
    this.mountList();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { date } = this.props;
    if (
      date !== prevProps.date ||
      this.props.workspaceCode !== prevProps.workspaceCode ||
      this.props.projectSelected !== prevProps.projectSelected
    ) {
      this.setState({
        loading: true,
      });
      this.mountList();
    }
  }

  public async mountList() {
    const { workspaceCode, projectSelected } = this.props;

    const { issueToBeOpened } = this.state;

    if (issueToBeOpened) {
      this.openIssueModal(issueToBeOpened);
    }

    if (!workspaceCode || !projectSelected) {
      return this.setState({
        worklogList: { results: [], totalPages: 0, currentPage: 0 },
        loading: false,
      });
    }
    this.loadWorklogs();
  }

  public async getWorklogList(
    workspaceCode: string,
    project: string,
    page?: number,
    started?: { start: string; end: string }
  ) {
    try {
      const { data } = await client.query<{
        worklogs: {
          results: IWorklog[];
          totalPages: number;
          currentPage: number;
        };
      }>({
        query: gql(queries.worklogsByProject),
        variables: {
          workspace: workspaceCode,
          started,
          project,
          page,
        },
      });
      let additional_data = { data: [] };
      try {
        const token =
          getItem(config.constants.devopsAuthCookie, 'cookies') ||
          config.cookies._authcd_;
        if (token) {
          console.log('entrou no token');
          additional_data = await axios.get(
            `${config.publicJiraAPI}/public_tickets/getlist`,
            {
              headers: {
                'x-access-token': token,
              },
              params: {
                start: started?.start,
                end: started?.end,
              },
            }
          );
        }
      } catch (e) {
        console.log(e);
      }
      data.worklogs.results = data.worklogs.results.concat(
        additional_data.data
      );
      return data.worklogs;
    } catch (error) {
      // TODO: Send to APM and redirect user to Error Page
      return { results: [], totalPages: 0, currentPage: 0 };
    }
  }

  public getWorklogByWeek(worklogList: any) {
    return worklogList.reduce((obj: any, data: any) => {
      const week = moment(data.started).week();
      if (!obj[week]) {
        obj[week] = { list: [] };
      }
      obj[week].list.push(data);
      return obj;
    }, {});
  }

  public async loadWorklogs(page = 1) {
    const { workspaceCode, projectSelected, date } = this.props;
    const { worklogList: oldWorklogList } = this.state;
    if (workspaceCode && projectSelected) {
      const dateSelected = date || moment().format('YYYY-MM');
      const start = moment(dateSelected, 'YYYY-MM')
        .utcOffset(moment().utcOffset(), true)
        .toISOString();
      const end = moment(dateSelected, 'YYYY-MM')
        .add(1, 'month')
        .utcOffset(moment().utcOffset(), true)
        .toISOString();

      const worklogList = await this.getWorklogList(
        workspaceCode,
        projectSelected.key,
        page,
        projectSelected.category !== 'pontual' ? { start, end } : undefined
      );
      if (page === 1) {
        worklogList.results = sumWorklogsByIssue(worklogList.results);
      } else {
        worklogList.results = sumWorklogsByIssue([
          ...oldWorklogList.results,
          ...worklogList.results,
        ]);
      }

      this.setState({
        worklogList,
        loading: false,
      });
    }
  }

  public renderDialog() {
    const { isModalOpen, issueIdSelected, selectedTab } = this.state;
    const { workspaceCode, projectSelected } = this.props;

    return (
      workspaceCode &&
      projectSelected &&
      issueIdSelected && (
        <IssueStyled.Dialog
          onClose={this.closeIssueModal}
          aria-labelledby="form-resource"
          open={isModalOpen}
          maxWidth="lg"
          disableEnforceFocus
        >
          <IssueModal
            workspaceCode={workspaceCode}
            projectKey={projectSelected.key}
            issueId={issueIdSelected}
            closeIssueModal={this.closeIssueModal}
            selectedTab={selectedTab}
          />
        </IssueStyled.Dialog>
      )
    );
  }

  public render() {
    const { worklogList, loading } = this.state;

    return (
      <S.CardTaskContent>
        <WorklogCardTaskList
          loading={loading}
          worklogList={worklogList}
          issueModalHandler={this.openIssueModal}
          loadMore={this.loadWorklogs.bind(this)}
        />
        {this.renderDialog()}
      </S.CardTaskContent>
    );
  }
}

export default ListWorklog;
