import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as React from 'react';
import { IWorkspace } from 'modules/auth/types';
import { IRouterProps } from 'modules/common/types';
import { withAppContext } from 'modules/common/hoc/withAppContext';
import * as S from './styled';
import { ClickAwayListener } from '@material-ui/core';

interface IProps extends IRouterProps {
  workspace: IWorkspace;
  workspaces: IWorkspace[];
  changeWorkspace: (workspaceCode: string) => void;
  handleChangeWorkspace: (workspaceCode: string) => void;
}

const WorkspaceMenu = ({ workspace, changeWorkspace, workspaces }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleChangeWorkspace = (code: string) => () => {
    changeWorkspace(code);
    setOpen(false);
  };

  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleCloseMenu = (): void => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <S.WorkspaceMenu>
        <S.ButtonWorkspaces
          aria-owns="menu-workspace"
          aria-haspopup="true"
          onClick={handleToggleMenu}
          className="btn-closeMenu"
        >
          <Avatar className="avatarCustom">{workspace.name.charAt(0)}</Avatar>
          <S.WorkspaceWrap>
            <S.WorkspaceLabel>{workspace.name}</S.WorkspaceLabel>
          </S.WorkspaceWrap>
          <S.WorkspaceMenuIcon>expand_more</S.WorkspaceMenuIcon>
        </S.ButtonWorkspaces>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          PaperProps={{ style: { width: 250, height: 300 } }}
        >
          {workspaces.map((item: IWorkspace) => {
            return (
              <MenuItem
                key={`workspace_${item.code}_option`}
                value={item.code}
                selected={item.code === workspace.code}
                onClick={handleChangeWorkspace(item.code)}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Menu>
      </S.WorkspaceMenu>
    </ClickAwayListener>
  );
};

export default withAppContext(WorkspaceMenu);
