import * as React from 'react';
import * as S from './styled';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

interface IProps {
  selected: string;
}

export default function ViewType(props: IProps) {
  return (
    <S.ViewTypeRoot>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={3}>
          <S.NavButtonGroup>
            <S.NavButton
              component={Link}
              to="/tasks"
              selected={props.selected === 'board'}
            >
              Quadro
            </S.NavButton>
            <S.NavButton
              component={Link}
              to="/tasks/list"
              selected={props.selected === 'list'}
            >
              Lista
            </S.NavButton>
          </S.NavButtonGroup>
        </Grid>
      </Grid>
    </S.ViewTypeRoot>
  );
}
