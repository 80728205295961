import ExpansionPanel from './ExpansionPanel';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import ExpandMore from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { IDrawerPayload, IServicesTypes, ISummary } from '../types';
import { hoursToFixed } from '../utils';
import BillingChart from './BillingChart';
import * as S from './Styled';
import { Input } from '@cmp/ui';
import { ReactComponent as SearchIcon } from 'modules/common/icons/icon-search.svg';
import { Title } from 'modules/common/components/Utils';

interface IProps {
  drawerListData: IDrawerPayload[];
  drawerChartData: ISummary[];
  status?: boolean;
  drawerStatus?: boolean;
  ref: React.Ref<HTMLDivElement>;
  forwardedRef?: React.Ref<HTMLDivElement>;
  filterWord: string;
  setFilterWord?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  billingUnit?: string;
  handleCloseDrawer: () => void;
}

function DetailsDrawer({
  filterWord,
  setFilterWord,
  forwardedRef,
  billingUnit,
  drawerStatus,
  drawerChartData,
  drawerListData,
  handleCloseDrawer,
}: IProps) {
  const hasSubTypes = (details: IDrawerPayload) => {
    return details.servicesPerRegion.length <= 1;
  };

  const mountSummary = (item: IDrawerPayload) => (
    <S.DrawerSummary>
      <S.RegionMobile>
        <S.CloudRegion>Região</S.CloudRegion>
        <Typography className="drawer-summary-text">{item.region}</Typography>
      </S.RegionMobile>

      {item.productImprovement && (
        <S.CustomTooltip
          title={
            <S.TooltipProgress>{item.productImprovement}</S.TooltipProgress>
          }
        >
          <Icon className="color-icon">warning</Icon>
        </S.CustomTooltip>
      )}
      <S.DividerLine />
      <S.AlignValue>
        <S.CloudRegion>Gasto Atual</S.CloudRegion>
        <div className="drawer-summary-value">
          {hoursToFixed(item.pricePerRegion)} {billingUnit}
        </div>
        {!hasSubTypes(item)}
      </S.AlignValue>
    </S.DrawerSummary>
  );

  const mountDetails = (item: IServicesTypes[]) => (
    <S.DrawerContent>
      {item.map((productDetail: any, index: number) => (
        <S.ApplicationDetails key={`drawer_content_${index}`}>
          <div className="service-alert-container">
            <S.CloudSubMenuMobile>Produto</S.CloudSubMenuMobile>
            <S.ApplicationSubTitle>
              {productDetail.product}
            </S.ApplicationSubTitle>
          </div>
          {productDetail.reserved && <S.ReservedTag>Reservada</S.ReservedTag>}
          <S.SubMenuDividerLine />
          <S.ValueField>
            <S.CloudSubMenuMobile>Gasto Atual</S.CloudSubMenuMobile>
            {hoursToFixed(productDetail.price)} {billingUnit}
          </S.ValueField>
        </S.ApplicationDetails>
      ))}
    </S.DrawerContent>
  );

  return (
    <>
      <S.DetailsDrawer status={drawerStatus} ref={forwardedRef}>
        <S.ContainerCloseButton>
          <S.CloseButton type="button" onClick={handleCloseDrawer}>
            Fechar
          </S.CloseButton>
        </S.ContainerCloseButton>
        <Box mb={3}>
          <BillingChart data={drawerChartData} />
        </Box>
        <Box mb={3}>
          <Input
            type="text"
            text="Digite aqui para buscar uma região"
            value={filterWord}
            onChange={setFilterWord}
            fullWidth={true}
            Icon={SearchIcon}
          />
        </Box>
        <S.TitleMobile>
          <Title>Regiões</Title>
        </S.TitleMobile>
        {drawerListData.map((item, index) => (
          <ExpansionPanel
            key={`drawer_service_${index}`}
            summaryStyle={{ opacity: 1, borderRadius: 0 }}
            summary={mountSummary(item)}
            details={mountDetails(item.servicesPerRegion)}
            expandIcon={!hasSubTypes(item) && <ExpandMore />}
            disabled={hasSubTypes(item)}
          />
        ))}
      </S.DetailsDrawer>
    </>
  );
}

export default React.forwardRef((props: IProps, ref: any) => (
  <DetailsDrawer {...props} forwardedRef={ref} />
));
