import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import ResourceBox from './ResourceBox';
import * as React from 'react';
import { IDependencyMatrixData, ImpactType, IResource } from '../types';
import { getImpactTypeName } from '../util';
import * as S from './styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { paletteColor } from 'modules/common/styles/colors';

interface IProps {
  workspaceCode: string;
  reload: boolean;
  setReload: (state: boolean) => void;
  handleDialogAddOpen: () => void;
  handleEditResource: (value: string | null) => void;
  handleDeleteResource: (value: string | null) => void;
  getResources: (workspaceCode: string) => any;
  createImpact: (
    workspaceCode: string,
    resourceId: string,
    dependsOnId: string,
    impactType: 'AVAILABLE' | 'DEGRADED' | 'UNAVAILABLE'
  ) => any;
}

const ResourceTable = ({
  reload,
  setReload,
  getResources,
  createImpact,
  workspaceCode,
  handleDialogAddOpen,
  handleEditResource,
  handleDeleteResource,
}: IProps) => {
  const [anchorMenu, setAnchorMenu] = React.useState<EventTarget | null>(null);
  const [resources, setResources] = React.useState<IResource[]>([]);
  const [applications, setApplications] = React.useState<IResource[]>([]);
  const [services, setServices] = React.useState<IResource[]>([]);
  const [dependencyMatrix, setDependencyMatrix] = React.useState<
    IDependencyMatrixData[][]
  >([]);
  const [resourceSelected, setResourceSelected] = React.useState<{
    applicationId: string | null;
    serviceId: string | null;
  }>({
    applicationId: null,
    serviceId: null,
  });

  const loadResources = React.useCallback(async () => {
    try {
      const response = await getResources(workspaceCode);
      setResources(response);
    } catch (error) {
      setResources([]);
    } finally {
      setReload(false);
    }
  }, [setReload, getResources, workspaceCode]);

  React.useEffect(() => {
    setReload(true);
    setResources([]);
  }, [workspaceCode, setReload]);

  React.useEffect(() => {
    if (reload) {
      setTimeout(() => loadResources(), 1000);
    }
  }, [reload, loadResources]);

  React.useEffect(() => {
    const resourceSorted = resources.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );

    const applicationsFiltered = resourceSorted.filter(
      (resource) => resource.type === 'APPLICATION'
    );
    setApplications(applicationsFiltered);

    const servicesFiltered = resourceSorted.filter(
      (resource) => resource.type === 'SERVICE' || resource.isDependency
    );
    setServices(servicesFiltered);

    const baseMatrix: IDependencyMatrixData[][] = applicationsFiltered.map(
      (application) =>
        servicesFiltered.map(
          (service): IDependencyMatrixData => ({
            resourceId: application.id,
            dependsOnId: service.id,
            impactType: 'AVAILABLE',
          })
        )
    );

    const mountMatrix = applicationsFiltered.reduce(
      (matrix, resource, index) => {
        resource.dependencies.forEach((dependency) => {
          const indexOfDependsOn = servicesFiltered.findIndex(
            (service) => service.id === dependency.dependsOn.id
          );
          if (indexOfDependsOn >= 0) {
            matrix[index][indexOfDependsOn].impactType = dependency.impactType;
          }
        });
        return matrix;
      },
      baseMatrix
    );
    setDependencyMatrix(mountMatrix);
  }, [resources]);

  const handleOpenMenu = (applicationId: string, serviceId: string) => (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setResourceSelected({ applicationId, serviceId });
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setResourceSelected({ applicationId: null, serviceId: null });
    setAnchorMenu(null);
  };

  const handleCreateImpact = (impactType: ImpactType) => () => {
    const { applicationId, serviceId } = resourceSelected;
    if (applicationId && serviceId) {
      createImpact(workspaceCode, applicationId, serviceId, impactType);
      const applicationIndex = applications.findIndex(
        (application) => application.id === applicationId
      );
      const serviceIndex = services.findIndex(
        (service) => service.id === serviceId
      );
      dependencyMatrix[applicationIndex][serviceIndex] = {
        resourceId: applicationId,
        dependsOnId: serviceId,
        impactType,
      };
      setDependencyMatrix(dependencyMatrix);
    }
    handleCloseMenu();
  };

  return (
    <>
      <S.MenuCustom
        id="menu-sprints"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <S.MenuItemCustom
          data-cy="btn-disponivel"
          onClick={handleCreateImpact('AVAILABLE')}
        >
          <S.ResourceStatusBar color={paletteColor.success.main} />
          Disponível
        </S.MenuItemCustom>
        <S.MenuItemCustom
          data-cy="btn-degradado"
          onClick={handleCreateImpact('DEGRADED')}
        >
          <S.ResourceStatusBar color={paletteColor.warning.main} />
          Degradado
        </S.MenuItemCustom>
        <S.MenuItemCustom
          data-cy="btn-indisponivel"
          onClick={handleCreateImpact('UNAVAILABLE')}
        >
          <S.ResourceStatusBar color={paletteColor.error.main} />
          Indisponível
        </S.MenuItemCustom>
      </S.MenuCustom>

      <S.ResourceTableContainer>
        <S.ResourceTableContent>
          <S.ResourceTable>
            <S.ResourceTableRow>
              <S.ResourceButtonAdd onClick={handleDialogAddOpen}>
                {reload ? (
                  <>
                    <Box mr={3}>
                      <CircularProgress
                        color="secondary"
                        size={14}
                        thickness={6}
                      />
                    </Box>
                    Atualizando...
                  </>
                ) : (
                  <>
                    <Icon className="icon-button-add" data-cy="add-resource">
                      add
                    </Icon>
                    Adicionar Recurso
                  </>
                )}
              </S.ResourceButtonAdd>
              {services.map((service: IResource, index: number) => (
                <S.ResourceTableHeader key={`service_${index}`}>
                  <ResourceBox
                    id={service.id}
                    name={service.name}
                    version={service.version}
                    handleDelete={() => handleDeleteResource(service.id)}
                    handleEdit={() => handleEditResource(service.id)}
                  />
                </S.ResourceTableHeader>
              ))}
            </S.ResourceTableRow>

            {dependencyMatrix.map((row, rowIndex) => (
              <S.ResourceTableRow key={`application_${rowIndex}`}>
                <S.ResourceTableCell>
                  <ResourceBox
                    id={applications[rowIndex].id}
                    name={applications[rowIndex].name}
                    version={applications[rowIndex].version}
                    handleDelete={() =>
                      handleDeleteResource(applications[rowIndex].id)
                    }
                    handleEdit={() =>
                      handleEditResource(applications[rowIndex].id)
                    }
                  />
                </S.ResourceTableCell>

                {row.map((dependencyRelation, columnIndex) => (
                  <S.ResourceTableCell key={`dependency_${columnIndex}`}>
                    {services[columnIndex].id !== applications[rowIndex].id && (
                      <S.ResourceButton
                        impactType={dependencyRelation.impactType}
                        onClick={handleOpenMenu(
                          applications[rowIndex].id,
                          services[columnIndex].id
                        )}
                      >
                        <S.ResourceStatusBar className="status-bar" />
                        <Typography className="text">
                          {getImpactTypeName(dependencyRelation.impactType)}
                        </Typography>
                        <S.ResourceIconContent>
                          <Icon
                            data-cy="btn_expand_more"
                            className="icon-button-resource"
                          >
                            expand_more
                          </Icon>
                        </S.ResourceIconContent>
                      </S.ResourceButton>
                    )}
                  </S.ResourceTableCell>
                ))}
              </S.ResourceTableRow>
            ))}
          </S.ResourceTable>
        </S.ResourceTableContent>
      </S.ResourceTableContainer>
    </>
  );
};

export default ResourceTable;
