import React, { useState } from 'react';
import { timeSpentToString } from 'modules/common/utils/worklog';
import { translatedName, translatedStatus } from '../../utils/support';
import { IIssue } from '../../types';
import * as S from './styled';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from 'modules/common/styles/theme';
import { ReactComponent as IconChain } from 'modules/common/icons/icon-chain.svg';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import copy from 'copy-to-clipboard';

type IProps = {
  issue: IIssue;
};

const IssueInformationBox = ({ issue }: IProps) => {
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const copySurveyUrl: any = async (key: any) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl).origin;

    const surveyPath = `${url}/worklogs/survey/key/${key}`;
    setShowCopyMessage(copy(surveyPath));
    setTimeout(() => setShowCopyMessage(false), 2000);
  };

  const MessageSuccess = () => (
    <S.TextContainer height="44px" display="flex">
      <Typography>Link copiado!</Typography>
    </S.TextContainer>
  );

  return (
    <S.IssueInformationBox>
      {useMediaQuery(theme.breakpoints.down('sm')) && (
        <S.Topic>
          <S.TopicHeading>Prioridade</S.TopicHeading>
          <S.IssuePriorityHeader
            className="bg-inherit"
            priority={issue.priority}
          />
        </S.Topic>
      )}
      <S.Topic>
        <S.TopicHeading>Registro de trabalho</S.TopicHeading>
        <S.InformationChip className="information-bg">
          {timeSpentToString(issue.worklogs.totalTimeSpent) || '0h'}
        </S.InformationChip>
      </S.Topic>
      <S.Topic>
        <S.TopicHeading>Tipo de demanda</S.TopicHeading>
        <S.InformationChip className="information-bg">
          {translatedName(issue.issuetype.name)}
        </S.InformationChip>
      </S.Topic>
      <S.Topic>
        <S.TopicHeading>Status</S.TopicHeading>
        <S.InformationChip className="information-bg">
          {translatedStatus(issue.status.name)}
        </S.InformationChip>
      </S.Topic>
      <S.Topic>
        <S.TopicHeading>Épico</S.TopicHeading>
        <S.InformationChip className="information-bg">
          {(issue.epicIssue && issue.epicIssue.summary) || 'Nenhum'}
        </S.InformationChip>
      </S.Topic>
      <S.Topic>
        <S.TopicHeading>Responsável</S.TopicHeading>
        <S.InformationChip className="information-bg">
          {(issue.assignee && issue.assignee.displayName) || 'Nenhum'}
        </S.InformationChip>
      </S.Topic>
      <S.Topic>
        <S.TopicHeading>Avaliação da Tarefa</S.TopicHeading>
        <S.CopyButton
          variant="outlined"
          color="primary"
          title="Copiar link direto para a avaliação desta tarefa."
          height="44px"
          maxwidth="none"
          onClick={() => copySurveyUrl(issue.issueKey)}
        >
          <IconChain />
          <span>Copiar</span>
        </S.CopyButton>
      </S.Topic>
      <S.Topic>
        <Collapse in={showCopyMessage}>
          <MessageSuccess />
        </Collapse>
      </S.Topic>
    </S.IssueInformationBox>
  );
};

export default IssueInformationBox;
