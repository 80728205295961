import { DatePicker } from '@cmp/ui';
import DateFnsUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import * as React from 'react';
import * as S from './styled';

interface IProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  setStartDate: (startDate: moment.Moment) => void;
  setEndDate: (endDate: moment.Moment) => void;
}

export default function PeriodDatePicker({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: IProps) {
  return (
    <S.ViewTypeRoot>
      <Grid container>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <S.TypographyCustom>Filtrar de</S.TypographyCustom>
          <DatePicker
            autoOk={true}
            value={startDate}
            onChange={setStartDate}
            variant="inline"
            format="DD/MM/YYYY"
            width={130}
            maxDate={endDate}
            data-cy="date-start"
          />
          <S.TypographyCustom>Até</S.TypographyCustom>
          <DatePicker
            autoOk={true}
            value={endDate}
            onChange={setEndDate}
            variant="inline"
            format="DD/MM/YYYY"
            width={130}
            maxDate={new Date()}
            minDate={startDate}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </S.ViewTypeRoot>
  );
}
