import React from 'react';
import { IIssue, SelectedIssueTabEnum } from '../../types';
import IssueModal from './IssueModal';
import client from '../../../../apolloClient';
import * as queries from './graphql/queries';
import { IWorkspace } from 'modules/auth/types';
import FakeModal from './FakeModal';
import { IProject } from 'modules/common/types';

interface IProps {
  workspaceCode: IWorkspace['code'];
  issueId: IIssue['id'];
  projectKey: IProject['key'];
  closeIssueModal: (e: React.MouseEvent) => void;
  selectedTab?: SelectedIssueTabEnum;
}

interface IState {
  loading: boolean;
  error?: string;
  issues: Map<IIssue['id'] | undefined, IIssue>;
}

export default class IssueDialogContainer extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      error: undefined,
      issues: new Map(),
    };
  }

  fetchIssueByKey = (): void => {
    const { workspaceCode, issueId, projectKey } = this.props;
    const { issues } = this.state;
    this.setState({ loading: true, error: undefined });
    if (issues.has(issueId)) {
      this.setState({ loading: false });
      return;
    } else {
      client
        .query<queries.IGetIssueById>({
          query: queries.getIssueById,
          variables: {
            workspaceCode,
            projectKey,
            issueId,
          },
        })
        .then((result) => {
          this.setState((state) => ({
            loading: false,
            issues: state.issues.set(issueId, result.data.issue),
          }));
        })
        .catch((error: Error) => {
          console.log(error);
          this.setState({ loading: false, error: error.toString() });
        });
    }
  };

  componentDidMount() {
    this.fetchIssueByKey();
  }

  render() {
    const { error, loading, issues } = this.state;
    const { issueId, closeIssueModal, selectedTab } = this.props;
    const selectedIssue = issues.get(issueId);

    if (error) {
      return <div>Erro ao carregar</div>;
    } else if (loading) {
      return <FakeModal closeIssueModal={closeIssueModal} />;
    } else if (selectedIssue) {
      return (
        <IssueModal
          issue={selectedIssue}
          closeIssueModal={closeIssueModal}
          selectedTab={selectedTab}
        />
      );
    }

    return <div />;
  }
}
