import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';
import { styled, effects } from '@cmp/ui';
import { Typography } from '@material-ui/core';
import { paletteColor } from 'modules/common/styles/colors';

interface IProps {
  width?: number;
  height?: number;
  mr?: number;
  mb?: number;
  shouldRenderQuarter?: boolean;
  active?: boolean;
}

const CustomTooltip = styled(({ title, className, ...other }: any) => (
  <Tooltip
    placement="bottom"
    classes={{ popper: className, tooltip: 'tooltip', arrow: 'arrow' }}
    title={title}
    {...other}
  />
))`
  && {
    margin-top: -10px;
    & .arrow {
      color: rgba(23, 24, 31, 0.9);
    }
    & .tooltip {
      background: rgba(23, 24, 31, 0.9);
    }
  }
`;

const TooltipProgress = styled.div`
  display: flex;
  position: relative;
  & .tooltip-content {
    width: 135px;
    padding: 10px;
    position: relative;
    &:nth-child(2):before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      border-left: 1px solid ${paletteColor.dark.medium};
      top: 0;
      left: 0;
    }
    & h5,
    & h6 {
      color: ${paletteColor.primary.white};
      font-size: 12px;
    }
    & h5 {
      font-weight: 500;
      margin-bottom: 3px;
    }
  }
`;

const Fake = styled.div`
  border-radius: 3px;
  width: ${({ width }: IProps) => width}px;
  height: ${({ height }: IProps) => height}px;
  margin-bottom: ${({ mb }: IProps) => mb}px;
  margin-right: ${({ mr }: IProps) => mr}px;
  background-color: ${paletteColor.info.fill};
  background: linear-gradient(
    270deg,
    ${paletteColor.info.fill},
    ${paletteColor.info.background},
    ${paletteColor.info.fill}
  );
  background-size: 200% 100%;
  ${effects.loader};
`;

const BoxCalendarRoot = styled.div`
  display: flex;
  background: ${paletteColor.info.fill};
  border-radius: 6px;
  padding: 14px 0;
  z-index: 100;
  width: 100%;
`;

const BoxCalendar = styled.div`
  display: flex;
  width: calc(100% - 80px);
  & .calendar-scroll {
    overflow: hidden;
    display: flex;
  }
`;

const BoxYear = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 115px;
  padding: 15px;
  margin-right: 8px;
  & h3 {
    color: ${paletteColor.info.text};
    font-size: 24px;
  }
  & h6 {
    font-weight: 500;
    font-size: 12px;
    color: ${paletteColor.info.soft};
  }
`;

const BoxQuarter = styled.div<IProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${paletteColor.primary.white};
  box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
  border-radius: 6px;
  & .content-months {
    display: flex;
    background-color: ${paletteColor.info.fill};
    border-radius: 6px;
    overflow: hidden;
  }
  & .progress-content {
    padding: 8px 16px;
    width: 100%;
    padding-top: 0px;
  }
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const BoxMonth = styled.div<IProps>`
  position: relative;
  min-width: 115px;
  background-color: ${paletteColor.primary.white};
  cursor: pointer;
  & .month-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid ${paletteColor.info.fill};
    & h3 {
      color: ${paletteColor.info.text};
      font-size: 24px;
    }
    & h5 {
      color: ${paletteColor.info.soft};
      font-weight: 500;
      font-size: 12px;
    }
    &:after {
      content: '';
      position: absolute;
      background: ${paletteColor.primary.main};
      width: 0;
      height: 3px;
      border-radius: 5px 5px 0 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: width 250ms;
    }
  }
  & .month-active {
    & .month-content {
      &:after {
        width: 24px;
      }
    }
  }
  & .progress-content {
    padding: 8px 16px;
  }
`;
const MonthActive = styled.div<IProps>`
  content: '';
  position: absolute;
  background: ${paletteColor.primary.main};
  width: ${(props) => (props.active ? '24px' : '0px')};
  height: 3px;
  border-radius: 5px 5px 0 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: width 250ms;
`;

const ProgressContent = styled.div`
  padding: 8px 16px;
  width: 100%;
`;

const Progress = styled.div`
  position: relative;
  width: 100%;
  background: ${paletteColor.info.border};
  border-radius: 3px;
  height: 4px;
  &:before {
    content: ' ';
    position: absolute;
    left: 0px;
    height: 100%;
    background-color: ${({ value }: { value: number }) =>
      value <= 100 ? paletteColor.success.main : paletteColor.primary.main};
    border-radius: 3px;
    transition: width 1.5s;
    width: ${({ value }: { value: number }) => (value > 100 ? 100 : value)}%;
  }
`;

const BoxArrowButton = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  & .arrow-button {
    padding: 0;
    min-width: 24px;
    height: 100%;
    background: ${paletteColor.info.border};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const Capitalize = styled(Typography)`
  && {
    text-transform: capitalize;
  }
`;

const Styled = {
  Fake,
  TooltipProgress,
  BoxCalendarRoot,
  BoxCalendar,
  BoxYear,
  BoxMonth,
  Progress,
  BoxArrowButton,
  CustomTooltip,
  BoxQuarter,
  ProgressContent,
  MonthActive,
  Capitalize,
};

export default Styled;
