export default {
  todo: [
    1,
    10009,
    10264,
    10265,
    10266,
    10267,
    10268,
    10275,
    10301,
    10386,
    10387,
    10461,
  ],
  doing: [3, 10195, 10385],
  blocked: [10006, 10114, 10388],
  review: [1019, 10055, 10193, 10197, 10389],
  done: [10008, 10074, 10269],
};
