import Typography from '@material-ui/core/Typography';
import { ReactComponent as EmptyBoard } from 'modules/common/images/empty_board.svg';
import { timeSpentToString } from 'modules/common/utils/worklog';
import * as React from 'react';
import { FakeCard } from '../components';
import { IBoards, IIssuesPage, ISupportIssueResult } from '../types';
import { priorityColor, translatedName } from '../utils/support';
import InfiniteScroller from './InfiniteScroller';
import S from './styled';

type IProps = {
  board: IBoards;
  loading: boolean;
  issuesPage: IIssuesPage;
  issueModalHandler: (issueId: ISupportIssueResult['id']) => void;
  loadIssuesForBoard: (board: IBoards, page: number) => void;
};

const render = (
  issueModalHandler: (issueId: ISupportIssueResult['id']) => void,
  issuesPage: IIssuesPage
) => {
  if (!issuesPage.count) {
    return <EmptyBoardRow />;
  }
  const issues = issuesPage.issues;
  return issues.map((issue, index) => (
    <S.CardRow
      key={`issue_${issue.issueKey}`}
      className="animation"
      onClick={() => issueModalHandler(issue.id)}
      style={{
        animationDelay: `${((index >= 25 ? index % 25 : index) || 1) * 0.1}s`,
      }}
      data-cy="type-list"
    >
      <div className="content-align">
        <div className="priority-content">
          <div
            className="priority"
            style={{ background: priorityColor(issue.priority) }}
          />
        </div>
        <div className="information-content">
          <div className="type">{translatedName(issue.issuetype.name)}</div>
          <div className="summary">{issue.summary}</div>
        </div>
      </div>
      <div className="pull-right">
        <S.Badge>{issue.issueKey}</S.Badge>
        <S.Badge>
          {timeSpentToString(issue.worklogs.totalTimeSpent, true) || '0h'}
        </S.Badge>
      </div>
    </S.CardRow>
  ));
};

export const EmptyBoardRow = () => (
  <S.EmptyText>
    <div className="image">
      <EmptyBoard />
    </div>
    <Typography align="center" className="title">
      Este quadro está vazio
    </Typography>
  </S.EmptyText>
);

export default function BoardRow({
  issuesPage,
  loading,
  issueModalHandler,
  loadIssuesForBoard,
  board,
}: IProps) {
  if (loading) {
    return <FakeCard count={3} />;
  }

  const ref = React.createRef<HTMLDivElement>();

  return (
    <div ref={ref} className="content">
      <InfiniteScroller
        hasMore={Boolean(
          !loading && issuesPage.currentPage < issuesPage.totalPages
        )}
        loadMore={() => loadIssuesForBoard(board, issuesPage.currentPage + 1)}
        getScrollParent={() => ref.current!}
      >
        {render(issueModalHandler, issuesPage)}
      </InfiniteScroller>
    </div>
  );
}
