import * as React from 'react';
import S from './styled';

export default function FakeLoaderTasks() {
  return (
    <>
      {new Array(7).fill(true).map((value: boolean, index: number) => (
        <S.Fake key={`fake_${index}`} width={620} height={50} mb={10} />
      ))}
    </>
  );
}
