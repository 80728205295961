import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import * as React from 'react';
import { ResourceType } from '../types';
import { ResourceForm } from './';
import * as S from './styled';

interface IProps {
  workspaceCode: string;
  dialogOpen: boolean;
  setReload: (state: boolean) => void;
  handleClose: () => void;
  createResource: (
    workspaceCode: string,
    resourceName: string,
    resourceVersion: string,
    resourceType: string,
    isDependency: boolean
  ) => Promise<any>;
}

const initialResourceType = 'APPLICATION';

const DialogResourceAdd = ({
  workspaceCode,
  setReload,
  dialogOpen,
  handleClose,
  createResource,
}: IProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [resourceName, setResourceName] = React.useState<string>('');
  const [resourceVersion, setResourceVersion] = React.useState<string>('');
  const [isDependency, setIsDependency] = React.useState<boolean>(false);
  const [resourceType, setResourceType] = React.useState<ResourceType>(
    initialResourceType
  );
  const [error, setError] = React.useState<{
    field: string;
    message: string;
  }>({
    field: '',
    message: '',
  });

  const handleCreateResource = async () => {
    if (!resourceName) {
      setError({ field: 'name', message: 'Campo obrigatório' });
    } else if (!resourceVersion) {
      setError({ field: 'version', message: 'Campo obrigatório' });
    } else if (resourceVersion.length > 8) {
      setError({ field: 'version', message: 'Máximo 8 caracteres' });
    } else {
      setLoading(true);
      try {
        await createResource(
          workspaceCode,
          resourceName,
          resourceVersion,
          resourceType,
          isDependency
        );
        setReload(true);
        cleanForm();
        handleClose();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const cleanForm = () => {
    setResourceName('');
    setResourceVersion('');
    setIsDependency(false);
    setResourceType(initialResourceType);
    setError({ field: '', message: '' });
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      <S.DialogTitleCustom>Adicionar novo recurso</S.DialogTitleCustom>
      <S.DialogContentCustom>
        <ResourceForm
          error={error}
          resourceName={resourceName}
          resourceVersion={resourceVersion}
          isDependency={isDependency}
          resourceType={resourceType}
          setResourceName={setResourceName}
          setResourceVersion={setResourceVersion}
          setIsDependency={setIsDependency}
          setResourceType={setResourceType}
        />
      </S.DialogContentCustom>
      <S.DialogActionsCustom>
        <Button color="primary" size="medium" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          data-cy="btn-addResource-modal"
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleCreateResource}
          disabled={loading}
        >
          Adicionar Recurso
        </Button>
      </S.DialogActionsCustom>
    </Dialog>
  );
};

export default DialogResourceAdd;
