import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import * as S from './CalendarStyled';

export default function FakeLoaderCalendar() {
  return (
    <>
      {new Array(12).fill(true).map((_, index) => (
        <S.BoxMonth key={`month_${index}`}>
          <div className="month-content">
            <Typography component="h3">
              <Skeleton width={55} height={42} />
            </Typography>
            <Typography component="h5">
              <Skeleton width={34} height={16} />
            </Typography>
          </div>
        </S.BoxMonth>
      ))}
    </>
  );
}
