import Grid from '@material-ui/core/Grid';
import { IUser, IWorkspace } from 'modules/auth/types';
import { withAppContext } from 'modules/common/hoc/withAppContext';
import Submenu from 'modules/layout/components/Submenu';
import * as React from 'react';
import client from '../../../apolloClient';
import { PreventivesList } from '../components';
import EmptyState from '../components/EmptyState';
import { queries } from '../graphql';
import {
  IPreventive,
  IPreventiveCategory,
  IPreventiveResponse,
} from '../types';
import {
  MCP_CLOUD_COMPLIANCE_JOYRIDE_STEPS,
  MCP_PREVENTIVES_SPEC,
  submenuItens,
} from '../utils/constants';

interface IProps {
  userLogged?: IUser;
  workspace: IWorkspace;
  preventivesList: IPreventive[];
  stepsJoyride?: any[];
  setStepsJoyride: (stepsJoyride: any[]) => void;
}

interface IState {
  wrapperRef: any;
  preventiveCategory: IPreventiveCategory[];
}

class PreventivesContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      preventiveCategory: MCP_PREVENTIVES_SPEC,
      wrapperRef: React.createRef(),
    };
  }

  public async executePreventives(
    workspaceCode: string,
    workspaceName: string,
    project: string,
    preventives: IPreventive[],
    regions?: string[],
    accounts?: string[]
  ): Promise<IPreventiveResponse[]> {
    const { userLogged } = this.props;
    return client
      .mutate({
        mutation: queries.executePreventive,
        variables: {
          cd: userLogged!.cd,
          code: workspaceCode,
          workspaceName: workspaceName,
          project,
          preventives,
          regions,
          accounts,
        },
      })
      .then((response) => response.data.executePreventives)
      .catch((error) => Promise.reject(error.message));
  }

  componentDidMount() {
    const { stepsJoyride, setStepsJoyride } = this.props;

    if (!stepsJoyride || stepsJoyride !== MCP_CLOUD_COMPLIANCE_JOYRIDE_STEPS) {
      setStepsJoyride(MCP_CLOUD_COMPLIANCE_JOYRIDE_STEPS);
    }
  }

  public render() {
    const {
      workspace,
      preventivesList,
      stepsJoyride,
      setStepsJoyride,
    } = this.props;
    const { preventiveCategory } = this.state;
    return (
      <>
        {workspace ? (
          <>
            <Submenu selected="service" items={submenuItens} />
            <Grid container justify="center">
              <Grid xs={10} sm={9} item>
                <PreventivesList
                  preventivesList={preventivesList}
                  workspaceCode={workspace.code}
                  workspaceName={workspace.name}
                  executePreventives={this.executePreventives.bind(this)}
                  preventiveCategory={preventiveCategory}
                  stepsJoyride={stepsJoyride}
                  setStepsJoyride={setStepsJoyride}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}

export default withAppContext(PreventivesContainer);
