import { paletteColor } from 'modules/common/styles/colors';
import { Seconds, Subtask, ISupportIssueResult } from '../types';

const priorityColor = (priority: string) => {
  switch (priority.toLowerCase()) {
    case '1':
    case '2':
    case 'low':
    case 'lowest':
      return paletteColor.success.main;
    case '3':
    case 'medium':
      return paletteColor.warning.main;
    case '4':
    case '5':
    case 'high':
    case 'highest':
      return paletteColor.error.main;
    default:
      return '#999999';
  }
};

const sumIssuesTimeSpent = (issues: ISupportIssueResult[]) => {
  const sumIssueTotalTimeSpent = (total: Seconds, issue: ISupportIssueResult) =>
    total + issue.worklogs.totalTimeSpent;
  const initialValue: Seconds = 0;

  return issues.reduce(sumIssueTotalTimeSpent, initialValue);
};

const filterIssues = (
  issues: (ISupportIssueResult | Subtask)[],
  filterStatus: number[]
) =>
  issues.filter(
    (issue) => issue.status && filterStatus.includes(issue.status.id)
  );

const translatedName = (name: string) => {
  if (name === 'Epic') {
    return 'Épico';
  }
  if (name === 'Task') {
    return 'Tarefa';
  }
  if (name === 'Sub-task') {
    return 'Subtarefa';
  }
  if (name === 'Sustentacao 24x7') {
    return 'Sustentação 24x7';
  }
  if (name === 'Sustentacao  8x5') {
    return 'Sustentação 8x5';
  }
  return name;
};

const translatedStatus = (name: string) => {
  if (name === 'TODO') {
    return 'A Fazer';
  }
  if (name === 'In Progress') {
    return 'Em Progresso';
  }
  if (name === 'Blocked') {
    return 'Bloqueado';
  }
  if (name === 'Review') {
    return 'Revisando';
  }
  if (name === 'Done') {
    return 'Feito';
  }

  return name;
};

export {
  priorityColor,
  filterIssues,
  sumIssuesTimeSpent,
  translatedName,
  translatedStatus,
};
