import { effects, styled, css } from '@cmp/ui';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { Base } from 'modules/common/components/Utils';
import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import { Button } from '@material-ui/core';
import { paletteColor } from 'modules/common/styles/colors';
export interface IStyledProps {
  flexDirection?: string;
  alignItems?: string;
  width?: string | number;
  color?: string;
  marginTop?: string;
  active?: boolean;
  highlightRow?: boolean;
  openChart?: boolean;
  textAlign?: string;
  height?: string;
  status?: boolean;
}

export const ChartTooltip = styled.div`
  background: rgba(23, 24, 31, 0.9);
  border-radius: 3px;
  padding: 10px 15px;
  color: ${paletteColor.primary.white};
`;

export const CustomTooltip = styled(({ title, className, ...other }: any) => (
  <Tooltip
    placement="top"
    classes={{ popper: className, tooltip: 'tooltip' }}
    title={title}
    TransitionComponent={Zoom}
    {...other}
  />
))`
  & .tooltip {
    background: ${paletteColor.info.text};
  }

  & .color-icon {
    color: ${paletteColor.warning.main};
  }
`;

export const TooltipProgress = styled.div`
  display: flex;
  position: relative;
  color: ${paletteColor.primary.white};
  font-size: 16px;
  width: 135px;
  padding: 10px;
`;

export const ActiveLink = styled.span<IStyledProps>`
  position: absolute;
  left: 0;
  width: 3px;
  height: 24px;
  background: ${paletteColor.primary.main};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;

export const AlertRow = styled.div<IStyledProps>`
  ${Base};
  position: relative;
  justify-content: space-between;
  transition: all 200ms;
  cursor: pointer;
  opacity: ${({ highlightRow }: IStyledProps) => (highlightRow ? 1 : 0.5)};

  @media (max-width: 320px) {
    min-width: 268px;
    max-width: 400px;
  }

  @media (min-width: 375px) {
    min-width: 302px;
    max-width: 400px;
  }

  @media (min-width: 425px) {
    min-width: 350px;
    max-width: 400px;
  }

  @media (min-width: 767px) {
    min-width: 200px;
    max-width: 800px;
  }

  & .service-alert-container {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 425px) {
    display: block;
    justify-content: space-between;
    padding: 16px;
  }
`;

export const AlertRowTotal = styled.div<IStyledProps>`
  ${Base};
`;

export const AlertCell = styled.div`
  display: flex;
  letter-spacing: 0.03em;
  color: ${({ color }: IStyledProps) => color || paletteColor.dark.main};
  margin-right: 8px;

  @media (max-width: 425px) {
    text-align: right;
  }
`;

export const AlertCellTotal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.03em;
  width: 100%;
  text-align: right;
  color: ${({ color }: IStyledProps) => color || paletteColor.dark.main};
  & .total-price {
    width: 100%;
    text-align: right;
  }

  & .color-icon {
    color: ${paletteColor.warning.main};
  }
`;

export const DrawerSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: '#3E3F42';

  @media (max-width: 425px) {
    display: inline;
  }

  & .drawer-summary-text {
    font-family: 'Roboto';
    font-size: 14px;
    letter-spacing: 0.03em;
  }
  & .drawer-summary-value {
    color: #e92641;
    display: flex;
    justify-content: space-between;

    @media (max-width: 425px) {
      display: block;
    }
  }
`;

export const Separator = styled.div`
  height: 25px;
  width: 0;
  border: 1px solid ${paletteColor.info.border};
  margin-right: 4px;
`;

export const ApplicationTitle = styled.h2`
  color: ${paletteColor.info.soft};
  font-size: 14px;
`;

export const ApplicationSubTitle = styled.div`
  font-size: 14px;
`;

export const BoardContent = styled.div`
  max-width: 850px;
  width: 100%;
  padding: 20px;

  @media (max-width: 320px) {
    padding: 0px;
  }
`;

export const BoardContainer = styled.div`
  display: flex;
  transition: width 0.5s;
  justify-content: center;
  width: ${({ status }: IStyledProps) =>
    status ? 'calc(100% - 416px)' : '100%'};
`;

export const DetailsDrawer = styled.div<IStyledProps>`
  position: sticky;
  top: 104px;
  max-height: calc(100vh - 104px);
  overflow: auto;
  background-color: ${paletteColor.info.fill};
  transition: 0.5s;
  ${({ status }: IStyledProps) => {
    return status
      ? css`
          padding: 20px 20px;
          width: 416px;
        `
      : css`
          padding: 20px 0;
          width: 0;
        `;
  }}
`;

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const GraphContainer = styled.div`
  min-height: 100%;
  min-width: 100%;
  padding-top: 16px;
  padding-right: 8px;
  background: ${paletteColor.primary.white};
  box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
  border-radius: 6px;
`;
export const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const TitleMobile = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 1024px) {
    padding: 0 12px 0 0;
  }
`;

export const ContentVerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ApplicationDetails = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px 16px;
  align-items: center;
  background: ${paletteColor.info.border};
  border-radius: 6px;
  margin-top: 6px;
  & .service-alert-container {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 425px) {
    display: block;
  }
`;

export const ValueField = styled.div`
  color: #e92641;
  display: flex;
  justify-content: space-between;
`;

export const ReservedTag = styled.div`
  min-height: 24px;
  background: ${paletteColor.info.fill};
  border: 1px solid ${paletteColor.info.border};
  border-radius: 3px;
  padding: 2px;
`;

export const EmptyStateTitle = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${paletteColor.info.soft};
`;

export const EmptyStateParagraph = styled.div`
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.03em;
  color: ${paletteColor.info.soft};
  max-width: 460px;

  & strong,
  & a {
    color: ${paletteColor.primary.main};
    font-weight: 400;
  }
  & a {
    text-decoration: none;
  }
`;

export const FormControlCustom = styled(FormControl)`
  && {
    min-width: 120px;
    max-width: 300px;
  }
`;

export const CustomSelect = styled(Select)`
  && {
    background-color: ${paletteColor.primary.white};
    width: 260px;
    height: 42px;
    border: 1px solid ${paletteColor.info.border};
    border-radius: 3px;
    padding: 5px;
    color: ${paletteColor.info.soft};
    font-size: 14px;
    letter-spacing: 0.03em;
    text-indent: 7px;
    margin-right: 20px;
    margin-bottom: 15px;

    &&:before {
      border-bottom: none;
    }

    & .MuiSelect-select {
      &:focus {
        background-color: transparent;
      }
      &:before {
        content: '';
        position: absolute;
        right: 16px;
        top: 14px;
        box-sizing: border-box;
        height: 8px;
        width: 8px;
        border-style: solid;
        border-color: ${paletteColor.info.soft};
        border-width: 0px 2px 2px 0px;
        transform: rotate(45deg);
      }

      &:after {
        content: '';
        position: absolute;
        border-left: 1px solid ${paletteColor.info.border};
        right: 34px;
        top: 9px;
        height: 24px;
      }

      && .MuiMenu-paper {
        top: 140px !important;
      }
    }

    & .MuiSelect-icon {
      display: none;
    }
    & .Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }

    & .MuiListItem-button:hover {
      background: none;
    }

    @media (max-width: 600px) {
      margin-bottom: 15px;
      margin-right: 0;
      width: 230px;
    }
  }

  &&:after {
    background-color: ${paletteColor.primary.white};
    border-bottom: none;
  }
`;

export const FormControlSpacing = styled.div`
  padding: 24px 0 0 0;
`;

export const CustomMenuItem = styled(MenuItem)`
  && {
    padding: 2px 7px;
  }
`;

export const SpacingAWSSelect = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  height: 400px;
`;

export const ScreenAWSSelectAccout = styled.div`
  ${effects.slideUp};
  color: ${paletteColor.info.soft};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 527px;
  background: ${paletteColor.info.fill};
  border-radius: 10px;

  & .title {
    font-weight: 500;
    margin-top: 20px;
  }
`;

export const CustomChip = styled(Chip)`
  && .MuiChip-label {
    padding-left: 2px;
    text-transform: capitalize;
  }
`;

export const MenuItemAccount = styled(MenuItem)`
  && {
    text-transform: capitalize;
  }
`;

export const AccountSelect = styled.span`
  text-transform: capitalize;
  padding: 0px 16px 0 0;
`;

export const ContainerCloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 0px 8px 0px;
`;

export const CloseButton = styled(Button)`
  && {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: ${paletteColor.primary.main};
    cursor: pointer;
  }
`;

export const TotalBilling = styled.div`
  color: #3e3f42;
  font-size: 14px;
  letter-spacing: 0.03em;
  width: 300px;
  align-items: center;
  display: flex;
  font-weight: 700;
`;

export const CloudAWSMobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 425px) {
    color: rgb(159, 162, 180);
    font-size: 14px;
    letter-spacing: 0.03em;
    width: 140px;
    align-items: center;
    display: flex;
  }
`;

export const CloudSubMenuMobile = styled(CloudAWSMobile)`
  @media (max-width: 425px) {
    color: #000000;
  }
`;

export const CloudRegion = styled(CloudAWSMobile)`
  @media (max-width: 425px) {
    width: 100px;
  }
`;

export const AlignValue = styled.div`
  @media (max-width: 425px) {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px 0 0 0px;
  }
`;

export const DividerLine = styled.div`
  @media (max-width: 425px) {
    border-top: 1px solid rgba(224, 224, 224, 1);
    margin-top: 8px;
  }
`;

export const SubMenuDividerLine = styled(DividerLine)`
  @media (max-width: 425px) {
    border-top: 1px solid rgb(187 187 187);
    margin-bottom: 8px;
  }
`;

export const RegionMobile = styled.div`
  @media (max-width: 425px) {
    display: flex;
    justify-content: space-between;
    text-align: end;
  }
`;

export const AlignTotalPrice = styled.div`
  @media (min-width: 1024px) {
    margin: 0 9px 0 0px;
  }

  @media (max-width: 768px) {
    margin: 0px 0px 80px 0;
  }
`;
