import * as React from 'react';
import ReactGantt from 'gantt-for-react';
import { ganttTask, IIssue } from '../types';
import { compareIssues } from '../utils/sort';
import * as S from './styled';
import Typography from '@material-ui/core/Typography';

interface IProps {
  issues: IIssue[];
  filteredIssues: IIssue[];
}

const EmptyStateGraph = () => (
  <S.EmptyState>
    <Typography className="title">Nenhuma tarefa encontrada</Typography>
  </S.EmptyState>
);

const Graph = ({ filteredIssues }: IProps) => {
  const ganttTasks = filteredIssues.sort(compareIssues).map(mapToGanttTask);

  return (
    <>
      {ganttTasks.length ? ( // do NOT remove the div element below (info: https://github.com/hustcc/gantt-for-react/issues/14)
        <div>
          <ReactGantt
            tasks={ganttTasks}
            viewMode={'Month'}
            language="ptBr"
            interactive={false}
            header_height={38}
            className="gantt"
          />
        </div>
      ) : (
        <EmptyStateGraph />
      )}
    </>
  );
};

const mapToGanttTask = (issue: IIssue): ganttTask => {
  return {
    id: `${issue.id}`,
    name: issue.summary,
    start: issue.startdate ? issue.startdate.split('T')[0] : null,
    end: issue.duedate ? issue.duedate.split('T')[0] : null,
    progress: (issue.progress || 0) * 100,
    dependencies: '',
  };
};

export default Graph;
