import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IIssue, SelectedIssueTabEnum } from '../../types';
import { sanitize } from 'dompurify';
import * as S from './styled';
import theme from 'modules/common/styles/theme';
import IssueTab from './IssueTab';
import IssueInformationBox from './IssueInformationBox';

type IProps = {
  issue: IIssue;
  closeIssueModal: (e: React.MouseEvent) => void;
  selectedTab?: SelectedIssueTabEnum;
};

const IssueModal = ({ issue, closeIssueModal, selectedTab }: IProps) => (
  <S.ModalBox>
    <Grid className="grid-left">
      <S.ModalBoxHeader>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item hidden={useMediaQuery(theme.breakpoints.up('md'))}>
            <IconButton className="btn-close" onClick={closeIssueModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item className="box-title-container">
            <S.BoxTitle>{issue.issueKey}</S.BoxTitle>
          </Grid>
          <Grid item hidden={useMediaQuery(theme.breakpoints.down('sm'))}>
            <S.InformationChipCentered>
              {issue.project}
            </S.InformationChipCentered>
          </Grid>
        </Grid>
      </S.ModalBoxHeader>
      <S.IssueDescriptionWrapper>
        <Grid item hidden={useMediaQuery(theme.breakpoints.up('md'))}>
          <S.Topic>
            <S.InformationChipTableCell>
              {issue.project}
            </S.InformationChipTableCell>
          </S.Topic>
        </Grid>
        <S.Topic>
          <S.TopicHeading>Sumário</S.TopicHeading>
          <S.Typography2>{issue.summary}</S.Typography2>
        </S.Topic>
        <S.Topic>
          <S.TopicHeading>Descrição</S.TopicHeading>
          <S.DangerInsert
            dangerouslySetInnerHTML={{
              __html: sanitize(issue.description),
            }}
          />
        </S.Topic>
      </S.IssueDescriptionWrapper>
    </Grid>
    <Grid className="grid-center">
      <S.TabContainer>
        <IssueTab
          comments={issue.comments}
          worklogs={issue.worklogs.results}
          issue={issue}
          selectedTab={selectedTab}
        />
      </S.TabContainer>
    </Grid>
    <S.GridRight className="grid-right">
      {useMediaQuery(theme.breakpoints.up('md')) && (
        <S.IssuePriorityHeader
          className="bg-inherit"
          priority={issue.priority}
        />
      )}
      {useMediaQuery(theme.breakpoints.up('md')) && (
        <IssueInformationBox issue={issue} />
      )}
    </S.GridRight>
  </S.ModalBox>
);

export default IssueModal;
