import { styled, effects } from '@cmp/ui';
import Typography from '@material-ui/core/Typography';
import { paletteColor } from 'modules/common/styles/colors';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from 'modules/common/styles/theme';

interface IProps {
  width?: string;
  height?: string;
  mr?: string;
  mb?: string;
}

export const Fake = styled.div`
  border-radius: 3px;
  width: ${({ width }: IProps) => width};
  height: ${({ height }: IProps) => height};
  margin-bottom: ${({ mb }: IProps) => mb};
  margin-right: ${({ mr }: IProps) => mr};
  background-color: ${paletteColor.info.fill};
  background: linear-gradient(
    270deg,
    ${paletteColor.info.fill},
    ${paletteColor.info.background},
    ${paletteColor.info.fill}
  );
  background-size: 200% 100%;
  ${effects.loader};
`;

export const CalendarPunctualContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  justify-items: center;
  width: 100%;
  max-width: 1240px;

  ${theme.breakpoints.down('sm')} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const CardCalendarPunctual = styled.div`
  width: 100%;
  max-width: 620px;
  background: ${paletteColor.primary.white};
  border-radius: 3px;
  margin-bottom: 8px;
  padding: 18px 14px;
  box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);

  & .separator-line {
    border-bottom: 1px solid ${paletteColor.info.fill};
  }
`;

export const TypographyCustom = styled(Typography)`
  && {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-align: center;
    color: ${paletteColor.info.text};
  }
`;

export const ProgressContent = styled.div`
  padding: 8px 16px;
  width: 100%;
`;

export const Progress = styled.div`
  position: relative;
  width: 100%;
  background: ${paletteColor.info.border};
  border-radius: 3px;
  height: 4px;
  &:before {
    content: ' ';
    position: absolute;
    left: 0px;
    height: 100%;
    background-color: ${({ value }: { value: number }) =>
      value <= 100 ? paletteColor.success.main : paletteColor.error.main};
    border-radius: 3px;
    transition: width 1.5s;
    width: ${({ value }: { value: number }) => (value > 100 ? 100 : value)}%;
  }
`;

export const CardDescription = styled(CardCalendarPunctual)`
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 0;
`;

export const CardItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
`;

export const Title = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.03em;
    color: ${paletteColor.info.soft};
  }
`;

export const BoxContainer = styled(Typography)`
  && {
    width: 100%;
    max-width: 1240px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

export const Text = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.03em;
    color: ${paletteColor.primary.main};
  }
`;

export const Percentage = styled.span`
  && {
    color: ${paletteColor.info.soft};
    mix-blend-mode: normal;
  }
`;
