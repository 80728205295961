import gql from 'graphql-tag';
import * as React from 'react';
import LogView from '../components/LogView';
import { queries } from '../graphql';
import client from '../../../apolloClient';
import { IWorkspace } from 'modules/auth/types';
import { withAppContext } from 'modules/common/hoc/withAppContext';
import EmptyState from '../components/EmptyState';
import { Submenu } from 'modules/layout/components';
import { submenuItens } from '../utils/constants';
import ButtonReportOurExecution from '../components/ButtonReportOurExecution';

interface IProps {
  workspace?: IWorkspace;
  stepsJoyride?: any[];
  setStepsJoyride: (stepsJoyride: any[]) => void;
}

const getLogs = (
  workspace: string,
  startDate: { day: number; month: number; year: number },
  endDate: { day: number; month: number; year: number },
  text: string,
  pageSize: number,
  nextToken?: string,
  total?: number
) => {
  return client
    .query({
      query: gql(queries.logs),
      variables: {
        workspace,
        startDate,
        endDate,
        text,
        pageSize,
        nextToken,
        total,
      },
      fetchPolicy: 'network-only',
    })
    .then((response) => response.data.getLogs);
};

const getLogsById = (workspace: string, id: string | string[]) => {
  return client
    .query({
      query: gql(queries.logsById),
      variables: {
        workspace,
        id,
      },
      fetchPolicy: 'network-only',
    })
    .then((response) => response.data.getLogsById.data);
};

const LogContainer = ({ workspace, stepsJoyride, setStepsJoyride }: IProps) => {
  const [loading, setLoading] = React.useState<boolean>(true);

  return (
    <>
      <ButtonReportOurExecution selected="history" />
      <Submenu selected="history" items={submenuItens} />
      {workspace ? (
        <>
          <LogView
            workspace={workspace.code}
            loading={loading}
            setLoading={setLoading}
            getLogs={getLogs}
            getLogsById={getLogsById}
            stepsJoyride={stepsJoyride}
            setStepsJoyride={setStepsJoyride}
            workspaceName={workspace.name}
          />
        </>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

export default withAppContext(LogContainer);
