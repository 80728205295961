import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PreventivesContainer } from './containers';
import { StatusContainer } from '../status/containers';
import { LogContainer } from 'modules/logs/containers';
import ReportContainer from 'modules/logs/containers/ReportContainer';

const routes = (props) => (
  <Switch>
    <Route
      path={props.match.path}
      exact={true}
      component={PreventivesContainer}
    />
    <Route
      path={`${props.match.path}/history`}
      exact={true}
      component={LogContainer}
    />
    <Route
      path={`${props.match.path}/report`}
      exact={true}
      component={ReportContainer}
    />
    <Route path="*" component={StatusContainer} />
  </Switch>
);

export default routes;
