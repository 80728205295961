import * as React from 'react';
import S from './styled';
import Typography from '@material-ui/core/Typography';
import { IProject } from '../types';

interface IProps {
  workspace: string;
  projectSelected?: IProject;
  getProjects: (workspace: string) => Promise<IProject[]>;
  setProjectSelected: (projectSelected: IProject | undefined) => void;
}

const SelectProject = ({
  getProjects,
  workspace,
  projectSelected,
  setProjectSelected,
}: IProps) => {
  const [
    anchorSelect,
    setAnchorSelect,
  ] = React.useState<HTMLInputElement | null>();

  const [loading, setLoading] = React.useState(false);

  const [projects, setProjects] = React.useState<IProject[]>([]);

  const handleOpenSelect = (event) => {
    setAnchorSelect(event.target);
  };

  const handleCloseSelect = () => {
    setAnchorSelect(null);
  };

  const handleChangeProject = React.useCallback(async () => {
    handleCloseSelect();
    setLoading(true);
    const projects = await getProjects(workspace);
    if (
      projectSelected &&
      !projects.some((p) => p.key === projectSelected.key)
    ) {
      setProjectSelected(undefined);
    }
    if (!projectSelected && projects.length === 1) {
      setProjectSelected(projects[0]);
    }
    setProjects(projects);
    setLoading(false);
  }, [getProjects, workspace, setProjectSelected, projectSelected]);

  React.useEffect(() => {
    handleChangeProject();
  }, [workspace, handleChangeProject]);

  const onSelectProject = (project: IProject) => {
    setProjectSelected(project);
    handleCloseSelect();
  };

  return (
    <S.FilterContent>
      <div className="sprint-content joyride__sprint-select">
        <S.FilterButton
          aria-owns={anchorSelect ? 'menu-projetos' : undefined}
          aria-haspopup="true"
          onClick={handleOpenSelect}
          data-cy="filter-select"
        >
          {loading ? (
            <S.Fake width={100} height={17} />
          ) : (
            <Typography className="time-spent-title">
              {projectSelected ? projectSelected.key : 'Selecione um Projeto'}
            </Typography>
          )}
        </S.FilterButton>
        <S.MenuSelect
          id="menu-projetos"
          anchorEl={anchorSelect}
          open={Boolean(anchorSelect)}
          onClose={handleCloseSelect}
          getContentAnchorEl={null}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          value={projectSelected ? projectSelected.key : undefined}
        >
          {projects.map((project: IProject) => (
            <S.MenuItemSelect
              key={`projects_${project.key}`}
              onClick={() => onSelectProject(project)}
            >
              {project.key}
            </S.MenuItemSelect>
          ))}
        </S.MenuSelect>
      </div>
    </S.FilterContent>
  );
};

export default SelectProject;
