import * as React from 'react';
import { IWorkspace } from 'modules/auth/types';
import { RowWorkspace } from '../components';
import { IRouterProps } from 'modules/common/types';
import { withAppContext } from 'modules/common/hoc/withAppContext';
import { Redirect } from 'react-router';
import * as S from './styled';

interface IProps extends IRouterProps {
  workspaces: IWorkspace[];
  redirectUrl: string;
  changeWorkspace: (workspaceCode: string) => void;
}

function ListWorkspace({ workspaces, changeWorkspace, redirectUrl }: IProps) {
  if (workspaces) {
    if (workspaces.length > 1) {
      return (
        <S.ListWorkspaces>
          {workspaces.map((workspace) => (
            <RowWorkspace
              key={`workspace_${workspace.code}`}
              code={workspace.code}
              name={workspace.name}
              redirectUrl={redirectUrl}
              changeWorkspace={changeWorkspace}
            />
          ))}
        </S.ListWorkspaces>
      );
    }

    if (workspaces.length === 1) {
      const { code } = workspaces[0];
      changeWorkspace(code);
    } else {
      changeWorkspace('empty');
    }
  }

  return <Redirect to={redirectUrl} />;
}

export default withAppContext(ListWorkspace);
