import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { IIssue } from '../types';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import * as S from './styled';
import { compareIssues } from '../utils/sort';
import FakeTable from './FakeTable';
import { makeStyles } from '@material-ui/core/styles';
import { paletteColor } from 'modules/common/styles/colors';

interface IProps {
  filteredIssues: IIssue[];
  loading: boolean;
}

export default function TableSchedule({ filteredIssues, loading }: IProps) {
  const [tableContent, setTableContent] = React.useState<JSX.Element>();
  const [order, setOrder] = React.useState<string>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const styleRowPerPage = makeStyles({
    paper: {
      padding: '0px 8px',
      boxShadow: '0px 4px 6px rgba(223, 225, 235, 0.5)',
      color: `${paletteColor.info.soft}`,
    },
    menuItem: {
      borderRadius: '3px',
      justifyContent: 'center',
      '&:hover, &:active, &:focus': {
        fontWeight: '500',
        color: `${paletteColor.primary.main}`,
        backgroundColor: 'rgba(242, 101, 34, 0.1)',
        hoverBackgroundColor: 'rgba(242, 101, 34, 0.1)',
        '&:hover': {
          backgroundColor: 'rgba(242, 101, 34, 0.1)',
        },
      },
    },
  });

  const classes = styleRowPerPage();

  const handleChangePage = (event, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDuration = (startdate, duedate) =>
    startdate && duedate
      ? moment.duration(moment(duedate).diff(moment(startdate))).asDays()
      : 0;

  const orderDuration = () => {
    if (order === 'durationUp') {
      setOrder('durationDown');
    } else {
      setOrder('durationUp');
    }
    renderTableContent(
      filteredIssues.sort((i1, i2) => {
        const diff =
          getDuration(i1.startdate, i1.duedate) -
          getDuration(i2.startdate, i2.duedate);
        if (diff > 0) {
          return order === 'durationUp' ? 1 : -1;
        } else if (diff < 0) {
          return order === 'durationUp' ? -1 : 1;
        } else {
          return 0;
        }
      })
    );
  };

  const orderStartDate = () => {
    if (order === 'startDateUp') {
      setOrder('startDateDown');
    } else {
      setOrder('startDateUp');
    }
    renderTableContent(
      filteredIssues.sort((i1, i2) => {
        const d1 = new Date(i1.startdate!).getTime();
        const d2 = new Date(i2.startdate!).getTime();
        const diff = d1 - d2;
        if (diff > 0) {
          return order === 'startDateUp' ? 1 : -1;
        } else if (diff < 0) {
          return order === 'startDateUp' ? -1 : 1;
        } else {
          return 0;
        }
      })
    );
  };

  const orderDueDate = () => {
    if (order === 'dueDateUp') {
      setOrder('dueDateDown');
    } else {
      setOrder('dueDateUp');
    }
    renderTableContent(
      filteredIssues.sort((i1, i2) => {
        const d1 = new Date(i1.duedate!).getTime();
        const d2 = new Date(i2.duedate!).getTime();
        const diff = d1 - d2;
        if (diff > 0) {
          return order === 'dueDateUp' ? 1 : -1;
        } else if (diff < 0) {
          return order === 'dueDateUp' ? -1 : 1;
        } else {
          return 0;
        }
      })
    );
  };

  const renderTableContent = React.useCallback(
    (issues) =>
      setTableContent(
        <TableBody>
          {issues
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((issue) => (
              <>
                <TableRow key={issue.key}>
                  <S.ScheduleTableCell component="th" scope="row">
                    {issue.summary}
                  </S.ScheduleTableCell>
                  <S.ScheduleTableCell align="left">
                    {issue.startdate && issue.duedate ? (
                      <S.TableText className="duration">
                        {getDuration(issue.startdate, issue.duedate).toFixed(
                          0
                        ) + ' dias'}
                      </S.TableText>
                    ) : (
                      <S.UndefinedDate className="undefined">
                        não definido
                      </S.UndefinedDate>
                    )}
                  </S.ScheduleTableCell>
                  <S.ScheduleTableCell align="left">
                    {issue.startdate ? (
                      <S.TableText className="defined">
                        {moment(issue.startdate, 'YYYY-MM-DD').format(
                          'DD/MM/YYYY'
                        )}
                      </S.TableText>
                    ) : (
                      <S.UndefinedDate className="undefined">
                        não definido
                      </S.UndefinedDate>
                    )}
                  </S.ScheduleTableCell>
                  <S.ScheduleTableCell align="left">
                    {issue.duedate ? (
                      <S.TableText className="defined">
                        {moment(issue.duedate, 'YYYY-MM-DD').format(
                          'DD/MM/YYYY'
                        )}
                      </S.TableText>
                    ) : (
                      <S.UndefinedDate className="undefined">
                        não definido
                      </S.UndefinedDate>
                    )}
                  </S.ScheduleTableCell>
                </TableRow>

                <S.TableMobile>
                  <Grid xs={12} container>
                    <S.AlertCustom key={issue.key}>
                      {issue.summary}
                    </S.AlertCustom>
                  </Grid>
                  <div className="card-items">
                    <Grid xs={12} container className="items">
                      <Grid xs={6}>
                        <S.TypographyCustom>Duração</S.TypographyCustom>
                      </Grid>
                      <Grid container xs={6} justify="flex-end">
                        <S.TypographyDate>
                          {issue.startdate && issue.duedate ? (
                            <S.TableText className="duration">
                              {getDuration(
                                issue.startdate,
                                issue.duedate
                              ).toFixed(0) + ' dias'}
                            </S.TableText>
                          ) : (
                            <S.UndefinedDate className="undefined">
                              não definido
                            </S.UndefinedDate>
                          )}
                        </S.TypographyDate>
                      </Grid>
                    </Grid>
                    <Grid xs={12} container className="items">
                      <Grid xs={6}>
                        <S.TypographyCustom>Data Início</S.TypographyCustom>
                      </Grid>
                      <Grid container xs={6} justify="flex-end">
                        <S.TypographyDate>
                          {issue.startdate ? (
                            <S.TableText className="defined">
                              {moment(issue.startdate, 'YYYY-MM-DD').format(
                                'DD/MM/YYYY'
                              )}
                            </S.TableText>
                          ) : (
                            <S.UndefinedDate className="undefined">
                              não definido
                            </S.UndefinedDate>
                          )}
                        </S.TypographyDate>
                      </Grid>
                    </Grid>
                    <Grid xs={12} container className="items">
                      <Grid xs={6}>
                        <S.TypographyCustom>Data Fim</S.TypographyCustom>
                      </Grid>
                      <Grid container xs={6} justify="flex-end">
                        <S.TypographyDate>
                          {issue.duedate ? (
                            <S.TableText className="defined">
                              {moment(issue.duedate, 'YYYY-MM-DD').format(
                                'DD/MM/YYYY'
                              )}
                            </S.TableText>
                          ) : (
                            <S.UndefinedDate className="undefined">
                              não definido
                            </S.UndefinedDate>
                          )}
                        </S.TypographyDate>
                      </Grid>
                    </Grid>
                  </div>
                </S.TableMobile>
              </>
            ))}
        </TableBody>
      ),
    [page, rowsPerPage]
  );

  React.useEffect(() => {
    filteredIssues.sort(compareIssues);
    renderTableContent(filteredIssues);
  }, [filteredIssues, loading, renderTableContent]);

  return !loading ? (
    !filteredIssues.length ? (
      <S.EmptyState>
        <Typography className="title">Nenhuma tarefa encontrada</Typography>
      </S.EmptyState>
    ) : (
      <>
        <S.BoxContainer>
          <div className="item-mobile">
            <S.AlertRow className="card-action">
              <Grid xs={12} item container>
                <S.AlertCell className="title-action">Épicos</S.AlertCell>
              </Grid>
            </S.AlertRow>
          </div>
          <S.TableContainerCustom>
            <Table className="cronograma">
              <S.ScheduleHead>
                <TableRow>
                  <S.ScheduleHeaderCellName>Nome</S.ScheduleHeaderCellName>
                  <S.ScheduleHeaderCell align="left">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={orderDuration}
                    >
                      {order === 'durationDown' ? (
                        <S.ArrowUpIcon className="arrow" />
                      ) : (
                        <S.ArrowDownIcon className="arrow" />
                      )}
                    </IconButton>
                    Duração
                  </S.ScheduleHeaderCell>
                  <S.ScheduleHeaderCell align="left">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={orderStartDate}
                    >
                      {order === 'startDateDown' ? (
                        <S.ArrowUpIcon className="arrow" />
                      ) : (
                        <S.ArrowDownIcon className="arrow" />
                      )}
                    </IconButton>
                    Início
                  </S.ScheduleHeaderCell>
                  <S.ScheduleHeaderCell align="left">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={orderDueDate}
                    >
                      {order === 'dueDateDown' ? (
                        <S.ArrowUpIcon className="arrow" />
                      ) : (
                        <S.ArrowDownIcon className="arrow" />
                      )}
                    </IconButton>
                    Fim
                  </S.ScheduleHeaderCell>
                </TableRow>
              </S.ScheduleHead>
              {tableContent}
            </Table>
          </S.TableContainerCustom>
          <S.Pagination
            rowsPerPageOptions={[5, 10, 25]}
            count={filteredIssues.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to, count }) =>
              `Mostrando ${from}-${to} de ${count} itens`
            }
            component="div"
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: classes.paper,
                },
              },
            }}
            classes={{ menuItem: classes.menuItem }}
          />
        </S.BoxContainer>
      </>
    )
  ) : (
    <>
      <S.FakeMargin />
      <FakeTable count={5} />
    </>
  );
}
