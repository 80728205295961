import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import client from 'apolloClient';
import React from 'react';
import { Link } from 'react-router-dom';
import { queries } from '../graphql';
import { IPreventiveResponse } from '../types';
import S from './styled';

interface IProps {
  loading: boolean;
  dialogStats: boolean;
  handleDialogClose: () => void;
  preventivesResponse: IPreventiveResponse[];
  workspaceCode: string;
  workspaceName: string;
  projectKey: string | undefined;
}

export default function PreventiveDialog({
  dialogStats,
  handleDialogClose,
  loading,
  preventivesResponse,
  workspaceCode,
  workspaceName,
  projectKey,
}: IProps) {
  const [report, setReport] = React.useState<string | null>('');

  const generateReport = async (
    workspaceCode: string,
    workspaceName: string,
    preventives: IPreventiveResponse[],
    projectKey: string | undefined
  ): Promise<string> => {
    return client
      .query({
        query: queries.generateReport,
        variables: {
          code: workspaceCode,
          data: JSON.stringify({
            preventives: preventives.map(({ log }) => log),
            workspaceName,
          }),
          type: 'preventives',
          jiraProject: projectKey,
        },
        fetchPolicy: 'network-only',
      })
      .then((response) => response.data.generateReport.data)
      .catch((error) => Promise.reject(error.message));
  };

  React.useEffect(() => {
    if (preventivesResponse.length) {
      generateReport(
        workspaceCode,
        workspaceName,
        preventivesResponse,
        projectKey
      ).then((rprt) => setReport(rprt));
    }
  }, [workspaceCode, workspaceName, preventivesResponse, projectKey]);

  const handleResult = () => {
    const pdfTab = window.open('about:blank', '_blank');
    if (pdfTab) {
      pdfTab.document.write(
        `<iframe width='100%' height='100%' src='${report}' frameBorder="0"></iframe>`
      );
      pdfTab.document.title = 'Resultados';
      pdfTab.focus();
    }
  };

  const handleClose = () => {
    setReport('');
    handleDialogClose();
  };

  const reportResultButton = React.useCallback(() => {
    if (report === '') {
      return <S.ButtonProgress size={20} />;
    }

    if (report === null) {
      return 'Erro de Execução';
    }

    return 'Resultados';
  }, [report]);

  return (
    <S.DialogCustom open={dialogStats}>
      <div className="dialogSuccess">
        <Icon className="icon-success">check</Icon>
      </div>
      <DialogTitle className="title-success">
        Serviços executados com sucesso!
      </DialogTitle>
      <DialogContent>
        <Typography component="p">
          Para acessar e fazer download das análises, aguarde o carregamento e
          clique abaixo em {`"Resultados"`}.
        </Typography>
        <Typography component="p">
          As análises e relatórios gerados também podem ser acessados na página
          de
          <Link to="/preventives/history" className="link-history">
            Histórico
          </Link>
          .
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Fechar
        </Button>
        <S.ButtonResult
          onClick={handleResult}
          color="primary"
          variant="contained"
          disabled={report === null || report === ''}
        >
          {reportResultButton()}
        </S.ButtonResult>
      </DialogActions>
    </S.DialogCustom>
  );
}
