import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { styled } from '@cmp/ui';
import { paletteColor } from '../styles/colors';

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 100%;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const GridItem = styled.div`
  flex-direction: row;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    max-width: 100%;
  }
`;

const TextContainer = styled.div`
  width: 345px;
  height: 415px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & .emptystate-text-container__btn-area {
    display: flex;
    flex-direction: row;
    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
`;

const ImageContainer = styled.div`
  width: 647px;
  height: 415px;
`;

const Title = styled(Typography)`
  && {
    font-weight: 300;
    font-size: 24px;
    color: ${paletteColor.info.text};
    font-weight: bold;
  }
`;

const Subtitle = styled(Typography)`
  && {
    color: ${paletteColor.info.soft};
    max-width: 380px;
    margin-top: 20px;
  }
`;

interface IButtonCustomProps extends ButtonProps {
  lastChild?: string;
}

const ButtonCustom = styled(({ lastChild, ...others }: IButtonCustomProps) => (
  <Button {...others} />
))`
  && {
    font-size: 14px;
    border-radius: 3px;
    padding: 12px 25px;
    width: 160px;
    text-align: center;
    letter-spacing: 0.03em;
    margin-top: 25px;
    margin-left: ${(props: IButtonCustomProps) =>
      props.lastChild ? '20px' : '0px'};
  }

  @media only screen and (max-width: 600px) {
    && {
      margin-left: 0px;
    }
  }
`;

const StyledImage = styled.div`
  width: 523px;
  height: 330px;
  position: relative;
  z-index: 5;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(99, 101, 115, 0.1);
  background-image: url(${(props) => props.image});
  top: -430px;
  background-size: cover;
  margin: 0 auto;
`;

const OrangeForm = styled.div`
  width: 558px;
  height: 233px;
  position: relative;
  border-radius: 40px 40px 40px 40px;
  transform: skew(-10deg);
  background: linear-gradient(
    66.34deg,
    ${paletteColor.primary.main} 3.55%,
    ${paletteColor.secondary.main} 198.18%
  );
  top: -54px;
  margin: 0 auto;
`;

const GreyForm = styled.div`
  width: 578px;
  height: 239px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  border-radius: 40px 40px 40px 40px;
  transform: perspective(551px) rotateX(-15deg);
  background-color: ${paletteColor.info.fill};
`;

interface IProps {
  title: string;
  description: string;
  image: string;
  readMoreURL: string;
  hireURL: string;
}

const EmptyState = ({
  title,
  description,
  image,
  readMoreURL,
  hireURL,
}: IProps) => (
  <GridContainer>
    <GridItem>
      <TextContainer id="emptystate-text-container">
        <Title>{title}</Title>
        <Subtitle>{description}</Subtitle>
        <div className="emptystate-text-container__btn-area">
          <ButtonCustom
            color="primary"
            variant="contained"
            href={hireURL}
            target="_blank"
            className="btnHire"
          >
            Contratar
          </ButtonCustom>
          {process.env.NODE_ENV === 'development' && (
            <ButtonCustom
              color="primary"
              variant="outlined"
              href={readMoreURL}
              target="_blank"
              lastChild
            >
              Saiba Mais
            </ButtonCustom>
          )}
        </div>
      </TextContainer>
    </GridItem>
    <GridItem>
      <ImageContainer id="emptystate-image-container">
        <GreyForm />
        <OrangeForm />
        <StyledImage
          image={image}
          alt={`Imagem ilustrativa da tela de ${title}.`}
        />
      </ImageContainer>
    </GridItem>
  </GridContainer>
);

export default EmptyState;
