import moment from 'moment';

export interface IBoards {
  columnTitle: string;
  filterStatus: number[];
  description: string;
}

export interface IIssue {
  id: number;
  issueKey: string;
  priority: string;
  summary: string;
  description: string;
  sprint: string;
  project: string;
  assignee: IAuthor;
  issuetype: IIssueType;
  status: IIssueStatus;
  worklogs: IIssueWorklog;
  comments: IIssueComment[];
  epicIssue?: Pick<IIssue, 'id' | 'issueKey' | 'summary'>;
  subtasks?: Subtask[];
}

export type Subtask = Pick<
  IIssue,
  | 'id'
  | 'issueKey'
  | 'summary'
  | 'status'
  | 'priority'
  | 'worklogs'
  | 'issuetype'
>;

export type Seconds = number;

export interface IWorklogIssue {
  id: number;
  key: string;
  summary: string;
  worklogs: IIssueWorklog;
}

export interface IIssueWorklog {
  results: IWorklog[];
  totalTimeSpent: Seconds;
}

export interface IIssueComment {
  id: string;
  body: string;
  created: string;
  author: IAuthor;
}

export interface IAuthor {
  displayName: string;
}

export interface IIssueType {
  name: string;
}

export interface IIssueStatus {
  id: number;
  name: string;
}

export interface ISprint {
  id: number;
  name: string;
  state: string;
  startDate: string;
  endDate: string;
}

export interface IWorklog {
  id: number;
  timeSpentSeconds: Seconds;
  started: string;
  comment: string;
  author: IAuthor;
}

export enum IssueType {
  epic = 'epic',
  task = 'task',
}

export interface IIssuesFilter {
  workspaceCode: string;
  projectKey: string;
  issueType: IssueType;
  startDate: string;
  endDate: string;
  status?: number[];
  page?: number;
}

export interface IIssuesPage {
  issues: (ISupportIssueResult | Subtask)[];
  totalPages: number;
  currentPage: number;
  count: number;
}

export interface IIssuesByBoard {
  [key: string]: IIssuesPage;
}

export interface IPropsView {
  loading: boolean;
  issuesByBoard: IIssuesByBoard;
  loadIssuesForBoard: (board: IBoards, page: number) => void;
  startDate: moment.Moment;
  endDate: moment.Moment;
  openIssueModal: (issueId: ISupportIssueResult['id']) => void;
  issuesTotalTime: number;
}

export interface IPageResults<T> {
  count: number;
  totalPages: number;
  results: Array<T>;
}

export interface ISupportSprintResult {
  id: number;
  name: string;
  state: string;
  endDate: string;
  startDate: string;
}

export interface ISupportIssueResult
  extends Pick<
    IIssue,
    'id' | 'issueKey' | 'summary' | 'priority' | 'worklogs' | 'subtasks'
  > {
  issuetype: Pick<IIssueType, 'name'>;
  status: IIssueStatus;
}

export interface IGetSprints {
  sprints: IPageResults<ISupportSprintResult>;
}

export interface IGetBacklogIssues {
  backlogIssues: IPageResults<ISupportIssueResult>;
}

export interface IGetSprintIssues {
  issues: IPageResults<ISupportIssueResult>;
}

export interface IGetIssues {
  issues: IPageResults<ISupportIssueResult>;
}

export interface IGetIssuesTotalTime {
  issuesTotalTime: number;
}

export interface ILocation {
  hash?: string;
  pathname: string;
  search?: string;
  state?: string;
}

export enum SelectedIssueTabEnum {
  GERAL = 'Geral',
  COMMENT = 'Comentários',
  WORK = 'Registro de trabalho',
  SURVEY = 'Avaliação',
}
