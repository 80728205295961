import { styled } from '@cmp/ui';
import Button from '@material-ui/core/Button';
import { paletteColor } from 'modules/common/styles/colors';

export const Root = styled.div`
  margin: 32px;
  & span {
    color: ${paletteColor.primary.main};
  }
`;

export const ButtonHome = styled(Button)`
  & {
    font-size: 12px;
    width: 300px;
    top: 40px;
    & span {
      color: ${paletteColor.primary.white};
    }
  }
`;
