import { effects, css, styled } from '@cmp/ui';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { paletteColor } from 'modules/common/styles/colors';
import theme from 'modules/common/styles/theme';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom';

interface IStyledProps {
  active?: boolean;
  selected?: boolean;
  color?: string;
  buttonBorder?: boolean;
  width?: string | number;
  height?: string | number;
  mb?: string | number;
}

export const Root = styled.div`
  flex-grow: 1;
  padding: 0 30px 30px 30px;

  @media (max-width: 767px) {
    padding: 20px 0;
  }
`;

export const Container = styled.div`
  width: 75%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  & .align-card {
    margin-bottom: 16px;
  }

  & .title-card {
    padding-left: 15px;
  }

  & .align-text {
    padding-right: 15px;
  }

  @media (max-width: 425px) {
    width: 100%;

    & .item-desktop {
      display: none;
    }

    & .title-card {
      background: ${paletteColor.primary.main};
      color: white;
      padding: 18px 8px;
      border-radius: 6px;
    }
  }
`;

export const GridTitle = styled.div`
  padding: 0 20px;
`;

export const AlertCell = styled.div`
  display: flex;
  letter-spacing: 0.03em;
  color: ${(props: IStyledProps) =>
    props.color ? props.color : paletteColor.dark.main};

  @media (max-width: 425px) {
    &.title-action {
      font-weight: 600;
      padding: 5px 0 20px 0;
      width: 100%;
    }
  }
`;

export const TypographyCustom = styled(Typography)`
  && {
    color: ${paletteColor.info.soft};
    font-size: 14px;
    letter-spacing: 0.03em;
  }
`;

export const AlertRow = styled(Grid)<IStyledProps>`
  background: ${paletteColor.primary.white};
  box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
  border-radius: 6px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  position: relative;
  transition: all 200ms;
  height: 56px;

  &:before {
    content: '';
    position: absolute;
    background: ${paletteColor.primary.main};
    width: 3px;
    height: 0;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0 5px 5px 0;
    transition: height 250ms;
  }
  &:hover {
    &:before {
      height: 24px;
    }
  }
  & .item-mobile {
    display: none;
  }

  @media (max-width: 425px) {
    height: 160px;
    display: block;

    & .item-mobile {
      display: block;
    }

    & .items-space {
      padding: 10px 0;
      border-top: 1px solid ${paletteColor.info.border};
    }
  }
`;

export const ButtonSearch = styled(Button)`
  && {
    background: ${paletteColor.info.background};
    color: ${paletteColor.primary.main};
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.03em;
    padding: 6px 8px;
    border-radius: 3px;
    width: 60px;
    height: 32px;
    margin-left: -70px;
    bottom: 2px;
    &:hover {
      background: ${paletteColor.info.fill};
    }
  }
`;

export const TypographyDate = styled(Typography)`
  background: ${paletteColor.info.fill};
  color: ${paletteColor.info.soft};
  font-size: 12px;
  font-weight: 500;
  padding: 6px 8px;
  border-radius: 3px;
`;

export const BoxContainer = styled.div`
  background: ${paletteColor.info.fill};
  border-radius: 10px;
  padding: 20px;
  margin: 24px 0;

  & .align-card {
    max-width: 320px;
  }

  & .box-wrapper {
    margin-bottom: 24px;
  }

  & .align-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  ${theme.breakpoints.up('md')} {
    & .align-card {
      width: 100%;
      min-width: 320px;
    }
  }

  ${theme.breakpoints.down('sm')} {
    && {
      & .MuiTable-root,
      .MuiTableHead-root,
      .MuiTableBody-root {
        display: block;
      }

      & .MuiTableContainer-root {
        overflow-x: unset;
      }

      & .align-card {
        width: 100%;
        min-width: 248px;
        max-width: 425px;
      }
    }
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;

  & .text-padding {
    padding: 0 12px;
  }
  ${theme.breakpoints.down('sm')} {
    margin: 16px 0;
    & .text-padding:first-child {
      padding-left: 0;
    }
  }

  @media (max-width: 375px) {
    display: grid;
    justify-content: space-between;
    width: 100%;
    grid-template-columns: 1fr 3fr;
    grid-row-gap: 5px;

    & .text-padding {
      padding-left: 0;
    }
  }
`;

export const TableHeader = styled(TableHead)`
  && {
    background: ${paletteColor.primary.main};
    border-radius: 6px 6px 0px 0px;

    ${theme.breakpoints.down('sm')} {
      && {
        width: 100%;
        border-radius: 6px;

        & .MuiTableRow-root {
          display: block;
        }
        & .MuiTableCell-root {
          border: none;
        }
      }
    }
  }

  & th {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    height: 60px;
  }

  & th:first-child {
    border-top-left-radius: 6px;
    padding-left: 64px;
    ${theme.breakpoints.down('sm')} {
      padding-left: 16px;
    }
  }
  & th:last-child {
    border-top-right-radius: 6px;
  }

  & .MuiTableCell-paddingCheckbox {
    max-width: 60px;
  }
`;

export const TableBodyCustom = styled(TableBody)`
  && {
    & .MuiTableCell-root {
      font-size: 14px;
      padding: 16px 0;
    }

    ${theme.breakpoints.up('md')} {
      & .MuiTableCell-root {
        padding: 16px;
      }

      & .MuiTableCell-root:first-child {
        padding-left: 64px;
      }
    }
  }
`;

export const CheckboxCustom = styled(Checkbox)`
  && {
    color: ${paletteColor.primary.white};
  }

  & th {
    min-width: 30px;
  }
`;

export const TableCellTitle = styled(TableCell)`
  && {
    color: ${paletteColor.primary.white};
  }
`;

export const TableRowContent = styled(TableRow)`
  && {
    background: ${paletteColor.primary.white};
  }
`;

export const TableFooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: flex-start;

    & .MuiToolbar-gutters {
      padding: 0;
    }
    & .MuiTablePagination-toolbar {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
    & .MuiTablePagination-actions {
      margin-left: 0;
    }
    & .MuiIconButton-root {
      padding: 8px;
    }
    & .MuiTablePagination-caption {
      grid-column-start: 1;
    }
    & .MuiTablePagination-select,
    .MuiTablePagination-selectRoot {
      width: 48px;
    }

    .MuiTablePagination-selectRoot {
      margin-left: 14px;
    }

    & .text-mobile {
      margin-bottom: 10px;
    }
  }

  & .MuiTablePagination-selectRoot {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: 10px;
      top: 10px;
      width: 8px;
      height: 8px;
      border-style: solid;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      border-color: ${paletteColor.info.soft};
    }
  }

  & .MuiTablePagination-selectIcon {
    color: transparent;
  }
`;

export const Pagination = styled(TablePagination)`
  && {
    border-bottom: none;
    color: ${paletteColor.info.soft};
    font-size: 12px;
    letter-spacing: 0.03em;
  }

  && .MuiTypography-body2 {
    font-weight: 500;
  }

  && .MuiSelect-root,
  .MuiSelect-select {
    color: transparent;
  }

  & .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: red;
  }

  && .MuiTablePagination-select {
    text-align: center;
    padding-left: 0;
    padding-right: 7px;
    text-align-last: center;
    background: transparent;
    z-index: 9;
  }
`;

export const DialogExecute = styled(Dialog)`
  & .MuiPaper-elevation24 {
    box-shadow: none;
    margin: 1px;
    width: 100%;
  }

  & .alignTooltip {
    display: flex;
    align-items: center;
  }

  & ul {
    padding-left: 18px;
    line-height: 2;
  }

  & .MuiButton-label {
    text-transform: none;
  }
`;

export const DialogActionsPreventive = styled(DialogActions)`
  && {
    padding: 18px 12px;

    & .btnExecute {
      min-height: 44px;
      max-height: 44px;
    }
  }
`;

export const DialogTitleCustom = styled(DialogTitle)`
  && {
    background: ${paletteColor.primary.main};
    color: white;

    & .MuiTypography-h6 {
      font-size: 16px;
    }
  }
`;

export const TableControlCheckbox = styled.div`
  display: block;
  width: 120px;
  color: ${paletteColor.primary.white};
`;

export const PaperCustom = styled(Paper)`
  && {
    display: flex;
    flex-direction: column;
    padding: 8px;
    box-shadow: 0px 4px 6px rgba(223, 225, 235, 0.5);
    border-radius: 3px;
  }
`;

export const ButtonContent = styled(MenuItem)`
  && {
    color: ${paletteColor.info.soft};

    &:hover,
    &:active,
    &:focus {
      background: rgba(242, 101, 34, 0.1);
      color: ${paletteColor.primary.main};
      font-weight: 500;
    }
  }
`;

export const AnalysisText = styled(Typography)`
  && {
    letter-spacing: 0.03em;
    margin-top: 16px;
  }
`;

export const BoldText = styled(Typography)`
  && {
    font-weight: 700;
    margin-right: 5px;
  }
`;

export const AnalysisTextFlex = styled(AnalysisText)`
  && {
    display: flex;
  }
`;

export const SmallButton = styled(Button)`
  && {
    width: 80px;
    height: 24px;
    max-height: 24px;
    min-height: 24px;
    line-height: 10px;
    border: none;
  }
`;

export const mediumButton = styled(SmallButton)`
  && {
    width: 120px;
  }
`;

export const ButtonReport = styled(Button)`
  && {
    width: 100%;
    min-width: 176px;
    max-width: 176px;
    height: 42px;
    border-radius: 3px;
    padding: 5px;
    font-size: 14px;
    letter-spacing: 0.03em;
    white-space: nowrap;

    ${theme.breakpoints.down('sm')} {
      max-width: 100%;
      margin-top: 0;
    }
  }
`;

export const ButtonSelectContainer = styled(Button)`
  && {
    color: transparent;
    min-width: 10px;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      background: transparent;
    }
  }

  & .icon-color {
    color: ${paletteColor.primary.white};
  }
`;

export const LinkOpenModal = styled(Button)`
  && {
    color: ${paletteColor.primary.main};
    height: 0;
    padding: 0;
    cursor: pointer;
    min-width: 50px;
    &:hover {
      text-decoration: none;
      background: none;
    }
  }
`;

export const Card = styled.div`
  &:hover {
    cursor: pointer;
  }
  &.animation {
    ${effects.slideUp};
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  & a {
    text-decoration: none;
  }
  & .content {
    padding: 10px;
    background: ${paletteColor.primary.white};
    box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
    border-radius: 6px;
  }
  & .title-content {
    position: relative;
    margin-bottom: 4px;
    & span {
      content: ' ';
      position: absolute;
      width: 3px;
      height: 18px;
      left: -10px;
      border-radius: 0px 3px 3px 0px;
      z-index: 0;
    }
    & .title {
      font-weight: 500;
      font-size: 12px;
      color: ${paletteColor.info.soft};
    }
  }
  & .description {
    font-size: 14px;
    color: ${paletteColor.info.text};
    word-break: break-word;
  }
`;

export const FakeCardMobile = styled.div`
  background-color: ${paletteColor.primary.white};
  height: auto;
  width: auto;
  overflow: hidden;
  margin: 16px 0;
  border-radius: 5px;
  padding: 10px;
`;

export const Fake = styled.div`
  border-radius: 3px;
  width: ${(props: IStyledProps) => props.width}px;
  height: ${(props: IStyledProps) => props.height}px;
  margin-bottom: ${(props: IStyledProps) => props.mb}px;
  ${effects.loader};
  background-color: ${paletteColor.info.fill};
  background: linear-gradient(
    270deg,
    ${paletteColor.info.fill},
    ${paletteColor.info.background},
    ${paletteColor.info.fill}
  );
  background-size: 200% 100%;
`;

export const AnalysisFake = styled(Typography)`
  && {
    letter-spacing: 0.03em;
    margin-top: 16px;
  }
`;

export const TableCellTooltip = styled.div`
  && {
    display: flex;
    align-items: center;
  }

  & .MuiButton-label {
    justify-content: flex-start;
    ${theme.breakpoints.down('sm')} {
      justify-content: flex-end;
    }
  }
`;

export const Text = styled(TypographyCustom)`
  && {
    color: ${paletteColor.info.text};

    &:first-child {
      padding-left: 0px;
    }
  }
`;

export const TableRowContentMobile = styled(TableRowContent)`
  && {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    width: 100%;
    border-radius: 6px;
    ${theme.breakpoints.down('sm')} {
      padding: 0 16px;
    }
  }
`;

export const TableCellMobile = styled(TableCell)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      &.MuiTableCell-root:last-child {
        border-bottom: none;
      }

      & .text-margin {
        margin-right: 5px;
      }
    }
  }
`;

export const TableCellCheckbox = styled(TableCellMobile)`
  && {
    justify-content: flex-start;
    padding: 9px 0;
  }
`;

export const EmptyStateText = styled(Typography)`
  && {
    font-size: 16px;
    color: ${paletteColor.info.soft};
    letter-spacing: 0.03em;
  }
`;

export const PageMenu = styled.div`
  display: flex;
  align-items: center;
  padding: 0 30px;
  margin: 77px 0 0 0;
`;

export const FabCustom = styled(Fab)`
  && {
    width: auto;
    height: 34px;
    border-radius: 25px;
    box-shadow: none;
    background: ${paletteColor.info.fill};
    text-transform: capitalize;
    margin-right: 10px;

    &:hover {
      background: ${paletteColor.info.fill};
      color: ${paletteColor.info.soft};
    }
  }
`;

export const PageMenuItem = styled(Link)`
  && {
    position: relative;
    border: 0;
    display: flex;
    color: ${paletteColor.info.soft};
    width: 85px;
    height: 34px;
    text-decoration: none;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    font-size: 12px;
    letter-spacing: 0.03em;
    font-family: Roboto;
    border-radius: 16px;

    ${(props: IStyledProps) =>
      props.selected &&
      css`
        color: ${paletteColor.primary.white};
        background: ${paletteColor.primary.main};
        border-radius: 25px;
        display: flex;

        &:after {
          width: 72%;
          left: -22%;
        }
      `}
  }
`;

export const Main = styled.div`
  margin-top: 5px;
`;

export const AlignSpace = styled.div`
  padding: 24px 0 0 0;

  @media (max-width: 425px) {
    padding: 40px 0 0 0;
  }
`;

export const SpaceFooter = styled.div`
  padding: 30px;
`;

export const ContainerReport = styled.div`
  margin-top: 8px;
  padding: 0 0 0 120px;

  @media (max-width: 425px) {
    padding: 0 0 0 32px;
  }

  @media (max-width: 320px) {
    padding: 0;
  }
`;

export const SquareReport = styled.div`
  height: auto;
  width: 100%;
  background: ${paletteColor.primary.white};
  box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
  margin-bottom: 10px;

  @media (max-width: 1024px) {
    width: 85%;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const AlignTextAndDocImg = styled.div`
  display: flex;
`;

export const DocImg = styled.div`
  padding: 8px 0 0 24px;

  @media (max-width: 320px) {
    padding: 8px 0 0 16px;
  }
`;

export const Title = styled.div`
  width: 464px;
  height: auto;
  font-size: 14px;
  line-height: 164.06%;
  letter-spacing: 0.03em;
  color: ${paletteColor.info.text};

  @media (max-width: 768px) {
    width: 350px;
  }

  @media (max-width: 425px) {
    width: 200px;
  }

  @media (max-width: 320px) {
    width: 170px;
  }
`;

export const SubTitle = styled.div`
  width: 464px;
  height: auto;
  font-size: 12px;
  line-height: 20px;
  color: ${paletteColor.info.soft};
  padding: 6px 0 0px 0px;

  @media (max-width: 768px) {
    width: 350px;
  }

  @media (max-width: 425px) {
    width: 200px;
  }

  @media (max-width: 320px) {
    width: 170px;
  }
`;

export const MobileText = styled.div`
  @media (max-width: 425px) {
    padding: 0 1px 0 1px;
  }
`;

export const ContainerText = styled.div`
  padding: 0 0 0 16px;
`;

export const ButtonDownloadImg = styled(Button)`
  && {
    width: 32px;
    height: 32px;
    background: ${paletteColor.info.background};
    box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
    border-radius: 50%;
    right: 25px;
  }

  &.MuiButton-root {
    min-width: 0px;
  }
`;

export const EmptyState = styled.div`
  ${effects.slideUp};
  color: ${paletteColor.info.soft};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;

  & .title {
    font-weight: 500;
    margin-top: 20px;
  }
`;
