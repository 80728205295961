const getLastYearBillingForWorkspace = `
query getLastYearBilling ($workspaceCode: String!, $account: String!) {
  getLastYearBilling(workspaceCode:$workspaceCode account:$account) {
    ResultsByTime {
      TimePeriod {
        Start
        End
      }
      Total {
        BlendedCost {
          Amount
          Unit
        }
        UnblendedCost {
          Amount
          Unit
        }
      }
    }
  }
}
`;

const getBillingByServiceForWorkspace = `
query getBillingByService ($workspaceCode: String!, $account: String!) {
  getBillingByService(workspaceCode:$workspaceCode account:$account) {
    ResultsByTime {
      Groups {
        Keys
        Metrics {
          BlendedCost {
            Amount
            Unit
          }
          UnblendedCost {
            Amount
            Unit
          }
        }
      }
    }
  }
}
`;

const getServiceBillingByRegionForWorkspace = `
query getServiceBillingByRegion ($workspaceCode: String!, $serviceName: String!, $period: String!, $account: String!) {
  getServiceBillingByRegion(workspaceCode: $workspaceCode serviceName:$serviceName period:$period account:$account) {
    ResultsByTime {
      TimePeriod {
        Start
        End
      }
      Total {
        BlendedCost {
          Amount
          Unit
        }
        UnblendedCost {
          Amount
          Unit
        }
      }
      Groups {
        Keys
        Metrics {
          BlendedCost {
            Amount
            Unit
          }
          UnblendedCost {
            Amount
            Unit
          }
        }
      }
      Estimated
    }
  }
}
`;

const getLastYearBillingForService = `
query getLastYearBillingForService ($workspaceCode: String!, $serviceName: String!, $account: String!) {
  getLastYearBillingForService(workspaceCode: $workspaceCode serviceName: $serviceName account: $account) {
    ResultsByTime {
      TimePeriod {
        Start
        End
      }
      Total {
        BlendedCost {
          Amount
          Unit
        }
        UnblendedCost {
          Amount
          Unit
        }
      }
    }
  }
}
`;
const getBillingServicesForMonth = `
query getBillingServicesForMonth ($workspaceCode: String!, $period: String!, $account: String!) {
  getBillingServicesForMonth(workspaceCode: $workspaceCode period:$period account: $account) {
    ResultsByTime {
      TimePeriod {
        Start
        End
      }
      Groups {
        Keys
        Metrics {
          BlendedCost {
            Amount
            Unit
          }
          UnblendedCost {
            Amount
            Unit
          }
        }
      }
    }
  }
}
`;

const awsAccounts = `
query awsAccounts {
  awsAccounts {
    id
    alias
  }
}
`;

export default {
  getLastYearBillingForWorkspace,
  getBillingByServiceForWorkspace,
  getServiceBillingByRegionForWorkspace,
  getLastYearBillingForService,
  getBillingServicesForMonth,
  awsAccounts,
};
