import { IWorklogIssue } from 'modules/support/types';
import { IWorklog } from './types';

export const sumWorklogsByIssue = (worklogs: IWorklog[]): IWorklog[] => {
  const mappingAndSum = (
    issueWorklogs: Map<IWorklogIssue['key'], IWorklog>,
    worklog: IWorklog
  ) => {
    const previousWorklog = issueWorklogs.get(worklog.issue.key);
    if (previousWorklog) {
      issueWorklogs.set(worklog.issue.key, {
        ...previousWorklog,
        timeSpentSeconds:
          previousWorklog.timeSpentSeconds + worklog.timeSpentSeconds,
      });
    } else {
      issueWorklogs.set(worklog.issue.key, { ...worklog });
    }
    return issueWorklogs;
  };

  return Array.from(
    worklogs
      .filter((w: IWorklog) => w.issue)
      .reduce(mappingAndSum, new Map<IWorklogIssue['key'], IWorklog>())
      .values()
  );
};
