import gql from 'graphql-tag';

export const executePreventive = gql`
  mutation executePreventives(
    $cd: String!
    $code: String!
    $workspaceName: String!
    $project: String!
    $preventives: [PreventiveInput]!
    $regions: [String]!
    $accounts: [String]!
  ) {
    executePreventives(
      cd: $cd
      code: $code
      workspaceName: $workspaceName
      project: $project
      preventives: $preventives
      regions: $regions
      accounts: $accounts
    ) {
      log
      account
      preventive {
        name
      }
      result {
        errors
        warnings
        error {
          code
        }
      }
    }
  }
`;

export const awsAccounts = gql`
  query awsAccounts {
    awsAccounts {
      id
      alias
    }
  }
`;

export const generateReport = gql`
  query generateReport(
    $code: String!
    $type: ReportType!
    $data: String!
    $jiraProject: String!
  ) {
    generateReport(
      code: $code
      type: $type
      data: $data
      jiraProject: $jiraProject
    ) {
      data
    }
  }
`;

export const getProjects = gql`
  query projects($workspace: String!) {
    projects(workspace: $workspace, includeInternal: true) {
      key
    }
  }
`;
