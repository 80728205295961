import gql from 'graphql-tag';
import * as React from 'react';
import { compose, graphql } from 'react-apollo';
import { queries } from '../graphql';

type Props = {
  currentWorkspacesQuery: any;
};

const withcurrentWorkspace = (Component: any) => {
  const Container = (props: Props) => {
    const { currentWorkspacesQuery } = props;

    if (currentWorkspacesQuery.loading) {
      return null;
    }

    const updatedProps = {
      ...props,
      userLogged: currentWorkspacesQuery.loggedUser,
      currentWorkspace: currentWorkspacesQuery.workspaces,
    };

    return <Component {...updatedProps} />;
  };

  return compose(
    graphql(gql(queries.currentWorkspaces), {
      name: 'currentWorkspacesQuery',
    })
  )(Container);
};

export default withcurrentWorkspace;
