import React from 'react';
import { AppContext } from 'appContext';

const withAppContext = (Component) => {
  return (props) => {
    return (
      <AppContext.Consumer>
        {(contextProps) => <Component {...props} {...contextProps} />}
      </AppContext.Consumer>
    );
  };
};

export { withAppContext };
