import * as React from 'react';
import { css, effects, styled } from '@cmp/ui';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import theme from 'modules/common/styles/theme';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { IStyledProps } from 'modules/layout/types';
import { paletteColor } from 'modules/common/styles/colors';

const objectToString = ({ style }) => {
  let properties = '';
  if (style) {
    for (const [key, value] of Object.entries(style)) {
      properties += `${key}: ${value};`;
    }
  }
  return properties;
};

const CustomTooltip = styled(({ title, className, ...other }: any) => (
  <Tooltip
    placement="bottom-start"
    classes={{ popper: className, tooltip: 'tooltip' }}
    title={title}
    {...other}
  />
))`
  && {
    & .tooltip {
      background: ${paletteColor.info.text};
    }
  }
`;

const MenuSelect = styled((props: any) => (
  <Menu {...props} open={props.open} classes={{ paper: 'menu-paper' }} />
))`
  && {
    & .menu-paper {
      padding: 0 8px;
      margin-top: 8px;
      box-shadow: 0px 4px 6px rgba(223, 225, 235, 0.5);
    }
  }
`;

const MenuItemSelect = styled((props: any) => <MenuItem {...props} />)`
  && {
    position: relative;
    font-weight: 500;
    font-size: 12px;
    color: ${paletteColor.info.soft};
    padding: 8px;
    border-radius: 3px;
    overflow: inherit;
    &:not(:first-child) {
      margin-top: 8px;
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:hover,
    &:active {
      background: rgba(242, 101, 34, 0.1);
      color: ${paletteColor.primary.main};
    }
    &:not(:last-child):before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 4px);
      border-bottom: 1px solid ${paletteColor.info.border};
    }
  }
`;

const Fake = styled.div`
  border-radius: 3px;
  width: ${(props: IStyledProps) => props.width}px;
  height: ${(props: IStyledProps) => props.height}px;
  margin-bottom: ${(props: IStyledProps) => props.mb}px;
  ${effects.loader};
  background-color: ${paletteColor.info.fill};
  background: linear-gradient(
    270deg,
    ${paletteColor.info.fill},
    ${paletteColor.info.background},
    ${paletteColor.info.fill}
  );
  background-size: 200% 100%;
`;

const CustomTooltipDescription = styled(Typography)`
  && {
    padding: 8px;
    color: ${paletteColor.primary.white};
  }
`;

const BoardRoot = styled.div`
  display: flex;
  width: 100%;
  margin-top: 50px;

  & > .container {
    width: 100%;

    & .grid-content-drawer {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;

      & > .content {
        width: 100%;
      }
    }

    @media only screen and (max-width: 425px) {
      & .grid-content-drawer {
        flex-direction: column;
      }
    }
  }
`;

const FilterContent = styled.div`
  display: flex;
  height: 42px;
  margin: 0px 16px 8px 0px;
  margin: 70px 0 30px 0;

  ${theme.breakpoints.down('xs')} {
    margin: 0px 0px 8px 0px;
  }

  & .sprint-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }
  & .time-spent-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 14px;

    & .time-spent-title {
      font-weight: 500;
      font-size: 12px;
      color: ${paletteColor.info.soft};
      text-transform: initial;
    }
    & .time-spent-text {
      font-size: 14px;
      color: ${paletteColor.info.text};
    }
  }
`;

const BoardContent = styled.div`
  flex-grow: 1;
  padding: 15px 30px;
  width: 100%;
`;

export const BoardColumnContainer = styled.div`
  width: calc(100% + 8px);
  margin: -4px;
  display: flex;
  box-sizing: border-box;
  overflow-x: auto;
`;

export const BoardColumnItem = styled.div`
  padding: 4px 4px;
  max-width: 85%;
  flex-basis: 85%;

  ${theme.breakpoints.up('sm')} {
    flex-grow: 0;
    max-width: 20%;
    flex-basis: 20%;
  }
`;

const BoardColumn = styled.div`
  background-color: ${paletteColor.info.fill};
  border-radius: 10px;
  padding: 8px;
  padding-right: 4px;
  height: calc(100vh - 250px);

  @media only screen and (max-width: 425px) {
    min-width: 240px;
  }
  & .content {
    overflow-y: auto;
    padding-right: 4px;
    height: 100%;
    max-height: 100%;
  }
`;

const EmptyText = styled.div`
  ${effects.slideUp};
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  & .image {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
    ${effects.shake};
    animation-delay: 500ms;
  }
  & .title {
    margin-bottom: 8px;
    font-weight: 500;
  }
  & .title,
  & .text {
    color: ${paletteColor.info.soft};
    font-size: 12px;
  }
`;

const Card = styled.div`
  &:hover {
    cursor: pointer;
  }
  &.animation {
    ${effects.slideUp};
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  & a {
    text-decoration: none;
  }
  & .content {
    padding: 10px;
    background: ${paletteColor.primary.white};
    box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
    border-radius: 6px;
  }
  & .title-content {
    position: relative;
    margin-bottom: 4px;
    & span {
      content: ' ';
      position: absolute;
      width: 3px;
      height: 18px;
      left: -10px;
      border-radius: 0px 3px 3px 0px;
      z-index: 0;
    }
    & .title {
      font-weight: 500;
      font-size: 12px;
      color: ${paletteColor.info.soft};
    }
  }
  & .description {
    font-size: 14px;
    color: ${paletteColor.info.text};
    word-break: break-word;
  }
`;

const CardRow = styled.div`
  display: flex;
  align-items: center;
  background: ${paletteColor.primary.white};
  box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 8px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
  &.animation {
    ${effects.slideUp};
  }

  & .content-align {
    display: flex;
    align-items: center;
  }
  & .priority-content {
    margin-right: 24px;
    width: 12px;
    height: 12px;
    & .priority {
      width: 12px;
      height: 12px;
      border-radius: 20px;
    }
  }
  & .information-content {
    display: flex;
    flex-direction: column;
    & .type {
      font-weight: 500;
      font-size: 12px;
      color: ${paletteColor.info.soft};
    }
    & .summary {
      font-size: 14px;
      color: ${paletteColor.info.text};
    }
  }
  & .pull-right {
    margin-left: auto;
  }

  @media (max-width: 768px) {
    display: block;

    & .pull-right {
      margin-top: 15px;
    }
  }
`;

const FilterButton = styled(Button)`
  && {
    height: 42px;
    min-width: 222px;
    border: 1px solid ${paletteColor.info.border};
    border-radius: 3px;
    text-transform: none;
    font-size: 14px;
    color: ${paletteColor.info.soft};
    background: ${paletteColor.primary.white};

    &:hover {
      background: ${paletteColor.primary.white};
    }

    & .MuiTypography-body1 {
      margin-right: 33px;
    }

    &:before {
      content: '';
      position: absolute;
      right: 18px;
      top: 14px;
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: ${paletteColor.info.soft};
      border-width: 0px 2px 2px 0px;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      position: absolute;
      border-left: 1px solid ${paletteColor.info.border};
      right: 40px;
      top: 8px;
      height: 24px;
    }
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
`;

const Badge = styled(({ lastChild, margins, ...other }) => <span {...other} />)`
  background: ${paletteColor.info.fill};
  border-radius: 3px;
  color: ${paletteColor.info.soft};
  padding: 6px 8px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  ${({ margins }) => margins && margins}

  &:last-child {
    ${({ lastChild }) => (lastChild ? lastChild : 'margin-left: 8px;')}
  }
`;

const TitleColumn = styled(({ style, ...other }: any) => <div {...other} />)`
  display: flex;
  align-items: center;
  padding: 10px 0;
  ${objectToString}

  ${theme.breakpoints.down('xs')} {
    display: flex;
  }

  ${({ id }) => {
    if (id && id === 'subtasks-list-title') {
      return `
        ${theme.breakpoints.down('xs')} {
          display: none;
        }
      `;
    }
  }}

  & .title {
    font-weight: 500;
    font-size: 14px;
    color: ${paletteColor.info.soft};
    margin-right: 8px;
  }
  & .icon {
    font-size: 18px;
    color: ${paletteColor.info.border};
    transition: color 500ms;
    &:hover {
      color: ${paletteColor.primary.main};
      cursor: help;
    }
  }
`;

const Drawer = styled.div`
  position: sticky;
  top: 0;
  width: 330px;
  max-width: 330px;
  transition: width 500ms;
  margin-left: 30px;

  @media only screen and (max-width: 425px) {
    margin: 0;
    width: 100%;
    max-width: 425px;
  }

  & .header {
    display: flex;
    align-items: center;
    height: 50px;
    background: ${paletteColor.primary.white};
    border-top: 1px solid ${paletteColor.info.border};
    border-left: 1px solid ${paletteColor.info.border};
    border-right: 1px solid ${paletteColor.info.border};

    & .title {
      font-size: 14px;
      color: ${paletteColor.info.text};
      margin-left: 14px;
    }
  }
  & .container {
    background: ${paletteColor.info.fill};
    padding: 14px;
    & .content {
      &:not(:first-child) {
        margin-top: 14px;
      }
      & > .title {
        font-weight: 500;
        font-size: 14px;
        color: ${paletteColor.info.soft};
        margin-bottom: 14px;
      }
      & .box {
        border-radius: 6px;
        background: ${paletteColor.primary.white};
        box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
        & .row {
          padding: 12px;
          &:not(:last-child) {
            border-bottom: 1px solid ${paletteColor.info.fill};
          }
          & .title {
            font-weight: 500;
            font-size: 12px;
            color: ${paletteColor.info.soft};
          }
          & .date {
            font-size: 14px;
            color: ${paletteColor.info.text};
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: auto;
    margin-bottom: 15px;
  }

  @media (max-width: 320px) {
    margin-left: 0;
  }
`;

export const NavButtonGroup = styled(ButtonGroup)`
  background: ${paletteColor.primary.white};
  box-sizing: border-box;
  border-radius: 3px;
  height: 42px;
`;

export const NavButton = styled(Button)`
  && {
    border: 1px solid ${paletteColor.info.border} !important;
    color: ${paletteColor.info.soft};
    &:after {
      content: '';
      background: ${paletteColor.primary.main};
      height: 2px;
      width: 0;
      position: absolute;
      bottom: 0;
      left: -30%;
      transform: translateX(50%);
      border-radius: 3px 3px 0 0;
      transition: width 250ms;
    }
    ${(props) =>
      props.selected &&
      css`
        color: ${paletteColor.info.text};
        &:after {
          width: 72%;
          left: -22%;
        }
      `}
  }
`;

export const ViewTypeRoot = styled.div`
  margin-left: 20px;

  @media only screen and (max-width: 425px) {
    max-width: 135px;
  }

  @media only screen and (max-width: 768px) {
    &:last-child {
      margin-bottom: 15px;
    }
  }
`;

export const TypographyCustom = styled(Typography)`
  padding: 0 16px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    &:first-child {
      padding-left: 0;
    }
  }

  @media only screen and (max-width: 425px) {
    padding: 5px 0;
  }
`;

const InfoContainer = styled.div`
  text-align: center;
  color: ${paletteColor.info.text};
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & .title-info {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.04em;
    line-height: 24px;
  }
  & .text-info {
    color: ${paletteColor.info.soft};
    font-weight: 500;
    letter-spacing: 0.03em;
    font-size: 12px;
    line-height: 14px;
    max-width: 250px;
  }
`;

const OtherTasks = styled(Typography)`
  && {
    font-size: 14px;
    display: flex;
    color: ${paletteColor.info.soft};
    font-weight: 500;
    padding: 19px 0px;
  }
`;

const TitleSubTasks = styled(Typography)`
  && {
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const KeySubTasks = styled(Typography)`
  && {
    font-weight: 500;
    white-space: nowrap;
    padding: 0 10px;
  }
`;

const KeyContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
`;

const TitleContainer = styled.div`
  width: 100%;
  max-width: 690px;

  ${theme.breakpoints.up('xl')} {
    max-width: 940px;
  }

  ${theme.breakpoints.down('md')} {
    max-width: 450px;
  }

  ${theme.breakpoints.down('sm')} {
    max-width: 350px;
  }

  ${theme.breakpoints.down('xs')} {
    max-width: 120px;
  }
`;

const ExpansionPanelCustom = styled(ExpansionPanel)`
  && {
    background: ${paletteColor.primary.white};
    border-radius: 6px;
    box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
    margin: 8px 0;

    &:before {
      content: '';
      background-color: transparent;
    }

    ${theme.breakpoints.down('xs')} {
      border-radius: 6px 0px 0px 6px;
      max-height: 70px;
    }
  }

  &&.Mui-expanded {
    margin: 0;
    box-shadow: none;

    ${theme.breakpoints.down('xs')} {
      background: transparent;
      min-height: 70px;
      max-height: none;
    }
  }

  & .MuiExpansionPanelSummary-expandIcon {
    transform: rotate(-90deg);
  }

  & .MuiExpansionPanelSummary-expandIcon.Mui-expanded {
    transform: rotate(0);
  }

  & .MuiExpansionPanelSummary-content {
    justify-content: space-between;

    ${theme.breakpoints.down('xs')} {
      overflow-y: auto;
    }
  }

  & .MuiExpansionPanelSummary-root {
    border-radius: 6px;

    ${theme.breakpoints.down('md')} {
      min-height: 48px;
    }

    ${theme.breakpoints.down('xs')} {
      min-height: 48px;
      margin: 16px 0;
    }
  }

  & .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 48px;
    box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);

    ${theme.breakpoints.down('xs')} {
      border-radius: 6px 0px 0px 6px;
      min-height: 70px;
    }
  }
`;

const PanelDetailsCustom = styled(ExpansionPanelDetails)`
  && {
    background: #fbfbfd;
    padding: 0px 0px 16px;
  }
`;

const ExpansionPanelSummaryCustom = styled(ExpansionPanelSummary)`
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 40px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${theme.breakpoints.up('sm')} {
    min-width: 125px;
  }

  ${theme.breakpoints.down('xs')} {
    margin-right: 8px;
  }
`;

const Square = styled.div`
  background-color: ${paletteColor.info.fill};
  border-radius: 3px;
  padding: 0 8px;
  min-width: 125px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${paletteColor.info.soft};
  min-height: 24px;
  line-height: 24px;

  ${theme.breakpoints.down('sm')} {
    margin: 8px;
  }
`;

export const Styled = {
  CustomTooltip,
  CustomTooltipDescription,
  BoardRoot,
  BoardContent,
  BoardColumnContainer,
  BoardColumnItem,
  BoardColumn,
  EmptyText,
  Card,
  CardRow,
  FilterButton,
  BadgeContainer,
  Badge,
  TitleColumn,
  Fake,
  MenuSelect,
  MenuItemSelect,
  Drawer,
  NavButtonGroup,
  NavButton,
  FilterContent,
  ViewTypeRoot,
  InfoContainer,
  OtherTasks,
  ExpansionPanelCustom,
  PanelDetailsCustom,
  TitleSubTasks,
  ExpansionPanelSummaryCustom,
  FlexContainer,
  Square,
  KeySubTasks,
  KeyContainer,
  TitleContainer,
};

export default Styled;
