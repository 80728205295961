import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import * as React from 'react';
import { timeSpentToString } from 'modules/common/utils/worklog';
import S from './styled';
import { IIssue } from 'modules/support/types';

export default function RowWorklog({
  timeSpent,
  issueId,
  issueKey,
  startedAt,
  index,
  summary,
  issueModalHandler,
  pageLastIndex,
  currentPage,
  isJira,
}: {
  timeSpent: number;
  issueId: IIssue['id'];
  issueKey: IIssue['issueKey'];
  startedAt: string;
  index: number;
  summary: string;
  issueModalHandler: (issueId: IIssue['id'], isJira?: string) => void;
  pageLastIndex: number[];
  currentPage: number;
  isJira?: string;
}) {
  return (
    <S.CardTask
      style={{
        animationDelay: `${(() => {
          if (currentPage > 1) {
            if (index < pageLastIndex[currentPage - 1]) {
              return 0;
            }
            return (index % pageLastIndex[currentPage - 1]) * 0.1;
          }
          return index * 0.1;
        })()}s`,
      }}
      onClick={() => issueModalHandler(issueId, isJira ? isJira : '')}
      className="card-task joyride__card-task"
    >
      <Typography component="h3" className="task-issue-key">
        {issueKey}
      </Typography>
      <Typography component="p" className="task-issue-summary">
        {summary}
      </Typography>
      <div className="task-pull-right">
        <Typography component="h3" className="task-time-spent">
          {timeSpentToString(timeSpent, true)}
        </Typography>
        <Typography component="h3" className="task-started">
          {moment(startedAt).format('DD/MM')}
        </Typography>
        <div className="task-icon-content">
          <Icon className="task-icon-arrow">chevron_right</Icon>
        </div>
      </div>
    </S.CardTask>
  );
}
