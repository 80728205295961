import * as React from 'react';
import EmptyPage from '../../common/components/EmptyState';
import image from 'modules/common/images/empty_cmdb.png';

const EmptyState = () => (
  <EmptyPage
    title="Matriz de Resiliência"
    description="A Matriz de Resiliência é um serviço que permite criar um inventário de todos os recursos do seu ambiente, sejam serviços ou aplicações."
    image={image}
    readMoreURL="https://platform.mandic.com.br/portfolio/monitoramento/"
    hireURL="https://br.claranet.com/contatos?pgc=portal.cliente"
  />
);

export default EmptyState;
