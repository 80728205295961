import * as React from 'react';

export const MCP_PA_SCHEDULE = 'mcpProjetosAgeisSchedules';

export const MCP_SCHEDULE_TABLE_JOYRIDE_STEPS: any[] = [
  {
    target: '.cronograma',
    content: (
      <p>
        Essa tabela mostra informações de início, fim e duração dos épicos do
        projeto selecionado, permitindo um acompanhamento mais prático de suas
        tarefas.
      </p>
    ),
    title: 'Cronograma',
    locale: {
      last: <span>Finalizar</span>,
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
  {
    target: '.arrow',
    content: (
      <p>
        Você pode ordenar a tabela por início, fim ou duração clicando nas setas
        ao lado da coluna no cabeçalho.
      </p>
    ),
    title: 'Cronograma',
    locale: {
      last: <span>Finalizar</span>,
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
];

export const MCP_SCHEDULE_TABLE_DURATION_STEPS: any[] = [
  {
    target: '.duration',
    content: (
      <p>
        Quando definidas as datas de início e fim de um épico podemos calcular e
        exibir sua duração em dias.
      </p>
    ),
    title: 'Cronograma',
    locale: {
      last: <span>Finalizar</span>,
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
];

export const MCP_SCHEDULE_TABLE_DEFINED_STEPS: any[] = [
  {
    target: '.defined',
    content: (
      <p>
        Quando definidas exibiremos as datas com os dias exatos de início e fim
        de uma tarefa.
      </p>
    ),
    title: 'Cronograma',
    locale: {
      last: <span>Finalizar</span>,
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
];

export const MCP_SCHEDULE_TABLE_UNDEFINED_STEPS: any[] = [
  {
    target: '.undefined',
    content: (
      <p>
        Caso um épico não possua suas datas de início e fim cadastradas
        exibiremos uma mensagem avisando que essas datas não foram definidas.
      </p>
    ),
    title: 'Cronograma',
    locale: {
      last: <span>Finalizar</span>,
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
];

export const MCP_SCHEDULE_GRAPH_JOYRIDE_STEPS: any[] = [
  {
    target: '.gantt',
    content: (
      <p>
        Esse gráfico mostra informações de início, fim e duração dos épicos do
        projeto selecionado, permitindo um acompanhamento mais visual de suas
        tarefas.
      </p>
    ),
    title: 'Cronograma',
    locale: {
      last: <span>Finalizar</span>,
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
];

export const MCP_SCHEDULE_GRAPH_DEFINED_STEPS: any[] = [
  {
    target: '.defined',
    content: (
      <p>
        Esta barra indica o progresso do seu épico de uma maneira simplificada
        mês a mês. Ela é interativa, então você pode saber os detalhes dos dias
        exatos ao clicar sobre ela.
      </p>
    ),
    title: 'Cronograma',
    locale: {
      last: <span>Finalizar</span>,
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'bottom-end',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
];

export const MCP_SCHEDULE_GRAPH_UNDEFINED_STEPS: any[] = [
  {
    target: '.undefined',
    content: (
      <p>
        Caso um épico não possua suas datas de início e fim cadastradas a barra
        aparecera na cor cinza para indicar que sua duração e progresso não
        puderam ser calculados.
      </p>
    ),
    title: 'Cronograma',
    locale: {
      last: <span>Finalizar</span>,
      next: <span>Próximo</span>,
      skip: <span>Pular</span>,
      back: <span>Voltar</span>,
    },
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 5,
    spotlightClicks: true,
  },
];

export const getStepsJoyride = (scheduleType, filteredIssues) => {
  if (scheduleType === 'graph') {
    const extraSteps: any[] = [];

    if (filteredIssues.some((issue) => issue.duedate || issue.startdate)) {
      extraSteps.push(...MCP_SCHEDULE_GRAPH_DEFINED_STEPS);
    }

    if (filteredIssues.some((issue) => !issue.duedate || !issue.startdate)) {
      extraSteps.push(...MCP_SCHEDULE_GRAPH_UNDEFINED_STEPS);
    }

    return [...MCP_SCHEDULE_GRAPH_JOYRIDE_STEPS, ...extraSteps];
  } else {
    const extraSteps: any[] = [];

    if (filteredIssues.some((issue) => issue.duedate && issue.startdate)) {
      extraSteps.push(...MCP_SCHEDULE_TABLE_DURATION_STEPS);
    }

    if (filteredIssues.some((issue) => issue.duedate || issue.startdate)) {
      extraSteps.push(...MCP_SCHEDULE_GRAPH_DEFINED_STEPS);
    }

    if (filteredIssues.some((issue) => !issue.duedate || !issue.startdate)) {
      extraSteps.push(...MCP_SCHEDULE_GRAPH_UNDEFINED_STEPS);
    }

    return [...MCP_SCHEDULE_TABLE_JOYRIDE_STEPS, ...extraSteps];
  }
};
