import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';

type IProps = {
  count: number;
};

export default function FakeTable({ count }: IProps) {
  return (
    <>
      {Array.from(Array(count).keys()).map((_, key) => (
        <Skeleton key={key} height={56} />
      ))}
    </>
  );
}
