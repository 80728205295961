import React from 'react';
import * as S from './styled';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import { MCP_AWS_REGIONS_LIST } from 'modules/preventives/utils/constants';
import { getAwsRegionName } from 'modules/logs/utils/constants';
import client from 'apolloClient';
import { queries } from '../graphql';
import FakeRow from './FakeRow';
import { getItem } from 'utils/storage';

interface IProps {
  logIds: string[];
  handleClose: () => void;
  open: boolean;
  getLogsById: (workspace: string, id: string[]) => any;
  workspace: string;
  workspaceName: string;
}

const ReportHistory = ({
  logIds,
  handleClose,
  open,
  getLogsById,
  workspace,
  workspaceName,
}: IProps) => {
  const [logs, setLogs] = React.useState<any[]>([]);
  const [report, setReport] = React.useState<string>('');

  const generateReport = async (
    workspaceCode: string,
    workspaceName: string,
    logIds: string[]
  ): Promise<string> => {
    const project = getItem('projectSelected', 'localStorage');
    return client
      .query({
        query: queries.generateReport,
        variables: {
          code: workspaceCode,
          data: JSON.stringify({
            preventives: logIds,
            workspaceName,
          }),
          type: 'preventives',
          jiraProject: project!,
        },
        fetchPolicy: 'network-only',
      })
      .then((response) => response.data.generateReport.data)
      .catch((error) => Promise.reject(error.message));
  };

  const handleReport = () => {
    const pdfTab = window.open('about:blank', '_blank');
    if (pdfTab) {
      pdfTab.document.write(
        `<iframe width='100%' height='100%' src='${report}' frameBorder="0"></iframe>`
      );
      pdfTab.document.title = 'Resultados';
      pdfTab.focus();
    }
  };

  React.useEffect(() => {
    if (logIds.length) {
      getLogsById(workspace, logIds).then((logs) => setLogs(logs));
      generateReport(workspace, workspaceName, logIds).then((rprt) =>
        setReport(rprt)
      );
    } else {
      setLogs([]);
    }
  }, [getLogsById, workspace, workspaceName, logIds]);

  const DialogActionsPreventive = () => (
    <S.DialogActionsPreventive>
      <S.SmallButton
        variant="outlined"
        size="small"
        color="primary"
        className="btnExecute"
        onClick={() => {
          setLogs([]);
          handleClose();
        }}
      >
        Voltar
      </S.SmallButton>
      <S.mediumButton
        variant="contained"
        size="small"
        color="primary"
        className="btnExecute"
        onClick={handleReport}
        disabled={!logs.length}
        data-cy="generate-report-modal"
      >
        Gerar relatório
      </S.mediumButton>
    </S.DialogActionsPreventive>
  );

  return (
    <S.DialogExecute open={open}>
      <S.DialogTitleCustom data-cy="generate-report-header">
        <S.BoldText>Gerar relatório</S.BoldText>
      </S.DialogTitleCustom>

      <DialogContent>
        <S.AnalysisText>
          <S.BoldText>
            Você está prestes a gerar um relatório contendo as seguintes
            execuções de serviços:
          </S.BoldText>
        </S.AnalysisText>
        {!logs.length ? (
          <FakeRow count={5} />
        ) : (
          logs.map((log, index) => {
            const logData = JSON.parse(log.data);
            return (
              <ul key={`log_${index}`}>
                <li>
                  <S.BoldText>{logData.preventive.name}</S.BoldText>
                  <S.AnalysisText>
                    Conta da AWS:
                    {logData.account}
                  </S.AnalysisText>
                  <S.AnalysisText>
                    Região da cloud:
                    {logData.global
                      ? 'Global'
                      : getAwsRegionName(MCP_AWS_REGIONS_LIST, logData.region)}
                  </S.AnalysisText>
                  <S.AnalysisText>
                    Data e hora de execução:
                    {moment(log.timestamp.split('.')[0]).format(
                      'DD/MM/YYYY, hh[h]mm[min]'
                    )}
                  </S.AnalysisText>
                  <S.AnalysisText>
                    Usuário:
                    {log.username}
                  </S.AnalysisText>
                </li>
              </ul>
            );
          })
        )}
      </DialogContent>
      <DialogActionsPreventive />
    </S.DialogExecute>
  );
};

export default ReportHistory;
