import { IFullProject, IProject } from 'modules/common/types';
import * as React from 'react';
import CalendarPunctual from './CalendarPunctual';

interface IProps {
  projectSelected: IProject;
  getFullProjectById: (projectId: number) => Promise<IFullProject | null>;
}

const CalendarPunctualContainer = ({
  projectSelected,
  getFullProjectById,
}: IProps) => {
  return (
    <CalendarPunctual
      projectSelected={projectSelected}
      getFullProjectById={getFullProjectById}
    />
  );
};

export default CalendarPunctualContainer;
