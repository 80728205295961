import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { IPropsView, ISupportIssueResult } from '../types';
import { MCP_PA_TASKS_BOARDS } from '../utils/constants';
import BoardColumn from './BoardColumn';
import S from './styled';
import SubtasksBoard from './SubtasksBoard';

interface IProps extends IPropsView {
  hiddenTitleColumns?: boolean;
  subtaskBoard?: boolean;
}

const aggregation = (allPages: ISupportIssueResult[], currentPage) => [
  ...allPages,
  ...currentPage.issues,
];

const filterOnlyIssuesWithSubtasks = (issue: ISupportIssueResult) => {
  return issue.subtasks && issue.subtasks.length > 0;
};

const BoardView = ({
  loading,
  openIssueModal,
  hiddenTitleColumns,
  issuesByBoard,
  issuesTotalTime,
  loadIssuesForBoard,
  subtaskBoard,
  startDate,
  endDate,
}: IProps) => {
  const [columns, setColumns] = React.useState<JSX.Element[]>([]);

  const initialValue: ISupportIssueResult[] = [];
  const issuesWithSubtasks = Object.values(issuesByBoard)
    .reduce(aggregation, initialValue)
    .filter(filterOnlyIssuesWithSubtasks);

  const renderColumns = React.useCallback(() => {
    return MCP_PA_TASKS_BOARDS.map((board) => {
      const issuesPage = issuesByBoard[board.columnTitle];
      return (
        <S.BoardColumnItem
          key={`board_${board.columnTitle}`}
          item={true}
          xs={10}
          sm={2}
        >
          {issuesWithSubtasks.length ? (
            ''
          ) : (
            <S.TitleColumn
              style={{ display: hiddenTitleColumns ? 'none' : 'flex' }}
            >
              <Typography className="title">
                {board.columnTitle} {!loading && issuesPage.count}
              </Typography>
              <S.CustomTooltip
                title={
                  <S.CustomTooltipDescription>
                    {board.description}
                  </S.CustomTooltipDescription>
                }
              >
                <Icon className="icon">info</Icon>
              </S.CustomTooltip>
            </S.TitleColumn>
          )}

          <BoardColumn
            board={board}
            loading={loading}
            issuesPage={issuesPage}
            issueModalHandler={openIssueModal}
            loadIssuesForBoard={loadIssuesForBoard}
          />
        </S.BoardColumnItem>
      );
    });
  }, [
    issuesByBoard,
    loading,
    hiddenTitleColumns,
    openIssueModal,
    loadIssuesForBoard,
    issuesWithSubtasks.length,
  ]);

  React.useEffect(() => {
    setColumns(renderColumns());
  }, [renderColumns]);

  return (
    <>
      {!subtaskBoard ? (
        <>
          <SubtasksBoard
            loading={loading}
            issuesByBoard={issuesByBoard}
            startDate={startDate}
            endDate={endDate}
            openIssueModal={openIssueModal}
            issuesWithSubtasks={issuesWithSubtasks}
            loadIssuesForBoard={loadIssuesForBoard}
            issuesTotalTime={issuesTotalTime}
          />
          {issuesWithSubtasks.length ? (
            <S.OtherTasks>Demais Atividades</S.OtherTasks>
          ) : (
            []
          )}
        </>
      ) : (
        []
      )}
      <Grid className="grid-content-drawer">
        <div className="content">
          <div className="joyride__board">
            <S.BoardColumnContainer container={true} spacing={1}>
              {columns}
            </S.BoardColumnContainer>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default BoardView;
