import * as React from 'react';
import EmptyPage from '../../common/components/EmptyState';
import image from 'modules/common/images/empty_suport.png';

const EmptyState = () => (
  <EmptyPage
    title="Tarefas"
    description="A página de tarefas possuí um quadro no formato de Kanban que permite uma visualização rápida das atividades do seu Projeto."
    image={image}
    readMoreURL="https://platform.mandic.com.br/portfolio/projetos-ageis/"
    hireURL="https://br.claranet.com/contatos?pgc=portal.cliente"
  />
);

export default EmptyState;
