import { Input } from '@cmp/ui';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { ResourceType } from '../types';
import * as S from './styled';

interface IProps {
  resourceName: string;
  resourceVersion: string;
  resourceType: string;
  isDependency: boolean;
  setResourceName: (value: string) => void;
  setResourceVersion: (value: string) => void;
  setResourceType: (value: ResourceType) => void;
  setIsDependency: (value: boolean) => void;
  error: {
    field: string;
    message: string;
  };
}

const ResourceForm = ({
  error,
  resourceName,
  setResourceName,
  resourceVersion,
  setResourceVersion,
  resourceType,
  setResourceType,
  isDependency,
  setIsDependency,
}: IProps) => {
  const handleSetRow = () => {
    setResourceType('APPLICATION');
    setIsDependency(false);
  };

  const handleSetColumn = () => {
    setResourceType('SERVICE');
  };

  const handleSetRowAndColumn = () => {
    setResourceType('APPLICATION');
    setIsDependency(true);
  };

  return (
    <S.FormContent>
      <S.FormCustom>
        <Input
          type="text"
          value={resourceName}
          onChange={(event) => setResourceName(event.target.value)}
          fullWidth={true}
          text="Nome do recurso"
          required={true}
          mr={8}
          error={error.field === 'name' ? error.message : ''}
        />
        <Input
          type="text"
          value={resourceVersion}
          onChange={(event) => setResourceVersion(event.target.value)}
          text="Versão"
          size={80}
          required={true}
          error={error.field === 'version' ? error.message : ''}
        />
      </S.FormCustom>
      <Typography variant="button">
        Selecione a posição do recurso na tabela:
      </Typography>
      <S.FormCustom>
        <S.SelectorCustom>
          <S.SelectorButtomCustom
            fullWidth={true}
            selected={resourceType === 'APPLICATION' && !isDependency}
            onClick={handleSetRow}
            data-cy="btn-line"
          >
            Linha
          </S.SelectorButtomCustom>
          <S.SelectorButtomCustom
            fullWidth={true}
            selected={resourceType === 'SERVICE'}
            onClick={handleSetColumn}
            data-cy="btn-column"
          >
            Coluna
          </S.SelectorButtomCustom>
          <S.SelectorButtomCustom
            fullWidth={true}
            selected={resourceType === 'APPLICATION' && isDependency}
            onClick={handleSetRowAndColumn}
            data-cy="btn-both"
          >
            Ambos
          </S.SelectorButtomCustom>
        </S.SelectorCustom>
      </S.FormCustom>
    </S.FormContent>
  );
};

export default ResourceForm;
