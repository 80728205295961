import * as React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { withCurrentWorkspaces } from './modules/auth/hoc';
import { IUser, IWorkspace } from './modules/auth/types';
import { MainLayout } from './modules/layout/containers';
import BillingRoutes from './modules/billing/routes';
import CmdbRoutes from './modules/cmdb/routes';
import Preventives from './modules/preventives/routes';
import SupportRoutes from './modules/support/routes';
import WorklogRoutes from './modules/worklog/routes';
import WorkspaceRoutes from './modules/workspace/routes';
import ScheduleRoutes from './modules/schedule/routes';
import LogsRoutes from './modules/logs/routes';
// import PlansRoutes from './modules/plans/routes';
// import Finops from './modules/finops/routes';
import { StatusContainer } from './modules/status/containers';

interface IProps {
  currentWorkspace: IWorkspace[];
  userLogged: IUser;
}

const RedirectWorkspaces = () => (
  <Redirect
    to={{
      pathname: '/workspaces',
      state: { redirectUrl: '/worklogs' },
    }}
  />
);

const Routes = ({ currentWorkspace, userLogged }: IProps) => {
  return (
    <Router>
      <MainLayout userLogged={userLogged} workspaces={currentWorkspace}>
        <Switch>
          <Route exact={true} path="/" component={RedirectWorkspaces} />
          <Route exact={true} path="/workspaces" component={WorkspaceRoutes} />
          <Redirect
            from="/workspaces/:workspaceCode/:functionality"
            to="/:functionality"
          />
          <Redirect from="/worklog" to="/worklogs" />
          <Redirect from="/support" to="/tasks" />
          <Route path="/tasks" component={SupportRoutes} />
          <Route path="/worklogs" component={WorklogRoutes} />
          <Route path="/worklogs/survey/key/:id" component={WorklogRoutes} />
          <Route path="/schedule" component={ScheduleRoutes} />
          <Route path="/billing" component={BillingRoutes} />
          <Route path="/cmdb" component={CmdbRoutes} />
          <Route path="/preventives" component={Preventives} />
          <Route path="/logs" component={LogsRoutes} />
          {/* <Route path="/plans" component={PlansRoutes} /> */}
          {/* <Route path="/finops" component={Finops} /> */}
          <Route component={StatusContainer} />
        </Switch>
      </MainLayout>
    </Router>
  );
};

export default withCurrentWorkspaces(Routes);
