import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Title } from 'modules/common/components/Utils';
import * as React from 'react';
import { IServiceList } from '../types';
import { hoursToFixed } from '../utils';
import CardRow from './CardRow';
import * as S from './Styled';

interface IProps {
  serviceList: IServiceList[];
  openDrawer: (
    arrayIndex: number,
    serviceName: string
  ) => (event: React.MouseEvent<HTMLDivElement>) => void;
  selectedService: number | null;
}

export default function CardList(props: IProps) {
  const { serviceList } = props;

  const billingUnit = serviceList[0] ? serviceList[0].billingUnit : '';
  const servicesTotalValue = serviceList.reduce(
    (acc: number, current: IServiceList) => {
      return acc + current.value;
    },
    0
  );
  const checkSelectedServiceStatus = (
    selectedService: number | null,
    index: number
  ): boolean => {
    return selectedService === null ? true : props.selectedService === index;
  };

  return (
    <Box>
      <S.TitleMobile>
        <Title>Nuvem AWS</Title>
        <Title>Gasto Atual</Title>
      </S.TitleMobile>

      <S.Wrap>
        <Grid container={true} justify="flex-end" spacing={2}>
          <Grid item={true} xs={12}>
            {serviceList &&
              serviceList.map((item: IServiceList, index: number) => (
                <CardRow
                  key={index}
                  arrayIndex={index}
                  serviceName={item.serviceName}
                  value={item.value}
                  isSelected={props.selectedService === index}
                  highlightRow={checkSelectedServiceStatus(
                    props.selectedService,
                    index
                  )}
                  openDrawer={props.openDrawer}
                  hasProductImprovement={item.hasProductImprovement}
                  billingUnit={item.billingUnit}
                />
              ))}
          </Grid>
        </Grid>
        <Grid container={true} justify="flex-end" spacing={2}>
          <Grid item={true} md={5} xs={12}>
            <S.AlignTotalPrice>
              <S.AlertRowTotal>
                <S.TotalBilling>Total do período:</S.TotalBilling>
                <S.AlertCellTotal color="#E92641">
                  <Typography component="p" className="total-price">
                    {hoursToFixed(servicesTotalValue)} {billingUnit}
                  </Typography>
                </S.AlertCellTotal>
              </S.AlertRowTotal>
            </S.AlignTotalPrice>
          </Grid>
        </Grid>
      </S.Wrap>
    </Box>
  );
}
