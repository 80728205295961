import * as React from 'react';
import * as S from './styled';

interface IProps {
  selected: string;
}

const ButtonReportOurExecution = (props: IProps) => (
  <S.Main>
    <S.PageMenu>
      <S.FabCustom>
        <S.PageMenuItem
          to="/preventives/history"
          selected={props.selected === 'history'}
        >
          Execuções
        </S.PageMenuItem>
      </S.FabCustom>
      <S.FabCustom>
        <S.PageMenuItem
          to="/preventives/report"
          selected={props.selected === 'report'}
        >
          Relatórios
        </S.PageMenuItem>
      </S.FabCustom>
    </S.PageMenu>
  </S.Main>
);

export default ButtonReportOurExecution;
